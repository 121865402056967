import * as THREE from "three";
import Utils from "./utils";
import EdgeOutput, { EdgeType } from "./edgeOutput";
import Roof from "./roof";
import Edge from "./edge";

export default class LineKM {
  public static classifyRoofEdges(roof: Roof, contourEdges: Edge[]) {
    const baseLevel = contourEdges[0].startNode.point3D.z;

    for (const surface of roof.roofSurfaces) {
      if (surface.slope == 0) {
        // find one edge parallel to XY plane
        let lowestParallelToXyPlaneEdge: EdgeOutput = surface.edges.filter(e => Utils.ValuesAreCloseEnough(e.startPoint.z, e.endPoint.z))[0];

        // find the lowest edge that parallel to XY plane
        for (const edge of surface.edges) {
          if (edge.isEqual(lowestParallelToXyPlaneEdge)) continue;

          if (Utils.ValuesAreCloseEnough(edge.startPoint.z, edge.endPoint.z) && lowestParallelToXyPlaneEdge.startPoint.z > edge.startPoint.z)
            lowestParallelToXyPlaneEdge = edge;
        }
        lowestParallelToXyPlaneEdge.edgeType = EdgeType.EAVE_TO_WALL;

        // Mark the others as rake (or rake to wall?)
        for (const edge of surface.edges) {
          if (edge.isEqual(lowestParallelToXyPlaneEdge)) continue;
          edge.edgeType = EdgeType.RAKE;
        }
      } else {
        for (const edge of surface.edges) {
          const contoureEdgeLevel = baseLevel;
          const sce = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
          const ece = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);

          if (Utils.ValuesAreCloseEnough(edge.startPoint.z, edge.endPoint.z) && edge.startPoint.z > baseLevel + Utils.Tolerance) {
            edge.edgeType = EdgeType.RIDGE;
          } else if (Utils.ValuesAreCloseEnough(edge.startPoint.z, edge.endPoint.z) && Utils.ValuesAreCloseEnough(edge.startPoint.z, baseLevel)) {
            edge.edgeType = EdgeType.EAVE;
          } else if (
            !Utils.ValuesAreCloseEnough(edge.startPoint.z, edge.endPoint.z) &&
            LineKM.oneOfTwoPointsInConvexCorner(sce, ece, contourEdges, contoureEdgeLevel)
          ) {
            edge.edgeType = EdgeType.VALLEY;
          } else if (
            !edge.belongToMoreThanOneSurface(roof.roofSurfaces, true) &&
            (Utils.ValuesAreCloseEnough(edge.startPoint.x, edge.endPoint.x) || Utils.ValuesAreCloseEnough(edge.startPoint.y, edge.endPoint.y))
          ) {
            edge.edgeType = EdgeType.RAKE;
          } else {
            edge.edgeType = EdgeType.HIP;
          }
        }
      }
    }
  }

  static oneOfTwoPointsInConvexCorner(sp: THREE.Vector3, ep: THREE.Vector3, contourEdges: Edge[], baseLevel: number): boolean {
    let edge1: Edge = null;
    let edge2: Edge = null;
    if (Utils.ValuesAreCloseEnough(sp.z, baseLevel)) {
      [edge1, edge2] = Utils.getTwoEdges(sp, contourEdges);
    }
    if (Utils.ValuesAreCloseEnough(ep.z, baseLevel)) {
      [edge1, edge2] = Utils.getTwoEdges(ep, contourEdges);
    }

    if (edge1 != null && edge2 != null && !edge1.isTwoEdgesCreateConcaveCorner(edge2)) {
      return true;
    }

    return false;
  }
}

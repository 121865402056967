import { appModel } from "../../../models/AppModel";
import { ModifiedWallManager } from "../../managers/SceneManager/ModifiedWallManager";
import { Direction } from "../Direction";
import { soWall2D, WallSides } from "../SceneObjects/Wall/soWall2D";
import { WallOffset } from "../SceneObjects/Wall/WallOffset";
import { RoomCommand } from "./RoomCommand";

export class ChangeWallAlignmentCommand extends RoomCommand {
  private isLocked: boolean;
  private offset: number; // associative array with key of index (start.x ; start.y ; end.x ; end.y) and value of offset
  private oldWallOffset: WallOffset;
  constructor(
    private soWall: soWall2D,
    offset: number
  ) {
    super(soWall.userData.id);
    this.isLocked = soWall.userData.isLocked;
    this.offset = offset;
    this.oldWallOffset = null;
  }

  apply(): void {
    this.lockWall();
    this.oldWallOffset = this.soWall.wallOffset;
    if (this.offset === 0) this.soWall.resetWallLocation();
    else {
      const side = this.offset > 0 ? WallSides.left : WallSides.right;
      this.soWall.setWallOffset(new WallOffset(side, Math.abs(this.offset)));
    }
    this.soWall.ParentRooms.forEach(room => {
      const wallOverride = room.getWallOveride(this.soWall.wallId);
      wallOverride.Offset = this.soWall.wallOffset?.getAbsoleteDirectionOffset() ?? 0;
      room.wallOverrides.set(this.soWall.wallId, wallOverride);
    });
    appModel.addModifiedSegmentWall(this.soWall);
  }

  undo(): void {
    this.unlockWall();
    if (this.oldWallOffset) this.soWall.setWallOffset(this.oldWallOffset);
    else this.soWall.resetWallLocation();
    this.soWall.ParentRooms.forEach(room => {
      const wallOverride = room.getWallOveride(this.soWall.wallId);
      wallOverride.Offset = this.soWall.wallOffset?.getAbsoleteDirectionOffset() ?? 0;
      room.wallOverrides.set(this.soWall.wallId, wallOverride);
    });
    appModel.removeModifiedSegmentWall(this.soWall);
  }

  private lockWall(): void {
    this.soWall.userData.isLocked = true;
  }

  private unlockWall(): void {
    this.soWall.userData.isLocked = this.isLocked;
  }
}

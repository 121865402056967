import Utils from "./utils";
import NodeGraph from "./nodeGraph";

export default class EdgeGraph {
  public node1: NodeGraph;
  public node2: NodeGraph;

  constructor(node1: NodeGraph, node2: NodeGraph) {
    this.node1 = node1;
    this.node2 = node2;
  }

  public isEqual(other: EdgeGraph): boolean {
    if (!other) return false;
    return (
      Utils.ValuesAreCloseEnough(other.node1.point3D.x, this.node1.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.node1.point3D.y, this.node1.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.node1.point3D.z, this.node1.point3D.z) &&
      Utils.ValuesAreCloseEnough(other.node2.point3D.x, this.node2.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.node2.point3D.y, this.node2.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.node2.point3D.z, this.node2.point3D.z)
    );
  }

  /// <summary>
  /// gets the other side of the edge
  /// </summary>
  public otherNode(node: NodeGraph): NodeGraph {
    if (node.isEqual(this.node1)) return this.node2;
    return this.node1;
  }
}

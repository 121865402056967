import * as THREE from "three";
import Utils from "./utils";

import Node from "./node";
import Line3dEquation from "./line3dEquation";
import type Plane from "./plane";
import type Graph from "./graph";
import type RoofSurface from "./roofSurface";
import type RoofSurfaceOutput from "./roofSurfaceOutput";

export enum EdgeFacadeDir {
  UNKNOWN = 0,
  NORTH = 1,
  EAST = 2,
  SOUTH = 3,
  WEST = 4,
}

export default class Edge {
  public startNode: Node;
  public endNode: Node;
  public slope: number = 0.5;
  public depth: number;
  public isVertical: boolean;
  public isHorizontal: boolean;
  public direction: EdgeFacadeDir;
  public line3dEquation: Line3dEquation;
  public overhang: number = -1;
  public id: string | null = null;
  public name: string = "";

  /// <summary>
  /// If the edge is part of the contour of the roof it will be assigned a Plane, otherwise it will be null
  /// </summary>
  public plane: Plane;
  public roofSurface: RoofSurface;
  public graph: Graph;

  constructor(startNode: Node, endNode: Node) {
    this.startNode = startNode;
    this.endNode = endNode;

    this.isVertical = Utils.ValuesAreCloseEnough(startNode.point3D.x, endNode.point3D.x);
    this.isHorizontal = Utils.ValuesAreCloseEnough(startNode.point3D.y, endNode.point3D.y);

    const dir = this.endNode.point3D.clone();
    dir.sub(this.startNode.point3D);
    this.line3dEquation = new Line3dEquation(this.startNode.point3D, dir);
  }

  public isEqual(other: Edge) {
    if (!other) return false;

    return (
      Utils.ValuesAreCloseEnough(other.startNode.point3D.x, this.startNode.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.startNode.point3D.y, this.startNode.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.startNode.point3D.z, this.startNode.point3D.z) &&
      Utils.ValuesAreCloseEnough(other.endNode.point3D.x, this.endNode.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.endNode.point3D.y, this.endNode.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.endNode.point3D.z, this.endNode.point3D.z)
    );
  }

  public getMidPoint(): THREE.Vector3 {
    // TODO: Avoid an extra addition/subtraction
    const midX = (this.endNode.point3D.x - this.startNode.point3D.x) / 2 + this.startNode.point3D.x;
    const midY = (this.endNode.point3D.y - this.startNode.point3D.y) / 2 + this.startNode.point3D.y;
    return new THREE.Vector3(midX, midY, this.startNode.point3D.z);
  }

  public isInConcaveCorner(contourEdges: Edge[]) {
    const edge1 = this.getSecondEdgeThatContainNodeOutOfEdges(this.startNode, contourEdges);
    const edge2 = this.getSecondEdgeThatContainNodeOutOfEdges(this.endNode, contourEdges);
    if (!edge1 || !edge2) return false;

    return this.isTwoEdgesCreateConcaveCorner(edge1) && this.isTwoEdgesCreateConcaveCorner(edge2);
  }

  private getSecondEdgeThatContainNodeOutOfEdges(node: Node, contourEdges: Edge[]): Edge | null {
    for (const edge of contourEdges) {
      if (this.isEqual(edge)) continue;

      if (node.isEqual(edge.startNode) || node.isEqual(edge.endNode)) return edge;
    }
    return null;
  }

  public isTwoEdgesCreateConcaveCorner(edge: Edge): boolean {
    if (this.isVertical) {
      if (this.startNode.isEqual(edge.startNode) && edge.isHorizontal && this.direction == EdgeFacadeDir.WEST && edge.direction == EdgeFacadeDir.SOUTH) {
        return true;
      } else if (this.endNode.isEqual(edge.endNode) && edge.isHorizontal && this.direction == EdgeFacadeDir.EAST && edge.direction == EdgeFacadeDir.NORTH) {
        return true;
      } else if (this.startNode.isEqual(edge.endNode) && edge.isHorizontal && this.direction == EdgeFacadeDir.EAST && edge.direction == EdgeFacadeDir.SOUTH) {
        return true;
      } else if (this.endNode.isEqual(edge.startNode) && edge.isHorizontal && this.direction == EdgeFacadeDir.WEST && edge.direction == EdgeFacadeDir.NORTH) {
        return true;
      }
    } else if (this.isHorizontal) {
      if (this.startNode.isEqual(edge.startNode) && edge.isVertical && this.direction == EdgeFacadeDir.SOUTH && edge.direction == EdgeFacadeDir.WEST) {
        return true;
      } else if (this.endNode.isEqual(edge.endNode) && edge.isVertical && this.direction == EdgeFacadeDir.NORTH && edge.direction == EdgeFacadeDir.EAST) {
        return true;
      } else if (this.endNode.isEqual(edge.startNode) && edge.isVertical && this.direction == EdgeFacadeDir.SOUTH && edge.direction == EdgeFacadeDir.EAST) {
        return true;
      } else if (this.startNode.isEqual(edge.endNode) && edge.isVertical && this.direction == EdgeFacadeDir.NORTH && edge.direction == EdgeFacadeDir.WEST) {
        return true;
      }
    }
    return false;
  }

  /// <summary>
  /// return the the full line edge that contain input partialEdge
  /// </summary>
  public static getEdgeThatContainsPartialContinousEdges(partialEdge: Edge, roofEdges: Edge[]): Edge | null {
    for (const roofEdge of roofEdges) {
      if (roofEdge.isVertical != partialEdge.isVertical) continue;

      // TODO: This needlessly computes the midpoint multiple times...
      if (
        partialEdge.isVertical &&
        Utils.ValuesAreCloseEnough(roofEdge.startNode.point3D.x, partialEdge.startNode.point3D.x) &&
        ((partialEdge.getMidPoint().y <= roofEdge.endNode.point3D.y && partialEdge.getMidPoint().y >= roofEdge.startNode.point3D.y) ||
          (partialEdge.getMidPoint().y >= roofEdge.endNode.point3D.y && partialEdge.getMidPoint().y <= roofEdge.startNode.point3D.y))
      ) {
        return roofEdge;
      }
      if (
        partialEdge.isHorizontal &&
        Utils.ValuesAreCloseEnough(roofEdge.startNode.point3D.y, partialEdge.startNode.point3D.y) &&
        ((partialEdge.getMidPoint().x <= roofEdge.endNode.point3D.x && partialEdge.getMidPoint().x >= roofEdge.startNode.point3D.x) ||
          (partialEdge.getMidPoint().x >= roofEdge.endNode.point3D.x && partialEdge.getMidPoint().x <= roofEdge.startNode.point3D.x))
      ) {
        return roofEdge;
      }
    }

    return null;
  }

  /// <summary>
  /// Creates an Edge object connecting two vertices, using the Z values from the original surfaces.
  /// </summary>
  /// <param name="startVertex">The starting vertex of the edge.</param>
  /// <param name="endVertex">The ending vertex of the edge.</param>
  /// <param name="originalSurfaces">The original list of roof surfaces for reference.</param>
  /// <returns>A new Edge object representing the connection between the two vertices.</returns>
  public static createEdge(startVertex: THREE.Vector2, endVertex: THREE.Vector2, originalSurfaces: RoofSurfaceOutput[]): Edge {
    const startNode = new Node(Utils.getClosestPoint3D(startVertex, originalSurfaces));
    const endNode = new Node(Utils.getClosestPoint3D(endVertex, originalSurfaces));
    return new Edge(startNode, endNode);
  }

  public static getBoundingBox(contourEdges: Edge[]) {
    let minX = Number.MAX_VALUE;
    let minY = Number.MAX_VALUE;
    let maxX = -Number.MAX_VALUE;
    let maxY = -Number.MAX_VALUE;
    for (const edge of contourEdges) {
      let point = edge.startNode.point3D;
      if (point.x < minX) minX = point.x;
      if (point.x > maxX) maxX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.y > maxY) maxY = point.y;

      point = edge.endNode.point3D;
      if (point.x < minX) minX = point.x;
      if (point.x > maxX) maxX = point.x;
      if (point.y < minY) minY = point.y;
      if (point.y > maxY) maxY = point.y;
    }

    // Don't do this... it's not even correct, since it doesn't check the end point for max...
    /*
        const minX = contourEdges.OrderBy(o => o.StartNode.Point3D.X).ToList().First().StartNode.Point3D.X;
        const minY = contourEdges.OrderBy(o => o.StartNode.Point3D.Y).ToList().First().StartNode.Point3D.Y;
        const maxX = contourEdges.OrderBy(o => o.EndNode.Point3D.X).ToList().Last().EndNode.Point3D.X;
        const maxY = contourEdges.OrderBy(o => o.EndNode.Point3D.Y).ToList().Last().EndNode.Point3D.Y;
        */

    return { min: new THREE.Vector3(minX, minY, 0), max: new THREE.Vector3(maxX, maxY, 0) };
  }
}

import * as THREE from "three";
import Utils from "./utils";

import Polygon from "./polygon";
import Edge, { EdgeFacadeDir } from "./edge";
import RoofSurface from "./roofSurface";
import HoleInSurface from "./holeInSurface";

import Node from "./node";

export default class Gable {
  public partialLines: Edge[];
  public edges: Edge[] = [];
  public nodes: Node[] = [];
  public roofSurfaces: RoofSurface[] = [];
  public originContourEdge: Edge;
  public depth: number;

  constructor(partialLines: Edge[], originContourEdge: Edge, depth: number) {
    this.partialLines = partialLines;
    this.originContourEdge = originContourEdge;
    this.depth = depth;
  }

  public static createGable(partialLines: Edge[], originContourEdge: Edge, depth: number) {
    return new Gable(partialLines, originContourEdge, depth);
  }

  public static setGables(gables: Gable[]) {
    if (gables.length == 0) return;

    const baseLevel = gables[0].originContourEdge.startNode.point3D.z;
    for (const gable of gables) {
      // set partial lines nodes list
      const nodes: Node[] = [];
      for (const edge of gable.partialLines) {
        if (!Utils.contains(nodes, edge.startNode))
          nodes.push(new Node(new THREE.Vector3(edge.startNode.point3D.x, edge.startNode.point3D.y, edge.startNode.point3D.z)));
        if (!Utils.contains(nodes, edge.endNode))
          nodes.push(new Node(new THREE.Vector3(edge.endNode.point3D.x, edge.endNode.point3D.y, edge.endNode.point3D.z)));
      }

      if (nodes.length === 0) continue;

      // Adjust nodes based on gable orientation and direction
      for (const node of nodes) {
        if (gable.originContourEdge.isVertical) node.point3D.x += gable.originContourEdge.direction == EdgeFacadeDir.WEST ? gable.depth : -gable.depth;
        else node.point3D.y += gable.originContourEdge.direction == EdgeFacadeDir.SOUTH ? gable.depth : -gable.depth;
        node.point3D.z += gable.depth / Math.tan(gable.originContourEdge.slope);
      }

      // set first edge
      let min: Node;
      let max: Node;
      if (gable.partialLines[0].isVertical) {
        min = nodes.sort((n1, n2) => n1.point3D.y - n2.point3D.y)[0];
        max = nodes.sort((n1, n2) => n2.point3D.y - n1.point3D.y)[0];
      } else {
        min = nodes.sort((n1, n2) => n1.point3D.x - n2.point3D.x)[0];
        max = nodes.sort((n1, n2) => n2.point3D.x - n1.point3D.x)[0];
      }

      // change length of base edge according to overhang parameter.
      // if min or max is not on corner of the contour, change the location by overhang (increase base edge length)
      if (
        !Utils.pointsAreCloseEnough(min.point3D, gable.originContourEdge.startNode.point3D) &&
        !Utils.pointsAreCloseEnough(min.point3D, gable.originContourEdge.endNode.point3D)
      ) {
        if (gable.originContourEdge.isVertical) {
          min = new Node(new THREE.Vector3(min.point3D.x, min.point3D.y, min.point3D.z));
        } else if (gable.originContourEdge.isHorizontal) {
          min = new Node(new THREE.Vector3(min.point3D.x, min.point3D.y, min.point3D.z));
        }
      }
      if (
        !Utils.pointsAreCloseEnough(max.point3D, gable.originContourEdge.startNode.point3D) &&
        !Utils.pointsAreCloseEnough(max.point3D, gable.originContourEdge.endNode.point3D)
      ) {
        if (gable.originContourEdge.isVertical) {
          max = new Node(new THREE.Vector3(max.point3D.x, max.point3D.y, max.point3D.z));
        } else if (gable.originContourEdge.isHorizontal) {
          max = new Node(new THREE.Vector3(max.point3D.x, max.point3D.y, max.point3D.z));
        }
      }

      // calculate top gable node
      let x = (min.point3D.x + max.point3D.x) / 2;
      let y = (min.point3D.y + max.point3D.y) / 2;
      let z = (Math.abs(y - min.point3D.y) + Math.abs(x - min.point3D.x)) / Math.tan(gable.originContourEdge.slope) + baseLevel;
      let adjustedZ = z + gable.depth / Math.tan(gable.originContourEdge.slope);

      if (!gable.originContourEdge.roofSurface) continue;
      //let topZ = gable.originContourEdge.RoofSurface.Nodes.
      //    OrderByDescending(n => n.point3D.z).FirstOrDefault().point3D.z;
      //z = Math.Min(z, topZ);

      let top = new Node(new THREE.Vector3(x, y, adjustedZ));

      // calculate top gable node on surface
      let surX: number;
      let surY: number;
      if (gable.originContourEdge.isVertical && gable.originContourEdge.direction == EdgeFacadeDir.WEST) {
        surX = x + adjustedZ * Math.tan(gable.originContourEdge.slope) - gable.depth;
        surY = y;
      } else if (gable.originContourEdge.isVertical && gable.originContourEdge.direction == EdgeFacadeDir.EAST) {
        surX = x - adjustedZ * Math.tan(gable.originContourEdge.slope) + gable.depth;
        surY = y;
      } else if (gable.originContourEdge.isHorizontal && gable.originContourEdge.direction == EdgeFacadeDir.SOUTH) {
        surX = x;
        surY = y + adjustedZ * Math.tan(gable.originContourEdge.slope) - gable.depth;
      } else {
        surX = x;
        surY = y - adjustedZ * Math.tan(gable.originContourEdge.slope) + gable.depth;
      }

      let topSurPoint = new THREE.Vector3(surX, surY, adjustedZ);
      let topSur = new Node(topSurPoint);

      if (gable.depth > 0) {
        const contourEdges: Edge[] = Polygon.orderPolygonEdges(gable.originContourEdge.roofSurface.edges);
        if (!Utils.pointIsInsidePolygon(topSurPoint, baseLevel, contourEdges)) {
          const distanceToPolygon = Gable.planarDistanceToPolygon(topSurPoint, contourEdges, gable);

          if (gable.partialLines[0].isVertical) {
            if (max.point3D.y - min.point3D.y - distanceToPolygon * 2 >= 1) {
              min.point3D.y += distanceToPolygon;
              max.point3D.y -= distanceToPolygon;
            } else {
              continue;
            }
          } else {
            if (max.point3D.x - min.point3D.x - distanceToPolygon * 2 >= 1) {
              min.point3D.x += distanceToPolygon;
              max.point3D.x -= distanceToPolygon;
            } else {
              continue;
            }
          }

          // calculate top gable node
          x = (min.point3D.x + max.point3D.x) / 2;
          y = (min.point3D.y + max.point3D.y) / 2;
          z = (Math.abs(y - min.point3D.y) + Math.abs(x - min.point3D.x)) / Math.tan(gable.originContourEdge.slope) + baseLevel;
          adjustedZ = z + gable.depth / Math.tan(gable.originContourEdge.slope);

          top = new Node(new THREE.Vector3(x, y, adjustedZ));

          // calculate top gable node on surface
          if (gable.originContourEdge.isVertical && gable.originContourEdge.direction == EdgeFacadeDir.WEST) {
            surX = x + adjustedZ * Math.tan(gable.originContourEdge.slope) - gable.depth;
            surY = y;
          } else if (gable.originContourEdge.isVertical && gable.originContourEdge.direction == EdgeFacadeDir.EAST) {
            surX = x - adjustedZ * Math.tan(gable.originContourEdge.slope) + gable.depth;
            surY = y;
          } else if (gable.originContourEdge.isHorizontal && gable.originContourEdge.direction == EdgeFacadeDir.SOUTH) {
            surX = x;
            surY = y + adjustedZ * Math.tan(gable.originContourEdge.slope) - gable.depth;
          } else {
            surX = x;
            surY = y - adjustedZ * Math.tan(gable.originContourEdge.slope) + gable.depth;
          }
          topSurPoint = new THREE.Vector3(surX, surY, adjustedZ);
          topSur = new Node(topSurPoint);
        }
      }

      const baseEdge = new Edge(min, max);
      gable.edges.push(baseEdge);
      gable.nodes.push(min);
      gable.nodes.push(max);
      min.edges.push(baseEdge);
      max.edges.push(baseEdge);
      gable.nodes.push(top);
      gable.nodes.push(topSur);

      // calculate two nore edges
      const left = new Edge(min, top);
      gable.edges.push(left);
      min.edges.push(left);
      top.edges.push(left);
      const right = new Edge(max, top);
      gable.edges.push(right);
      max.edges.push(right);
      top.edges.push(right);

      // create base surface
      const baseSurface = new RoofSurface(baseEdge, 0, gable.originContourEdge.direction);
      baseSurface.edges.push(left);
      baseSurface.edges.push(right);
      baseSurface.nodes.push(top);
      gable.roofSurfaces.push(baseSurface);

      // calculate three more edges
      const leftSur = new Edge(min, topSur);
      gable.edges.push(leftSur);
      min.edges.push(leftSur);
      topSur.edges.push(leftSur);
      const rightSur = new Edge(max, topSur);
      gable.edges.push(rightSur);
      max.edges.push(rightSur);
      topSur.edges.push(rightSur);
      const topEdge = new Edge(top, topSur);
      gable.edges.push(topEdge);
      top.edges.push(topEdge);
      topSur.edges.push(topEdge);

      // create two more surfaces
      const slop = Math.atan(Math.abs(top.point3D.y - min.point3D.y + top.point3D.x - min.point3D.x) / (z - baseLevel));

      let secondDir = EdgeFacadeDir.UNKNOWN;
      if (gable.originContourEdge.direction == EdgeFacadeDir.WEST) {
        secondDir = EdgeFacadeDir.SOUTH;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.EAST) {
        secondDir = EdgeFacadeDir.SOUTH;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.NORTH) {
        secondDir = EdgeFacadeDir.WEST;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.SOUTH) {
        secondDir = EdgeFacadeDir.WEST;
      }
      const secondSurface = new RoofSurface(leftSur, slop, secondDir);
      secondSurface.edges.push(left);
      secondSurface.edges.push(topEdge);
      secondSurface.nodes.push(topSur);
      gable.roofSurfaces.push(secondSurface);

      let thirdDir = EdgeFacadeDir.UNKNOWN;
      if (gable.originContourEdge.direction == EdgeFacadeDir.WEST) {
        thirdDir = EdgeFacadeDir.NORTH;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.EAST) {
        thirdDir = EdgeFacadeDir.NORTH;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.NORTH) {
        thirdDir = EdgeFacadeDir.EAST;
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.SOUTH) {
        thirdDir = EdgeFacadeDir.EAST;
      }
      const thirdSurface = new RoofSurface(rightSur, slop, thirdDir);
      thirdSurface.edges.push(right);
      thirdSurface.edges.push(topEdge);
      thirdSurface.nodes.push(topSur);
      gable.roofSurfaces.push(thirdSurface);

      // create hole in origin surface
      const holeEdges: Edge[] = [baseEdge, leftSur, rightSur];
      const holeNodes: Node[] = [min, topSur, max];

      if (gable.depth > 0) {
        const contourHoles: Node[] = [];
        const contourEdges: Edge[] = Polygon.orderPolygonEdges(gable.originContourEdge.roofSurface.edges);
        for (const holeNode of holeNodes) {
          const point = new THREE.Vector2(holeNode.point3D.x, holeNode.point3D.y);
          if (Utils.isPointInContour(point, contourEdges, true)) contourHoles.push(holeNode);
        }

        if (contourHoles.length == 0) gable.originContourEdge.roofSurface.dutchGableInnerHoles.push(new HoleInSurface(holeEdges, holeNodes));
        else if (contourHoles.length > 1) gable.originContourEdge.roofSurface.dutchGableContourHoles.push(new HoleInSurface(holeEdges, holeNodes));
      } else {
        gable.originContourEdge.roofSurface.holes.push(new HoleInSurface(holeEdges, holeNodes));
      }
    }
  }

  static planarDistanceToPolygon(point3D: THREE.Vector3, contourEdges: Edge[], gable: Gable): number {
    let distanceToPolygon = Number.MAX_VALUE;

    const point = new THREE.Vector2(point3D.x, point3D.y);
    const polygon: THREE.Vector2[] = [];

    for (const edge of contourEdges) {
      const startPoint = new THREE.Vector2(edge.startNode.point3D.x, edge.startNode.point3D.y);
      const endPoint = new THREE.Vector2(edge.endNode.point3D.x, edge.endNode.point3D.y);

      if (!Utils.containsPoint2d(polygon, startPoint)) polygon.push(startPoint);
      if (!Utils.containsPoint2d(polygon, endPoint)) polygon.push(endPoint);
    }

    const funcMinX = (fPrevDist, pCurrObj) => {
      const fDist = Math.abs(pCurrObj.x - point.x);
      return fPrevDist < fDist ? fPrevDist : fDist;
    };

    const funcMinY = (fPrevDist, pCurrObj) => {
      const fDist = Math.abs(pCurrObj.y - point.y);
      return fPrevDist < fDist ? fPrevDist : fDist;
    };

    const intersections: THREE.Vector2[] = [];
    if (gable.originContourEdge.isVertical) {
      const y = point.y;
      for (const edge of contourEdges) {
        const start = new THREE.Vector2(edge.startNode.point3D.x, edge.startNode.point3D.y);
        const end = new THREE.Vector2(edge.endNode.point3D.x, edge.endNode.point3D.y);

        if ((start.y <= y && end.y >= y) || (start.y >= y && end.y <= y)) {
          const x = start.x + ((y - start.y) * (end.x - start.x)) / (end.y - start.y);
          intersections.push(new THREE.Vector2(x, y));
        }
      }

      if (gable.originContourEdge.direction == EdgeFacadeDir.WEST) {
        distanceToPolygon = intersections.filter(i => i.x <= point.x).reduce(funcMinX, Number.MAX_VALUE);
      } else if (gable.originContourEdge.direction == EdgeFacadeDir.EAST) {
        distanceToPolygon = intersections.filter(i => i.x >= point.x).reduce(funcMinX, Number.MAX_VALUE);
      }
    } else if (gable.originContourEdge.isHorizontal) {
      const x = point.x;
      for (const edge of contourEdges) {
        const start = new THREE.Vector2(edge.startNode.point3D.x, edge.startNode.point3D.y);
        const end = new THREE.Vector2(edge.endNode.point3D.x, edge.endNode.point3D.y);

        if ((start.x <= x && end.x >= x) || (start.x >= x && end.x <= x)) {
          const y = start.y + ((x - start.x) * (end.y - start.y)) / (end.x - start.x);
          intersections.push(new THREE.Vector2(x, y));
        }
      }

      if (gable.originContourEdge.direction == EdgeFacadeDir.SOUTH) {
        distanceToPolygon = intersections.filter(i => i.y <= point.y).reduce(funcMinY, Number.MAX_VALUE);
      } else {
        distanceToPolygon = intersections.filter(i => i.y >= point.y).reduce(funcMinY, Number.MAX_VALUE);
      }
    }

    return distanceToPolygon;
  }
}

import { soWall2D } from "./soWall2D";

export default class WallFuncInfo {
  private key: string | string[];
  private walls: soWall2D[];
  constructor(key: string | string[], walls: soWall2D[]) {
    this.key = key;
    this.walls = walls;
  }
  get Key(): string | string[] {
    return this.key;
  }
  get Walls(): soWall2D[] {
    return this.walls;
  }
}

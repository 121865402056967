import * as THREE from "three";
import MathUtils from "../MathUtils";
import { Vector3V } from "../../../models/Vector3V";
import { EPSILON } from "../../consts";
import UnitsUtils from "../UnitsUtils";

/**
 * Utility class for common vector operations.
 */
export default class VectorUtils {
  /**
   * Converts a Vector3V object to a THREE.Vector3 object.
   *
   * @param v - A Vector3V object containing x, y, and z values.
   * @returns A THREE.Vector3 object with the same x, y, and z values.
   */
  static Vector3VToVector3(v: Vector3V): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, v.z);
  }

  /**
   * Converts a THREE.Vector3 object to a Vector3V object.
   *
   * @param v - A THREE.Vector3 object containing x, y, and z values.
   * @returns A Vector3V object with the same x, y, and z values.
   */
  static Vector3ToVector3V(v: THREE.Vector3): Vector3V {
    return new Vector3V(v.x, v.y, v.z);
  }

  /**
   * Converts a THREE.Vector2 object to a THREE.Vector3 object.
   * The z value of the resulting vector will always be 0.
   *
   * @param v - A THREE.Vector2 object containing x and y values.
   * @returns A THREE.Vector3 object with x, y, and z (set to 0).
   */
  static Vector2ToVector3(v: THREE.Vector2): THREE.Vector3 {
    return new THREE.Vector3(v.x, v.y, 0);
  }

  /**
   * Converts a THREE.Vector3 object to a THREE.Vector2 object by discarding the z component.
   *
   * @param v - A THREE.Vector3 object containing x, y, and z values.
   * @returns A THREE.Vector2 object with x and y values from the input vector.
   */
  static Vector3ToVector2(v: THREE.Vector3): THREE.Vector2 {
    return new THREE.Vector2(v.x, v.y);
  }

  /**
   * Compares two THREE.Vector3 objects for equality within a given epsilon.
   *
   * @param left - The first THREE.Vector3 object.
   * @param right - The second THREE.Vector3 object.
   * @param epsilon - Optional epsilon value for floating-point comparison (default is EPSILON).
   * @returns True if the vectors are equal within the given epsilon, otherwise false.
   */
  static areVectorsEqual(left: THREE.Vector3, right: THREE.Vector3, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }

  /**
   * Compares two THREE.Vector2 objects for equality within a given epsilon.
   *
   * @param left - The first THREE.Vector2 object.
   * @param right - The second THREE.Vector2 object.
   * @param epsilon - Optional epsilon value for floating-point comparison (default is EPSILON).
   * @returns True if the vectors are equal within the given epsilon, otherwise false.
   */
  static areVectors2Equal(left: THREE.Vector2, right: THREE.Vector2, epsilon = EPSILON): boolean {
    return MathUtils.areNumberArraysEqual(left.toArray(), right.toArray(), epsilon);
  }

  /**
   * Converts a direction code to a corresponding THREE.Vector3 object.
   *
   * @param directionCode - The code representing a direction:
   *   - 1: Down (0, -1, 0)
   *   - 2: Left (-1, 0, 0)
   *   - 3: Up (0, 1, 0)
   *   - 4: Right (1, 0, 0)
   *   - Any other value: Returns a zero vector (0, 0, 0) and logs a warning.
   * @returns A THREE.Vector3 object representing the direction.
   */
  static directionToVector3(directionCode): THREE.Vector3 {
    switch (directionCode) {
      case 1:
        return new THREE.Vector3(0, -1, 0);
      case 2:
        return new THREE.Vector3(-1, 0, 0);
      case 3:
        return new THREE.Vector3(0, 1, 0);
      case 4:
        return new THREE.Vector3(1, 0, 0);
      default:
        console.warn("Unknown direction code:", directionCode);
        return new THREE.Vector3(0, 0, 0);
    }
  }

  /**
   * Rounds up the components of a THREE.Vector3 object to the nearest precision value.
   *
   * @param target - The THREE.Vector3 object to be rounded.
   * @param e - The rounding precision (default is retrieved from UnitsUtils).
   * @returns The rounded THREE.Vector3 object.
   */
  static roundVector(target: THREE.Vector3, e: number = UnitsUtils.getRoundPrecision()): THREE.Vector3 {
    target.x = MathUtils.round(target.x, e);
    target.y = MathUtils.round(target.y, e);
    target.z = MathUtils.round(target.z, e);

    return target;
  }

  /**
   * Rounds up the components of a THREE.Vector3 object to the nearest precision value.
   *
   * @param target - The THREE.Vector3 object to be rounded.
   * @param e - The rounding precision (default is retrieved from UnitsUtils).
   * @returns The rounded THREE.Vector3 object.
   */
  static ceilVector(target: THREE.Vector3, e: number = UnitsUtils.getRoundPrecision()): THREE.Vector3 {
    target.x = MathUtils.ceil(target.x, e);
    target.y = MathUtils.ceil(target.y, e);
    target.z = MathUtils.ceil(target.z, e);

    return target;
  }

  /**
   * Rotates a vector 90 degrees to the right or left in a specified plane.
   * @param vector - The input THREE.Vector3 to rotate.
   * @param plane - The plane of rotation: 'xy', 'xz', or 'yz' defaults to XY.
   * @returns An object containing the rotated vectors (right and left).
   */
  static rotateVector(vector: THREE.Vector3, plane: "xy" | "xz" | "yz" = "xy"): { right: THREE.Vector3; left: THREE.Vector3 } {
    let right, left;

    switch (plane) {
      case "xy": // Rotate in XY plane
        right = new THREE.Vector3(-vector.y, vector.x, vector.z); // +90° (right)
        left = new THREE.Vector3(vector.y, -vector.x, vector.z); // -90° (left)
        break;

      case "xz": // Rotate in XZ plane
        right = new THREE.Vector3(-vector.z, vector.y, vector.x); // +90° (right)
        left = new THREE.Vector3(vector.z, vector.y, -vector.x); // -90° (left)
        break;

      case "yz": // Rotate in YZ plane
        right = new THREE.Vector3(vector.x, -vector.z, vector.y); // +90° (right)
        left = new THREE.Vector3(vector.x, vector.z, -vector.y); // -90° (left)
        break;

      default:
        throw new Error('Invalid plane specified. Use "xy", "xz", or "yz".');
    }

    return { right, left };
  }

  static rotateVectorAccordingToParentRotation(vector: THREE.Vector3, obj: THREE.Object3D): THREE.Vector3 {
    return vector.clone().applyQuaternion(new THREE.Quaternion().setFromAxisAngle(new THREE.Vector3(0, 0, 1), obj.parent.rotation.z));
  }

  /**
   * Normalizes the given vector and determines its direction.
   * The possible directions are "right", "left", "up", "down", or "unknown".
   * @param vector - The input vector to determine the direction of.
   * @returns A string representing the direction of the vector.
   */
  static getVectorDirection(vector: THREE.Vector3): string {
    const normalized = vector.clone().normalize();
    if (normalized.x === 1) {
      return "right";
    } else if (normalized.x === -1) {
      return "left";
    } else if (normalized.y === 1) {
      return "up";
    } else if (normalized.y === -1) {
      return "down";
    } else {
      return "unknown";
    }
  }
}

import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class RotateRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private angle: number,
    private position?: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.RotateRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.rotateRoom(soRoom, this.angle, this.position);
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);

    roomManager.updateGroupedRoomsChanged(soRoom, true);
  }

  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.rotateRoom(soRoom, -this.angle, this.position);
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);

    roomManager.updateGroupedRoomsChanged(soRoom, true);
  }
}

import * as THREE from "three";
import Graph from "./graph";
import Utils from "./utils";
import Edge from "./edge";
import Polygon from "./polygon";
import Node from "./node";
import NodeGraph from "./nodeGraph";

export default class CoreAlg {
  public static createGraphOnEachContourEdge(contourEdges: Edge[]) {
    for (const edge of contourEdges) {
      Graph.createGraph(edge, contourEdges);
    }

    // remove nodes with one edge
    for (const edge of contourEdges) {
      if (!edge.graph) continue;

      // TODO: Make removal not need a copy
      const nodesCopy = edge.graph.nodes.slice();
      for (const node of nodesCopy) {
        if (node.edges.length < 2) {
          edge.graph.delete(node);
        }
      }
    }
  }

  public static findCompletePolygonsForEachContourEdge(contourEdges: Edge[]) {
    for (const edge of contourEdges) {
      if (!edge.graph) continue;
      const startNode: NodeGraph = edge.graph.getNode(edge.startNode.point3D);
      const endNode: NodeGraph = edge.graph.getNode(edge.endNode.point3D);
      CoreAlg.findCompletePolygons(startNode, [], endNode, edge.graph.allPossiblePolygons, edge.isVertical);
    }

    // add lines for each polygon
    for (const contourEdge of contourEdges) {
      if (!contourEdge.graph) continue;
      for (const polygon of contourEdge.graph.allPossiblePolygons) polygon.lines = contourEdge.graph.lines;
    }
  }

  static findCompletePolygons(node: NodeGraph, pathSoFar: NodeGraph[], finalNode: NodeGraph, allPossibleResults: Polygon[], isVertical: boolean) {
    if (node == finalNode) {
      pathSoFar.push(node);
      allPossibleResults.push(new Polygon([...pathSoFar]));
      pathSoFar.pop();
      return;
    }
    pathSoFar.push(node);
    for (const edge of node.edges) {
      if (CoreAlg.isAllowedMove(pathSoFar, edge.otherNode(node), finalNode, isVertical)) {
        CoreAlg.findCompletePolygons(edge.otherNode(node), pathSoFar, finalNode, allPossibleResults, isVertical);
      }
    }
    pathSoFar.pop();
  }

  static isAllowedMove(nodes: NodeGraph[], node: NodeGraph, finalNode: NodeGraph, isVertical: boolean): boolean {
    // can not go to finalNode in the first step
    if (nodes.length == 1 && Utils.pointsAreCloseEnough(node.point3D, finalNode.point3D)) return false;
    // if already visited, return false
    if (Utils.contains(nodes, node)) return false;

    // can not go down and back and then down and forward.
    // can not go up and forward and then up and back
    const lastIndex = nodes.length - 1;
    const secondLastIndex = nodes.length - 2;
    if (isVertical && nodes.length > 1) {
      // go down
      if (
        nodes[lastIndex].point3D.y < nodes[secondLastIndex].point3D.y &&
        node.point3D.y > nodes[lastIndex].point3D.y &&
        nodes[lastIndex].point3D.z <= nodes[secondLastIndex].point3D.z + Utils.Tolerance &&
        node.point3D.z <= nodes[lastIndex].point3D.z + Utils.Tolerance
      ) {
        return false;
      }
      // go up
      if (
        nodes[lastIndex].point3D.y > nodes[secondLastIndex].point3D.y &&
        node.point3D.y < nodes[lastIndex].point3D.y &&
        nodes[lastIndex].point3D.z >= nodes[secondLastIndex].point3D.z - Utils.Tolerance &&
        node.point3D.z >= nodes[lastIndex].point3D.z - Utils.Tolerance
      ) {
        return false;
      }
    }
    if (!isVertical && nodes.length > 1) {
      // go down
      if (
        nodes[lastIndex].point3D.x < nodes[secondLastIndex].point3D.x &&
        node.point3D.x > nodes[lastIndex].point3D.x &&
        nodes[lastIndex].point3D.z <= nodes[secondLastIndex].point3D.z + Utils.Tolerance &&
        node.point3D.z <= nodes[lastIndex].point3D.z + Utils.Tolerance
      ) {
        return false;
      }
      // go up
      if (
        nodes[lastIndex].point3D.x > nodes[secondLastIndex].point3D.x &&
        node.point3D.x < nodes[lastIndex].point3D.x &&
        nodes[lastIndex].point3D.z >= nodes[secondLastIndex].point3D.z - Utils.Tolerance &&
        node.point3D.z >= nodes[lastIndex].point3D.z - Utils.Tolerance
      ) {
        return false;
      }
    }

    if (nodes.length > 3 && false) {
      const plane = new THREE.Plane();
      plane.setFromCoplanarPoints(nodes[0].point3D, nodes[1].point3D, nodes[2].point3D);
      //console.log(`Normal: ${plane.normal.toArray()}`)
      if (plane.normal.length() > 0)
        for (let nodeIdx = 3; nodeIdx < nodes.length; nodeIdx++) {
          const dist = Math.abs(plane.distanceToPoint(nodes[nodeIdx].point3D));
          if (dist > 1e-4) {
            return false;
          }
        }
    }

    return true;
  }

  /// <summary>
  /// remove polygons that won't be part of the roof.
  /// if there is a node (in polygon) that not belong to at least 3 polygons in differnt contoureEdges, remove that polygon
  /// </summary>
  public static reducePolygons(contourEdges: Edge[]) {
    const baseLevel = contourEdges[0].startNode.point3D.z;

    for (const edge of contourEdges) {
      if (!edge.graph) continue;
      edge.graph.reducePolygons = [...edge.graph.allPossiblePolygons];

      // TODO: Make removal not needa  copy
      const allPolysCopy = edge.graph.allPossiblePolygons.slice();
      for (const polygon of allPolysCopy) {
        for (const node of polygon.nodes) {
          if (Utils.ValuesAreCloseEnough(baseLevel, node.point3D.z)) continue;

          if (node.isMiddleNode(polygon)) continue;

          // if node not belong to at least 3 polygons in differnt contoureEdges, remove polygon
          if (CoreAlg.getContourEdgesNumber(node, contourEdges) < 3) {
            Utils.remove(edge.graph.reducePolygons, polygon);
            break;
          }
        }
      }
    }
  }

  /// <summary>
  /// Get the number of contoure edges that contain polygon with input node
  /// </summary>
  static getContourEdgesNumber(node: NodeGraph, contourEdges: Edge[]): number {
    let counter = 0;
    const edges: Edge[] = [];
    for (const edge of contourEdges) {
      if (!edge.graph) continue;

      for (const polygon of edge.graph.allPossiblePolygons) {
        if (Utils.contains(polygon.nodes, node)) {
          counter++;
          edges.push(edge);
          break;
        }
      }
    }
    if (CoreAlg.thereAreTwoContinuesEdges(edges, contourEdges)) {
      counter--;
    }
    return counter;
  }

  /// <summary>
  /// need to check if there are two continuse edges out of input edges.
  /// (the reduce polygon proccess count number of contourEdges that have polygon that contain a node.
  /// if the edges are continuse, count them as one and not as two).
  ///
  /// NEED TO IMPROVE - the check of continuse edges is not good enough.
  /// </summary>
  static thereAreTwoContinuesEdges(edges: Edge[], contourEdges: Edge[]): boolean {
    const baseLevel = edges[0].startNode.point3D.z;
    for (let i = 0; i < edges.length; i++) {
      for (let j = i + 1; j < edges.length; j++) {
        const pi = edges[i].getMidPoint();
        const pj = edges[j].getMidPoint();
        const mid = new THREE.Vector3((pi.x + pj.x) / 2, (pi.y + pj.y) / 2, (pi.z + pj.z) / 2);

        if (
          edges[i].isVertical &&
          edges[j].isVertical &&
          Utils.ValuesAreCloseEnough(edges[i].startNode.point3D.x, edges[j].startNode.point3D.x) &&
          edges[i].slope == edges[j].slope &&
          Utils.pointIsInsidePolygon(mid, baseLevel, contourEdges)
        ) {
          return true;
        }
        if (
          edges[i].isHorizontal &&
          edges[j].isHorizontal &&
          Utils.ValuesAreCloseEnough(edges[i].startNode.point3D.y, edges[j].startNode.point3D.y) &&
          edges[i].slope == edges[j].slope &&
          Utils.pointIsInsidePolygon(mid, baseLevel, contourEdges)
        ) {
          return true;
        }
      }
    }
    return false;
  }

  public static findCompleteRoof(contourEdges: Edge[]): Polygon[] | null {
    const listOfAllPolygons: Polygon[][] = [];
    for (const edge of contourEdges) {
      if (!edge.graph) continue;
      listOfAllPolygons.push(edge.graph.reducePolygons);
    }

    const roofSolution = CoreAlg.findRoof(listOfAllPolygons, 0, []);

    // set selected polygons in contourEdges
    for (const edge of contourEdges) {
      // TODO: Take this outside the loop... sigh...
      if (!roofSolution) continue;
      if (!edge.graph) continue;
      if (!edge.roofSurface) continue;
      const polygon = edge.graph.getRoofPolygon(roofSolution);
      for (let i = 0; i < polygon.nodes.length - 1; i++) {
        const node1 = new Node(polygon.nodes[i].point3D);
        const node2 = new Node(polygon.nodes[i + 1].point3D);
        const newEdge = new Edge(node1, node2);
        // add new roof edge
        if (!Utils.contains(edge.roofSurface.edges, newEdge)) {
          edge.roofSurface.edges.push(newEdge);
        }

        if (!Utils.contains(edge.roofSurface.nodes, node1)) {
          edge.roofSurface.nodes.push(node1);
        }
        node1.edges.push(newEdge);

        if (!Utils.contains(edge.roofSurface.nodes, node2)) {
          edge.roofSurface.nodes.push(node2);
        }
        node2.edges.push(newEdge);
      }
    }

    return roofSolution;
  }

  static findRoof(lists: Polygon[][], index: number, currentCombination: Polygon[]): Polygon[] | null {
    if (index == lists.length) {
      if (CoreAlg.isRoofValid(currentCombination)) {
        return currentCombination.slice();
      }
      return null;
    }

    for (const polygon of lists[index]) {
      currentCombination.push(polygon);
      const currRoofSol = CoreAlg.findRoof(lists, index + 1, currentCombination);
      if (currRoofSol) return currRoofSol;
      currentCombination.splice(currentCombination.length - 1, 1);
    }

    return null;
  }

  /// <summary>
  /// check if each node belong to at least 3 polygons,
  /// except for nodes in base level,
  /// except for middle nodes. middle node is a node in-between two nodes on the same line.
  /// </summary>
  static isRoofValid(combination: Polygon[]): boolean {
    const baseLevel = combination[0].nodes[0].point3D.z;
    for (const polygon1 of combination) {
      for (const node of polygon1.nodes) {
        // other nodes should belong to at least 3 polygons (or matnr only 3?)
        let minContainCount = 3;
        // - if node in base level, it should belong to 2 polygons
        // - or if node is middle node, it should belong to 2 polygons
        if (Utils.ValuesAreCloseEnough(baseLevel, node.point3D.z)) minContainCount = 2;
        // Note that for some reason this was commented out in the
        // original C# code.
        // It was originally the same as minContainCount = 2,
        // but commented out, it meant middle nodes are always viewed
        // as valid.
        else if (node.isMiddleNode(polygon1)) continue;

        let count = 0;
        for (const polygon2 of combination) {
          if (!Utils.contains(polygon2.nodes, node)) continue;
          count++;
          if (count >= minContainCount) break;
        }

        if (count < minContainCount) return false;
      }
    }
    return true;
  }

  public static findAllCombinations(contourEdges: Edge[]): Polygon[][] {
    const listOfAllPolygons: Polygon[][] = [];
    for (const edge of contourEdges) {
      if (!edge.graph) continue;
      listOfAllPolygons.push(edge.graph.reducePolygons);
    }

    const combinations: Polygon[][] = [];
    CoreAlg.findRoofCombinations(listOfAllPolygons, 0, [], combinations);

    return combinations;
  }

  public static findRoofCombinations(lists: Polygon[][], index: number, currentCombination: Polygon[], combinations: Polygon[][]): boolean {
    if (index == lists.length) {
      combinations.push(CoreAlg.shallowCopyOf(currentCombination));
      return false;
    }

    for (const polygon of lists[index]) {
      currentCombination.push(polygon);
      if (CoreAlg.findRoofCombinations(lists, index + 1, currentCombination, combinations)) {
        return true;
      }
      currentCombination.splice(currentCombination.length - 1, 1);
    }

    return false;
  }

  static shallowCopyOf(polygons: Polygon[]): Polygon[] {
    const copy: Polygon[] = [];
    for (const polygon of polygons) {
      copy.push(new Polygon(polygon.nodes));
    }
    return copy;
  }
}

import * as THREE from "three";
import Utils from "./utils";

export default class Plane {
  public pointA: THREE.Vector3;
  public pointB: THREE.Vector3;
  public pointC: THREE.Vector3;

  /// <summary>
  /// The plane equation is: Ax+By+Cz+D=0
  /// </summary>
  public A: number;
  public B: number;
  public C: number;
  public D: number;
  public normal: THREE.Vector3;

  constructor(a: THREE.Vector3, b: THREE.Vector3, c: THREE.Vector3) {
    this.pointA = a;
    this.pointB = b;
    this.pointC = c;
    this.normal = new THREE.Vector3(0, 0, 0);
  }

  public isEqual(other: Plane): boolean {
    if (!other) return false;

    let firstRatioSet = false;
    let firstRatio = 0;
    if (other.A != 0) {
      const currentRatio = this.A / other.A;
      if (!firstRatioSet) {
        firstRatio = currentRatio;
        firstRatioSet = true;
      } else if (!Utils.ValuesAreCloseEnough(firstRatio, currentRatio)) {
        return false;
      }
    } else if (this.A != 0) {
      return false;
    }
    if (other.B != 0) {
      const currentRatio = this.B / other.B;
      if (!firstRatioSet) {
        firstRatio = currentRatio;
        firstRatioSet = true;
      } else if (!Utils.ValuesAreCloseEnough(firstRatio, currentRatio)) {
        return false;
      }
    } else if (this.B != 0) {
      return false;
    }
    if (other.C != 0) {
      const currentRatio = this.C / other.C;
      if (!firstRatioSet) {
        firstRatio = currentRatio;
        firstRatioSet = true;
      } else if (!Utils.ValuesAreCloseEnough(firstRatio, currentRatio)) {
        return false;
      }
    } else if (this.C != 0) {
      return false;
    }
    if (other.D != 0) {
      const currentRatio = this.D / other.D;
      if (!firstRatioSet) {
        firstRatio = currentRatio;
        firstRatioSet = true;
      } else if (!Utils.ValuesAreCloseEnough(firstRatio, currentRatio)) {
        return false;
      }
    } else if (this.D != 0) {
      return false;
    }
    return true;
  }

  /// <summary>
  /// Set a plane out of three points
  /// </summary>
  public setPlane() {
    this.normal = new THREE.Vector3(
      (this.pointB.y - this.pointA.y) * (this.pointC.z - this.pointA.z) - (this.pointB.z - this.pointA.z) * (this.pointC.y - this.pointA.y),
      (this.pointB.z - this.pointA.z) * (this.pointC.x - this.pointA.x) - (this.pointB.x - this.pointA.x) * (this.pointC.z - this.pointA.z),
      (this.pointB.x - this.pointA.x) * (this.pointC.y - this.pointA.y) - (this.pointB.y - this.pointA.y) * (this.pointC.x - this.pointA.x)
    );

    this.A = this.normal.x;
    this.B = this.normal.y;
    this.C = this.normal.z;
    this.D = -(this.A * this.pointA.x + this.B * this.pointA.y + this.C * this.pointA.z);
  }

  /// <summary>
  /// Check if this plane parllel to input plane
  /// </summary>
  public isParallelTo(plane: Plane): boolean {
    const thisNormal = this.normal;
    const normal = plane.normal;
    if ((thisNormal.x == 0 && thisNormal.y == 0 && thisNormal.z == 0) || (normal.x == 0 && normal.y == 0 && normal.z == 0)) {
      throw new Error("One of the plane normals is the zero vector, which is invalid.");
    }
    // cross calculation
    const crossVector = new THREE.Vector3(this.B * plane.C - this.C * plane.B, this.A * plane.C - this.C * plane.A, this.A * plane.B - this.B * plane.A);
    return crossVector.x == 0 && crossVector.y == 0 && crossVector.z == 0;
  }
}

import * as THREE from "three";
import Edge, { EdgeFacadeDir } from "./edge";
import EdgeOutput, { EdgeType } from "./edgeOutput";
import Roof from "./roof";
import Utils from "./utils";
import Polygon from "./polygon";

export default class ChangeSurfacePosition {
  public static decreaseRoofLevel(roof: Roof, heelHeight: number): number {
    const heightChange = heelHeight;
    for (const surf of roof.roofSurfaces) {
      for (const edge of surf.edges) {
        edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z + heightChange);
        edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z + heightChange);
      }
    }

    return heightChange;
  }

  private static getContourEdgesContainingEdge(edge: EdgeOutput, contourEdges: Edge[], bProjectTo2D: boolean): Edge[] {
    const results = [];

    // Note that we flip the test and see if the *contour* edge points are
    // part of the output edge. This way, if we have one long edge that
    // has been merged, then we'll actually pick up all of the input edges
    // that are part of the output edge, which could have been merged
    // due to collinear lines.
    const startCopy = edge.startPoint.clone();
    const endCopy = edge.endPoint.clone();
    if (bProjectTo2D) {
      startCopy.z = 0;
      endCopy.z = 0;
    }

    for (const contourEdge of contourEdges) {
      if (
        Utils.doesSegmentContainPoint(startCopy, endCopy, contourEdge.startNode.point3D) &&
        Utils.doesSegmentContainPoint(startCopy, endCopy, contourEdge.endNode.point3D) &&
        !Utils.contains(results, contourEdge)
      )
        results.push(contourEdge);
    }

    // We actually need to do both, since there may have been collinear input edges that have
    // been merged into a single large output edge (this is what the above is testing),
    // but also a single input edge that may have been broken into two (in case of gables).

    for (const contourEdge of contourEdges) {
      if (
        Utils.doesSegmentContainPoint(contourEdge.startNode.point3D, contourEdge.endNode.point3D, startCopy) &&
        Utils.doesSegmentContainPoint(contourEdge.startNode.point3D, contourEdge.endNode.point3D, endCopy) &&
        !Utils.contains(results, contourEdge)
      )
        results.push(contourEdge);
    }
    return results;
  }

  private static getContourEdgesForOutputEdge(
    edge: EdgeOutput,
    roof: Roof,
    contourEdges: Edge[],
    bProjectTo2D: boolean,
    bProjectCollinearTo2D: boolean
  ): Edge[] {
    const collinearEdges = [edge];
    if (bProjectCollinearTo2D && (edge.startPoint.z > 0 || edge.endPoint.z > 0)) {
      const outlineEdges = ChangeSurfacePosition.getContourEdgesContainingEdge(edge, contourEdges, bProjectTo2D);
      if (outlineEdges.length > 0) {
        // Create a temporary EdgeOutput just for searching...
        collinearEdges[0] = new EdgeOutput(outlineEdges[0].startNode.point3D, outlineEdges[0].endNode.point3D);
        collinearEdges[0].edgeType = edge.edgeType;
      }
    }

    const allEdges = roof.getAllEdges(true);
    let currEdgeIndex = Utils.indexOf(allEdges, collinearEdges[0]);
    allEdges.splice(currEdgeIndex, 1);

    // From start point
    let currEdge = collinearEdges[0];
    let currPoint = currEdge.startPoint;
    do {
      currEdgeIndex = Roof.findEdgeIndexForPoint(currPoint, allEdges);
      if (currEdgeIndex < 0) break;

      if (!allEdges[currEdgeIndex].isCollinear(edge, bProjectCollinearTo2D)) break;

      currPoint = allEdges[currEdgeIndex].otherPoint(currPoint);
      collinearEdges.push(allEdges[currEdgeIndex]);
      allEdges.splice(currEdgeIndex, 1);
    } while (true);

    // From end point
    currEdge = collinearEdges[0];
    currPoint = currEdge.endPoint;
    do {
      currEdgeIndex = Roof.findEdgeIndexForPoint(currPoint, allEdges);
      if (currEdgeIndex < 0) break;

      if (!allEdges[currEdgeIndex].isCollinear(edge, bProjectCollinearTo2D)) break;

      currPoint = allEdges[currEdgeIndex].otherPoint(currPoint);
      collinearEdges.push(allEdges[currEdgeIndex]);
      allEdges.splice(currEdgeIndex, 1);
    } while (true);

    let results = [];
    for (currEdge of collinearEdges) results = results.concat(ChangeSurfacePosition.getContourEdgesContainingEdge(currEdge, contourEdges, bProjectTo2D));
    return results;
  }

  private static getPerEdgeOverhangFor(edge: EdgeOutput, roof: Roof, contourEdges: Edge[], defaultOverhang: number): number {
    const inputEdges = ChangeSurfacePosition.getContourEdgesForOutputEdge(edge, roof, contourEdges, true, true);
    // Find the largest:
    let overhang = 0;
    for (const currEdge of inputEdges) {
      if (currEdge.overhang > overhang) overhang = currEdge.overhang;
    }

    if (overhang === 0) overhang = defaultOverhang;
    return overhang;
  }

  public static mapOutputEdgesToInputEdges(roof: Roof, contourEdges: Edge[]) {
    for (const surface of roof.roofSurfaces) {
      for (const edge of surface.edges) {
        const inputEdges = ChangeSurfacePosition.getContourEdgesForOutputEdge(edge, roof, contourEdges, true, false);
        edge.inputEdgeIds = [];
        inputEdges.forEach(inputEdge => {
          if (inputEdge.id) edge.inputEdgeIds.push(inputEdge.id);
        });
      }
    }
  }

  public static applyOverhang(roof: Roof, contourEdges: Edge[], globalOverhang: number) {
    const orderedEdges: Edge[] = Polygon.orderPolygonEdges(contourEdges);
    const verts = orderedEdges.map(edge => edge.startNode.point3D);

    // Because all points are separate copies of each other, we go over all of them
    // and essentially find all the connections.
    let pointsToMove = [];
    const generalPointEdgeInfo = [];
    for (let iSurface = 0; iSurface < roof.roofSurfaces.length; iSurface++) {
      const surface = roof.roofSurfaces[iSurface];
      if (surface.slope === 0) continue;

      for (const edge of surface.edges) {
        for (const currPoint of [edge.startPoint, edge.endPoint]) {
          const idx = generalPointEdgeInfo.findIndex(info => Utils.pointsAreCloseEnough(info.point, currPoint));
          let info;
          if (idx < 0) {
            info = { point: currPoint.clone(), edges: [], edgeTypes: {} };
            generalPointEdgeInfo.push(info);
          } else info = generalPointEdgeInfo[idx];
          info.edges.push(edge);
          info.edgeTypes[edge.edgeType] = true;
        }

        if (edge.edgeType != EdgeType.EAVE && edge.edgeType != EdgeType.RAKE) continue;

        if (edge.startPoint.z > 0.0 && edge.endPoint.z > 0.0) continue;

        for (const currPoint of [edge.startPoint, edge.endPoint]) {
          const idx = pointsToMove.findIndex(info => Utils.pointsAreCloseEnough(info.point, currPoint));
          let info;
          if (idx < 0) {
            info = { point: currPoint.clone(), edges: [], surfaces: [], surfaceIndices: { [iSurface]: true }, slope: surface.slope };
            pointsToMove.push(info);
          } else {
            info = pointsToMove[idx];
            info.surfaceIndices[iSurface] = true;
          }

          info.edges.push(edge);
          info.surfaces.push(surface);
        }
      }
    }

    // Compute average moving directions and per-edge overhang amounts
    for (const info of pointsToMove) {
      let overhangCount = 0;
      let firstNorm = null;
      let maxDownAmount = 0;
      let finalMoveVec = new THREE.Vector3();
      info.edges.forEach((edge, index) => {
        // Figure out our smooth vs not normals
        const norm = edge.getXYPlaneNormal(verts);
        const overhang = ChangeSurfacePosition.getPerEdgeOverhangFor(edge, roof, contourEdges, globalOverhang);
        if (overhang > 0) {
          overhangCount++;
          edge.effectiveOverhang = overhang;
        }
        const mult = firstNorm ? 1.0 - Math.abs(firstNorm.dot(norm)) : 1.0;
        if (!firstNorm) firstNorm = norm.clone();

        norm.multiplyScalar(overhang * mult);
        finalMoveVec.add(norm);

        let downAmount;
        if (edge.edgeType === EdgeType.RAKE) downAmount = info.surfaces[index].slope * globalOverhang;
        else downAmount = info.surfaces[index].slope * overhang;
        if (downAmount > maxDownAmount) maxDownAmount = downAmount;
      });

      // Find edges generally meeting at this point:
      let smallestCos = 1;
      const genIdx = generalPointEdgeInfo.findIndex(genInfo => Utils.pointsAreCloseEnough(genInfo.point, info.point));
      const genInfo = generalPointEdgeInfo[genIdx];
      genInfo.edges.forEach(edge => {
        const norm = edge.getXYPlaneNormal(verts);
        const currCosProd = Math.abs(firstNorm.dot(norm));
        if (currCosProd < smallestCos) smallestCos = currCosProd;
      });

      // If there's a hip edge here, though, move in that direction instead
      const hipEdge = overhangCount > 1 && genInfo.edges.filter(edge => edge.edgeType === EdgeType.HIP || edge.edgeType === EdgeType.VALLEY)[0];
      if (hipEdge) {
        finalMoveVec = hipEdge.getProjectedDirection(null, info.point, verts);

        const eaveEdges = genInfo.edges.filter(edge => edge.edgeType === EdgeType.EAVE);
        for (const eave of eaveEdges) {
          const overhang = ChangeSurfacePosition.getPerEdgeOverhangFor(eave, roof, contourEdges, globalOverhang);
          if (overhang <= 0) continue;

          finalMoveVec.multiplyScalar(overhang / firstNorm.dot(finalMoveVec));
          break;
        }
      }

      // We don't want to lower the tops of gables or edge-to-egge gable sides
      const bHaveEdgeToEdgeRake = smallestCos < 1e-4 && overhangCount == 1 && genInfo.edgeTypes[EdgeType.RAKE] && genInfo.edgeTypes[EdgeType.EAVE];
      if (info.point.z > 0 || bHaveEdgeToEdgeRake) maxDownAmount *= 0;

      finalMoveVec.z -= maxDownAmount;
      info.finalMoveVec = finalMoveVec;
    }

    pointsToMove = pointsToMove.filter(info => info.finalMoveVec.lengthSquared() > 1e-4);

    // Step 3: Actually move the points
    for (const surface of roof.roofSurfaces) {
      if (surface.slope === 0) continue;

      for (const edge of surface.edges) {
        //if(edge.startPoint.z > 0.0 && edge.endPoint.z > 0.0)
        //   continue;

        for (const currPoint of [edge.startPoint, edge.endPoint]) {
          const idx = pointsToMove.findIndex(info => Utils.pointsAreCloseEnough(info.point, currPoint));
          if (idx < 0) continue;
          currPoint.add(pointsToMove[idx].finalMoveVec);
        }
      }
    }
  }

  /// <summary>
  /// this contour is after offset. this function bring back the gables above walls using the overhang param
  /// </summary>
  public static makeEdgePointsUnique(roof: Roof) {
    for (const surf of roof.roofSurfaces) {
      for (const edge of surf.edges) {
        edge.startPoint = edge.startPoint.clone();
        edge.endPoint = edge.endPoint.clone();
      }
    }
  }
}

import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
export class PasteRoomCommand extends RoomCommand {
  public copiedRoomAppliedCommandsNumber: number;
  private pastedSoRoom: soRoom2D;
  private pastedRoom: Room;
  constructor(
    roomId: string,
    public copiedRoomId: string,
    private position: THREE.Vector3
  ) {
    super(roomId);
    this.type = CommandTypes.PasteRoomCommand;
  }
  apply(roomManager: RoomManager): void {
    const activeSoFloor = roomManager.getActiveSoFloor();
    activeSoFloor.addRoom(this.pastedSoRoom);
    appModel.activeFloor.addRoom(this.pastedRoom);
    roomManager.checkRoomsSharedObjects([this.entityId]);

    const groupedRooms = roomManager.getCorePlanSoRooms().filter(room => room.groupedBy === this.pastedSoRoom.groupedBy);
    if (groupedRooms.length) {
      roomManager.onGroupedRoomsChanged(this.pastedSoRoom, groupedRooms, roomManager.getActiveSoFloor(), false);
    }
  }
  undo(roomManager: RoomManager): void {
    const activeSoFloor = roomManager.getActiveSoFloor();
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);

    const soGroupedRoom = roomManager.getCorePlanSoRooms().find(room => room.groupedBy === soRoom.groupedBy);

    if (soGroupedRoom) {
      SceneUtils.removeGroupedLines(soGroupedRoom, roomManager.getActiveSoFloor());
    }

    roomManager.selectRoom(soRoom, false);
    activeSoFloor.removeRoom(soRoom);
    this.pastedSoRoom = soRoom;
    this.pastedRoom = appModel.activeFloor.rooms.find(r => r.id === this.pastedSoRoom.userData.id);
    appModel.activeFloor.removeRoom(this.pastedRoom.id);
  }
}

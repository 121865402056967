import * as THREE from "three";
import { Clipper, ClipType, PolyType, Paths, Path, PolyFillType, IntPoint } from "js-clipper";
import GeometryUtils from "./GeometryUtils/GeometryUtils";

export default class ClipperUtils {
  private static readonly scale = 10000; // Scale factor for js-clipper to maintain precision

  static toClipperPath(points): IntPoint[] {
    return points.map(p => ({ X: Math.round(p.x * ClipperUtils.scale), Y: Math.round(p.y * ClipperUtils.scale) }));
  }

  static fromClipperPath(path: Path): THREE.Vector2[] {
    return path.map(p => new THREE.Vector2(p.X / ClipperUtils.scale, p.Y / ClipperUtils.scale));
  }

  static fromClipperPathToVector3(path: Path): THREE.Vector3[] {
    return path.map(p => new THREE.Vector3(p.X / ClipperUtils.scale, p.Y / ClipperUtils.scale, 0));
  }

  static difference(positive: IntPoint[], negatives: IntPoint[][]): Paths {
    const clipper = new Clipper();
    const solutionPaths = new Paths();
    clipper.AddPaths([positive], PolyType.ptSubject, true);

    negatives.forEach(negativePath => {
      clipper.AddPath(negativePath, PolyType.ptClip, true);
    });

    clipper.Execute(ClipType.ctDifference, solutionPaths, PolyFillType.pftNonZero, PolyFillType.pftNonZero);
    clipper.Clear();

    // Assuming solutionPaths returns paths that might need their collinear points reintroduced
    const reintroducedPaths = solutionPaths.map(path => {
      // Convert back to THREE.Vector2 format for reintroduceCollinearPoints method
      const pathPoints = ClipperUtils.fromClipperPath(path);
      // Reintroduce collinear points
      return GeometryUtils.reintroduceCollinearPoints(pathPoints, ClipperUtils.fromClipperPath(positive));
    });

    // Convert reintroduced paths back to Clipper format if further processing requires it
    return reintroducedPaths.map(path => ClipperUtils.toClipperPath(path));
  }

  static isPathFullyEnclosed(innerPath: IntPoint[], outerPath: IntPoint[]): boolean {
    // Ensure all points of the inner path are inside the outer path.
    return innerPath.every(innerPoint => {
      // Convert innerPoint from {X, Y} to a Clipper IntPoint if not already in that format.
      const point = new IntPoint(innerPoint.X, innerPoint.Y);
      const result = Clipper.PointInPolygon(point, outerPath);
      return result === 1; // Return true if innerPoint is inside outerPath, false otherwise.
    });
  }

  /**
   * Subtracts the overlapping area from the subject polygons using the clip polygons.
   *
   * @param subjectPolygons - An array of polygons represented as arrays of THREE.Vector3 points, which are the subject of the subtraction.
   * @param clipPolygons - An array of polygons represented as arrays of THREE.Vector3 points, which will be used to clip the subject polygons.
   * @returns An array of polygons represented as arrays of THREE.Vector3 points, which are the result of the subtraction.
   */
  static subtractOverlappingAreFromPolygons(subjectPolygons: THREE.Vector3[][], clipPolygons: THREE.Vector3[][]): THREE.Vector3[][] {
    const clipperDifference = new Clipper();
    clipperDifference.AddPaths(
      subjectPolygons.map(poly => this.toClipperPath(poly)),
      PolyType.ptSubject,
      true
    );
    clipperDifference.AddPaths(
      clipPolygons.map(poly => this.toClipperPath(poly)),
      PolyType.ptClip,
      true
    );

    const clippedPolygons = new Paths();
    const succeeded = clipperDifference.Execute(ClipType.ctDifference, clippedPolygons, PolyFillType.pftNonZero, PolyFillType.pftNonZero);

    if (succeeded && clippedPolygons.length > 0) {
      return clippedPolygons.map(poly => this.fromClipperPathToVector3(poly));
    }
    // If the subtraction failed or there aren't any results, return an empty array.
    return [];
  }
}

export type KeyValue = { [key: string]: any };
export type KeyString = { [key: string]: string };
export enum SettingsUnits {
  ft = "ft",
  sqft = "sqft",
  inches = "inches",
  units = "units",
  hex = "#hex",
  plf = "plf",
  percent = "%",
  psf = "psf",
  img = "img",
  empty = "",
}

export enum WebAppUISettingsKeys {
  displayUnits = "displayUnits",
  selectionColor = "selectionColor",
  highlightColor = "highlightColor",
  wallsColor = "wallsColor",
  floorsColor = "floorsColor",
  openingsColor = "openingsColor",
  gridPrimaryColor = "gridPrimaryColor",
  gridSecondaryColor = "gridSecondaryColor",
  lotLinesColor = "lotLinesColor",
  lotSetbackColor = "lotSetbackColor",
  obsoleteRoomsColor = "obsoleteRoomsColor",

  glassColor = "glassColor",
  doorColor = "doorColor",
  roofSurface = "roofSurface",
  roofEdge = "roofEdge",

  livingSpaces = "livingSpaces",
  garage = "garage",
  porchesAndPatio = "porchesAndPatio",
  roof = "roof",
}

export type TWebAppUISettings = {
  [WebAppUISettingsKeys.displayUnits]: "feet" | "inch";
  [WebAppUISettingsKeys.selectionColor]: string;
  [WebAppUISettingsKeys.highlightColor]: string;
  [WebAppUISettingsKeys.wallsColor]: string;
  [WebAppUISettingsKeys.floorsColor]: string;
  [WebAppUISettingsKeys.openingsColor]: string;
  [WebAppUISettingsKeys.gridPrimaryColor]: string;
  [WebAppUISettingsKeys.gridSecondaryColor]: string;
  [WebAppUISettingsKeys.lotLinesColor]: string;
  [WebAppUISettingsKeys.lotSetbackColor]: string;
  [WebAppUISettingsKeys.obsoleteRoomsColor]: string;

  [WebAppUISettingsKeys.glassColor]: string;
  [WebAppUISettingsKeys.doorColor]: string;
  [WebAppUISettingsKeys.roofSurface]: string;
  [WebAppUISettingsKeys.roofEdge]: string;

  [WebAppUISettingsKeys.livingSpaces]: string;
  [WebAppUISettingsKeys.garage]: string;
  [WebAppUISettingsKeys.porchesAndPatio]: string;
  [WebAppUISettingsKeys.roof]: string;
};

export enum CorePlanSettingsKeys {
  numOfStories = "numOfStories",
  storiesMax = "storiesMax",
  numOfBedrooms = "numOfBedrooms",
  bedroomMin = "bedroomMin",
  bedroomMax = "bedroomMax",
  numOfBathrooms = "numOfBathrooms",
  bathroomMin = "bathroomMin",
  bathroomMax = "bathroomMax",
  numOfHalfBathrooms = "numOfHalfBathrooms",
  numOfCars = "numOfCars",
  carsMax = "carsMax",

  defaultWindowHeaderHeight = "defaultWindowHeaderHeight",
  windowTopOfWallClearance = "windowTopOfWallClearance",
  floorToPlateLevelHeight = "floorToPlateLevelHeight",
  minFloorToPlateLevelHeight = "minFloorToPlateLevelHeight",
  maxFloorToPlateLevelHeight = "maxFloorToPlateLevelHeight",

  floorThickness = "floorThickness",

  windowWidth = "windowWidth",
  windowHeight = "windowHeight",

  minBuildingNetArea = "minBuildingNetArea",
  maxBuildingGrossArea = "maxBuildingGrossArea",
  lotArea = "lotArea",
  lotWidth = "lotWidth",
  lotLength = "lotLength",
  lotFrontSetback = "lotFrontSetback",
  lotBackSetback = "lotBackSetback",
  lotSideSetback = "lotSideSetback",
  lotFront2Setback = "lotFront2Setback",
}

export type TCorePlanSettings = {
  [CorePlanSettingsKeys.numOfStories]: number;
  [CorePlanSettingsKeys.storiesMax]: number;
  [CorePlanSettingsKeys.numOfBedrooms]: number;
  [CorePlanSettingsKeys.bedroomMin]: number;
  [CorePlanSettingsKeys.bedroomMax]: number;
  [CorePlanSettingsKeys.numOfBathrooms]: number;
  [CorePlanSettingsKeys.bathroomMin]: number;
  [CorePlanSettingsKeys.bathroomMax]: number;
  [CorePlanSettingsKeys.numOfHalfBathrooms]: number;
  [CorePlanSettingsKeys.numOfCars]: number;
  [CorePlanSettingsKeys.carsMax]: number;

  [CorePlanSettingsKeys.defaultWindowHeaderHeight]: number;
  [CorePlanSettingsKeys.windowTopOfWallClearance]: number;
  [CorePlanSettingsKeys.floorToPlateLevelHeight]: number;
  [CorePlanSettingsKeys.minFloorToPlateLevelHeight]: number;
  [CorePlanSettingsKeys.maxFloorToPlateLevelHeight]: number;

  [CorePlanSettingsKeys.floorThickness]: number;

  [CorePlanSettingsKeys.windowWidth]: number;
  [CorePlanSettingsKeys.windowHeight]: number;

  [CorePlanSettingsKeys.minBuildingNetArea]: number;
  [CorePlanSettingsKeys.maxBuildingGrossArea]: number;
  [CorePlanSettingsKeys.lotArea]: number;
  [CorePlanSettingsKeys.lotWidth]: number;
  [CorePlanSettingsKeys.lotLength]: number;
  [CorePlanSettingsKeys.lotFrontSetback]: number;
  [CorePlanSettingsKeys.lotBackSetback]: number;
  [CorePlanSettingsKeys.lotSideSetback]: number;
  [CorePlanSettingsKeys.lotFront2Setback]: number;
};

export enum ParametersSettingsKeys {
  plmFixtureWallSplitTolerance = "plmFixtureWallSplitTolerance",
  stretchAllowance = "stretchAllowance",
  minimalWallSegmentLength = "minimalWallSegmentLength",
  windowEdgeOffset = "windowEdgeOffset",
  doorEdgeOffset = "doorEdgeOffset",
  minDistanceForOpeningToCoreWall = "minDistanceForOpeningToCoreWall",
  openingAlignmentMinGap = "openingAlignmentMinGap",

  gridCellSizeForNewCorePlans = "gridCellSizeForNewCorePlans",
  frameSize = "frameSize",
  areaCalculationExteriorOffset = "areaCalculationExteriorOffset",

  roofDefaultSlope = "roofDefaultSlope",
  roofDefaultOverhang = "roofDefaultOverhang",
  overhangOptions = "overhangOptions",
  defaultHeelHeight = "defaultHeelHeight",
  minimumGableSeparation = "minimumGableSeparation",
  roofThickness = "roofThickness",

  roofDiagram = "roofDiagram",

  floorWeight = "floorWeight",
  roofWeight = "roofWeight",

  downspoutOffset = "downspoutOffset",
  minDistanceBetweenDownspouts = "minDistanceBetweenDownspouts",
}

export type TParametersSettings = {
  [ParametersSettingsKeys.plmFixtureWallSplitTolerance]: number;
  [ParametersSettingsKeys.stretchAllowance]: number;
  [ParametersSettingsKeys.minimalWallSegmentLength]: number;
  [ParametersSettingsKeys.windowEdgeOffset]: number;
  [ParametersSettingsKeys.doorEdgeOffset]: number;
  [ParametersSettingsKeys.minDistanceForOpeningToCoreWall]: number;
  [ParametersSettingsKeys.openingAlignmentMinGap]: number;

  [ParametersSettingsKeys.gridCellSizeForNewCorePlans]: number;
  [ParametersSettingsKeys.frameSize]: number;
  [ParametersSettingsKeys.areaCalculationExteriorOffset]: number;

  [ParametersSettingsKeys.roofDefaultSlope]: number;
  [ParametersSettingsKeys.roofDefaultOverhang]: number;
  [ParametersSettingsKeys.overhangOptions]: string;
  [ParametersSettingsKeys.defaultHeelHeight]: number;
  [ParametersSettingsKeys.minimumGableSeparation]: number;
  [ParametersSettingsKeys.roofThickness]: number;

  [ParametersSettingsKeys.roofDiagram]: string;

  [ParametersSettingsKeys.floorWeight]: number;
  [ParametersSettingsKeys.roofWeight]: number;

  [ParametersSettingsKeys.downspoutOffset]: number;
  [ParametersSettingsKeys.minDistanceBetweenDownspouts]: number;
};

export type TSettingsValues = {
  corePlanSettings: TCorePlanSettings;
  parametersSettings: TParametersSettings;
  webAppUISettings: TWebAppUISettings;
};

export type TSettingsDescriptions = {
  corePlanSettings: { [Key in keyof TCorePlanSettings]: string };
  parametersSettings: { [Key in keyof TParametersSettings]: string };
  webAppUISettings: { [Key in keyof TWebAppUISettings]: string };
};

export type TSettings = {
  values: TSettingsValues;
  descriptions: TSettingsDescriptions;
  tooltips: TSettingsDescriptions;
};

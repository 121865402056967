import { Tabs } from "antd";
import { observer } from "mobx-react-lite";
import { useEffect } from "react";
import { CorePlan } from "../../../../models/CorePlan";
import "./CorePlanPackageDetails.sass";
import TabCorePlanOverview from "../CorePlanDetails/TabCorePlanOverview";
import TabCorePlanVariations from "./TabCorePlanVariations";

export enum CorePlanPackageDetailTabs {
  CorePlanOverview = "corePlanOverview",
  Variations = "variations",
}

interface CorePlanPackageDetailsProps {
  corePlan: CorePlan;
  defaultActiveTab: CorePlanPackageDetailTabs;
}

let CorePlanDetails = ({ corePlan: corePlan, defaultActiveTab }: CorePlanPackageDetailsProps) => {
  useEffect(() => {}, [corePlan]);

  const corePlanTabs = [
    { label: "Overview", key: CorePlanPackageDetailTabs.CorePlanOverview, children: <TabCorePlanOverview corePlan={corePlan} /> },
    {
      label: "Elevations",
      key: CorePlanPackageDetailTabs.Variations,
      children: <TabCorePlanVariations corePlan={corePlan} />,
    },
  ];

  return <Tabs defaultActiveKey={defaultActiveTab} className="tabs-corePlan" items={corePlanTabs} />;
};

CorePlanDetails = observer(CorePlanDetails);
export default CorePlanDetails;

import { v4 as uuid } from "uuid";
import { isEmpty } from "../helpers/pojo";
import { isWooMode } from "../helpers/utilities";
import { RoomEntity } from "./RoomEntity";
import { RoomEntityType } from "./RoomEntityType";
import { Vector3V } from "./Vector3V";

function getCustomBooleanProperty(property?: any): boolean {
  if (typeof property === "number") {
    return property !== 0;
  }

  const value = property?.toString()?.toLowerCase();
  return value === "yes" || value === "y" || value === "true";
}
function getCustomNumberProperty(property?: any): number {
  return isNaN(+property) ? 0.0 : +property;
}

export enum VeevParameters {
  Indoor = "Indoor",
  NetArea = "Net Area",
  PlumbingRangeCollinear = "Plumbing range collinear",
  PlumbingRangePerpendicular = "Plumbing range perpendicular",
  Opening = "Opening",
  MaxOpeningRangeRight = "WZ R",
  MaxOpeningRangeLeft = "WZ L",
  CostRoomType = "Cost room type",
  GapStatus = "Gap status",
  GapDescription = "Gap description",
}

export enum RoomEntityProperties {
  PlumbingRangeCollinear = "plumbingRangeCollinear",
  PlumbingRangePerpendicular = "plumbingRangePerpendicular",
  IsOpening = "IsOpening",
  IsStretchable = "IsStretchable",
  MaxOpeningRangeRight = "MaxOpeningRangeRight",
  MaxOpeningRangeLeft = "MaxOpeningRangeLeft",
  Points = "points",
  Max = "max",
  FamilyType = "familyType",
  FamilyName = "familyName",
  sillHeight = "sillHeight",
  roughWidth = "roughWidth",
  roughHeight = "roughHeight",
  DataBox = "DataBox",
}

export class RevitRoomType {
  id: number = uuid();
  name: string = "";
  tagName: string = "";
  studioHasRoof: boolean = true;
  category: string = "";
  function: string = "";
  typeNumber: string = "";
  indoor: boolean = false;
  netArea: boolean = false;
  ceilingHeightOffsetFromLevel: number = 0.0;
  // floorOverride: boolean = false;
  entities: RoomEntity[] = [];
  gapDescription: string = "";
  gapStatus: string = "";

  public static fromJsonFile(roomJson: any): RevitRoomType {
    const timeStamp = Date.now().toString(36);

    const roomEntities = roomJson.entities.map((revitEntity: any) => {
      let roomEntity = new RoomEntity();
      roomEntity.id = uuid();
      roomEntity.type = revitEntity.type;
      roomEntity.bindingId = revitEntity.ownerId?.toString();
      roomEntity.revit = { id: undefined, name: roomJson.name };
      roomEntity.properties = [];

      switch (roomEntity.type) {
        case RoomEntityType.Floor:
        case RoomEntityType.Wall:
        case RoomEntityType.Door:
        case RoomEntityType.Window:
        case RoomEntityType.Furniture: {
          const revitEntityId: string = revitEntity.id?.toString();
          if (isEmpty(revitEntityId)) throw `RoomEntityType ${roomEntity.type} requires ID`;
          roomEntity.revit.id = revitEntityId;

          if (isWooMode()) {
            roomEntity.fileId = "/rooms/" + roomJson.name + "/" + (roomEntity.type + "-" + revitEntityId).toString().toLowerCase() + ".dxf"; // for Mockups
          } else {
            roomEntity.fileId = (roomEntity.type + "-" + revitEntityId + "-" + timeStamp).toLowerCase() + ".dxf"; // for DB
          }

          if (roomEntity.type === RoomEntityType.Wall && revitEntity.isPlumbing) {
            roomEntity.type = RoomEntityType.PlumbingWall;
          }

          if (roomEntity.type === RoomEntityType.Furniture) {
            roomEntity.properties.push({
              name: RoomEntityProperties.IsStretchable,
              value: getCustomBooleanProperty(revitEntity.isStretchable),
            });

            if (revitEntity.isPlumbingPoint) {
              roomEntity.type = RoomEntityType.PlumbingPoint;
              // TODO need to put the correct parameter name and handle the value format properly
              roomEntity.properties.push(
                {
                  name: RoomEntityProperties.PlumbingRangeCollinear,
                  value: revitEntity.veevParameters?.[VeevParameters.PlumbingRangeCollinear] ?? 0,
                },
                {
                  name: RoomEntityProperties.PlumbingRangePerpendicular,
                  value: revitEntity.veevParameters?.[VeevParameters.PlumbingRangePerpendicular] ?? 0,
                }
              );
            }
          }

          if (revitEntity.dataBox) {
            roomEntity.properties.push({
              name: RoomEntityProperties.DataBox,
              value: revitEntity.dataBox,
            });
          }

          if (revitEntity.veevParameters?.[VeevParameters.Opening]) {
            roomEntity.properties.push({
              name: RoomEntityProperties.IsOpening,
              value: getCustomBooleanProperty(revitEntity.veevParameters?.[VeevParameters.Opening]),
            });
          }

          if (revitEntity.familyType) {
            roomEntity.properties.push({
              name: RoomEntityProperties.FamilyType,
              value: revitEntity.familyType,
            });
          }
          if (revitEntity.familyName) {
            roomEntity.properties.push({
              name: RoomEntityProperties.FamilyName,
              value: revitEntity.familyName,
            });
          }

          if (revitEntity.veevParameters?.[VeevParameters.MaxOpeningRangeRight]) {
            roomEntity.properties.push({
              name: RoomEntityProperties.MaxOpeningRangeRight,
              value: revitEntity.veevParameters?.[VeevParameters.MaxOpeningRangeRight],
            });
          }
          if (revitEntity.veevParameters?.[VeevParameters.MaxOpeningRangeLeft]) {
            roomEntity.properties.push({
              name: RoomEntityProperties.MaxOpeningRangeLeft,
              value: revitEntity.veevParameters?.[VeevParameters.MaxOpeningRangeLeft],
            });
          }

          if (roomEntity.type === RoomEntityType.Door || roomEntity.type === RoomEntityType.Window) {
            roomEntity.properties.push({
              name: RoomEntityProperties.sillHeight,
              value: getCustomNumberProperty(revitEntity.sillHeight),
            });
            roomEntity.properties.push({
              name: RoomEntityProperties.roughWidth,
              value: getCustomNumberProperty(revitEntity.roughWidth),
            });
            roomEntity.properties.push({
              name: RoomEntityProperties.roughHeight,
              value: getCustomNumberProperty(revitEntity.roughHeight),
            });
          }
          break;
        }
        case RoomEntityType.ModelLine: {
          const start = revitEntity.startPoint;
          const end = revitEntity.endPoint;
          if (!start || !end) {
            roomEntity = null;
          } else {
            roomEntity.properties.push({
              name: RoomEntityProperties.Points,
              value: [new Vector3V(start.X, start.Y, 0), new Vector3V(end.X, end.Y, 0)],
            });
          }
          break;
        }
        case RoomEntityType.RoomBoundaryLines: {
          const start = revitEntity.startPoint;
          const end = revitEntity.endPoint;
          if (!start || !end) {
            roomEntity = null;
          } else {
            roomEntity.properties.push({
              name: RoomEntityProperties.Points,
              value: [new Vector3V(start.X, start.Y, 0), new Vector3V(end.X, end.Y, 0)],
            });
          }
          break;
        }
        case RoomEntityType.ReferenceLine: {
          const start = revitEntity.firstPoint;
          const end = revitEntity.secondPoint;
          if (!start || !end) {
            roomEntity = null;
          } else {
            roomEntity.properties.push(
              {
                name: RoomEntityProperties.Points,
                value: [new Vector3V(start.X, start.Y, 0), new Vector3V(end.X, end.Y, 0)],
              },
              {
                name: RoomEntityProperties.Max,
                value: revitEntity.max,
              }
            );
          }
          break;
        }
      }

      return roomEntity;
    });

    const result = new RevitRoomType();
    result.id = roomJson.id;
    result.name = roomJson.name;
    result.tagName = roomJson.tagName;
    result.studioHasRoof = roomJson?.studioHasRoof ?? true;
    result.category = roomJson.category;
    result.function = roomJson.function;
    result.typeNumber = roomJson.typeNumber;
    result.indoor = getCustomBooleanProperty(roomJson.veevParameters?.[VeevParameters.Indoor]);
    result.netArea = getCustomBooleanProperty(roomJson.veevParameters?.[VeevParameters.NetArea]);
    result.ceilingHeightOffsetFromLevel = roomJson.ceilingHeightOffsetFromLevel;
    result.entities = roomEntities.filter((e: RoomEntity) => e != null);
    result.gapStatus = roomJson.veevParameters?.[VeevParameters.GapStatus];
    result.gapDescription = roomJson.veevParameters?.[VeevParameters.GapDescription];

    return result;
  }
}

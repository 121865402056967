import { UploadFile } from "antd";
import { computed, makeObservable, observable, runInAction, IObservableValue } from "mobx";
import { v4 as uuid } from "uuid";
import { DTOConvertOptions, EntityDataType, PersistentObject } from "../entities/entityManager";
import { deepCopy2DTO, isEmpty, DTORecord } from "../helpers/pojo";
import { arrayIdGet, arrayIdSet } from "../helpers/utilities";
import { kindRoomTypeRaw, markDelete, markHidden, markPublic } from "../services/allKinds";
import { RawRoomType } from "../services/rawTypes";
import { FileEntity } from "./FileEntity";
import { RevitRoomType } from "./RevitRoomType";
import { RoomEntity } from "./RoomEntity";
import { appModel } from "./AppModel";

export enum AssetsState {
  NOT_LOADED = "NOT_LOADED",
  LOADING = "LOADING",
  LOADED = "LOADED",
}

export class RoomType implements PersistentObject<RoomType, RawRoomType> {
  roomCategoryId: string = "";
  id: string = uuid();
  name: string = "";
  thumbnailUrl: string = "";
  roomEntities: RoomEntity[] = [];
  externalId: number;
  settings?: DTORecord; // for roomSettings.json
  mark: number = markPublic.id;

  constructor(name: string = "", thumbnailUrl: string = "", roomEntities: RoomEntity[] = [], externalId: number = 0) {
    this.name = name;
    this.thumbnailUrl = thumbnailUrl;
    this.roomEntities = roomEntities;
    this.externalId = externalId;

    makeObservable(this, {
      name: observable,
      thumbnailUrl: observable,
      roomEntities: observable,
      mark: observable,
      isModification: computed,
      isMarkDeleted: computed,
      isMarkHidden: computed,
      attributes: computed,
      assetsState: computed,
    });
  }

  static fromRevitJson(roomJson: any, roomType?: RoomType): RoomType {
    const revitRoomType = RevitRoomType.fromJsonFile(roomJson);

    if (!roomType) {
      roomType = new RoomType(revitRoomType.name, "/assets/foo_room_type.jpg", revitRoomType.entities, revitRoomType.id);
    } else {
      runInAction(() => {
        roomType.externalId = revitRoomType.id;
        roomType.settings = roomJson;
        roomType.roomEntities = revitRoomType.entities;
        roomType.attributes.hasRoof = revitRoomType.studioHasRoof ?? true;
        roomType.attributes.revitRoomName = revitRoomType.name;
        roomType.attributes.revitRoomTagName = revitRoomType.tagName;
        roomType.attributes.indoor = revitRoomType.indoor;
        roomType.attributes.netArea = revitRoomType.netArea;
        roomType.attributes.ceilingHeightOffsetFromLevel = revitRoomType.ceilingHeightOffsetFromLevel;
        roomType.attributes.gapStatus = revitRoomType.gapStatus;
        roomType.attributes.gapDescription = revitRoomType.gapDescription;
      });
    }

    return roomType;
  }

  // * UI properties *
  description?: string;
  sortNum?: number;
  createdAt?: string;

  protected _attributes: DTORecord;
  get attributes(): DTORecord {
    if (!this._attributes) this._attributes = observable({});
    return this._attributes;
  }
  set attributes(attr: DTORecord) {
    Object.entries(attr || {}).forEach(([prop, val]) => {
      this.attributes[prop] = val;
    });
  }

  variety?: string[];
  get isModification(): boolean {
    return this.variety?.some(elem => elem === "mod");
  }
  files?: FileEntity[];
  _uploadFiles?: UploadFile[]; // for UI only

  get isMarkDeleted(): boolean {
    return this.mark === markDelete.id;
  }

  get isMarkHidden(): boolean {
    return this.mark === markHidden.id;
  }

  protected _assetsState: IObservableValue<AssetsState> = observable.box(AssetsState.NOT_LOADED);
  get assetsState(): AssetsState {
    return this._assetsState.get();
  }

  get isShaft(): boolean {
    const roomTypeFunction = (this.settings?.function as string) ?? "";
    return roomTypeFunction.toLowerCase().includes("shaft");
  }

  protected changeAssetsState(newState: AssetsState): void {
    // Since assetsState is a simple Finite State Machine (FSM) we handle only valid transitions
    runInAction(() => {
      const currentState = this._assetsState.get();
      switch (true) {
        case currentState === AssetsState.NOT_LOADED && newState === AssetsState.LOADING:
          this._assetsState.set(AssetsState.LOADING);
          break;

        case currentState === AssetsState.LOADING && newState === AssetsState.LOADED:
          this._assetsState.set(AssetsState.LOADED);
          break;
      }
    });
  }

  assetsLoading() {
    this.changeAssetsState(AssetsState.LOADING);
  }

  assetsLoaded() {
    this.changeAssetsState(AssetsState.LOADED);
  }

  /**
   * Returns (unique) file IDs for all RoomEntities, related to the current RoomType
   */
  getAllFileIds(): string[] {
    const fileIds = this.roomEntities.map(re => re.fileId).filter(Boolean);
    const uniqueFileIds = [...new Set(fileIds)];
    return uniqueFileIds;
  }

  // * Persistence part *

  static readonly kind = kindRoomTypeRaw;

  toDTO(format?: EntityDataType, options?: DTOConvertOptions): RawRoomType {
    const result = deepCopy2DTO(
      {
        kind: RoomType.kind.id,
        id: this.id,
        roomCategoryId: this.roomCategoryId,
        name: this.name,
        externalId: this.externalId,
        attributes: this.attributes,
        attached: options?.scope === "full" ? this.roomEntities : undefined,
        settings: this.settings,
        description: this.description,
        sortNum: this.sortNum,
        variety: this.variety,
      },
      { scope: options?.scope, only: options?.onlyProps }
    ) as unknown as RawRoomType;
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    if (!isEmpty(this._uploadFiles)) result._uploadFiles = this._uploadFiles;
    return result;
  }

  protected fromFormData(formData: RoomFormData) {
    if (formData.id) this.id = formData.id;
    if (formData.name) this.name = formData.name;
    if (formData.roomCategoryId) this.roomCategoryId = formData.roomCategoryId;
    this._uploadFiles = formData._uploadFiles;
  }

  protected fromAPI(dto: RawRoomType, format: EntityDataType = "APIdata"): RoomType {
    if (RoomType.kind.id != dto?.kind) throw "dto: RoomType: incorrect kind";

    this.id = dto.id;
    this.roomCategoryId = dto.roomCategoryId;
    this.name = dto.name;
    this.createdAt = dto.createdAt;
    this.thumbnailUrl = dto.thumbnailUrl ? process.env.REACT_APP_API_HOST + dto.thumbnailUrl : "/assets/room_placeholder.svg";
    this.mark = dto.mark;
    if (format === "APIdata") {
      this.variety = dto.variety;
      this.sortNum = dto.sortNum;
      this.externalId = dto.externalId;
      this.attributes = dto.attributes;
      this.settings = dto.settings;
      this.description = dto.description;
    }

    for (const roomTypeAttached of dto.attached || []) {
      if (roomTypeAttached.kind == RoomEntity.kind.id) {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const roomEntity: RoomEntity = (arrayIdGet(this.roomEntities, roomTypeAttached.id) || new RoomEntity()).fromDTO(roomTypeAttached, format);
        arrayIdSet(this.roomEntities, roomEntity);
      } else if (roomTypeAttached.kind == FileEntity.kind.id) {
        if (!this.files) this.files = [];
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const existsFile = arrayIdGet(this.files, roomTypeAttached.id) || new FileEntity();
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        arrayIdSet(this.files, existsFile.fromDTO(roomTypeAttached));
      } else throw "Category.attached item kind is unknown";
    }

    return this;
  }

  fromDTO(arg: any, format: EntityDataType = "APIdata"): RoomType {
    if (format === "FormData") {
      this.fromFormData(arg as RoomFormData);
    } else if (format.startsWith("APIdata")) {
      this.fromAPI(arg as RawRoomType, format);
    } else throw `Unknown format ${format}`;

    return this;
  }

  clearUploadFiles(): void {
    this._uploadFiles.splice(0, this._uploadFiles.length);
  }
}

export type RoomFormData = {
  id?: string;
  name?: string;
  roomCategoryId: string;
  _uploadFiles?: UploadFile[];
};

export type BulkRoomFormData = {
  roomTypes: RoomFormData[];
};

export type RoomTypeAttributes = {
  fooAttr?: any;
};

export function updateRoomTypeMark(data: any[]) {
  for (const item of data || []) {
    if (item.kind != RoomType.kind.id) continue;

    const roomType = appModel.getRoomType(item.id);

    if (roomType && roomType.mark != item.mark) {
      runInAction(() => {
        roomType.mark = item.mark;
      });
    }
  }
}

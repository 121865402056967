import * as THREE from "three";

/**
 * Utility class for performing raycasting operations.
 */
export default class RaycastUtils {
  /**
   * Finds the closest intersection point between a ray and an array of bounding boxes. The z values of the boxes are ignored so it checks for 2D intersections.
   * @param ray - The ray to test for intersections.
   * @param boxes - An array of boxes to test against the ray.
   * @returns An object containing the closest intersection point (or null if no intersection) and the distance to that point.
   */
  static getClosestIntersectionWithBoxes(ray: THREE.Ray, boxes: THREE.Box3[]): { intersection: THREE.Vector3 | null; distance: number } {
    const intersectionPoint = new THREE.Vector3();
    let closestIntersection: THREE.Vector3 | null = null;
    let minDistance = Infinity;
    for (const box of boxes) {
      // Convert the box to 2D by setting the z values to 0 in order to perform the intersection test
      const clonedBox2d = box.clone();
      clonedBox2d.min.z = 0;
      clonedBox2d.max.z = 0;
      if (ray.intersectBox(clonedBox2d, intersectionPoint)) {
        const distance = ray.origin.distanceTo(intersectionPoint);
        if (distance < minDistance) {
          minDistance = distance;
          closestIntersection = intersectionPoint.clone();
        }
      }
    }
    return { intersection: closestIntersection, distance: minDistance };
  }
}

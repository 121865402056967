import * as THREE from "three";

export default function patchThreeJS() {
  THREE.Vector3.prototype.subtract = function (vec) {
    const result = new THREE.Vector3();
    result.subVectors(this, vec);
    return result;
  };

  THREE.Vector3.prototype.addNew = function (vec) {
    const result = new THREE.Vector3();
    result.addVectors(this, vec);
    return result;
  };

  THREE.Vector3.prototype.scaleToRef = function (fValue, vecOut) {
    vecOut.copy(this);
    vecOut.multiplyScalar(fValue);
    return vecOut;
  };

  THREE.Vector3.prototype.lengthSquared = function () {
    return this.lengthSq();
  };

  THREE.Vector3.Dot = function (vec1, vec2) {
    return vec1.dot(vec2);
  };
  THREE.Vector3.Cross = function (vec1, vec2) {
    const crossProd = new THREE.Vector3();
    crossProd.crossVectors(vec1, vec2);
    return crossProd;
  };

  THREE.Vector2.prototype.subtract = function (vec) {
    const result = new THREE.Vector2();
    result.subVectors(this, vec);
    return result;
  };

  THREE.Vector2.prototype.addNew = function (vec) {
    const result = new THREE.Vector2();
    result.addVectors(this, vec);
    return result;
  };

  THREE.Vector2.Dot = function (vec1, vec2) {
    return vec1.dot(vec2);
  };
}

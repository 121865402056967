import * as THREE from "three";
import RoomManager from "../../managers/SceneManager/SceneManager";
import SceneUtils from "../../utils/SceneUtils";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class MirrorRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private vector: THREE.Vector3,
    private position?: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.MirrorRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.scaleRoomInPosition(soRoom, this.vector, this.position);
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom], false);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    SceneUtils.scaleRoomInPosition(soRoom, this.vector, this.position);
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom], false);

    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}

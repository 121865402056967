import { Vector2, Vector3 } from "three";
import { settings } from "../../entities/settings";
import { appModel } from "../../models/AppModel";
import { Roof, RoofEdge } from "../../models/Roof";
import ClipperUtils from "./ClipperUtils";
import GeometryUtils from "./GeometryUtils/GeometryUtils";
import { Paths, IntPoint } from "js-clipper";
import { soFloor2D } from "../models/SceneObjects/Floor/soFloor2D";
import SceneManager from "../managers/SceneManager/SceneManager";
import FloorUtils from "./FloorUtils";
import soSpace from "../models/graph/Space";
import VectorUtils from "./GeometryUtils/VectorUtils";
import log from "loglevel";
import apiProvider from "../../services/api/utilities/Provider";
import { getAxiosRequestConfig } from "../../services/api/utilities";
import axios from "axios";
import MathUtils from "./MathUtils";
import UnitsUtils from "./UnitsUtils";
import RoofAlgParams from "../../roofGenerator/roofAlgParams";
import RoofBuilder from "../../roofGenerator/roofBuilder";

export default class RoofUtils {
  public static readonly OVERLAPPING_PRECISION = 20;
  private static readonly indexMap: Record<string, number> = { top: 0, right: 1, bottom: 2, left: 3 };

  /**
   * Checks if two edges are equivalent, regardless of their direction.
   * @param edge - The roof edge with properties startNode and endNode.
   * @param surfaceEdge - The edge from roofSurfaces with properties StartNode and EndNode.
   * @param tolerance - Allowed difference for floating-point comparisons.
   * @returns True if the edges are equivalent.
   */
  static areEdgesEquivalent(edge: RoofEdge, surfaceEdge: any, tolerance: number = 0.001): boolean {
    const approxEqual = (a: number, b: number) => Math.abs(a - b) < tolerance;

    const directMatch =
      approxEqual(edge.startNode.x, surfaceEdge.StartNode.X) &&
      approxEqual(edge.startNode.y, surfaceEdge.StartNode.Y) &&
      approxEqual(edge.endNode.x, surfaceEdge.EndNode.X) &&
      approxEqual(edge.endNode.y, surfaceEdge.EndNode.Y);

    const reverseMatch =
      approxEqual(edge.startNode.x, surfaceEdge.EndNode.X) &&
      approxEqual(edge.startNode.y, surfaceEdge.EndNode.Y) &&
      approxEqual(edge.endNode.x, surfaceEdge.StartNode.X) &&
      approxEqual(edge.endNode.y, surfaceEdge.StartNode.Y);

    return directMatch || reverseMatch;
  }

  static generateEdgeId(startNode: Vector2, endNode: Vector2): string {
    return `${startNode.x},${startNode.y}$${endNode.x},${endNode.y}`;
  }

  /**
   * Generates edges and surfaces of the roof based on the provided edges data JSON.
   * @param roof - edges data of roof contour.
   * @param heelHeight - The heel height of the roof.
   * @returns Edges and surfaces of the roof.
   */
  static async getRoofSurfaces(roof: any, heelHeight: number): Promise<any | undefined> {
    if (!roof || !roof.roofEdges) return undefined;
    roof.roofEdges = roof.roofEdges.map(edge => ({
      ...edge,
      id: RoofUtils.generateEdgeId(edge.startNode, edge.endNode),
    }));

    let surfacesData = undefined;
    try {
      if (appModel.featureFlags["roofAlgorithmMigration"]) {
        const roofDefaultOverhang = settings.values.parametersSettings.roofDefaultOverhang;
        heelHeight ||= settings.values.parametersSettings.defaultHeelHeight;
        const roofParams: RoofAlgParams = { ...new RoofAlgParams(), heelHeight, overhang: roofDefaultOverhang };
        console.log("Input roof data:", roof.roofEdges, roofParams);
        const newRoofData = await RoofBuilder.create(roof.roofEdges, roofParams);
        surfacesData = newRoofData.roof;
      } else {
        const url = `${apiProvider.host}create-roof`;
        const jsonBody = { json: JSON.stringify(roof.roofEdges) };
        const axiosConfig = getAxiosRequestConfig();
        const response = await axios.post(url, jsonBody, axiosConfig);
        surfacesData = this.addHeelHeight(response.data, heelHeight);
      }
      console.log(appModel.featureFlags["roofAlgorithmMigration"] ? "NEW" : "OLD", surfacesData, "heel:", heelHeight);
      this.enrichRoofSurfaces(surfacesData);
      return surfacesData;
    } catch (error) {
      console.error("Error sending data:", error);
      return undefined;
    }
  }

  static addHeelHeight(data, heelHeight: number) {
    if (!heelHeight) return data;
    data.RoofSurface?.forEach(surface => {
      surface.Edges.forEach(edge => {
        edge.StartNode.Z += heelHeight;
        edge.EndNode.Z += heelHeight;
      });

      surface.Nodes.forEach(nodeObj => {
        nodeObj.Node.Z += heelHeight;
      });
    });

    return data;
  }

  /**
   * Enriches the given roof surfaces by placing gutters and downspouts on each roof surface. It must place gutters before downspouts.
   * @param roofSurfaces - The roof surfaces to be enriched. It is expected to have a property `RoofSurface` which is an array of roof surface objects.
   * @private
   */
  private static enrichRoofSurfaces(roofSurfaces): void {
    roofSurfaces.RoofSurface.forEach(roofSurface => {
      this.placeGutters(roofSurface);
      this.placeDownspouts(roofSurface);
    });
  }

  private static placeGutters(roofSurface): void {
    roofSurface.Edges.forEach(surfaceEdge => {
      // EdgeType 5 means that the edge is of type "Eave" hence it has a gutter
      surfaceEdge.hasGutter = surfaceEdge.EdgeType === 5;
    });
  }

  /**
   * Places downspouts on the given roof surface if certain conditions are met. The downspouts positions are the relative distance from the start of the edge.
   * @param roofSurface - The roof surface object which contains information like surface edges, direction, nodes and slope.
   */
  private static placeDownspouts(roofSurface): void {
    // Do not place downspouts if Direction is 3 (facing south/front of the house)
    if (roofSurface.Direction === 3) {
      return;
    }
    roofSurface.Edges.forEach(surfaceEdge => {
      if (!surfaceEdge.hasGutter) {
        return;
      }
      // A safety check - skip edges that are 3D diagonals by checking if the start and end nodes have different Z coordinates
      if (!MathUtils.areNumbersEqual(surfaceEdge.StartNode.Z, surfaceEdge.EndNode.Z)) {
        return;
      }
      // Initialize the downspouts object
      surfaceEdge.downspouts = {
        position: [],
        z0: 0,
        z1: UnitsUtils.inchesToFeet(appModel.activeCorePlan.firstFloorToFloorHeight), // This currently supports only a single floor
      };
      const roofEdgeLength = Math.sqrt(
        Math.pow(surfaceEdge.EndNode.X - surfaceEdge.StartNode.X, 2) + Math.pow(surfaceEdge.EndNode.Y - surfaceEdge.StartNode.Y, 2)
      );
      const downspoutOffset = settings.values.parametersSettings.downspoutOffset;
      const minDistanceBetweenDownspouts = settings.values.parametersSettings.minDistanceBetweenDownspouts;
      // Determines the positions of the downspouts
      if (roofEdgeLength <= 2 * downspoutOffset) {
        // If the edge length is less than or equal to twice the downspout offset, no downspouts are placed
        surfaceEdge.downspouts = null;
        return;
      }
      // The roof edge length minus 2 times the downspout offset is the actual distance between the downspouts
      if (roofEdgeLength - 2 * downspoutOffset <= minDistanceBetweenDownspouts) {
        // If the edge length is less than or equal to the minimum distance between downspouts, place a single downspout in the relative middle
        surfaceEdge.downspouts.position = [0.5];
        return;
      }
      // Otherwise, two downspouts are placed at calculated relative positions from the start and end of the edge
      const startDsRelativePos = downspoutOffset / roofEdgeLength;
      const endDsRelativePos = 1 - startDsRelativePos;
      surfaceEdge.downspouts.position = [startDsRelativePos, endDsRelativePos];
      return;
    });
  }

  /**
   * @param roofSurfaces - The roof surfaces data from an external API.
   * @param edge - The roof edge to compare against the roof surfaces.
   * @param roof - The roof instance containing the roof edge that will be updated.
   */
  // TODO this method might be not used, we are manipulating the roof surfaces directly after we got the roof algorithm response to place gutters and downspouts.
  static getFoundEdgeType(roofSurfaces: any, edge: RoofEdge, roof: Roof): void {
    if (!roofSurfaces?.RoofSurface?.length) return undefined;
    // Loop through each roof surface.
    for (const roofSurface of roofSurfaces.RoofSurface) {
      // Check if any of the roofSurface edges match one of our roofEdges.
      roofSurface.Edges.forEach((surfaceEdge: any) => {
        if (this.areEdgesEquivalent(edge, surfaceEdge)) {
          roof?.updateEdgeHasGutter(edge.startNode, edge.endNode, Boolean(surfaceEdge.EdgeType === 5));
        } else {
          roof?.updateEdgeHasGutter(edge.startNode, edge.endNode, false);
        }
      });
    }
  }

  static async calculateActiveCorePlanRoofs(roomManager: SceneManager): Promise<void> {
    // not to make overhang for countours for new algorithm
    const offset = appModel.featureFlags["roofAlgorithmMigration"] ? 0 : settings.values.parametersSettings.roofDefaultOverhang;
    const floorsAreaContours: Map<string, Vector3[][]> = new Map();

    appModel.activeCorePlan.floors.forEach(floor => {
      const soFloor = roomManager.getSoFloor(floor.id);
      const soRooms = FloorUtils.getFloorSoRooms(soFloor).filter(room => room.hasRoof);

      const spaces: soSpace[] = soFloor.wallManager.createEnclosedSpacesFromExternalWalls(soRooms.map(soRoom => soRoom.soId));
      // the roof generator requires the spaces to be in reverse order!
      // can be several countours for one floor: separated spaces
      const spacesPolylines: Vector3[][] = spaces.map(soSpace => soSpace.getPolylineWithOffsets(offset).reverse());
      // debug devtools: spacesPolylines[0].map(v => [v.x, v.y])
      floorsAreaContours.set(floor.id, spacesPolylines);
    });

    // TODO: now to create the roof only for the upper floor that has rooms - support for all
    const floor = RoofUtils.getHigestNotEmptyFloor(appModel.activeCorePlan.floors, floorsAreaContours);
    appModel.activeCorePlan.floors.forEach(floor => floor.resetRoofs());

    let activeOverhangIndex = -1;
    floorsAreaContours.get(floor.id).forEach(path => {
      const roof = new Roof();
      const edges: RoofEdge[] = [];
      for (let i = 0; i < path.length; i++) {
        const startNode = VectorUtils.Vector3ToVector2(path[i]);
        const endNode = VectorUtils.Vector3ToVector2(path[(i + 1) % path.length]); // Loop back to the first point at the end
        const { slope, overhang, gableDepth, isActiveOverhang } = this.getEdgeSlopeAndDepthAndOverhang(startNode, endNode, roomManager.getSoFloor(floor.id));
        if (isActiveOverhang) {
          activeOverhangIndex = i;
        }
        //const gableDepth = this.getEdgeGableDepth(startNode, endNode, roomManager.getSoFloor(floor.id));
        const roofEdge = new RoofEdge(startNode, endNode, slope, gableDepth, false, overhang);
        edges.push(roofEdge);
        roof.addEdge(roofEdge);
      }
      if (activeOverhangIndex >= 0) {
        this.propagateOverhang(edges, activeOverhangIndex, roomManager.getSoFloor(floor.id));
      }

      floor.addRoof(roof);
    });
  }

  private static propagateOverhang(edges: RoofEdge[], activeIndex: number, soFloor: soFloor2D): void {
    let [leftIndex, rightIndex] = [activeIndex, activeIndex];
    const { slope = -1, gableDepth = 0, overhang = 0 } = edges[activeIndex] ?? {};

    // Helper function to update overhang
    const updateOverhang = (index: number) => {
      edges[index].overhang = overhang;
      this.updateEdgeOverhangToActiveOverhang(
        new Vector2(edges[index].startNode.x, edges[index].startNode.y),
        new Vector2(edges[index].endNode.x, edges[index].endNode.y),
        overhang,
        soFloor
      );
    };

    // Traverse to the left full around to the countour max till active index
    let steps = 0;
    while (steps < edges.length && this.shouldPropagate(edges[leftIndex], slope, gableDepth)) {
      updateOverhang(leftIndex--);
      steps++;
      if (leftIndex < 0) {
        leftIndex += edges.length;
      }
    }

    // Traverse to the right full around to the countour max till active index
    steps = 0;
    while (steps < edges.length && this.shouldPropagate(edges[rightIndex], slope, gableDepth)) {
      updateOverhang(rightIndex++);
      steps++;
      if (rightIndex >= edges.length) {
        rightIndex = rightIndex % edges.length;
      }
    }
  }

  /**
   * Determines whether overhang should propagate based on slope and gable depth conditions.
   * Same roof type or hip and dutch gable...
   */
  private static shouldPropagate(edge: RoofEdge, targetSlope: number, targetGableDepth: number): boolean {
    return (edge.slope === targetSlope && edge.gableDepth === targetGableDepth) || (edge.slope != targetSlope && edge.gableDepth != targetGableDepth);
  }

  static updateEdgeOverhangToActiveOverhang(startNode: Vector2, endNode: Vector2, overhang: number, soFloor: soFloor2D) {
    const floor = appModel.activeCorePlan.floors.find(f => f.id === soFloor.soId);
    const midEdge = startNode.clone().add(endNode).multiplyScalar(0.5);

    let minDist = Number.MAX_VALUE;
    let wallSide = "unknown";
    let room = null;

    for (const soRoom of soFloor.soRooms) {
      const foundRoom = floor.rooms.find(r => r.id === soRoom.soId);
      for (const wall of soRoom.walls) {
        const dist = wall.getWallMidpoint().distanceTo(midEdge);
        if (dist < minDist) {
          minDist = dist;
          wallSide = wall.getRelativeWallSide(soRoom);
          room = foundRoom;
        }
      }
    }

    const index = RoofUtils.indexMap[wallSide] ?? -1;
    if (index >= 0) {
      room.roofOverhangs[index] = overhang;
    }
  }

  static getEdgeSlopeAndDepthAndOverhang(
    startNode: Vector2,
    endNode: Vector2,
    soFloor: soFloor2D
  ): { slope: number; overhang: number; gableDepth: number; isActiveOverhang: boolean } {
    const floor = appModel.activeCorePlan.floors.find(f => f.id === soFloor.soId);
    const midEdge = startNode.clone().add(endNode).multiplyScalar(0.5);

    let minDist = Number.MAX_VALUE;
    let wallSide = "unknown";
    let room = null;

    for (const soRoom of soFloor.soRooms) {
      const foundRoom = floor.rooms.find(r => r.id === soRoom.soId);
      for (const wall of soRoom.walls) {
        const dist = wall.getWallMidpoint().distanceTo(midEdge);
        if (dist < minDist) {
          minDist = dist;
          wallSide = wall.getRelativeWallSide(soRoom);
          room = foundRoom;
        }
      }
    }

    const index = RoofUtils.indexMap[wallSide] ?? -1;
    const slope = room?.roofSlopes?.[index] ?? -1;
    const overhang = room?.roofOverhangs?.[index] ?? -1;
    const gableDepth = room?.dutchGableDepths?.[index] ?? -1;
    const isActiveOverhang = room === appModel.activeOverHangRoom && index === appModel.activeOverHangRoomWallIndex;

    const logIfNotFound = (value: number, name: string, defaultValue: number) => {
      if (value === -1) {
        //log.error(`${name} NOT FOUND for edge (${startNode.x},${startNode.y}) -> (${endNode.x},${endNode.y})`);
        return defaultValue;
      }
      return value;
    };

    return {
      slope: logIfNotFound(slope, "Slope", settings.values.parametersSettings.roofDefaultSlope),
      overhang: logIfNotFound(overhang, "Overhang", settings.values.parametersSettings.roofDefaultOverhang),
      gableDepth: logIfNotFound(gableDepth, "Gable Depth", 0),
      isActiveOverhang,
    };
  }

  static getEdgeGableDepth(startNode: Vector2, endNode: Vector2, soFloor: soFloor2D): number {
    const floor = appModel.activeCorePlan.floors.find(floor => floor.id == soFloor.soId);
    const middlePoint = new Vector2((startNode.x + endNode.x) / 2, (startNode.y + endNode.y) / 2);
    let res = -1;
    let intersected = false;

    for (const soRoom of soFloor.soRooms) {
      const room = floor.rooms.find(room => room.id == soRoom.soId);
      for (const wall of soRoom.walls) {
        const point3D = VectorUtils.Vector2ToVector3(middlePoint);
        if (GeometryUtils.isPointInsideBoundingBox(point3D, wall.BoundingBox3D, RoofUtils.OVERLAPPING_PRECISION)) {
          const wallSide = wall.getRelativeWallSide(soRoom);
          // Ensure dutchGableDepths is initialized
          const index = RoofUtils.indexMap[wallSide] ?? 0;
          res = room.dutchGableDepths[index];
          intersected = true;
          break;
        }
      }
      if (intersected) {
        break;
      }
    }

    if (!intersected) {
      log.error(`NOT FOUND intersection for countour edge to find the gable roof depth ${startNode.x},${startNode.y},${endNode.x},${endNode.y}`);
    }
    return res == -1 ? 0 : res;
  }

  static isOverlapping(startNode1: Vector2, endNode1: Vector2, startNode2: Vector2, endNode2: Vector2) {}

  // TODO: to use to support multiple floors
  static expandAndSubtractPaths(paths: Paths, negativePaths: IntPoint[][], offset: number): Paths {
    const finalPaths = new Paths();
    paths.forEach(path => {
      const expandedPath = GeometryUtils.addOffsetToContour2D(ClipperUtils.fromClipperPath(path), offset);
      const expandedClipperPath = ClipperUtils.toClipperPath(expandedPath);
      finalPaths.push(...ClipperUtils.difference(expandedClipperPath, negativePaths));
    });
    return finalPaths;
  }

  // TODO: to use to support multiple floors
  static getHigestNotEmptyFloor(floors, floorsAreaContours) {
    for (let i = floors.length - 1; i >= 0; i--) {
      const floor = floors[i];
      const contour = floorsAreaContours.get(floor.id);

      // Check if the contour exists and is not empty
      if (contour && contour.length > 0) {
        return floor;
      }
    }
    return null;
  }
}

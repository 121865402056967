import * as THREE from "three";
import Utils from "./utils";
import EdgeOutput from "./edgeOutput";
import Roof from "./roof";
import Edge, { EdgeFacadeDir } from "./edge";

export default class ChangeSurfaceGeo {
  /// <summary>
  /// After I move the gables above walls. If the gable was in concave corner,
  /// and if the gable is on full line, I need to strech the second full surface in this corner.
  /// </summary>
  public static strechSurfacesInConcaveCornerWithGable(roof: Roof, contourEdges: Edge[], roofThickness: number, slope: number) {
    const alpha = Math.atan(slope);
    const t = roofThickness * Math.cos(alpha);
    const s = roofThickness * Math.sin(alpha);

    for (let i = 0; i < contourEdges.length; i++) {
      // the "i" index is the gable index
      if (roof.roofSurfaces[i].slope == 0 && !contourEdges[i].isInConcaveCorner(contourEdges)) {
        for (let j = 0; j < contourEdges.length; j++) {
          // the "j" index is the surface to strech

          // find second edge
          if (i == j) continue;
          if (roof.roofSurfaces[j].slope == 0) continue;

          if (!contourEdges[j].isInConcaveCorner(contourEdges)) {
            const nodes = roof.roofSurfaces[j].getNodes();

            // strech this surface (j)
            // there are 4 different concave corner and 8 options
            //1
            if (contourEdges[i].endNode.isEqual(contourEdges[j].endNode) && contourEdges[j].isVertical) {
              // move nodes
              const nodeToRemove: THREE.Vector3 = nodes[2];
              const newNode1 = new THREE.Vector3(nodeToRemove.x - t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x - t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);

              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.y == edge.startPoint.y) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.y == edge.endPoint.y) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[1].endPoint = newNode1;
              roof.roofSurfaces[j].edges[2].startPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode2, newNode3));
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode1, newNode2));
            }
            //2
            if (contourEdges[i].endNode.isEqual(contourEdges[j].endNode) && contourEdges[j].isHorizontal) {
              // move nodes
              const nodeToRemove = nodes[2];
              const newNode1 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y - t, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y - t, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.x == edge.startPoint.x) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.x == edge.endPoint.x) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[1].endPoint = newNode1;
              roof.roofSurfaces[j].edges[2].startPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode2, newNode3));
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode1, newNode2));
            }
            //3
            if (contourEdges[i].endNode.isEqual(contourEdges[j].startNode) && contourEdges[j].isVertical) {
              // move nodes
              const nodeToRemove = nodes[3];
              const newNode1 = new THREE.Vector3(nodeToRemove.x - t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x - t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.y == edge.startPoint.y) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.y == edge.endPoint.y) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[3].startPoint = newNode1;
              roof.roofSurfaces[j].edges[2].endPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode2, newNode1));
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode3, newNode2));
            }
            //4
            if (contourEdges[i].startNode.isEqual(contourEdges[j].endNode) && contourEdges[j].isHorizontal) {
              // move nodes
              const nodeToRemove = nodes[2];
              const newNode1 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y + t, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y + t, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.x == edge.startPoint.x) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.x == edge.endPoint.x) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[1].endPoint = newNode1;
              roof.roofSurfaces[j].edges[2].startPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode2, newNode3));
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode1, newNode2));
            }
            //5
            if (contourEdges[i].startNode.isEqual(contourEdges[j].endNode) && contourEdges[j].isVertical) {
              // move nodes
              const nodeToRemove = nodes[2];
              const newNode1 = new THREE.Vector3(nodeToRemove.x + t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x + t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.y == edge.startPoint.y) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.y == edge.endPoint.y) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[1].endPoint = newNode1;
              roof.roofSurfaces[j].edges[2].startPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode2, newNode3));
              roof.roofSurfaces[j].edges.splice(2, 0, new EdgeOutput(newNode1, newNode2));
            }
            //6
            if (contourEdges[i].endNode.isEqual(contourEdges[j].startNode) && contourEdges[j].isHorizontal) {
              // move nodes
              const nodeToRemove = nodes[3];
              const newNode1 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y - t, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y - t, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.x == edge.startPoint.x) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.x == edge.endPoint.x) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[3].startPoint = newNode1;
              roof.roofSurfaces[j].edges[2].endPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode2, newNode1));
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode3, newNode2));
            }
            //7
            if (contourEdges[i].startNode.isEqual(contourEdges[j].startNode) && contourEdges[j].isVertical) {
              // move nodes
              const nodeToRemove = nodes[3];
              const newNode1 = new THREE.Vector3(nodeToRemove.x + t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x + t, nodeToRemove.y, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.y == edge.startPoint.y) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.y == edge.endPoint.y) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[2].endPoint = newNode3;
              roof.roofSurfaces[j].edges[3].startPoint = newNode1;
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode2, newNode1));
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode3, newNode2));
            }
            //8
            if (contourEdges[i].startNode.isEqual(contourEdges[j].startNode) && contourEdges[j].isHorizontal) {
              // move nodes
              const nodeToRemove = nodes[3];
              const newNode1 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y + t, nodeToRemove.z - s);
              const newNode2 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y + t, nodeToRemove.z - s);
              const newNode3 = new THREE.Vector3(nodeToRemove.x, nodeToRemove.y, nodeToRemove.z);
              // move edges
              for (const edge of roof.roofSurfaces[j].edges) {
                if (contourEdges[i].endNode.point3D.x == edge.startPoint.x) {
                  edge.startPoint = new THREE.Vector3(edge.startPoint.x, edge.startPoint.y, edge.startPoint.z);
                }
                if (contourEdges[i].endNode.point3D.x == edge.endPoint.x) {
                  edge.endPoint = new THREE.Vector3(edge.endPoint.x, edge.endPoint.y, edge.endPoint.z);
                }
              }
              roof.roofSurfaces[j].edges[3].startPoint = newNode1;
              roof.roofSurfaces[j].edges[2].endPoint = newNode3;
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode2, newNode1));
              roof.roofSurfaces[j].edges.splice(3, 0, new EdgeOutput(newNode3, newNode2));
            }
          }
        }
      }
    }
  }

  public static increaseBaseLevelOfGables(roof: Roof, contourEdges: Edge[]) {
    for (const surf of roof.roofSurfaces) {
      if (surf.slope != 0) continue;

      const nodes = surf.getNodes();
      if (surf.direction == EdgeFacadeDir.NORTH || surf.direction == EdgeFacadeDir.SOUTH) {
        const sp = new THREE.Vector3(nodes[0].x, nodes[0].y, nodes[0].z);
        const ep = new THREE.Vector3(nodes[1].x, nodes[1].y, nodes[1].z);

        const [edge1, edge2] = Utils.getTwoEdges(sp, contourEdges);
        const [edge3, edge4] = Utils.getTwoEdges(ep, contourEdges);

        nodes[0] = new THREE.Vector3(sp.x, sp.y, sp.z);
        nodes[1] = new THREE.Vector3(ep.x, ep.y, ep.z);

        if (edge1 != null && edge2 != null && !edge1.isTwoEdgesCreateConcaveCorner(edge2)) {
          nodes[0] = new THREE.Vector3(sp.x, sp.y, sp.z);
        }
        if (edge3 != null && edge4 != null && !edge3.isTwoEdgesCreateConcaveCorner(edge4)) {
          nodes[1] = new THREE.Vector3(ep.x, ep.y, ep.z);
        }

        for (const edge of surf.edges) {
          if (Utils.pointsAreCloseEnough(edge.startPoint, sp)) {
            edge.startPoint = nodes[0];
          }
          if (Utils.pointsAreCloseEnough(edge.startPoint, ep)) {
            edge.startPoint = nodes[1];
          }
          if (Utils.pointsAreCloseEnough(edge.endPoint, sp)) {
            edge.endPoint = nodes[0];
          }
          if (Utils.pointsAreCloseEnough(edge.endPoint, ep)) {
            edge.endPoint = nodes[1];
          }
        }
      } else if (surf.direction == EdgeFacadeDir.WEST || surf.direction == EdgeFacadeDir.EAST) {
        const sp = new THREE.Vector3(nodes[0].x, nodes[0].y, nodes[0].z);
        const ep = new THREE.Vector3(nodes[1].x, nodes[1].y, nodes[1].z);

        const [edge1, edge2] = Utils.getTwoEdges(sp, contourEdges);
        const [edge3, edge4] = Utils.getTwoEdges(ep, contourEdges);

        nodes[0] = new THREE.Vector3(sp.x, sp.y, sp.z);
        nodes[1] = new THREE.Vector3(ep.x, ep.y, ep.z);

        if (edge1 != null && edge2 != null && !edge1.isTwoEdgesCreateConcaveCorner(edge2)) {
          nodes[0] = new THREE.Vector3(sp.x, sp.y, sp.z);
        }
        if (edge3 != null && edge4 != null && !edge3.isTwoEdgesCreateConcaveCorner(edge4)) {
          nodes[1] = new THREE.Vector3(ep.x, ep.y, ep.z);
        }

        for (const edge of surf.edges) {
          if (Utils.pointsAreCloseEnough(edge.startPoint, sp)) {
            edge.startPoint = nodes[0];
          }
          if (Utils.pointsAreCloseEnough(edge.startPoint, ep)) {
            edge.startPoint = nodes[1];
          }
          if (Utils.pointsAreCloseEnough(edge.endPoint, sp)) {
            edge.endPoint = nodes[0];
          }
          if (Utils.pointsAreCloseEnough(edge.endPoint, ep)) {
            edge.endPoint = nodes[1];
          }
        }
      }
    }
  }
}

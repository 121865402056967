import { IReactionDisposer, reaction } from "mobx";
import * as THREE from "three";
import { Vector2 } from "three";

import { GLOBAL_GRID_PLANE_RENDER_ORDER, RENDERER_CLEAR_COLOR, SCENE_AMBIENT_LIGHT_COLOR, SCENE_BACKGROUND_COLOR, SCENE_SUN_COLOR } from "../../consts";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import TrackballControls from "../../libs/TrackballControls";
import OrbitControls from "../../libs/OrbitControls";
import RoomColorScheme from "../../models/RoomColorScheme";
import { SceneMode } from "../../../models/SceneMode";
import SceneUtils from "../../utils/SceneUtils";
import BaseManager from "../BaseManager";
import UnitsUtils from "../../utils/UnitsUtils";
import GridManager from "./GridManager";
import ViewCubeControls from "../../libs/ViewCubeControls";
import MathUtils from "../../utils/MathUtils";
import RoofUtils from "../../utils/RoofUtils";
import { appModel } from "../../../models/AppModel";
import { SoPreviewRoom } from "../../models/scene/SoPreviewRoom";
import { EffectComposer } from "../../libs/PostProcessor/EffectComposer";
import { SSAOPass } from "../../libs/PostProcessor/SSAOPass";
import { RoomEntityType } from "../../../models/RoomEntityType";
import { SoPreviewOpening } from "../../models/scene/SoPreviewOpening";
import { SoPreviewFace } from "../../models/scene/SoPreviewFace";
import { SoPreviewWall } from "../../models/scene/SoPreviewWall";
import { SoPreviewGable } from "../../models/scene/SoPreviewGable";
import { RoofEdge } from "../../../models/Roof";
import { settings } from "../../../entities/settings";
import { Segment } from "../../models/segments/Segment";
import SegmentsUtils from "../../utils/SegmentsUtils";

export const enum SnapshotPosition {
  FRONT = "front",
  LEFT = "left",
}

export default class PreviewManager {
  private reactions: IReactionDisposer[] = [];
  private scene: THREE.Scene = null;
  private soRoot: THREE.Group = null;
  private soAmbientLight: THREE.AmbientLight = null;
  private soSunLight: THREE.DirectionalLight;
  private soGroundPlane: THREE.Mesh;
  private composer: EffectComposer;
  private controls: TrackballControls | OrbitControls = null;
  private raycaster: THREE.Raycaster;
  private cubeControls: ViewCubeControls = null;
  private gridManager: GridManager = null;

  public soRoomsRoot: THREE.Group = null;
  public soGridRoot: THREE.Group = null;
  public camera: THREE.PerspectiveCamera = null;

  constructor(public baseManager: BaseManager) {
    this.scene = new THREE.Scene();
    this.scene.background = new THREE.Color(SCENE_BACKGROUND_COLOR);

    this.camera = new THREE.PerspectiveCamera(35, 1.0, 15, 30000);
    this.raycaster = new THREE.Raycaster();

    // this.controls = new TrackballControls(this.camera);
    // this.controls.minDistance = 1.0 * UnitsManager.getConversionFactor();
    // this.controls.maxDistance = 1000.0 * UnitsManager.getConversionFactor();
    // this.controls.rotateSpeed = 10.0;
    // this.controls.zoomSpeed = 5.0;
    // this.controls.panSpeed = 1.0;
    // this.controls.staticMoving = true;
    // this.controls.dynamicDampingFactor = 1.0;
    // this.controls.target.copy(this.scene.position);

    this.controls = new OrbitControls(this.camera);
    this.controls.enableKeys = false;
    this.controls.mouseButtonActions = { LEFT: -1, MIDDLE: THREE.MOUSE.PAN, RIGHT: THREE.MOUSE.ROTATE };
    this.controls.minDistance = 1.0 * UnitsUtils.getConversionFactor();
    this.controls.maxDistance = 1000.0 * UnitsUtils.getConversionFactor();
    this.controls.rotateSpeed = 1.0;
    this.controls.zoomSpeed = 0.5;
    this.controls.panSpeed = 1.0;
    this.controls.dampingFactor = 1.0;
    this.controls.target.copy(this.scene.position);

    // this.scene.add(new THREE.AxesHelper(500));

    this.soAmbientLight = new THREE.AmbientLight(SCENE_AMBIENT_LIGHT_COLOR);
    this.scene.add(this.soAmbientLight);

    this.soSunLight = new THREE.DirectionalLight(SCENE_SUN_COLOR, 0.08);
    this.soSunLight.castShadow = true;
    this.soSunLight.shadow.mapSize = new THREE.Vector2(2048, 2048);
    this.scene.add(this.soSunLight);
    this.scene.add(this.soSunLight.target);

    this.soRoot = new THREE.Group();
    this.soRoot.name = "Preview Manager Root";
    this.scene.add(this.soRoot);

    this.soRoomsRoot = new THREE.Group();
    this.soRoomsRoot.name = "Preview Manager Rooms Root";
    this.soRoomsRoot.position.z = 3;
    this.soRoot.add(this.soRoomsRoot);

    this.soGridRoot = new THREE.Group();
    this.soGridRoot.name = "Preview Manager Grid Root";
    this.soRoot.add(this.soGridRoot);

    this.soGroundPlane = new THREE.Mesh(new THREE.PlaneBufferGeometry(), new THREE.MeshStandardMaterial({ color: 0xd3d3d3 }));
    this.soGroundPlane.receiveShadow = true;
    this.soGroundPlane.renderOrder = GLOBAL_GRID_PLANE_RENDER_ORDER;
    this.scene.add(this.soGroundPlane);

    this.cubeControls = new ViewCubeControls(35, 5, 170);

    this.gridManager = new GridManager(this);

    this.onMouseMove = this.onMouseMove.bind(this);
    this.onMouseUp = this.onMouseUp.bind(this);
    this.onMouseWheel = this.onMouseWheel.bind(this);
    this.onCubeControlsAngleChanged = this.onCubeControlsAngleChanged.bind(this);

    reaction(() => appModel.sceneMode, this.onSceneModeChanged.bind(this));
  }

  public init(): void {
    this.controls.domElement = this.baseManager.renderer.domElement;

    if (this.baseManager.renderer && this.baseManager.renderer.shadowMap) {
      this.baseManager.renderer.shadowMap.enabled = true;
      this.baseManager.renderer.shadowMap.type = THREE.PCFSoftShadowMap;

      this.composer = new EffectComposer(this.baseManager.renderer);

      const ssaoPass = new SSAOPass(
        this.scene,
        this.camera,
        this.baseManager.renderer.domElement.clientWidth,
        this.baseManager.renderer.domElement.clientHeight
      );
      ssaoPass.kernelRadius = 15;
      ssaoPass.minDistance = 0.001;
      ssaoPass.maxDistance = 1;
      this.composer.addPass(ssaoPass);
    }

    this.controls.init();
    this.controls.addEventListener("change", this.onMouseWheel);

    this.cubeControls.init();
    this.cubeControls.addEventListener("angle-changed", this.onCubeControlsAngleChanged);
    this.baseManager.getParentContainer().appendChild(this.cubeControls.domElement);

    this.updateCubeControls();

    this.gridManager.update();
  }
  public setSize(width: number, height: number): void {
    this.camera.aspect = MathUtils.areNumbersEqual(height, 0.0) ? 1.0 : width / height;
    this.camera.updateProjectionMatrix();

    if (this.controls instanceof TrackballControls) {
      this.controls?.handleResize();
    }
    this.controls?.update();

    this.gridManager.update();
    this.composer?.setSize(width, height);
  }
  public setActive(val: boolean): void {
    this.controls.enabled = val;
    this.cubeControls.setActive(val);
  }
  public render(): void {
    this.controls?.update();
    this.cubeControls?.update();

    this.cubeControls.render();
    this.composer?.render();
    this.baseManager.renderer?.render(this.scene, this.camera);
  }
  public uninit(): void {
    if (this.baseManager.renderer && this.baseManager.renderer.shadowMap) {
      this.baseManager.renderer.shadowMap.enabled = false;
    }
    this.gridManager.reset();
    GeometryUtils.disposeObject(this.soRoomsRoot);

    this.baseManager.getParentContainer()?.removeChild(this.cubeControls.domElement);
    this.cubeControls.removeEventListener("angle-changed", this.onCubeControlsAngleChanged);
    this.cubeControls.dispose();

    this.controls.removeEventListener("change", this.onMouseWheel);
    this.controls.dispose();
    this.controls.domElement = document;
  }

  public zoomToFit(): void {
    const sphere = GeometryUtils.getGeometryBoundingSphere(this.soRoomsRoot);
    const lookAt = new THREE.Vector3(-1, 1, -1);
    const up = new THREE.Vector3(0, 0, 1);
    GeometryUtils.zoomToFit3DByDirection(sphere, lookAt, up, this.camera, this.controls);

    this.gridManager.update();
    this.updateCubeControls();
  }

  public makeSnapshot(): void {
    const canvas = document.createElement("canvas");
    const renderer = new THREE.WebGLRenderer({ antialias: true, canvas, preserveDrawingBuffer: true });
    renderer.setClearColor(RENDERER_CLEAR_COLOR, 1);
    renderer.setSize(this.baseManager.renderer.domElement.clientWidth, this.baseManager.renderer.domElement.clientHeight);

    const zoomToFitCamera = new THREE.PerspectiveCamera(this.camera.fov, this.camera.aspect, this.camera.near, this.camera.far);
    zoomToFitCamera.up.copy(this.camera.up);
    zoomToFitCamera.zoom = this.camera.zoom;
    const target = this.controls.target.clone();

    const sphere = GeometryUtils.getGeometryBoundingSphere(this.soRoomsRoot);
    const lookAt = new THREE.Vector3(-1, 1, -1);
    const up = new THREE.Vector3(0, 0, 1);
    GeometryUtils.zoomToFit3DByDirection(sphere, lookAt, up, zoomToFitCamera, this.controls);
    this.controls.target.copy(target);
    this.controls.update();

    renderer.render(this.scene, zoomToFitCamera);

    const screenshot = renderer.domElement.toDataURL("image/jpeg");
    const link = document.createElement("a");
    link.download = "Preview Snapshot.png";
    link.href = screenshot;
    link.click();
  }

  public makeThumbnailSnapshot(position = SnapshotPosition.FRONT): string {
    // Create a new canvas element
    const canvas = document.createElement("canvas");

    appModel.baseManager.renderer.setClearColor(RENDERER_CLEAR_COLOR, 1);
    appModel.baseManager.renderer.setSize(this.baseManager.renderer.domElement.clientWidth, this.baseManager.renderer.domElement.clientHeight);

    // Create a new perspective camera with the same settings as the existing camera
    const snapshotCamera = new THREE.PerspectiveCamera(this.camera.fov, this.camera.aspect, this.camera.near, this.camera.far);
    snapshotCamera.up.copy(this.camera.up);

    // Calculate the bounding sphere of the object to frame
    const sphere = GeometryUtils.getGeometryBoundingSphere(this.soRoomsRoot);

    // Position the camera based on the desired snapshot position
    if (position === SnapshotPosition.FRONT) {
      snapshotCamera.up.set(0, 1, 0);
      // Set the camera position to be in front of the object along the Z-axis
      snapshotCamera.position.set(sphere.center.x, sphere.center.y - sphere.radius * 2, sphere.center.z);
    } else if (position === SnapshotPosition.LEFT) {
      snapshotCamera.up.set(0, 0, 1);
      // Set the camera position to be to the left of the object along the X-axis
      snapshotCamera.position.set(sphere.center.x - sphere.radius * 2, sphere.center.y, sphere.center.z);
    }

    // Make the camera look at the center of the object
    snapshotCamera.lookAt(sphere.center);

    // Render the scene with the snapshot camera
    appModel.baseManager.renderer.render(this.scene, snapshotCamera);

    // Get the screenshot data URL
    const screenshotDataUrl = appModel.baseManager.renderer.domElement.toDataURL("image/jpeg");

    // Return the base64 data URL
    return screenshotDataUrl;
  }

  public onMouseMove(e: MouseEvent): void {
    this.updateIntersections();
  }
  public onMouseUp(e: MouseEvent): void {
    if (e.button === 0) {
      if (appModel.activeFinish !== null) {
        const face = this.getClickedFace(false);
        if (face instanceof SoPreviewWall) {
          if (face != null) this.changeWallExteriorFinish(face);
        }
        if (face instanceof SoPreviewGable) {
          if (face != null) this.changeGableExteriorFinish(face);
        }
      } else if (appModel.activeRoofId != null) {
        const face = this.getClickedFace(true);
        if (face != null && face instanceof SoPreviewWall) this.changeWallRoofType(face);
      } else if (appModel.activeOverHangValue != null) {
        const face = this.getClickedFace(true);
        if (face != null && face instanceof SoPreviewWall) this.changeWallOverHang(face);
      }
    }
  }

  private async changeWallExteriorFinish(wall: SoPreviewWall) {
    if (wall.direction == "top" || wall.direction == "bottom") return;
    // soFace
    wall.updateExteriorFinish(appModel.activeFinish.index);
    // room array
    const room = appModel.activeCorePlan.getRoom(wall.soRoom.roomId);
    room.setWallExteriorFinish(wall.index, appModel.activeFinish.index);
    await this.loadSoPreviewRooms();
  }

  private async changeGableExteriorFinish(gable: SoPreviewGable) {
    // soFace
    gable.updateExteriorFinish(appModel.activeFinish.index);
    // room array
    gable.rooms.forEach(room => {
      room.setGableExteriorFinish(gable.index, appModel.activeFinish.index);
    });
    await this.loadSoPreviewRooms();
  }

  private async changeWallRoofType(wall: SoPreviewWall) {
    if (wall.direction == "top" || wall.direction == "bottom") return;
    const prevRoofSlope = wall.roofSlope;
    const room = appModel.activeCorePlan.getRoom(wall.soRoom.roomId);

    if (appModel.activeRoofId === "gable") {
      wall.roofSlope = 0;
      wall.dutchGableDepth = 0;
    } else if (appModel.activeRoofId === "ducthGable") {
      wall.roofSlope = 0;
      wall.dutchGableDepth = 12;
    } else if (appModel.activeRoofId === "hip") {
      wall.roofSlope = -1;
    }

    try {
      room.setRoofSlope(wall.index, wall.roofSlope);
      const isDutchGable = appModel.activeRoofId === "ducthGable";
      if (isDutchGable && appModel.dutchGableError) {
        return;
      }
      const roofDepth = isDutchGable ? appModel.dutchGableDepth : wall.dutchGableDepth;
      room.setDutchGableDepth(wall.index, roofDepth);
      await this.loadSoPreviewRooms();

      if (appModel.activeRoofId === "gable" || appModel.activeRoofId === "ducthGable") {
        const floors = appModel.activeCorePlan.floors;
        for (const floor of floors) {
          if (room.floorId === floor.id) {
            const roofEdgesCopy = floor.roofs[0].roofEdges;

            // FREEZED! the corner rule for now - not to check for gable corners walls
            /*if (PreviewManager.checkConsecutiveZeroSlopes(roofEdgesCopy, wall)) {
              const room = appModel.activeCorePlan.getRoom(wall.soRoom.roomId);
              wall.roofSlope = prevRoofSlope;
              room.setRoofSlope(wall.index, wall.roofSlope);
              await this.loadSoPreviewRooms();
            } else */ if (wall.roofSlope == 0) {
              await this.adjustDutchGableDepth(floor, wall, roofEdgesCopy);
            }
          }
        }
      }
    } catch (error) {
      console.log("error", error);
    }
  }
  private async changeWallOverHang(wall: SoPreviewWall) {
    const room = appModel.activeCorePlan.getRoom(wall.soRoom.roomId);
    wall.setWallOverhang(appModel.activeOverHangValue);
    room.setRoofOverHang(wall.index, appModel.activeOverHangValue);
    appModel.activeOverHangRoom = room;
    appModel.activeOverHangRoomWallIndex = wall.index;
    await this.loadSoPreviewRooms();
  }

  /**
   * Adjusts the Dutch gable depth for overlapping walls on a given floor,
   * based on the roof edges and the current wall configuration.
   *
   * @param floor - The floor containing the rooms and walls to adjust.
   * @param wall - The wall whose configuration is being adjusted.
   * @param roofEdgesCopy - A copy of the roof edges to check for overlaps.
   */
  private async adjustDutchGableDepth(floor: any, wall: SoPreviewWall, roofEdgesCopy: any[]) {
    const roomIds = new Set();

    // Collect the IDs of all rooms on the floor
    floor.rooms.forEach(element => {
      roomIds.add(element.id);
    });

    // Get all relevant walls on the floor that need adjustment
    const walls = this.getSoPreviewRooms()
      .map(room => room.walls)
      .flat()
      .filter(wall => roomIds.has(wall.soRoom.roomId) && wall.direction !== "top" && wall.direction !== "bottom");

    const overlaps = [];
    let index = 0;
    let currentWallIndex = 0;

    // Loop through each roof edge and check for overlaps with walls
    for (const roofEdge of roofEdgesCopy) {
      const startNode = new Vector2(roofEdge.startNode.x, roofEdge.startNode.y);
      const endNode = new Vector2(roofEdge.endNode.x, roofEdge.endNode.y);
      const segment = new Segment(startNode, endNode);

      // Loop through each room on the floor to check for overlapping walls
      for (const room of floor.rooms) {
        const x = room.x;
        const y = room.y;
        const width = room.width;
        const height = room.height;
        const offset = appModel.featureFlags["roofAlgorithmMigration"] ? 0 : settings.values.parametersSettings.roofDefaultOverhang;

        // Define the bounding box of the room with/without an offset
        const min = new Vector2(x - width / 2, y - height / 2);
        const max = new Vector2(x + width / 2, y + height / 2);

        // Create segments for each side of the room's bounding box
        const back = new Segment(new Vector2(min.x, min.y - offset), new Vector2(max.x, min.y - offset));
        const front = new Segment(new Vector2(min.x, max.y + offset), new Vector2(max.x, max.y + offset));
        const left = new Segment(new Vector2(min.x - offset, min.y), new Vector2(min.x - offset, max.y));
        const right = new Segment(new Vector2(max.x + offset, min.y), new Vector2(max.x + offset, max.y));

        let matchingWall = null;

        // Check if the roof edge overlaps with any wall segment
        if (SegmentsUtils.segmentsOverlap(back, segment, 0.01)) {
          matchingWall = walls.find(wall => wall.soRoom.roomId === room.id && wall.direction === "back");
        } else if (SegmentsUtils.segmentsOverlap(front, segment, 0.01)) {
          matchingWall = walls.find(wall => wall.soRoom.roomId === room.id && wall.direction === "front");
        } else if (SegmentsUtils.segmentsOverlap(right, segment, 0.01)) {
          matchingWall = walls.find(wall => wall.soRoom.roomId === room.id && wall.direction === "right");
        } else if (SegmentsUtils.segmentsOverlap(left, segment, 0.01)) {
          matchingWall = walls.find(wall => wall.soRoom.roomId === room.id && wall.direction === "left");
        }

        // If an overlapping wall is found, store it with the roof edge
        if (matchingWall) {
          overlaps.push([roofEdge, matchingWall]);

          // If the matching wall is the one being adjusted, store its index
          if (matchingWall.soRoom.roomId === wall.soRoom.roomId && matchingWall.direction === wall.direction) {
            currentWallIndex = index;
          }
        }
      }

      index++;
    }

    // Apply the Dutch gable depth to all overlapping walls
    await this.applyDutchGableDepthToOverlappingWalls(overlaps, currentWallIndex);
  }

  /**
   * Applies the Dutch gable depth to all overlapping walls, both forward and backward,
   * ensuring consistent depth across all relevant walls.
   *
   * @param overlaps - The list of overlapping walls and roof edges.
   * @param currentWallIndex - The index of the current wall in the overlaps list.
   */
  private async applyDutchGableDepthToOverlappingWalls(overlaps: any[], currentWallIndex: number) {
    const totalOverlaps = overlaps.length;

    // Helper function to adjust depth in a given direction
    const adjustDepthInDirection = async (startIndex: number, direction: 1 | -1) => {
      let index = startIndex;
      let iterations = 0;

      while (iterations < totalOverlaps) {
        iterations++;
        const currentWall = overlaps[index][1];

        // Determine the next index based on the direction (1 for forward, -1 for backward)
        const nextIndex = (index + direction + totalOverlaps) % totalOverlaps;
        const nextWall = overlaps[nextIndex][1];

        if (nextWall.roofSlope === 0) {
          const room = appModel.activeCorePlan.getRoom(nextWall.soRoom.roomId);
          nextWall.dutchGableDepth = currentWall.dutchGableDepth;
          room.setDutchGableDepth(nextWall.index, nextWall.dutchGableDepth);
          await this.loadSoPreviewRooms();
        } else {
          break;
        }

        // Update index for the next iteration
        index = nextIndex;
      }
    };

    // Adjust depth forward (to the next walls)
    await adjustDepthInDirection(currentWallIndex, 1);

    // Adjust depth backward (to the previous walls)
    await adjustDepthInDirection(currentWallIndex, -1);
  }

  public onMouseWheel(e: MouseEvent): void {
    this.gridManager.update();
    this.updateCubeControls();
  }

  static checkConsecutiveZeroSlopes(edges: RoofEdge[], wall: SoPreviewWall): boolean {
    const soRoomPos = new Vector2(wall.soRoom.position.x, wall.soRoom.position.y);
    const wallPos = new Vector2(wall.position.x, wall.position.y);
    const { x, y } = new Vector2().addVectors(soRoomPos, wallPos);
    const point = new Vector2(x, y);

    const distanceBetweenMidpoints = PreviewManager.getEdgeToWallDistance(point, edges[0]);

    let minDistance = distanceBetweenMidpoints;
    let index = 0;
    for (let i = 1; i < edges.length; i++) {
      const distanceBetweenMidpoints = PreviewManager.getEdgeToWallDistance(point, edges[i]);

      if (distanceBetweenMidpoints < minDistance) {
        minDistance = distanceBetweenMidpoints;
        index = i;
      }
    }

    const currentEdge = edges[index];
    const nextEdge = edges[(index + 1) % edges.length];
    const prevEdge = edges[(index - 1 + edges.length) % edges.length];
    const nextCheck = PreviewManager.checkEdgeSlopes(currentEdge, nextEdge);
    const prevCheck = PreviewManager.checkEdgeSlopes(prevEdge, currentEdge);

    return nextCheck || prevCheck;
  }

  static checkEdgeSlopes(edge1, edge2) {
    if (edge1.slope === 0 && edge2.slope === 0) {
      // Round to 3 Decimal Places
      const round3 = value => Math.round(value * 1000) / 1000;

      const isCollinear =
        round3(edge1.endNode.x - edge1.startNode.x) * round3(edge2.endNode.y - edge2.startNode.y) ===
        round3(edge1.endNode.y - edge1.startNode.y) * round3(edge2.endNode.x - edge2.startNode.x);

      const isDirectlyConnected = round3(edge1.endNode.x) === round3(edge2.startNode.x) && round3(edge1.endNode.y) === round3(edge2.startNode.y);

      if (!isCollinear || !isDirectlyConnected) {
        return true;
      }
    }
    return false;
  }

  static getEdgeToWallDistance(point, edge) {
    const start = new Vector2(edge.startNode.x, edge.startNode.y);
    const end = new Vector2(edge.endNode.x, edge.endNode.y);
    const midpoint2 = new Vector2().addVectors(start, end).multiplyScalar(0.5);
    return point.distanceTo(midpoint2);
  }

  public loadRoomVariation(
    roomGuid: string,
    colorIndex: number,
    roofSlopes: number[],
    roofOverhangs: number[],
    wallExteriorFinishes: number[],
    gableExteriorFinishes: number[],
    dutchGableDepths: number[]
  ): void {
    const soPreviewRooms = this.getSoPreviewRooms();

    // if empty - try to load rooms
    if (soPreviewRooms.length === 0) {
      this.load();
    }

    // Find the room where roomId matches roomGuid
    const matchedRoom = soPreviewRooms.find(room => room.roomId === roomGuid);

    if (matchedRoom) {
      const room = appModel.activeCorePlan.getRoom(matchedRoom.roomId);

      matchedRoom.walls.forEach(wall => {
        wall.updateExteriorFinish(room.exteriorFinishes[wall.index]);
      });

      // The gable update is after the gables created in Lambda and not here
      // matchedRoom.gables.forEach(gable => {
      //   gable.updateExteriorFinish(room.gableExteriorFinishes[gable.index]);
      // });

      for (const floor of appModel.activeCorePlan.floors) {
        for (const room of floor.rooms) {
          if (room.id === roomGuid) {
            room.roofSlopes = roofSlopes;
            room.roofOverhangs = roofOverhangs;
            room.dutchGableDepths = dutchGableDepths;
            room.exteriorFinishes = wallExteriorFinishes;
            room.gableExteriorFinishes = gableExteriorFinishes;
          }
        }
      }

      matchedRoom.updateRoofSlopes(roofSlopes);
      matchedRoom.updateRoofOverhangs(roofOverhangs);
    }
  }

  // --------------------------------------
  private adjustShadow() {
    const bb = GeometryUtils.getGeometryBoundingBox3D(this.soRoomsRoot);
    const bbSize = bb.getSize(new THREE.Vector3());
    const center = bb.getCenter(new THREE.Vector3());
    const radius = center.distanceTo(bb.max);

    this.soSunLight.position.set(center.x + bbSize.x * -5, center.y + bbSize.y * 5, bb.max.z * 5);
    const dist = this.soSunLight.position.distanceTo(center);

    this.soSunLight.shadow.camera.left = -radius;
    this.soSunLight.shadow.camera.right = radius;
    this.soSunLight.shadow.camera.top = radius;
    this.soSunLight.shadow.camera.bottom = -radius;
    this.soSunLight.shadow.camera.near = 0.1;
    this.soSunLight.shadow.camera.far = dist * 2;
    this.soSunLight.target.position.set(center.x, center.y, center.z);
    this.soSunLight.shadow.camera.updateProjectionMatrix();

    if (this.soGridRoot.children.length === 0) {
      this.soGroundPlane.visible = false;
      return;
    }

    this.soGroundPlane.visible = true;
    const gridBb = GeometryUtils.getGeometryBoundingBox3D(this.soGridRoot);
    const gridBbSize = gridBb.getSize(new THREE.Vector3());
    this.soGroundPlane.geometry.dispose();
    this.soGroundPlane.geometry = new THREE.PlaneBufferGeometry(gridBbSize.x, gridBbSize.y);
    this.soGroundPlane.position.copy(this.soGridRoot.children[0].position);
  }

  public getSoPreviewRooms(): SoPreviewRoom[] {
    return this.soRoomsRoot.children.filter(so => so instanceof SoPreviewRoom) as SoPreviewRoom[];
  }
  public getSoPreviewGables(): SoPreviewGable[] {
    return this.getSoPreviewGablesRecursive(this.soRoomsRoot.children);
  }
  private getSoPreviewGablesRecursive(children: any[]): SoPreviewGable[] {
    let result: SoPreviewGable[] = [];

    for (const child of children) {
      if (child instanceof SoPreviewGable) {
        result.push(child);
      }
      if (child.children && child.children.length > 0) {
        result = result.concat(this.getSoPreviewGablesRecursive(child.children));
      }
    }

    return result;
  }

  private getClickedFace(selectTransparentWall: boolean): SoPreviewFace {
    // Set the raycaster from the camera and mouse pointer position
    this.raycaster.setFromCamera(this.baseManager.mousePointer, this.camera);
    const walls = this.getSoPreviewRooms()
      .map(room => room.walls)
      .flat();
    const gables = this.getSoPreviewGables();
    const objectsToIntersect = walls.concat(gables);
    const intersections = this.raycaster.intersectObjects(objectsToIntersect, true);

    let face = intersections[0]?.object as SoPreviewFace;

    if (face instanceof SoPreviewWall) {
      if (selectTransparentWall) {
        return face;
      } else {
        for (let i = 0; i < intersections.length; i++) {
          face = intersections[i]?.object as SoPreviewWall;
          if (face.isIndoor) {
            return face;
          }
        }
      }
    }
    if (face instanceof SoPreviewGable) {
      return face;
    }
  }

  private subscribe(): void {
    this.reactions.push(reaction(() => appModel.activeDesignStyle, this.onActiveDesignStyleChanged.bind(this), { fireImmediately: false }));
  }
  private unsubscribe(reactions: IReactionDisposer[]): void {
    reactions.forEach(r => r());
    reactions.length = 0;
  }

  private async onSceneModeChanged(mode?: SceneMode): Promise<void> {
    GeometryUtils.disposeObject(this.soRoomsRoot);
    this.gridManager.reset();

    this.unsubscribe(this.reactions);

    if (appModel.activeCorePlan && mode === SceneMode.Preview) {
      await this.loadMaterials();
      await this.loadSoPreviewRooms();

      this.zoomToFit();
      this.subscribe();
      this.adjustShadow();
    }
  }
  private async onActiveDesignStyleChanged(): Promise<void> {
    if (!appModel.activeDesignStyle) {
      return;
    }

    await this.loadMaterials(true);
    this.updateSoPreviewRoomsMaterials();
  }
  private onCubeControlsAngleChanged(e): void {
    const sphere = GeometryUtils.getGeometryBoundingSphere(this.soRoomsRoot);
    const quaternion = (e.quaternion as THREE.Quaternion).clone().invert();
    GeometryUtils.zoomToFit3DByQuaternion(sphere, quaternion, this.camera, this.controls);

    this.gridManager.update();
  }

  private async loadMaterials(forceUpdateColorIndices: boolean = false) {
    const rooms = appModel.activeCorePlan.getRooms();
    const needUpdateColorIndices = rooms.length && (forceUpdateColorIndices || rooms.every(room => !room.colorIndex));

    const updateIndices = async () => {
      if (!needUpdateColorIndices) {
        return;
      }

      const boxes = SceneUtils.collectRoomBoundingBoxes(
        appModel.activeCorePlan.floors,
        appModel.activeCorePlan.firstFloorToFloorHeight,
        appModel.activeCorePlan.upperFloorToFloorHeight
      );
      const roomSchemes = Array.from(boxes.entries()).map(([roomId, bb]) => {
        // convert to feet
        bb.min.multiplyScalar(UnitsUtils.INCHES2FEET);
        bb.max.multiplyScalar(UnitsUtils.INCHES2FEET);
        return new RoomColorScheme(roomId, bb);
      });
      const colorSchemes = await SceneUtils.loadRoomColorSchemes(roomSchemes);

      for (const floor of appModel.activeCorePlan.floors) {
        for (const room of floor.rooms) {
          room.colorIndex = colorSchemes.find(s => s.roomId === room.id).colorIndex;
          room.customColorIndex = null;
        }
      }
    };

    await Promise.all([updateIndices()]);
  }
  public load(toResetOverhands = false) {
    const boundingBoxes = SceneUtils.collectRoomBoundingBoxes(
      appModel.activeCorePlan.floors,
      appModel.activeCorePlan.firstFloorToFloorHeight,
      appModel.activeCorePlan.upperFloorToFloorHeight
    );

    const sortedFloors = [...appModel.activeCorePlan.floors.sort((a, b) => a.index - b.index)];

    for (let i = 0; i < sortedFloors.length; i++) {
      const floor = sortedFloors[i];

      floor.rooms.forEach(room => {
        const bb = boundingBoxes.get(room.id);
        const isIndoor = !!appModel.getRoomType(room.roomTypeId).attributes.indoor;

        const so3DRoom = new SoPreviewRoom(bb, room.id, isIndoor);

        // update soPreviewWall finishes
        so3DRoom.walls.forEach(wall => {
          wall.updateExteriorFinish(room.exteriorFinishes[wall.index]);
        });
        // update soPreviewRoom slopes
        so3DRoom.updateRoofSlopes(room.roofSlopes);
        // update soPreviewRoom overhangs or reset if passed
        if (toResetOverhands) {
          room.roofOverhangs = [];
          so3DRoom.resetRoofOverhangs();
        } else {
          so3DRoom.updateRoofOverhangs(room.roofOverhangs);
        }

        so3DRoom.updateDutchGableDepths(room.dutchGableDepths);

        const roomSize = bb.getSize(new THREE.Vector3());

        const soRoom = this.baseManager.roomManager.getCorePlanSoRoom(room.id);
        if (soRoom) {
          soRoom.children
            .filter(ch => [RoomEntityType.Door, RoomEntityType.Window].includes(ch.userData.type))
            .forEach(ch => {
              const so3DOpening = SoPreviewOpening.create(ch, roomSize);
              if (so3DOpening) {
                so3DRoom.add(so3DOpening);
              }
            });
        }
        this.soRoomsRoot.add(so3DRoom);
      });
    }
  }

  public async loadSoPreviewRooms(heelHeight?: number, toResetOverhands = false): Promise<void> {
    GeometryUtils.disposeObject(this.soRoomsRoot);
    RoofUtils.calculateActiveCorePlanRoofs(this.baseManager.roomManager);

    this.load(toResetOverhands);

    const sortedFloors = [...appModel.activeCorePlan.floors.sort((a, b) => a.index - b.index)];

    for (let i = 0; i < sortedFloors.length; i++) {
      const floor = sortedFloors[i];

      if (floor.index >= 0 || floor.index === appModel.activeCorePlan.floors.length - 1) {
        const roofElevation =
          floor.index === 0
            ? appModel.activeCorePlan.firstFloorToFloorHeight
            : appModel.activeCorePlan.firstFloorToFloorHeight + floor.index * appModel.activeCorePlan.upperFloorToFloorHeight;
        floor.roofs.forEach(async roof => {
          if (floor.id == appModel.activeFloor.id && toResetOverhands) {
            roof.resetRoofOverhangs();
          }

          const soRoof = await SceneUtils.createMeshesFromRoof(roof, floor.rooms, this, heelHeight); // update soPreviewGables finishes
          soRoof.position.z = roofElevation;
          this.soRoomsRoot.add(soRoof);
        });
      }

      // TODO - remove this after the roof surfaces integration with our system classes is implemented by Oleg.
      // Commented by Niv because for now it doesn't have any effect and it calls `RoofUtils.getRoofSurfaces` again after `createMeshesFromRoof` is being called above.
      // For now we manipulate the roof surfaces directly and have no integration with our classes.
      // floor.roofs.forEach(async roof => {
      //   try {
      //     const roofSurfaces = await RoofUtils.getRoofSurfaces(roof);

      //     roof.roofEdges.forEach((edge, i) => {
      //       RoofUtils.getFoundEdgeType(roofSurfaces, edge, roof);
      //     });
      //   } catch (error) {
      //     console.error("Error fetching roof surfaces:", error);
      //   }
      // });
    }
  }

  private updateSoPreviewRoomsMaterials(): void {
    const soRooms = this.getSoPreviewRooms();
    soRooms.forEach(async soRoom => {
      const room = appModel.activeCorePlan.getRoom(soRoom.roomId);
      soRoom.walls.forEach(wall => {
        wall.updateExteriorFinish(room.exteriorFinishes[wall.index]);
      });
      soRoom.gables.forEach(gable => {
        gable.updateExteriorFinish(room.exteriorFinishes[gable.index]);
      });
    });
  }

  private updateIntersections(): void {
    if (this.controls instanceof TrackballControls) {
      const raycaster = new THREE.Raycaster();
      raycaster.setFromCamera(this.baseManager.mousePointer, this.camera);

      const intersections = raycaster.intersectObjects(this.soRoot.children, true);
      const point =
        intersections && intersections.length
          ? intersections[0].point
          : GeometryUtils.getSpaceIntersectionPoint(this.baseManager.mousePointer, this.camera, this.controls.target);
      this.controls.zoomPoint.copy(point);
    }
  }
  private updateCubeControls(): void {
    this.cubeControls.setQuaternion(this.camera.quaternion.clone());
  }
}

import * as THREE from "three";
import Utils from "./utils";
import RoofSurfaceOutput from "./roofSurfaceOutput";
import { EdgeFacadeDir } from "./edge";
import type EdgeOutput from "./edgeOutput";
import Polyline from "./polyline";

export default class Roof {
  public roofSurfaces: RoofSurfaceOutput[];

  constructor(roofSurface: RoofSurfaceOutput[]) {
    this.roofSurfaces = roofSurface;
  }

  public isEqual(other: Roof): boolean {
    if (!other) return false;
    if (other.roofSurfaces.length != this.roofSurfaces.length) return false;

    for (let i = 0; i < this.roofSurfaces.length; i++) {
      if (!other.roofSurfaces[i].isEqual(this.roofSurfaces[i])) return false; // not good, the list does not have to be in the same order to be equal
    }
    return true;
  }

  public getAllEdges(bBaseLevelOnly: boolean): EdgeOutput[] {
    let allEdges = [];
    for (const surface of this.roofSurfaces) {
      allEdges = allEdges.concat(surface.edges);
    }

    if (bBaseLevelOnly) allEdges = allEdges.filter(edge => edge.startPoint.z == 0 && edge.endPoint.z == 0);
    return allEdges;
  }

  public static findEdgeIndexForPoint(point: THREE.Vector3, edges: EdgeOutput[]): number {
    for (let idx = 0; idx < edges.length; idx++) {
      if (Utils.pointsAreCloseEnough(edges[idx].startPoint, point) || Utils.pointsAreCloseEnough(edges[idx].endPoint, point)) return idx;
    }

    return -1;
  }

  /// <summary>
  /// Updates the roof object with unioned polylines by converting them back into roof surfaces.
  /// </summary>
  /// <param name="roof">The roof object to be updated.</param>
  /// <param name="originalSurfaces">The original list of roof surfaces before the union operation.</param>
  /// <param name="unionedPolylines">The array of unioned polylines representing the merged roof surfaces.</param>
  /// <param name="slope">The slope value to be applied to the new surfaces.</param>
  /// <param name="dir">The direction of the edges in the facade.</param>
  /// <param name="holesInSurfaceGroup">A list of holes that need to be preserved in the new surfaces.</param>
  public updateRoofWithUnionedPolylines(
    originalSurfaces: RoofSurfaceOutput[],
    unionedPolylines: Polyline[],
    slope: number,
    dir: EdgeFacadeDir,
    holesInSurfaceGroup: THREE.Vector3[][]
  ) {
    for (const polyline of unionedPolylines) {
      // Convert each unioned polyline back to a RoofSurface
      const newSurface = polyline.convertPolylineToRoofSurface(originalSurfaces, slope, dir, holesInSurfaceGroup);
      this.roofSurfaces.push(newSurface);
    }
  }
}

import { observer } from "mobx-react-lite";
import { useState } from "react";

import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { Box, Button, Stack, Popover, TextField, InputLabel, InputAdornment, Tooltip, Typography, Dialog, DialogActions, DialogTitle } from "@mui/material";
import { UseFormReturn, Controller, RegisterOptions } from "react-hook-form";

import { appModel } from "../../../../models/AppModel";
import LoadingSpinner from "../../common/LoadingSpinner";
import { feetInchesFractionToInches, fractionalFeetInchesRegex, fractionalInchesRegex, fromDisplayValue, hexColorRegex } from "../../../../helpers/measures";
import { settings } from "../../../../entities/settings";
import "./SettingsTab.sass";
import { SettingsUnits, TSettingsValues, ParametersSettingsKeys } from "../../../../entities/settings/types";
import { defaultSettings, defaultUnits } from "../../../../entities/settings/defaults";
import { ChromePicker } from "react-color";
import { showToastMessage } from "../../../../helpers/messages";

export type SubCategorySettings = {
  subCategoryDisplayName?: string;
  subCategoryName: string;
  settings: string[];
  className?: string;
};

export type SettingsTabProps = {
  tabSettings: SubCategorySettings[];
  handleModalClose: () => void;
  form: UseFormReturn<any>;
  resetToDefaults: (values: TSettingsValues) => void;
  activeTabKey: string;
  tabKey: string;
};

const greaterThanZeroValidator = {
  validate: (value: number) => value >= 0 || "Value must be greater or equal to 0.",
};
const notEmptyValidator = {
  validate: (value: string) => !!value || "Value must be not empty.",
};
const feetInchesValidator = (setting: string, units: string) => {
  const incorrectFormatValidator = {
    validate: (value: string) => {
      return !!value.match(units === SettingsUnits.inches ? fractionalInchesRegex : fractionalFeetInchesRegex) || "Incorrect value format.";
    },
  };
  const positiveNumberValidator = {
    validate: (value: string) => {
      const result = incorrectFormatValidator.validate(value);
      if (result !== true) {
        return result;
      }
      return feetInchesFractionToInches(value) >= 0 || "Value must be greater or equal to 0.";
    },
  };
  const edgeOffsetValidator = {
    validate: (value: string) => {
      const result = positiveNumberValidator.validate(value);
      if (result !== true) {
        return result;
      }
      return feetInchesFractionToInches(value) <= 24 || "Value must be smaller or equal to 2’-0”.";
    },
  };

  if (setting === ParametersSettingsKeys.windowEdgeOffset || setting === ParametersSettingsKeys.doorEdgeOffset) {
    return edgeOffsetValidator;
  } else {
    return incorrectFormatValidator;
  }
};
const unitParametersRules: { [key: string]: (setting: string) => RegisterOptions } = {
  [SettingsUnits.inches]: setting => feetInchesValidator(setting, SettingsUnits.inches),
  [SettingsUnits.ft]: setting => feetInchesValidator(setting, SettingsUnits.ft),
  [SettingsUnits.units]: () => ({
    validate: (value: string) => Number.isInteger(Number.parseFloat(value)) || "Value must be an integer.",
  }),
  [SettingsUnits.hex]: () => ({
    validate: (value: string) => !!value.match(hexColorRegex) || "Incorrect value format.",
  }),
  [SettingsUnits.percent]: () => ({
    validate: (value: number) => (value >= 0 && value <= 100) || "Value must be between 0 and 100.",
  }),
  [SettingsUnits.sqft]: () => greaterThanZeroValidator,
  [SettingsUnits.plf]: () => greaterThanZeroValidator,
  [SettingsUnits.psf]: () => greaterThanZeroValidator,
  [SettingsUnits.img]: () => notEmptyValidator,
  [SettingsUnits.empty]: () => notEmptyValidator,
};

const disabledInputs = ["shearF2cap", "shearPreliminaryFactor"];

let SettingsTab = ({ tabSettings, handleModalClose, form, resetToDefaults, activeTabKey, tabKey }: SettingsTabProps) => {
  const [isUpdatingSettings, setIsUpdatingSettings] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null);
  const [activeColorSetting, setActiveColorSetting] = useState<{ category: string; setting: string } | null>(null);
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);

  const onColorChange = (categoryName: string, settingName: string, newColor: string) => {
    form.setValue(`${categoryName}.${settingName}`, newColor.slice(1).toUpperCase());
  };
  const handleColorPickerClick = (event: React.MouseEvent<HTMLButtonElement>, category: string, setting: string) => {
    setAnchorEl(event.currentTarget);
    setActiveColorSetting({ category, setting });
  };
  const handleColorPickerClose = () => {
    setAnchorEl(null);
    setActiveColorSetting(null);
  };

  const onCancelClick = () => {
    handleModalClose();
  };
  const onResetToDefaults = () => {
    setIsUpdatingSettings(true);
    resetToDefaults(defaultSettings);
    setIsUpdatingSettings(false);
    showToastMessage("Info", "The settings have been reset. Remember to save them.");
  };

  const handleConfirmDialogClose = () => {
    setOpenConfirmDialog(false);
  };
  const handleConfirmDialogOpen = () => {
    setOpenConfirmDialog(true);
  };
  const updateSettings = () => {
    handleConfirmDialogOpen();
  };
  const handleConfirm = async () => {
    const formFields = form.getValues();
    setIsUpdatingSettings(true);
    const newSettingsValues: TSettingsValues = Object.assign({}, settings.values);
    for (const settingCategoryKey in formFields) {
      if (Object.prototype.hasOwnProperty.call(newSettingsValues, settingCategoryKey)) {
        const apiSettings = Object.entries(formFields[settingCategoryKey]).reduce((acc, key: [string, string]) => {
          const unit = defaultUnits[settingCategoryKey][key[0]];
          acc[key[0]] = fromDisplayValue(key[1], unit);
          return acc;
        }, {});
        newSettingsValues[settingCategoryKey] = { ...newSettingsValues[settingCategoryKey], ...apiSettings };
      }
    }
    await settings.updateSettings(newSettingsValues);
    setIsUpdatingSettings(false);
    handleConfirmDialogClose();
    handleModalClose();
  };

  const handleErrors = errors => {
    for (const settingCategoryKey in errors) {
      for (const param in errors[settingCategoryKey]) {
        showToastMessage("Error", `${descriptions[settingCategoryKey][param]}: ${errors[settingCategoryKey][param].message}`);
      }
    }
  };

  if (appModel.isBusy) {
    return (
      <div style={{ textAlign: "center" }}>
        <LoadingSpinner />
        <p>Loading settings...</p>
      </div>
    );
  }

  const { descriptions, tooltips } = settings;

  return (
    <Box
      component="form"
      onSubmit={form.handleSubmit(updateSettings, errors => handleErrors(errors))}
      hidden={activeTabKey !== tabKey}
      className="settings-tab"
      autoComplete="off"
    >
      <Box className="settings-panel" sx={{ columnCount: { xs: 1, sm: 2, md: 3 } }}>
        {tabSettings.map((subCategory, index) => (
          <Box sx={{ breakInside: "avoid" }} key={index} className={`${subCategory.className || ""} sub-category`}>
            <div className="sub-category-name">{subCategory.subCategoryDisplayName}</div>
            {subCategory.settings.map((setting, index2) => {
              if (!setting) {
                return <div key={`break${index2}`} className="sub-category-break" />;
              }
              const description = descriptions[subCategory.subCategoryName][setting];
              const tooltip = tooltips[subCategory.subCategoryName][setting];
              const unit = defaultUnits[subCategory.subCategoryName][setting];
              const className = unit === SettingsUnits.hex ? "color" : "setting";

              const rules = unitParametersRules[unit](setting);
              rules.required = "Field is required";

              return (
                <Controller
                  key={`${subCategory.subCategoryName}.${setting}`}
                  name={`${subCategory.subCategoryName}.${setting}`}
                  control={form.control}
                  rules={rules}
                  render={({ field }) => {
                    if (unit === SettingsUnits.img) {
                      return <img src={field.value} alt={description} loading="lazy" className="setting-img" />;
                    }
                    return (
                      <Box key={setting} className={`${className}-row`}>
                        <Box className={`${className}-label`}>
                          <InputLabel hidden={!!tooltip}>{description}</InputLabel>
                          <Tooltip
                            hidden={!tooltip}
                            id={`${!!subCategory.className ? `${subCategory.className}-` : ""}${className}-tooltip`}
                            placement="bottom-start"
                            title={
                              <Box>
                                <Box className="tooltip-title">
                                  <InfoOutlinedIcon />
                                  <Typography variant="caption">{description}</Typography>
                                </Box>
                                <Typography variant="caption" className="tooltip-description">
                                  {tooltip}
                                </Typography>
                              </Box>
                            }
                          >
                            <InputLabel>{description}</InputLabel>
                          </Tooltip>
                        </Box>
                        <Button
                          hidden={unit !== SettingsUnits.hex}
                          className="color-rectangle"
                          style={{ backgroundColor: field.value?.match(hexColorRegex) ? `#${field.value}` : "unset" }}
                          onClick={e => handleColorPickerClick(e, subCategory.subCategoryName, setting)}
                        />
                        {activeTabKey === tabKey && (
                          <Tooltip
                            id="error-tooltip"
                            placement="bottom-start"
                            title={form.formState.errors?.[subCategory.subCategoryName]?.[setting]?.message || ""}
                            open={!!form.formState.errors?.[subCategory.subCategoryName]?.[setting]}
                          >
                            <TextField
                              {...field}
                              fullWidth
                              className="setting-input"
                              disabled={disabledInputs.includes(setting)}
                              id={`${[subCategory.subCategoryName]}_${[setting]}`}
                              error={!!form.formState.errors?.[subCategory.subCategoryName]?.[setting]}
                            />
                          </Tooltip>
                        )}
                        <InputAdornment position="end" className="setting-unit">
                          {unit}
                        </InputAdornment>
                      </Box>
                    );
                  }}
                />
              );
            })}
          </Box>
        ))}
      </Box>
      <Popover
        className="color-picker-popover"
        open={Boolean(anchorEl)}
        anchorEl={anchorEl}
        onClose={handleColorPickerClose}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
      >
        <Controller
          key="color-picker"
          name={`${activeColorSetting?.category}.${activeColorSetting?.setting}`}
          control={form.control}
          render={({ field }) => (
            <ChromePicker
              color={field.value}
              onChange={newColor => {
                onColorChange(activeColorSetting?.category, activeColorSetting?.setting, newColor.hex);
              }}
              disableAlpha
            />
          )}
        />
      </Popover>

      <Stack className="buttons-row" direction="row">
        <Button className="left-button" onClick={onResetToDefaults} variant="outlined">
          Reset to Default
        </Button>
        <Stack className="right-buttons" direction="row">
          <Button className="submit" disabled={isUpdatingSettings} type="submit" variant="contained">
            Save & Close
          </Button>
          <Button onClick={onCancelClick} variant="outlined">
            Cancel
          </Button>
        </Stack>
      </Stack>

      <Dialog open={openConfirmDialog} onClose={handleConfirmDialogClose} fullWidth={false} className="confirm-dialog">
        <DialogTitle>Are you sure you want to update system settings?</DialogTitle>
        <DialogActions>
          <Button onClick={handleConfirm} className="submit" variant="contained">
            Yes
          </Button>
          <Button onClick={handleConfirmDialogClose}>No</Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

SettingsTab = observer(SettingsTab);

export default SettingsTab;

import * as THREE from "three";
import Utils from "./utils";
import EdgeOutput from "./edgeOutput";
import { EdgeFacadeDir } from "./edge";
import Plane from "./plane";
import Polyline from "./polyline";

export default class RoofSurfaceOutput {
  public edges: EdgeOutput[];
  public slope: number;
  public direction: EdgeFacadeDir;

  public gableDepth: number;
  public holesInSurface: THREE.Vector3[][] | null;

  constructor(edges: EdgeOutput[], slope: number, dir: EdgeFacadeDir, gableDepth = 0, holesInSurface: THREE.Vector3[][] | null = null) {
    this.edges = edges;
    this.slope = slope;
    this.direction = dir;
    this.gableDepth = gableDepth;
    this.holesInSurface = holesInSurface;
  }

  public isEqual(other: RoofSurfaceOutput): boolean {
    if (!other) return false;

    if (other.edges.length != this.edges.length) return false;
    for (let i = 0; i < this.edges.length; i++) {
      if (!other.edges[i].isEqual(this.edges[i])) return false; // not good, the list does not have to be in the same order to be equal
    }

    if (other.slope != this.slope) return false;
    if (other.direction != this.direction) return false;
    if (other.gableDepth != this.gableDepth) return false;

    // TODO: check holes

    return true;
  }

  /// <summary>
  /// Calculates the plane defined by a given roof surface and checks if it matches any existing plane in the provided list of surfaces.
  /// </summary>
  /// <param name="surface">The roof surface for which the plane is being calculated.</param>
  /// <param name="allSurfaces">The list of all roof surfaces to check for plane equivalence.</param>
  /// <returns>The existing plane if a match is found; otherwise, returns the newly calculated plane.</returns>
  public getPlane(allSurfaces: RoofSurfaceOutput[]): Plane {
    // Assuming surface has at least three points to define a plane
    const nodes = this.getNodes();
    const pointA = nodes[0];
    const pointB = nodes[1];
    const pointC = nodes[2];
    const plane = new Plane(pointA, pointB, pointC);
    plane.setPlane();

    // Check if this plane is close to any existing plane in the list
    for (const existingSurface of allSurfaces) {
      if (this.isEqual(existingSurface)) continue;

      const existingSurfNodes = existingSurface.getNodes();
      const existingPlane = new Plane(existingSurfNodes[0], existingSurfNodes[1], existingSurfNodes[2]);
      existingPlane.setPlane();

      if (plane.isEqual(existingPlane)) return existingPlane; // Return the matching plane
    }

    return plane; // Return the new plane if no match is found
  }

  /// <summary>
  /// Converts a roof surface into a polyline by extracting the X and Y coordinates of its edges.
  /// </summary>
  /// <param name="surface">The roof surface to be converted into a polyline.</param>
  /// <returns>A Polyline object representing the edges of the roof surface.</returns>
  public convertSurfaceToPolyline(): Polyline {
    const vertices = this.edges.map(edge => new THREE.Vector2(edge.startPoint.x, edge.startPoint.y));
    return new Polyline(vertices, true);
  }

  public getDataInOutputFormat() {
    this.orderPolygonEdges();

    const result = {
      Slope: this.slope,
      Direction: this.direction,
      GableDepth: this.gableDepth,
      Edges: null,
      Nodes: null,
      HolesInSurface: [],
    };

    result.Edges = this.edges.map(edge => {
      return {
        StartNode: { X: edge.startPoint.x, Y: edge.startPoint.y, Z: edge.startPoint.z },
        EndNode: { X: edge.endPoint.x, Y: edge.endPoint.y, Z: edge.endPoint.z },
        EdgeType: edge.edgeType,
        InputEdgeIds: edge.inputEdgeIds.slice(),
        EffectiveOverhang: edge.effectiveOverhang,
      };
    });

    const nodes = this.getNodes();
    result.Nodes = nodes.map(point => {
      return { Node: { X: point.x, Y: point.y, Z: point.z } };
    });

    if (this.holesInSurface)
      result.HolesInSurface = this.holesInSurface.map((hole: THREE.Vector3[]) => {
        return hole.map(point => {
          return { X: point.x, Y: point.y, Z: point.z };
        });
      });

    return result;
  }

  /// <summary>
  /// Orders the edges of a polygon such that each edge's end node matches the start node of the next edge.
  /// </summary>
  public orderPolygonEdges() {
    const reOrder: EdgeOutput[] = [this.edges[0]];
    for (let i = 1; i < this.edges.length; i++) {
      for (const edge of this.edges) {
        if (Utils.contains(reOrder, edge)) continue;

        if (Utils.pointsAreCloseEnough(reOrder[reOrder.length - 1].endPoint, edge.startPoint)) {
          reOrder.push(edge);
        } else if (Utils.pointsAreCloseEnough(reOrder[reOrder.length - 1].endPoint, edge.endPoint)) {
          edge.flip();
          reOrder.push(edge);
        }
      }
    }
    this.edges = reOrder;
  }

  public getNodes(): THREE.Vector3[] {
    return this.edges.map(edge => edge.startPoint.clone());
  }

  public static removeCollinearPoints(surfaces) {
    for (const surface of surfaces) {
      surface.orderPolygonEdges();
      let bDidMerge;
      do {
        // set default angle for all merged edges
        bDidMerge = Utils.mergeContinuousOutputEdges(surface.edges);
      } while (bDidMerge);
    }
  }
}

import Utils from "./utils";
import type Node from "./node";
import type Line3dEquation from "./line3dEquation";
import Edge, { EdgeFacadeDir } from "./edge";
import type HoleInSurface from "./holeInSurface";
import type Gable from "./gable";

export default class RoofSurface {
  public edges: Edge[];
  public nodes: Node[];
  public slope: number;
  public direction: EdgeFacadeDir;

  public lines: Line3dEquation[];
  public holes: HoleInSurface[] = [];
  public dutchGableContourHoles: HoleInSurface[] = [];
  public dutchGableInnerHoles: HoleInSurface[] = [];

  constructor(edge: Edge, slope: number, dir: EdgeFacadeDir) {
    this.edges = [edge];
    this.nodes = [edge.startNode, edge.endNode];
    this.lines = [edge.line3dEquation];
    this.slope = slope;
    this.direction = dir;
  }

  public static setOrderOfRoofEdgesAndNodesOnEachSurface(contourEdges: Edge[], gables: Gable[]) {
    // set order in contourEdges
    for (const contourEdge of contourEdges) {
      if (!contourEdge.roofSurface) continue;

      // set edges order
      const polygonEdges: Edge[] = [contourEdge.roofSurface.edges[0]];
      for (let i = 1; i < contourEdge.roofSurface.edges.length; i++) {
        const last = polygonEdges[i - 1];
        for (const edge of contourEdge.roofSurface.edges) {
          if (Utils.contains(polygonEdges, edge)) continue;

          if (edge.startNode.isEqual(last.endNode)) {
            polygonEdges.push(edge);
            break;
          }
          if (edge.endNode.isEqual(last.endNode)) {
            [edge.startNode, edge.endNode] = [edge.endNode, edge.startNode];
            polygonEdges.push(edge);
            break;
          }
        }
        //TEMP: there are probably two surfaces, includes only the first one
        if (i == polygonEdges.length) {
          break;
        }
      }
      contourEdge.roofSurface.edges = polygonEdges;

      // set nodes order
      const polygonNodes: Node[] = [];
      for (const edge of contourEdge.roofSurface.edges) polygonNodes.push(edge.startNode);
      contourEdge.roofSurface.nodes = polygonNodes;
    }

    // set order in gables
    for (const gable of gables) {
      for (const roofSurface of gable.roofSurfaces) {
        if (!roofSurface) continue;

        // set edges order
        const polygonEdges: Edge[] = [roofSurface.edges[0]];
        for (let i = 1; i < roofSurface.edges.length; i++) {
          const last = polygonEdges[i - 1];
          for (const edge of roofSurface.edges) {
            if (Utils.contains(polygonEdges, edge)) continue;

            if (edge.startNode.isEqual(last.endNode)) {
              polygonEdges.push(edge);
              break;
            }
            if (edge.endNode.isEqual(last.endNode)) {
              [edge.startNode, edge.endNode] = [edge.endNode, edge.startNode];
              polygonEdges.push(edge);
              break;
            }
          }
          //TEMP: there are probably two surfaces, includes only the first one
          if (i == polygonEdges.length) {
            break;
          }
        }
        roofSurface.edges = polygonEdges;

        // set nodes order
        const polygonNodes: Node[] = [];
        for (const edge of roofSurface.edges) {
          polygonNodes.push(edge.startNode);
        }
        roofSurface.nodes = polygonNodes;
      }
    }
  }
}

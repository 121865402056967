import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Row } from "antd";

import { CorePlan } from "../../../../models/CorePlan";
import CorePlanVariationItem from "./CorePlanVariationItem";
import { appModel } from "../../../../models/AppModel";
import { Variation } from "../../../../models/Variation";

import "./TabCorePlanVariations.sass";

interface ITabCorePlanVariationsProps {
  corePlan: CorePlan;
}

const TabCorePlanVariations = observer(({ corePlan: corePlan }: ITabCorePlanVariationsProps) => {
  const variations = [...corePlan.variations].sort((a: Variation, b: Variation) => a.name.localeCompare(b.name));
  const [loadingCount, setLoadingCount] = useState(variations.length);

  useEffect(() => {
    if (loadingCount === 0) {
      appModel.setIsBusy(false);
    } else {
      appModel.setIsBusy(true);
    }
  }, [loadingCount]);

  return (
    <>
      <Row gutter={24} className="tab-core-plan-variations">
        {!variations.length && (
          <Col span={24}>
            <p>No elevations</p>
          </Col>
        )}
      </Row>
      {variations.length > 0 && (
        <div className="core-plan-variations-container">
          <Row className="tab-core-plan-variations-title">
            <Col>
              <p>Click on a elevation to view package files</p>
            </Col>
          </Row>
          <Row gutter={24} className="tab-core-plan-variations-list">
            {variations.map(item => (
              <CorePlanVariationItem key={item.id} variation={item} corePlan={corePlan} onLoadComplete={() => setLoadingCount(prev => prev - 1)} />
            ))}
          </Row>
        </div>
      )}
    </>
  );
});
export default TabCorePlanVariations;

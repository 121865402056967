import log from "loglevel";
import MathUtils from "../editor/utils/MathUtils";
import { SettingsUnits } from "../entities/settings/types";

const INCHES_IN_FOOT = 12;
const SQ_INCHES_IN_FOOT = INCHES_IN_FOOT * INCHES_IN_FOOT;

// ^(-)?: Optionally matches a negative sign at the start.
// (\d+)['’]?: Matches one or more digits followed by a foot symbol (' or ’)
// (?<inches>\d+)?: Optionally captures an integer representing the inches.
// \s*(\d+\/\d+)?: Optionally matches a fraction (e.g., 1/2 or 3/4).
// (?:"|”|''|’’)?: Optionally matches a double quote (" or ” or '' or ’’) at the end
// example: -1’-4 5/8” -> ["-1’-4 5/8”", "-", "1", "4", "5/8"]
export const fractionalFeetInchesRegex = /^(-)?(\d+)['’]?-?(?<inches>\d+)?\s*(\d+\/\d+)?(?:"|”|''|’’)?$/;

// same as fractionalFeetInchesRegex, but excluding feet
// example: 14 5/8” -> ["14 5/8”", null, "14", null, "5/8"]
export const fractionalInchesRegex = /^(-)?(\d+)\s*(\d+\/\d+)?(?:"|”|''|’’)?$/;

export const hexColorRegex = /^([A-Fa-f0-9]{6}|[A-Fa-f0-9]{3})$/;

export function roundPrecision(num: number | string, precision: number = 9): number {
  let result = typeof num === "number" ? num : parseFloat(num);
  if (Number.isNaN(result)) {
    log.error(`roundPrecision: num ${num} is NaN`);
    return NaN;
  }

  for (let i = 3; i >= 0; --i) {
    result = Number(result.toFixed(precision + i));
  }

  return result;
}

export function inches2feetSq(value?: number, precision?: number): number {
  return roundPrecision((value || 0) / SQ_INCHES_IN_FOOT, precision);
}
export function feet2inchesSq(value: number): number {
  return roundPrecision((value || 0) * SQ_INCHES_IN_FOOT);
}

export function inches2feet(value: number, precision?: number) {
  return roundPrecision((value || 0) / INCHES_IN_FOOT, precision);
}

export function feet2inches(value: number) {
  return roundPrecision((value || 0) * INCHES_IN_FOOT);
}

export const showFeet = (value: any, opts: "sqft" | "ft" = "sqft", defaultValue: any = "0.00") => {
  if (value === undefined || value === null) return defaultValue;
  if (typeof value === "string") {
    if (!value.length) return defaultValue;
    value = parseFloat(value);
    if (Number.isNaN(value)) return defaultValue;
  }

  value = opts === "ft" ? inches2feet(value) : inches2feetSq(value);

  return value.toFixed(opts === "sqft" ? 1 : 2);
};

export function inchesToFeetInchesFraction(inches: number, fractionalPrecision: number = 16, floorToTwo = false, inchesOnly: boolean = false): string {
  function gcd(a: number, b: number) {
    if (!b) {
      return a;
    } else {
      return gcd(b, a % b);
    }
  }
  const sign = inches < 0 ? "-" : "";
  inches = Math.abs(inches);

  let wholeFeet = Math.floor(inches / 12);
  const remainingInches = inches - wholeFeet * 12;
  let wholeInches = Math.floor(remainingInches);
  let fractionalInches = Math.round((remainingInches - wholeInches) * fractionalPrecision);

  const divisor = gcd(fractionalInches, fractionalPrecision);
  fractionalInches /= divisor;
  let denominator = fractionalPrecision / divisor;

  if (MathUtils.areNumbersEqual(fractionalInches, denominator)) {
    ++wholeInches;
    fractionalInches = 0;
    if (MathUtils.areNumbersEqual(wholeInches, 12)) {
      ++wholeFeet;
      wholeInches = 0;
    }
  }

  if (floorToTwo && denominator > 2 && fractionalInches > 2) {
    fractionalInches = 1;
    denominator = 2;
  } else if (floorToTwo && denominator > 2 && fractionalInches < 2) {
    fractionalInches = 0;
  }

  // inchesOnly is true -> 14 5/8”
  // inchesOnly is false -> 1’-2 5/8”
  const feetInchesFractionString =
    (inchesOnly ? wholeFeet * 12 + wholeInches : wholeFeet + "’-" + wholeInches) +
    (fractionalInches !== 0 ? " " + fractionalInches + "/" + denominator : "") +
    "”";

  return sign + feetInchesFractionString;
}
export function feetInchesFractionToInches(feetInchesFractional: string, inchesOnly: boolean = false): number | null {
  const match = feetInchesFractional.match(fractionalFeetInchesRegex);
  if (match) {
    const sign = match[1] ? -1 : 1;

    const feet = inchesOnly ? 0 : parseInt(match[2]);

    const inchesIndex = inchesOnly ? 2 : 3;
    const inches = match[inchesIndex] ? parseInt(match[inchesIndex]) : 0;

    const fraction = match[4] ? match[4].split("/") : ["0", "1"];
    const numerator = parseInt(fraction[0]);
    const denominator = parseInt(fraction[1]);

    return sign * (feet * 12 + inches + numerator / denominator);
  }

  return null;
}

export function getDisplayValue(value: any, unit: SettingsUnits) {
  switch (unit) {
    case SettingsUnits.ft:
      return inchesToFeetInchesFraction(value);
    case SettingsUnits.inches:
      return inchesToFeetInchesFraction(value, 16, false, true);
    case SettingsUnits.sqft:
      return roundPrecision(value / 144, 4).toString();
    case SettingsUnits.plf:
      return roundPrecision(value * 12, 4).toString();
    case SettingsUnits.psf:
      return roundPrecision(value * 144, 4).toString();
    case SettingsUnits.hex:
      return value.slice(1).toString();
    case SettingsUnits.percent:
      return roundPrecision(value * 100, 4).toString();
    case SettingsUnits.units:
    default:
      return value.toString();
  }
}

export function fromDisplayValue(value: any, unit: SettingsUnits): any {
  switch (unit) {
    case SettingsUnits.ft:
      return feetInchesFractionToInches(value);
    case SettingsUnits.inches:
      return feetInchesFractionToInches(value, true);
    case SettingsUnits.sqft:
      return value * 144;
    case SettingsUnits.plf:
      return value / 12;
    case SettingsUnits.psf:
      return value / 144;
    case SettingsUnits.hex:
      return `#${value}`;
    case SettingsUnits.percent:
      return value / 100;
    case SettingsUnits.units:
      return Number.parseInt(value);
    default:
      return value;
  }
}

export const numberToEnUsLocaleString = (value: number, precision: number = 1): string => {
  return MathUtils.round(value, precision).toLocaleString("en-US");
};

export const areaToEnUsLocaleString = (value: number, precision: number = 1): string => {
  return numberToEnUsLocaleString(inches2feetSq(value), precision);
};

export function createArray(min: number, max: number, increment = 1) {
  return Array.from({ length: Math.floor((max - min) / increment) + 1 }, (_, i) => min + i * increment);
}

import * as THREE from "three";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import VectorUtils from "../../utils/GeometryUtils/VectorUtils";
import MathUtils from "../../utils/MathUtils";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";

type ContourPoint = {
  point: THREE.Vector3;
  thickness: {
    // The thickness of the wall on the left and right sides
    left: number;
    right: number;
  };
  wallAlignment: number;
};

export default class soSpace {
  spaceType: string;
  private _contour: Map<string, soWall2D> = new Map();
  private _innerSegments: Map<string, soWall2D> = new Map();
  private _contourPoints: ContourPoint[] = [];
  containedRoomsIds: string[] = [];
  constructor() {
    // Initialize default properties here
  }
  /**
   * Adds a contour wall to the space if it does not already exist.
   * The method checks if the wall is already present in the contour. If not, it adds the wall to the contour map.
   * It then calculates the thickness and alignment of the wall. If the contour points array is empty, it adds the end point of the wall.
   * Otherwise, it matches the previously added point with the end point of the wall. If they are equal, it adds the start point of the wall;
   * otherwise, it adds the end point. This ensures that the points are added in the right sequence.
   * Adding the _contourPoints is similar to `getContourPointsByClockwiseDirection` logic.
   * @param wall - The wall to be added to the contour.
   */
  public addContourWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) {
      this._contour.set(wall.wallId, wall);
      const thickness = { left: wall.wallLeftSideThickness, right: wall.wallRightSideThickness };
      const wallAlignment = wall.wallOffset?.getAbsoleteDirectionOffset() || 0;
      if (this._contourPoints.length === 0) {
        this._contourPoints.push({
          point: VectorUtils.Vector2ToVector3(wall.end),
          thickness,
          wallAlignment,
        });
      } else {
        // We match the previously added point with the end point of the wall
        const prevPoint = this.contourPoints[this._contourPoints.length - 1];
        // If it's equal, we add the start point of the wall to the array, otherwise we add the end point
        // This way we ensure that the points are added in clockwise direction since wall directions are from bottom to top and from left to right
        const isEndPointEqualToPrev = VectorUtils.areVectorsEqual(VectorUtils.Vector2ToVector3(wall.end), prevPoint);
        const vector = isEndPointEqualToPrev ? wall.start : wall.end;
        this._contourPoints.push({ point: VectorUtils.Vector2ToVector3(vector), thickness, wallAlignment });
      }
    }
  }
  public addinternalWall(wall: soWall2D): void {
    if (!this._contour.has(wall.wallId)) this._innerSegments.set(wall.wallId, wall);
  }
  public addContainedRoom(roomId: string): void {
    if (!this.containedRoomsIds.includes(roomId)) this.containedRoomsIds.push(roomId);
  }
  public addContainedRooms(roomIds: string[]): void {
    roomIds.forEach(roomId => this.addContainedRoom(roomId));
  }
  get contour(): soWall2D[] {
    return Array.from(this._contour.values());
  }
  get innerSegments(): soWall2D[] {
    return Array.from(this._innerSegments.values());
  }
  get contourPoints(): THREE.Vector3[] {
    return this._contourPoints.map(contourPoint => contourPoint.point);
  }
  public containsSpace(space: soSpace): boolean {
    return space.contourPoints.every(point => this.containsPoint(point));
  }
  public containsPoint(point: THREE.Vector3): boolean {
    return GeometryUtils.isPointInsidePolygon(this.contourPoints, point);
  }
  public containsSegment(segment: soWall2D): boolean {
    return this.containsPoint(VectorUtils.Vector2ToVector3(segment.start)) || this.containsPoint(VectorUtils.Vector2ToVector3(segment.end));
  }
  /**
   * Retrieves the contour points of the space in clockwise direction.
   * This method iterates through the contour walls of the space and constructs
   * an array of points based on the direction of the walls.
   * `addContourWall` should do the same as this method but wall by wall.
   * @returns {THREE.Vector3[]} An array of `THREE.Vector3` points representing the contour of the space.
   * @deprecated `addContourWall` should take care of adding the contour points in the right order.
   */
  public getContourPointsByClockwiseDirection(): THREE.Vector3[] {
    const points = [];
    this.contour.forEach((wall, idx) => {
      if (!points.length) {
        // Add the first point to the array and continue
        points.push(VectorUtils.Vector2ToVector3(wall.end));
        return;
      }
      const prevPoint = points[idx - 1];
      // We match the previously added point with the end point of the wall
      const isEndPointEqualToPrev = MathUtils.areNumbersEqual(prevPoint.x, wall.end.x) && MathUtils.areNumbersEqual(prevPoint.y, wall.end.y);
      // If it's equal, we add the start point of the wall to the array, otherwise we add the end point
      // This way we ensure that the points are added in clockwise direction since wall directions are from bottom to top and from left to right
      const vector = isEndPointEqualToPrev ? wall.start : wall.end;
      points.push(VectorUtils.Vector2ToVector3(vector));
    });

    return points;
  }

  /**
   * Generates a polyline with offsets based on the contour points of the space.
   * The offsets are calculated based on the thickness and wall alignment of each point (each point is part of a wall).
   * @param [additionalOffset=0] - An additional offset to be applied to the polyline.
   * @returns {THREE.Vector3[]} An array of THREE.Vector3 points representing the expanded polyline with offsets.
   */
  public getPolylineWithOffsets(additionalOffset = 0): THREE.Vector3[] {
    const expandedVertices: THREE.Vector3[] = [];
    const vertices = this._contourPoints;
    for (let i = 0; i < vertices.length; i++) {
      // Get the previous, current, and next vertices
      const prev = vertices[i === 0 ? vertices.length - 1 : i - 1];
      const curr = vertices[i];
      const next = vertices[(i + 1) % vertices.length];

      // Calculate the direction vectors for the edges
      const prevEdgeDir = new THREE.Vector3().subVectors(curr.point, prev.point).normalize();
      const nextEdgeDir = new THREE.Vector3().subVectors(next.point, curr.point).normalize();

      // Calculate the perpendicular vector for the previous edge
      const prevEdgePerpendicular = new THREE.Vector3(-prevEdgeDir.y, prevEdgeDir.x, 0).normalize();

      // Get the offset (based on thickness)
      const thickness = curr.thickness;
      const offset = this.getOffsetForVectorBasedOnDirection(prevEdgeDir, thickness, curr.wallAlignment) + additionalOffset;

      // Apply the offset along the perpendicular vector
      const offsetVector = prevEdgePerpendicular.clone().multiplyScalar(offset);

      // Create a new point and apply the offset
      const newVertex = new THREE.Vector3(curr.point.x, curr.point.y, curr.point.z).add(offsetVector);

      // Get the thickness for the next vertex
      const nextThickness = next.thickness;
      const nextOffset = this.getOffsetForVectorBasedOnDirection(nextEdgeDir, nextThickness, next.wallAlignment) + additionalOffset;

      if (VectorUtils.areVectorsEqual(prevEdgeDir, nextEdgeDir)) {
        // Add the new point to the array
        expandedVertices.push(newVertex);
        if (offset !== nextOffset) {
          // In case of a straight line between walls, calculate the difference between the offsets and add another new point with the applied difference
          const offsetDiff = nextOffset - offset;
          const offsetVector = prevEdgePerpendicular.multiplyScalar(offsetDiff);
          expandedVertices.push(newVertex.clone().add(offsetVector));
        }
      } else {
        // In case of a corner, calculate the perpendicular vector for the next edge and apply the offset to newVertex vector
        const nextEdgePerpendicular = new THREE.Vector3(-nextEdgeDir.y, nextEdgeDir.x, 0).normalize();
        const nextOffsetVector = nextEdgePerpendicular.multiplyScalar(nextOffset);
        newVertex.add(nextOffsetVector);
        // Add the new point to the array after the next wall offset was applied
        expandedVertices.push(newVertex);
      }
    }

    return expandedVertices;
  }

  private getOffsetForVectorBasedOnDirection(vector: THREE.Vector3, wallThickness: ContourPoint["thickness"], wallAlignment: number): number {
    const direction = VectorUtils.getVectorDirection(vector);
    if (direction === "up" || direction === "right") {
      // Because of the direction of the walls (bottom => top, left => right), negative alignment is actually expanding the offset (negative minus negative is positive)
      return wallThickness.left - wallAlignment;
    } else if (direction === "down" || direction === "left") {
      return wallThickness.right + wallAlignment;
    }
    return 0;
  }

  /**
   * In some cases, the contour points of a space may not be in clockwise order because of the way they were added.
   * This method ensures that the contour points are in clockwise order by checking the signed area of the polygon defined by the contour points.
   */
  public ensureClockwiseContour(): void {
    const signedArea = this.calculateSignedArea();
    if (signedArea > 0) {
      // If the signed area is positive reverse the contour points to ensure they are in clockwise order
      this._contourPoints.reverse();
    }
  }

  /**
   * Calculates the signed area of the polygon defined by the contour points based on Shoelace formula.
   * The signed area is positive if the points are ordered counterclockwise,
   * and negative if they are ordered clockwise.
   * @returns {number} The signed area of the polygon.
   */
  private calculateSignedArea(): number {
    let area = 0;
    const n = this._contourPoints.length;

    for (let i = 0; i < n; i++) {
      const current = this._contourPoints[i];
      const next = this._contourPoints[(i + 1) % n];
      area += current.point.x * next.point.y - next.point.x * current.point.y;
    }

    return area / 2;
  }
}

import * as THREE from "three";
import Utils from "./utils";
import type Node from "./node";
import type Edge from "./edge";
import Roof from "./roof";
import RoofSurfaceOutput from "./roofSurfaceOutput";
import EdgeOutput from "./edgeOutput";
import Polyline from "./polyline";

export default class HoleInSurface {
  public edges: Edge[];
  public nodes: Node[];

  constructor(edges: Edge[], nodes: Node[]) {
    this.edges = edges;
    this.nodes = nodes;
  }

  public static createHolesInSurfacesDueToMiniGables(roof: Roof, contourEdges: Edge[]) {
    for (let i = 0; i < contourEdges.length; i++) {
      const contourEdge: Edge = contourEdges[i];
      if (!contourEdge.roofSurface) continue;

      contourEdge.roofSurface.holes = contourEdges[i].isVertical
        ? [...contourEdge.roofSurface.holes.sort((h1, h2) => h1.edges[0].startNode.point3D.y - h2.edges[0].startNode.point3D.y)]
        : [...contourEdge.roofSurface.holes.sort((h1, h2) => h1.edges[0].startNode.point3D.x - h2.edges[0].startNode.point3D.x)];

      const roofSurfaceOutput: RoofSurfaceOutput = roof.roofSurfaces[i];
      const originalSecondEdge: EdgeOutput = roofSurfaceOutput.edges[1];
      const originalLastEdge: EdgeOutput = roofSurfaceOutput.edges[roofSurfaceOutput.edges.length - 1];

      const holes: HoleInSurface[] = contourEdge.roofSurface.holes;
      if (holes.length == 0) {
        continue;
      }
      roofSurfaceOutput.edges.shift();

      let indexForAddingEdgesOnTheContour = 0;

      for (let j = 0; j < holes.length; j++) {
        const currentHole: HoleInSurface = contourEdge.roofSurface.holes[j];
        let lastHole: HoleInSurface | null = null;
        if (j > 0) {
          lastHole = contourEdge.roofSurface.holes[j - 1];
        }

        // if hole locate at the begining of the surface
        if (Utils.pointsAreCloseEnough(currentHole.edges[0].startNode.point3D, contourEdge.startNode.point3D)) {
          const edgeLast1 = new EdgeOutput(originalLastEdge.startPoint, currentHole.edges[2].endNode.point3D);
          const edgeLast2 = new EdgeOutput(currentHole.edges[2].endNode.point3D, currentHole.edges[2].startNode.point3D);

          // Find and remove the edge matching originalLastEdge
          roofSurfaceOutput.edges = roofSurfaceOutput.edges.filter(
            edge =>
              !(
                Utils.pointsAreCloseEnough(edge.startPoint, originalLastEdge.startPoint) && Utils.pointsAreCloseEnough(edge.endPoint, originalLastEdge.endPoint)
              )
          );

          roofSurfaceOutput.edges.push(edgeLast1);
          roofSurfaceOutput.edges.push(edgeLast2);

          // if it is the only hole
          if (holes.length == 1) {
            const afterHole = new EdgeOutput(currentHole.edges[0].endNode.point3D, contourEdge.endNode.point3D);
            roofSurfaceOutput.edges.splice(0, 0, afterHole);
          }
        }
        // if hole locate at the end of the surface
        else if (Utils.pointsAreCloseEnough(currentHole.edges[0].endNode.point3D, contourEdge.endNode.point3D)) {
          const edge1 = new EdgeOutput(currentHole.edges[1].startNode.point3D, currentHole.edges[1].endNode.point3D);
          const edge2 = new EdgeOutput(currentHole.edges[1].endNode.point3D, originalSecondEdge.endPoint);

          const secondEdgeIndex = roofSurfaceOutput.edges.findIndex(
            edge =>
              Utils.pointsAreCloseEnough(edge.startPoint, originalSecondEdge.startPoint) &&
              Utils.pointsAreCloseEnough(edge.endPoint, originalSecondEdge.endPoint)
          );

          if (secondEdgeIndex != -1) {
            roofSurfaceOutput.edges.splice(secondEdgeIndex, 0, edge2);
            roofSurfaceOutput.edges.splice(secondEdgeIndex, 0, edge1);
          }

          const indexToRemove = roofSurfaceOutput.edges.findIndex(
            edge =>
              Utils.pointsAreCloseEnough(edge.startPoint, originalSecondEdge.startPoint) &&
              Utils.pointsAreCloseEnough(edge.endPoint, originalSecondEdge.endPoint)
          );

          if (indexToRemove != -1) {
            roofSurfaceOutput.edges.splice(indexToRemove, 1);
          }

          // if it is the only hole
          if (holes.length == 1) {
            const beforHole = new EdgeOutput(contourEdge.startNode.point3D, currentHole.edges[0].startNode.point3D);
            roofSurfaceOutput.edges.splice(0, 0, beforHole);
          }
          // if it is not the only hole
          else {
            if (lastHole == null) continue;
            const beforHole = new EdgeOutput(lastHole.edges[0].endNode.point3D, currentHole.edges[0].startNode.point3D);
            roofSurfaceOutput.edges.splice(indexForAddingEdgesOnTheContour, 0, beforHole);
          }
        }

        // if gable in the middle
        else if (
          !Utils.pointsAreCloseEnough(currentHole.edges[0].startNode.point3D, contourEdge.startNode.point3D) &&
          !Utils.pointsAreCloseEnough(currentHole.edges[0].endNode.point3D, contourEdge.endNode.point3D)
        ) {
          let beforeHole: EdgeOutput;
          // if it is the first gable
          if (j == 0) {
            beforeHole = new EdgeOutput(contourEdge.startNode.point3D, currentHole.edges[0].startNode.point3D);
          } else {
            if (lastHole == null) continue;
            beforeHole = new EdgeOutput(lastHole.edges[0].endNode.point3D, currentHole.edges[0].startNode.point3D);
          }
          const edge1 = new EdgeOutput(currentHole.edges[1].startNode.point3D, currentHole.edges[1].endNode.point3D);
          const edge2 = new EdgeOutput(currentHole.edges[2].endNode.point3D, currentHole.edges[2].startNode.point3D);

          roofSurfaceOutput.edges.splice(indexForAddingEdgesOnTheContour, 0, edge2);
          roofSurfaceOutput.edges.splice(indexForAddingEdgesOnTheContour, 0, edge1);
          roofSurfaceOutput.edges.splice(indexForAddingEdgesOnTheContour, 0, beforeHole);
          indexForAddingEdgesOnTheContour += 3;

          // if it is the only hole or it is the last hole
          if (holes.length == 1 || j == holes.length - 1) {
            const afterHole = new EdgeOutput(currentHole.edges[0].endNode.point3D, contourEdge.endNode.point3D);
            roofSurfaceOutput.edges.splice(indexForAddingEdgesOnTheContour, 0, afterHole);
          }
        }
      }
    }
  }

  public static createHolesInSurfacesDueToDutchGables(roof: Roof, contourEdges: Edge[]) {
    for (let i = 0; i < contourEdges.length; i++) {
      const contourEdge: Edge = contourEdges[i];
      if (!contourEdge.roofSurface) continue;

      contourEdge.roofSurface.holes = contourEdges[i].isVertical
        ? [...contourEdge.roofSurface.holes.sort((h1, h2) => h1.edges[0].startNode.point3D.y - h2.edges[0].startNode.point3D.y)]
        : [...contourEdge.roofSurface.holes.sort((h1, h2) => h1.edges[0].startNode.point3D.x - h2.edges[0].startNode.point3D.x)];

      const roofSurfaceOutput: RoofSurfaceOutput = roof.roofSurfaces[i];

      const holes: HoleInSurface[] = contourEdge.roofSurface.dutchGableContourHoles;
      if (holes.length == 0) {
        continue;
      }

      const roofSurfaceVector2Nodes: THREE.Vector2[] = [];
      const roofSurfaceNodes = roofSurfaceOutput.getNodes();
      for (let j = 0; j < roofSurfaceNodes.length; j++) {
        roofSurfaceVector2Nodes[j] = new THREE.Vector2(roofSurfaceNodes[j].x, roofSurfaceNodes[j].y);
      }
      let roofSurfacePolyline = new Polyline(roofSurfaceVector2Nodes, true);

      for (const hole of holes) {
        const holeVector2Node: THREE.Vector2[] = [];
        for (let j = 0; j < hole.nodes.length; j++) {
          holeVector2Node[j] = new THREE.Vector2(hole.nodes[j].point3D.x, hole.nodes[j].point3D.y);
        }
        const holePolyline = new Polyline(holeVector2Node, true);

        roofSurfacePolyline = Polyline.difference([roofSurfacePolyline], [holePolyline])[0];
      }

      const points: THREE.Vector3[] = [];
      for (const point of roofSurfacePolyline.vertices) {
        if (Utils.tryAddNodeFromRoofSurface(point, roofSurfaceNodes, points) || Utils.tryAddNodeFromHoles(point, holes, points)) continue;
      }

      // Create edges from the nodes
      const edges: EdgeOutput[] = [];
      for (let j = 0; j < points.length; j++) {
        const startPoint: THREE.Vector3 = points[j];
        const endPoint: THREE.Vector3 = points[(j + 1) % points.length];
        edges.push(new EdgeOutput(startPoint, endPoint));
      }

      roofSurfaceOutput.edges = edges;
    }
  }

  /// <summary>
  /// Filters and preserves the holes in the surface group that are inside the newly created edges.
  /// </summary>
  /// <param name="holesInSurfaceGroup">A list of holes to be checked and filtered.</param>
  /// <param name="edges">The edges of the newly created surface to check for containment.</param>
  /// <returns>A list of holes that are inside the new surface.</returns>
  public static filterHolesInSurfaceGroup(holesInSurfaceGroup: THREE.Vector3[][], edges: Edge[]): THREE.Vector3[][] {
    const holesInSurface: THREE.Vector3[][] = [];
    for (const hole of holesInSurfaceGroup) {
      if (Utils.pointIsInsidePolygon(hole[0], 0, edges)) holesInSurface.push(hole);
    }
    return holesInSurface;
  }
}

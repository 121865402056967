import { appModel } from "../../models/AppModel";
import { soRoom2D } from "../models/SceneObjects/Room/soRoom2D";
import { soWall2D } from "../models/SceneObjects/Wall/soWall2D";
import WallUtils from "./WallUtils";
import WallOverrides from "../models/SceneObjects/Wall/wallOverrides";
import WallFuncInfo from "../models/SceneObjects/Wall/WallFuncInfo";

/**
 * Utility class for handling operations related to wall overrides.
 */
export default class WallOverrideUtils {
  static getExistingWallOverrides(room: soRoom2D): WallOverrides[] {
    const modifications = appModel.baseManager.roomManager.getRoomModificationsData();
    const oldWallOverrides = new Map<string, WallOverrides>();

    room?.wallOverrides.forEach((value, key) => {
      oldWallOverrides.set(key, value);
    });

    modifications.forEach(override => {
      const wallId = override.wallId;
      const newWallOverride = new WallOverrides(wallId);
      newWallOverride.FunctionCode = override.functionCode;
      if (override.offset) {
        newWallOverride.Offset = override.offset;
      }
      oldWallOverrides.set(wallId, newWallOverride);
    });

    if (!oldWallOverrides.size) return;

    const oldWallOverridesArray = [...new Set([...oldWallOverrides.values()])];
    return oldWallOverridesArray;
  }

  static haveIdenticalOrder(array1: WallFuncInfo[], array2: WallFuncInfo[]): boolean {
    // Check if the sizes of both arrays are the same
    if (array1.length !== array2.length) {
      return false;
    }

    // Check if the func codes are in the same order
    for (let i = 0; i < array1.length; i++) {
      if (!this.sameKeys(array1[i].Key, array2[i].Key)) {
        return false;
      }
    }

    return true;
  }

  static sameKeys(key1: string | string[], key2: string | string[]): boolean {
    const keys1 = Array.isArray(key1) ? key1 : [key1];
    const keys2 = Array.isArray(key2) ? key2 : [key2];

    // Check if the sizes of both arrays are the same
    if (keys1.length !== keys2.length) {
      return false;
    }

    return keys1.every(parent1 => keys2.some(parent2 => parent1 === parent2));
  }

  static updateWallOverrides(room: soRoom2D, oldWall: soWall2D, newWall: soWall2D, oldWallOverrides: WallOverrides[], deleteOldWall: boolean = true) {
    const oldWallOverride = oldWallOverrides?.find(o => o.WallId === oldWall.wallId);
    if (!oldWallOverride) return;

    newWall.updateProperties(oldWallOverride);

    if (oldWall.wallId === newWall.wallId) return;

    if (deleteOldWall) {
      appModel.removeModifiedSegmentWall(oldWall);
      room.wallOverrides.delete(oldWall.wallId);
    }

    appModel.addModifiedSegmentWall(newWall);

    const newWallOverride = room.getWallOveride(newWall.wallId);
    newWallOverride.FunctionCode = newWall.FunctionCode;
    newWallOverride.Offset = newWall.wallOffset?.getAbsoleteDirectionOffset() ?? 0;
    room.wallOverrides.set(newWall.wallId, newWallOverride);
  }

  static deleteWallOverrides(room: soRoom2D, oldWall: soWall2D, oldWallOverrides: WallOverrides[]) {
    const oldWallOverride = oldWallOverrides?.find(o => o.WallId === oldWall.wallId);
    if (!oldWallOverride) return;

    appModel.addModifiedSegmentWall(oldWall);
    room.wallOverrides.delete(oldWall.wallId);
  }

  static getWallsBySameOrder(
    oldArray: WallFuncInfo[],
    newArray: WallFuncInfo[],
    idx: number,
    diffToCompare: number = 1,
    tail1: WallFuncInfo[] = [],
    tail2: WallFuncInfo[] = []
  ): WallFuncInfo[][] {
    const totalDiff = oldArray.length - newArray.length;
    let oldArray1;
    let newArray1;

    if (totalDiff > 0) {
      oldArray1 = idx === 0 ? oldArray.slice(0, oldArray.length - diffToCompare) : idx === -1 ? oldArray.slice(-oldArray.length + diffToCompare) : oldArray;
      newArray1 = newArray;
    } else if (totalDiff < 0) {
      if (idx === 0) {
        newArray1 = newArray.slice(0, newArray.length - diffToCompare);
        tail1 = newArray.length > 1 ? newArray.slice(newArray.length - diffToCompare, newArray.length) : [];
      } else if (idx === -1) {
        newArray1 = newArray.slice(-newArray.length + diffToCompare);
        tail2 = newArray.length > 1 ? newArray.slice(0, -newArray.length + diffToCompare) : [];
      } else {
        newArray1 = newArray;
      }
      oldArray1 = oldArray;
    } else {
      return WallOverrideUtils.haveIdenticalOrder(oldArray, newArray) ? [oldArray, newArray, tail1, tail2] : [];
    }

    const newFuncCodeArray1 = WallOverrideUtils.getWallsBySameOrder(oldArray1, newArray1, 0, 1, tail1, tail2);
    const newFuncCodeArray2 = WallOverrideUtils.getWallsBySameOrder(oldArray1, newArray1, -1, 1, tail1, tail2);

    if (!newFuncCodeArray1.length) return newFuncCodeArray2;
    if (!newFuncCodeArray2.length) return newFuncCodeArray1;
    return newFuncCodeArray1[0].length >= newFuncCodeArray2[0].length ? newFuncCodeArray1 : newFuncCodeArray2;
  }

  static orderWallsByFuncCode(wallsBySide: soWall2D[]): WallFuncInfo[] {
    const wallsArrayByFuncCode: WallFuncInfo[] = [];
    let wall = wallsBySide[0];
    // if wall is old
    if (wall.WallSides.left === undefined && wall.WallSides.right === undefined) {
      if (wall.parentRoomSides?.length > 0) wall.WallSides.right = wall.parentRoomSides[0];
      if (wall.parentRoomSides?.length > 1) wall.WallSides.left = wall.parentRoomSides[1];
    }

    let wallFunc: string = WallUtils.generateWallFunctionCode(wall).replace("EXT_", "INT_");
    let wallsByFuncCode = [];

    for (let i = 0; i < wallsBySide.length; i++) {
      wall = wallsBySide[i];
      // if wall is old
      if (wall.WallSides.left === undefined && wall.WallSides.right === undefined) {
        if (wall.parentRoomSides?.length > 0) wall.WallSides.right = wall.parentRoomSides[0];
        if (wall.parentRoomSides?.length > 1) wall.WallSides.left = wall.parentRoomSides[1];
      }

      const newWallFunc = WallUtils.generateWallFunctionCode(wall).replace("EXT_", "INT_");
      if (wallFunc === newWallFunc) {
        wallsByFuncCode.push(wall);
      } else {
        wallsArrayByFuncCode.push(new WallFuncInfo(wallFunc, wallsByFuncCode));
        wallFunc = newWallFunc;
        wallsByFuncCode = [wall];
      }
    }
    wallsArrayByFuncCode.push(new WallFuncInfo(wallFunc, wallsByFuncCode));

    return wallsArrayByFuncCode;
  }

  static updateWallArrays(room: soRoom2D, oldArray: soWall2D[], newArray: soWall2D[], oldWallOverrides: WallOverrides[]) {
    const oldProcessedWalls: soWall2D[] = [];
    const newProcessedWalls: soWall2D[] = [];

    if (oldArray.length === 1 && newArray.length === 1) {
      WallOverrideUtils.updateWallOverrides(room, oldArray[0], newArray[0], oldWallOverrides);
      return;
    }

    // Process walls with the same parents
    newArray.forEach(newWall => {
      if (newWall.parentRoomSides.length !== 2) return;

      const oldWall = oldArray?.find(oWall => {
        if (newWall.parentRoomSides.length !== oWall.parentRoomSides.length) return false;
        if (oldProcessedWalls?.find(pw => pw.wallId === oWall.wallId)) return;
        newWall.parentRoomSides.forEach(newParent => {
          if (!oWall.parentRoomSides?.find(oldParent => oldParent.RoomId === newParent.RoomId && oldParent.RoomSide === newParent.RoomSide)) return false;
        });
        return true;
      });

      if (!oldWall) return;

      WallOverrideUtils.updateWallOverrides(room, oldWall, newWall, oldWallOverrides);

      oldProcessedWalls.push(oldWall);
      newProcessedWalls.push(newWall);
    });

    // Process walls with the same neighbor
    newArray.forEach((newWall, newIdx) => {
      if (newProcessedWalls?.find(pw => pw.wallId === newWall.wallId)) return;

      const newNeighbor1 = newIdx === 0 ? null : newArray[newIdx - 1];
      const newNeighbor2 = newIdx === newArray.length - 1 ? null : newArray[newIdx + 1];

      const oldWall = oldArray?.find((oWall, oldIdx) => {
        if (oldProcessedWalls?.find(pw => pw.wallId === oWall.wallId)) return;

        let hasCommonNeighbor = false;
        const oldNeighbor1 = oldIdx === 0 ? null : oldArray[oldIdx - 1];
        const oldNeighbor2 = oldIdx === oldArray.length - 1 ? null : oldArray[oldIdx + 1];

        if (newNeighbor1 && oldNeighbor1 && newNeighbor1.parentRoomSides.length === oldNeighbor1.parentRoomSides.length) {
          hasCommonNeighbor = true;
          newNeighbor1.parentRoomSides.forEach(newParent => {
            if (!oldNeighbor1.parentRoomSides?.find(oldParent => oldParent.RoomId === newParent.RoomId && oldParent.RoomSide === newParent.RoomSide)) {
              hasCommonNeighbor = false;
              return;
            }
          });
        }

        if (!hasCommonNeighbor && newNeighbor2 && oldNeighbor2 && newNeighbor2.parentRoomSides.length === oldNeighbor2.parentRoomSides.length) {
          hasCommonNeighbor = true;
          newNeighbor2.parentRoomSides.forEach(newParent => {
            if (!oldNeighbor2.parentRoomSides?.find(oldParent => oldParent.RoomId === newParent.RoomId && oldParent.RoomSide === newParent.RoomSide)) {
              hasCommonNeighbor = false;
              return;
            }
          });
        }

        return hasCommonNeighbor;
      });

      if (!oldWall) return;

      WallOverrideUtils.updateWallOverrides(room, oldWall, newWall, oldWallOverrides);

      oldProcessedWalls.push(oldWall);
      newProcessedWalls.push(newWall);
    });

    // Process the corners
    if (newArray.length > 1 && !newProcessedWalls.find(pw => pw.wallId === newArray[0].wallId)) {
      WallOverrideUtils.updateWallOverrides(room, newArray[1], newArray[0], oldWallOverrides);
      newProcessedWalls.push(newArray[0]);
    }

    if (newArray.length > 1 && !newProcessedWalls.find(pw => pw.wallId === newArray[newArray.length - 1].wallId)) {
      WallOverrideUtils.updateWallOverrides(room, newArray[newArray.length - 2], newArray[newArray.length - 1], oldWallOverrides);
      newProcessedWalls.push(newArray[newArray.length - 1]);
    }

    if (newArray.length === 1 && !newProcessedWalls.find(pw => pw.wallId === newArray[0].wallId)) {
      WallOverrideUtils.updateWallOverrides(room, oldArray[Math.ceil(oldArray.length / 2)], newArray[0], oldWallOverrides);
      newProcessedWalls.push(newArray[0]);
    }

    // Remove old unnecessary overrides
    oldArray.forEach(w => {
      if (!oldProcessedWalls.find(pw => pw.wallId === w.wallId)) WallOverrideUtils.deleteWallOverrides(room, w, oldWallOverrides);
    });
  }
}

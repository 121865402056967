export default class CoreUtils {
  static clone(obj, optExcludedAttrs, bKeepBabylonClasses) {
    let copy;

    // Handle the 3 simple types, and null or undefined
    if (null === obj || "object" !== typeof obj) return obj;

    // Handle Date
    if (obj instanceof Date) {
      copy = new Date();
      copy.setTime(obj.getTime());
      return copy;
    }

    // Handle Array
    if (obj instanceof Array) {
      copy = [];
      for (let i = 0, len = obj.length; i < len; i++) copy[i] = CoreUtils.clone(obj[i], optExcludedAttrs, bKeepBabylonClasses);
      return copy;
    }

    if (bKeepBabylonClasses) {
      if (obj.clone) return obj.clone();
    }

    // Handle Object
    if (obj instanceof Object) {
      copy = {};
      for (const attr in obj) {
        if (optExcludedAttrs && optExcludedAttrs.indexOf(attr) >= 0) continue;

        if (obj.hasOwnProperty(attr)) {
          // In newer versions of BJS, they renamed properties like '.x' to '._x', and
          // provided setters/getters named x(). However, since those are getters/setters,
          // we don't copy them here. Thus, we check if the current property starts with
          // an underscore, and if so, we check if it has a getter that evaluates to the
          // same value. If that's the case, we use that name instead:
          let copiedAttrName = attr;
          if (attr[0] === "_" && obj[attr.substr(1)] === obj[attr]) copiedAttrName = attr.substr(1);
          copy[copiedAttrName] = CoreUtils.clone(obj[attr], optExcludedAttrs, bKeepBabylonClasses);
        }
      }
      return copy;
    }

    throw new Error("Unable to copy obj! Its type isn't supported.");
  }

  static interpolate(t, a, b) {
    return (1 - t) * a + t * b;
  }

  static interpolateCircular(t, a, b) {
    const t2 = Math.sqrt(1.0 - t * t);
    return t2 * a + (1.0 - t2) * b;
  }

  static parseStringToDecimal(input) {
    const numberMatches = input.match(this.REAL_NUMBERS_REGEX);
    if (!numberMatches) {
      return null;
    }

    return numberMatches[0];
  }

  static nicefyNameForUI(origName, bRemoveShortAndNumericWords = false) {
    if (!origName || origName.length === 0) return "";

    let res = origName[0];
    for (let idx = 1; idx < origName.length; idx++) {
      if (origName[idx] === " " || origName[idx] === "." || origName[idx] === "-") {
        res += "_";
        continue;
      }

      // Insert an underscore whenever we change from lower to upper ase
      const isCurrentUpperCase = origName[idx] == origName[idx].toUpperCase();
      const isCurrentNumber = !isNaN(origName[idx] * 1);
      const isPreviousLowerCase = origName[idx - 1] == origName[idx - 1].toLowerCase();
      const isPreviousNumber = !isNaN(origName[idx - 1] * 1);

      if (
        isCurrentUpperCase &&
        (isPreviousLowerCase || isPreviousNumber) &&
        !(isCurrentNumber && isPreviousNumber) &&
        res[idx - 1] != "_" &&
        origName[idx] != "_"
      )
        res += "_";
      res += origName[idx];
    }

    const splitArray = res.split("_");
    const finalArray = [];
    for (let idx = 0; idx < splitArray.length; idx++) {
      const currToken = splitArray[idx];
      if (bRemoveShortAndNumericWords && currToken.length <= 1) continue;

      if (bRemoveShortAndNumericWords && /^[0-9]*$/.test(currToken)) continue;

      if (currToken.indexOf("LOD") === 0) continue;

      finalArray.push(currToken);
    }

    let finalName = finalArray.join(" ");
    finalName = CoreUtils.capitalizeFirstLetterAllWords(finalName);

    return finalName;
  }

  static capitalizeFirstLetterAllWords(string) {
    return string.replace(/\w\S*/g, CoreUtils.capitalizeFirstLetter);
  }

  static capitalizeFirstLetter(string) {
    return string.charAt(0).toUpperCase() + string.slice(1);
  }

  static downloadString(filename, text) {
    const pom = document.createElement("a");
    pom.setAttribute("href", "data:text/plain;charset=utf-8," + encodeURIComponent(text));
    pom.setAttribute("download", filename);

    if (document.createEvent) {
      const event = document.createEvent("MouseEvents");
      event.initEvent("click", true, true);
      pom.dispatchEvent(event);
    } else {
      pom.click();
    }
  }

  static mode(arrayIn, equalityEpsilon = 0.0) {
    const array = arrayIn.slice().sort((x, y) => x - y);

    let bestStreak = 1;
    let bestElem = array[0];
    let currentStreak = 1;
    let currentElem = array[0];

    for (let i = 1; i < array.length; i++) {
      if (Math.abs(array[i - 1] - array[i]) > equalityEpsilon) {
        if (currentStreak > bestStreak) {
          bestStreak = currentStreak;
          bestElem = currentElem;
        }

        currentStreak = 0;
        currentElem = array[i];
      }

      currentStreak++;
    }

    return currentStreak > bestStreak ? currentElem : bestElem;
  }
}

import * as THREE from "three";
import { SoPreviewFace } from "./SoPreviewFace";
import { Room } from "../../../models/Room";
import { Segment } from "../segments/Segment";
import log from "loglevel";

export class SoPreviewGable extends SoPreviewFace {
  public static readonly OVERLAPPING_PRECISION = 20.0;
  private _roofBaseSegment: Segment;
  private _rooms: Room[];

  constructor(geometry: THREE.Geometry, material: THREE.MeshStandardMaterial, index: number) {
    super(geometry, material, index, "", null, 0, 0, new THREE.Vector3(), new THREE.Euler(), false);
    // index: 0 (front), 1 (right), 2 (back), 3 (left)
  }

  public get roofBaseSegment(): Segment {
    return this._roofBaseSegment;
  }

  public set roofBaseSegment(segment: Segment) {
    this._roofBaseSegment = segment;
  }

  public get rooms(): Room[] {
    return this._rooms;
  }

  public set rooms(rooms: Room[]) {
    this._rooms = rooms;
  }

  public CalcRoofBaseSegment(geometry: THREE.Geometry): Segment | null {
    // Find the minimum Z value among all vertices
    const minZ = Math.min(...geometry.vertices.map(vertex => vertex.z));

    // Find all vertices in geometry with the minimum Z value
    const vertices = geometry.vertices.filter(vertex => vertex.z === minZ);

    // Log warning if there are more or less than 2 vertices
    if (vertices.length !== 2) {
      log.warn(`Expected exactly 2 vertices with z = 0, but found: ${vertices.length}`);
      return null;
    }

    const [start, end] = vertices;

    const tolerance = 0.001;
    const isParallelToXAxis = Math.abs(start.y - end.y) < tolerance;
    const isParallelToYAxis = Math.abs(start.x - end.x) < tolerance;

    // Log warning if the segment is not parallel to X or Y axis
    if (!isParallelToXAxis && !isParallelToYAxis) {
      log.warn("The two vertices do not form a segment parallel to the X or Y axis.");
      return null;
    }

    // Return the segment formed by the two vertices
    return new Segment(new THREE.Vector2(start.x, start.y), new THREE.Vector2(end.x, end.y));
  }

  public getOverlappingRooms(rooms: Room[]): Room[] {
    if (!this.roofBaseSegment) {
      log.warn("roofBaseSegment is not set.");
      return [];
    }

    // use 20 as precision for foudning overlapping rooms (it contains the thickness of the walls. overhangs, alignment)
    return rooms.filter(room => this.roofBaseSegment.overlapsRoomEdges(room, SoPreviewGable.OVERLAPPING_PRECISION));
  }

  public updateExteriorFinish(exteriorFinishIndex: number): void {
    this.exteriorFinishIndex = exteriorFinishIndex;
    const color = this.finish.colorRGB;
    this.updateMaterial(new THREE.Color(color.r / 255, color.g / 255, color.b / 255));
  }
}

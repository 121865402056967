/**
 * Represents the side offset information of a room side in the scene.
 */
export default class soRoomNetBoundaryOffset {
  private funcCodeOffset: number;
  private wallOffset: number;
  /**
   * Initializes a new instance of the soRoomNetBoundaryOffset class.
   */
  constructor(funcCodeOffset: number = 0, wallOffset: number = 0) {
    this.funcCodeOffset = funcCodeOffset;
    this.wallOffset = wallOffset;
  }

  /**
   * Gets the Func Code offset by side.
   * @returns {number} The Func Code offset.
   */
  public get FuncCodeOffset(): number {
    return this.funcCodeOffset;
  }

  /**
   * Sets a value of the Func Code offset by side.
   * @param {number} value - The Func Code offset.
   */
  public set FuncCodeOffset(value: number) {
    this.funcCodeOffset = value;
  }

  /**
   * Gets the wall offset by side.
   * @returns {number} The wall offset.
   */
  public get WallOffset(): number {
    return this.wallOffset;
  }

  /**
   * Sets a value of the wall offset by side.
   * @param {number} value The wall offset.
   */
  public set WallOffset(value: number) {
    this.wallOffset = value;
  }
}

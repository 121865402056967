import { appModel } from "../../../models/AppModel";
import { Room } from "../../../models/Room";
import SceneUtils from "../../utils/SceneUtils";
import RoomManager from "../../managers/SceneManager/SceneManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";
import { soRoom2D } from "../SceneObjects/Room/soRoom2D";
import { soWall2D } from "../SceneObjects/Wall/soWall2D";

export class SoDeleteRoomCommand extends RoomCommand {
  private room: Room;
  private modifiedWallsMap: Map<string, soWall2D> = new Map<string, soWall2D>();

  constructor(private soRoom: soRoom2D) {
    super(soRoom.userData.id);

    this.type = CommandTypes.DeleteRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    roomManager.selectRoom(this.soRoom, false);
    SceneUtils.unhighlightSelectedRoom(this.soRoom);
    SceneUtils.displayRoomStretchControls(this.soRoom, false);

    const soRoom = roomManager.getCorePlanSoRooms().find(room => this.soRoom.groupedBy === room.groupedBy);
    if (soRoom) {
      SceneUtils.removeGroupedLines(soRoom, roomManager.getActiveSoFloor());
      soRoom.walls.forEach(w => {
        const modifiedWall = appModel.findModifiedSegmentWall(w);
        if (!modifiedWall) return;

        this.modifiedWallsMap.set(modifiedWall.wallId, modifiedWall);
        appModel.removeModifiedSegmentWall(modifiedWall);
      });
    }

    //roomManager.getActiveSoFloor()?.remove(this.soRoom);
    roomManager.getActiveSoFloor()?.removeRoom(this.soRoom, false);
    this.room = appModel.activeFloor.rooms.find(r => r.id === this.soRoom.userData.id);
    appModel.activeFloor.removeRoom(this.soRoom.userData.id);
  }

  undo(roomManager: RoomManager): void {
    appModel.setModifiedSegmentWalls([...this.modifiedWallsMap.values()]);
    roomManager.getActiveSoFloor()?.addRoom(this.soRoom);
    appModel.activeFloor.addRoom(this.room);

    const groupedRooms = roomManager.getCorePlanSoRooms().filter(room => room.groupedBy === this.soRoom.groupedBy);
    if (groupedRooms.length && this.soRoom.groupedBy) {
      roomManager.onGroupedRoomsChanged(this.soRoom, groupedRooms, roomManager.getActiveSoFloor(), false);
    }

    roomManager.checkRoomsSharedObjects([this.entityId]);
  }
}

import { WallType } from "../../../../entities/catalogSettings/types";

export default class WallOverrides {
  private wallId: string;
  private functionCode: string;
  private offset: number;
  private wallTypeTag: WallType[] = [];
  constructor(wallId: string) {
    this.wallId = wallId;
  }
  get WallId(): string {
    return this.wallId;
  }
  get FunctionCode(): string {
    return this.functionCode;
  }
  set FunctionCode(value: string) {
    this.functionCode = value;
  }
  get Offset(): number {
    return this.offset;
  }
  set Offset(value: number) {
    this.offset = value;
  }
  get WallTypeTag(): WallType[] {
    return this.wallTypeTag;
  }
}

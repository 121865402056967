import "./ModalSystemSettings.sass";
import { observer } from "mobx-react-lite";
import { useEffect, useState } from "react";
import { useForm } from "react-hook-form";
import { Tabs, Tab, Box, Typography, Dialog, DialogTitle, DialogContent, IconButton } from "@mui/material";
import CloseIcon from "@mui/icons-material/Close";
import AutoAwesomeMosaicIcon from "@mui/icons-material/AutoAwesomeMosaic";
import HomeIcon from "@mui/icons-material/Home";
import SettingsIcon from "@mui/icons-material/Settings";
import VisibilityIcon from "@mui/icons-material/Visibility";

import RoomManagementTab from "./RoomManagementTab";
import SettingsTab, { SubCategorySettings } from "./SettingsTab";
import { settings } from "../../../../entities/settings";
import { getDisplayValue } from "../../../../helpers/measures";
import { defaultSettings, defaultUnits } from "../../../../entities/settings/defaults";
import { CorePlanSettingsKeys, TSettingsValues, ParametersSettingsKeys, WebAppUISettingsKeys } from "../../../../entities/settings/types";

const defaultActiveTabKey = "roomManagement";
export const corePlanBriefSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Room Specifications",
    subCategoryName: "corePlanSettings",
    settings: [
      CorePlanSettingsKeys.numOfStories,
      CorePlanSettingsKeys.storiesMax,
      CorePlanSettingsKeys.bedroomMin,
      CorePlanSettingsKeys.numOfBedrooms,
      CorePlanSettingsKeys.bedroomMax,
      CorePlanSettingsKeys.bathroomMin,
      CorePlanSettingsKeys.numOfBathrooms,
      CorePlanSettingsKeys.bathroomMax,
      CorePlanSettingsKeys.numOfCars,
      CorePlanSettingsKeys.carsMax,
    ],
  },
  {
    subCategoryDisplayName: "Building Specifications",
    subCategoryName: "corePlanSettings",
    settings: [
      CorePlanSettingsKeys.defaultWindowHeaderHeight,
      CorePlanSettingsKeys.windowTopOfWallClearance,
      CorePlanSettingsKeys.floorToPlateLevelHeight,
      CorePlanSettingsKeys.maxFloorToPlateLevelHeight,
      CorePlanSettingsKeys.minFloorToPlateLevelHeight,
    ],
  },
  {
    subCategoryDisplayName: "Construction Specifications",
    subCategoryName: "corePlanSettings",
    settings: [CorePlanSettingsKeys.floorThickness],
  },
  {
    subCategoryDisplayName: "Building Graphics",
    subCategoryName: "corePlanSettings",
    settings: [CorePlanSettingsKeys.windowWidth, CorePlanSettingsKeys.windowHeight],
  },
  {
    subCategoryDisplayName: "Lot Specifications",
    subCategoryName: "corePlanSettings",
    settings: [
      CorePlanSettingsKeys.minBuildingNetArea,
      CorePlanSettingsKeys.maxBuildingGrossArea,
      CorePlanSettingsKeys.lotArea,
      CorePlanSettingsKeys.lotWidth,
      CorePlanSettingsKeys.lotLength,
      CorePlanSettingsKeys.lotFrontSetback,
      CorePlanSettingsKeys.lotBackSetback,
      CorePlanSettingsKeys.lotSideSetback,
      CorePlanSettingsKeys.lotFront2Setback,
    ],
  },
];

export const parametersSettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "Walls",
    subCategoryName: "parametersSettings",
    className: "walls",
    settings: [
      ParametersSettingsKeys.plmFixtureWallSplitTolerance,
      ParametersSettingsKeys.stretchAllowance,
      ParametersSettingsKeys.minimalWallSegmentLength,
      ParametersSettingsKeys.windowEdgeOffset,
      ParametersSettingsKeys.doorEdgeOffset,
      ParametersSettingsKeys.minDistanceForOpeningToCoreWall,
      ParametersSettingsKeys.openingAlignmentMinGap,
    ],
  },
  {
    subCategoryDisplayName: "Design Canvas",
    subCategoryName: "parametersSettings",
    settings: [ParametersSettingsKeys.gridCellSizeForNewCorePlans, ParametersSettingsKeys.frameSize, ParametersSettingsKeys.areaCalculationExteriorOffset],
  },
  {
    subCategoryDisplayName: "Roof",
    subCategoryName: "parametersSettings",
    settings: [
      ParametersSettingsKeys.roofDefaultSlope,
      ParametersSettingsKeys.roofDefaultOverhang,
      ParametersSettingsKeys.overhangOptions,
      ParametersSettingsKeys.defaultHeelHeight,
      ParametersSettingsKeys.minimumGableSeparation,
      ParametersSettingsKeys.roofThickness,
      ParametersSettingsKeys.downspoutOffset,
      ParametersSettingsKeys.minDistanceBetweenDownspouts,
    ],
  },
  {
    subCategoryDisplayName: "Roof Section Diagram",
    subCategoryName: "parametersSettings",
    className: "roofDiagram",
    settings: [ParametersSettingsKeys.roofDiagram],
  },
];

export const displaySettings: SubCategorySettings[] = [
  {
    subCategoryDisplayName: "2D Canvas",
    subCategoryName: "webAppUISettings",
    settings: [
      WebAppUISettingsKeys.selectionColor,
      WebAppUISettingsKeys.highlightColor,
      WebAppUISettingsKeys.wallsColor,
      WebAppUISettingsKeys.floorsColor,
      WebAppUISettingsKeys.openingsColor,
      WebAppUISettingsKeys.gridPrimaryColor,
      WebAppUISettingsKeys.gridSecondaryColor,
      WebAppUISettingsKeys.lotLinesColor,
      WebAppUISettingsKeys.lotSetbackColor,
      WebAppUISettingsKeys.obsoleteRoomsColor,
    ],
  },
  {
    subCategoryDisplayName: "3D Canvas",
    subCategoryName: "webAppUISettings",
    settings: [WebAppUISettingsKeys.glassColor, WebAppUISettingsKeys.doorColor, WebAppUISettingsKeys.roofSurface, WebAppUISettingsKeys.roofEdge],
  },
  {
    subCategoryDisplayName: "Areas Take Off",
    subCategoryName: "webAppUISettings",
    settings: [WebAppUISettingsKeys.livingSpaces, WebAppUISettingsKeys.garage, WebAppUISettingsKeys.porchesAndPatio, WebAppUISettingsKeys.roof],
  },
];

let ModalSystemSettings = ({ isOpen, handleModalClose, handleBulkUploadModalOpen }) => {
  const [activeTabKey, setActiveTabKey] = useState<string>(defaultActiveTabKey);
  const form = useForm({ mode: "onChange" });

  useEffect(() => {
    if (!isOpen) {
      return;
    }
    setFormValues(settings.values);
  }, [settings.values, isOpen, form]);

  if (!isOpen) return <></>;

  const handleTabClick = (event: React.SyntheticEvent, key: string) => {
    setActiveTabKey(key);
  };
  const onCloseClick = () => {
    form.reset();
    setActiveTabKey(defaultActiveTabKey);
    handleModalClose();
  };
  const setFormValues = (settingsValues: TSettingsValues) => {
    const formSettings = {};

    for (const settingCategoryKey in settingsValues) {
      if (typeof settingsValues[settingCategoryKey] !== "object") {
        continue;
      }

      formSettings[settingCategoryKey] = {};

      for (const settingKey in defaultSettings[settingCategoryKey]) {
        const setting = settingsValues[settingCategoryKey][settingKey] ?? defaultSettings[settingCategoryKey][settingKey];
        formSettings[settingCategoryKey][settingKey] = getDisplayValue(setting, defaultUnits[settingCategoryKey][settingKey]);
      }
      form.setValue(settingCategoryKey, formSettings[settingCategoryKey]);
    }
  };

  const corePlanTabs = [
    {
      label: "Rooms",
      key: defaultActiveTabKey,
      icon: <AutoAwesomeMosaicIcon />,
      tabPanel: (
        <RoomManagementTab
          handleModalClose={onCloseClick}
          handleBulkUploadModalOpen={handleBulkUploadModalOpen}
          activeTabKey={activeTabKey}
          tabKey="roomManagement"
          key="roomManagement"
        />
      ),
    },
    {
      label: "Core Plan Brief",
      key: "corePlanBrief",
      icon: <HomeIcon />,
      tabPanel: (
        <SettingsTab
          handleModalClose={onCloseClick}
          tabSettings={corePlanBriefSettings}
          form={form}
          resetToDefaults={setFormValues}
          activeTabKey={activeTabKey}
          tabKey="corePlanBrief"
          key="corePlanBrief"
        />
      ),
    },
    {
      label: "Parameters",
      key: "parameters",
      icon: <SettingsIcon />,
      tabPanel: (
        <SettingsTab
          handleModalClose={onCloseClick}
          tabSettings={parametersSettings}
          form={form}
          resetToDefaults={setFormValues}
          activeTabKey={activeTabKey}
          tabKey="parameters"
          key="parameters"
        />
      ),
    },
    {
      label: "Display & Graphics",
      key: "display",
      icon: <VisibilityIcon />,
      tabPanel: (
        <SettingsTab
          handleModalClose={onCloseClick}
          tabSettings={displaySettings}
          form={form}
          resetToDefaults={setFormValues}
          activeTabKey={activeTabKey}
          tabKey="display"
          key="display"
        />
      ),
    },
  ];

  return (
    <Dialog className="system-settings-dialog" open={isOpen} onClose={onCloseClick} maxWidth="md" id="settings">
      <DialogTitle>
        <Typography variant="h4" component="p">
          Settings
        </Typography>
        <IconButton className="close-button" aria-label="close" size="large" onClick={onCloseClick}>
          <CloseIcon fontSize="inherit" />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        <Box>
          <Box sx={{ borderBottom: 1, borderColor: "divider" }} className="setting-tabs">
            <Tabs value={activeTabKey} onChange={handleTabClick} variant="fullWidth">
              {corePlanTabs.map(tab => (
                <Tab key={tab.key} label={tab.label} value={tab.key} iconPosition="start" icon={tab.icon} />
              ))}
            </Tabs>
          </Box>
          {corePlanTabs.map(tab => tab.tabPanel)}
        </Box>
      </DialogContent>
    </Dialog>
  );
};

ModalSystemSettings = observer(ModalSystemSettings);
export default ModalSystemSettings;

import { runInAction } from "mobx";
import { useEffect, useState } from "react";

// prettier-ignore
export const isLocalhost =
  window.location.hostname === "localhost"
  || window.location.hostname === "127.0.0.1"
  || window.location.hostname === "";

export const isHttps = window.location.protocol === "https:";

let _isWooMode = undefined;
export const isWooMode = (setWooMode?: boolean) => {
  if (typeof setWooMode === "boolean") {
    _isWooMode = setWooMode;
    return _isWooMode;
  }
  if (_isWooMode !== undefined) return _isWooMode;

  // returns env variable, if it is set up in .env file.
  // otherwise - isLocalhost
  const checkMode = () => (process.env.REACT_APP_IS_MOCKED_API !== undefined ? parseToBoolean(process.env.REACT_APP_IS_MOCKED_API) : isLocalhost);

  return (_isWooMode = checkMode());
};

export const formatToInches = num => {
  const whole = Math.floor(num);
  const fraction = num - whole;

  let fractionalPart = "";
  if (fraction === 0.25) fractionalPart = "1/4";
  else if (fraction === 0.5) fractionalPart = "1/2";
  else if (fraction === 0.75) fractionalPart = "3/4";
  else if (fraction !== 0) return `${num}″`;

  return fractionalPart ? `${whole} ${fractionalPart}″` : `${whole}″`;
};

function parseToBoolean(val: any): boolean {
  if (typeof val === "string") val = val.toLocaleLowerCase();
  switch (val) {
    case true:
    case "true":
    case 1:
    case "1":
    case "on":
    case "yes":
      return true;
  }
  return false;
}

export function integerToHexColor(value: number): string {
  return "#" + value.toString(16).padStart(6, "0");
}

export function debounce(func: (...args: any[]) => void, wait: number) {
  let timeout: ReturnType<typeof setTimeout>;

  // Use an arrow function to automatically bind `this`
  return (...args: any[]) => {
    clearTimeout(timeout);
    timeout = setTimeout(() => func(...args), wait);
  };
}

export const shrinkUnique = <T>(a: T[], keyGet: (e: T) => string | number): T[] => {
  const mapUniq = new Map<string | number, T>();

  for (const aa of a) mapUniq.set(keyGet(aa), aa);

  return Array.from(mapUniq.values());
};

type Oid = { id: number | string };
type ArrayIds = Oid[];
export const arrayIdSet = (a: ArrayIds, object: Oid) => {
  return runInAction(() => {
    const existsIndex = a.findIndex(item => item.id == object.id);
    if (existsIndex >= 0) {
      a[existsIndex] = object;
    } else {
      a.push(object);
    }
    return a;
  });
};

export const arrayIdGet = (a: ArrayIds, id: string | number): Oid => {
  return a?.find(item => item.id == id);
};

export interface Size {
  width: number | undefined;
  height: number | undefined;
}

export function useWindowSize(): Size {
  const [windowSize, setWindowSize] = useState<Size>({
    width: undefined,
    height: undefined,
  });
  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    window.addEventListener("resize", handleResize);
    handleResize();
    return () => window.removeEventListener("resize", handleResize);
  }, []);
  return windowSize;
}

export function getFileExtension(name: string) {
  const dotPosition = name.lastIndexOf(".");
  if (name === "" || dotPosition < 1) {
    return "";
  }
  return name.slice(dotPosition + 1).toLowerCase();
}

export function getFormattedDate(date: string) {
  const dateFormatted = new Date(parseInt(date)).toLocaleDateString();
  return dateFormatted.replaceAll("/", ".");
}
export function getFormattedLocalDate(date) {
  const dateObj = new Date(parseInt(date));

  const timeFormat: Intl.DateTimeFormatOptions = {
    day: "2-digit",
    month: "2-digit",
    year: "2-digit",
    hour: "2-digit",
    minute: "2-digit",
    hour12: false,
    timeZoneName: "short",
  };
  const formatter = new Intl.DateTimeFormat("en-GB", timeFormat);
  const formattedParts = formatter.formatToParts(dateObj);

  let formattedDate = "";
  for (const part of formattedParts) {
    if (part.type === "literal" && part.value === ",") {
      formattedDate += " ";
    } else if (part.type === "literal" && part.value === "/") {
      formattedDate += ".";
    } else {
      formattedDate += part.value;
    }
  }

  return formattedDate;
}

export function areWeTestingWithJest() {
  return process.env.JEST_WORKER_ID !== undefined || process.env.NODE_ENV === "test";
}

export function getDropdownTrigger() {
  return areWeTestingWithJest() ? ["click"] : ["hover"];
}

/**
 * Redirect browser (change current location) to given URL
 */
export const redirectBrowser = (url: string): void => {
  window.location.replace(url);
};

export const removeFileExtension = (fileName: string): string => {
  const idx = fileName.lastIndexOf(".");
  if (idx !== -1) {
    return fileName.substring(0, idx);
  }

  return fileName;
};

export const strHasSpaces = (str: string): boolean => {
  return /\s/.test(str);
};

export const extractWordsOnly = (input: string): string => {
  return input.replace(/\d+$/, "").trim();
};

import * as THREE from "three";
import { ExteriorFinish } from "../../../models/DesignStyle";
import { appModel } from "../../../models/AppModel";
import { SoPreviewRoom } from "./SoPreviewRoom";

export class SoPreviewFace extends THREE.Mesh {
  public index: number;
  public direction: string;
  public soRoom: SoPreviewRoom;
  public width: number;
  public height: number;
  public roofSlope: number;
  public dutchGableDepth: number;
  public exteriorFinishIndex: number;
  public isIndoor: boolean;
  public overHang: number;

  constructor(
    geometry: THREE.Geometry | THREE.BufferGeometry,
    material: THREE.MeshStandardMaterial,
    index: number,
    direction: string,
    soRoom: SoPreviewRoom,
    width: number,
    height: number,
    position: THREE.Vector3,
    rotation: THREE.Euler,
    isIndoor: boolean
  ) {
    super(geometry, material);
    this.index = index;
    this.direction = direction;
    this.soRoom = soRoom;
    this.width = width;
    this.height = height;
    this.position.copy(position);
    this.rotation.copy(rotation);
    this.isIndoor = isIndoor;
  }

  public setWallOverhang(overHang: number): void {
    this.overHang = overHang;
  }
  protected updateMaterial(color?: THREE.Color, texture?: THREE.Texture | null): void {
    const material = this.material as THREE.MeshStandardMaterial;
    if (color) {
      material.color.copy(color);
    }
    if (texture !== undefined) {
      material.map = texture;
      material.needsUpdate = true;
    }
  }

  get finish(): ExteriorFinish {
    const idx = this.exteriorFinishIndex;
    return appModel.activeDesignStyle?.finishes?.find(f => f.index === idx)?.clone() || ExteriorFinish.createFallback();
  }
}

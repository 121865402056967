import { observer } from "mobx-react";
import { Modal, Row, Button, UploadFile, Col } from "antd";
import { VeUpload } from "../common/VeUpload";
import { Line, LineAlignment } from "../../../editor/tools/OpeningAlignmentTool";
import { settings } from "../../../entities/settings";
import log from "loglevel";

type Props = {
  isOpen: boolean;
  onSelect: any;
  onClose: any;
};

const AlignmentTestModal = observer(({ isOpen, onSelect, onClose }: Props) => {
  const testFiles: UploadFile[] = [];
  const expectedResultFiles: UploadFile[] = [];

  const readFile = file => {
    return new Promise<any>((resolve, reject) => {
      const reader = new FileReader();
      reader.addEventListener(
        "loadend",
        () => {
          try {
            resolve(JSON.parse(reader.result as string));
          } catch (error) {
            reject(error);
          }
        },
        false
      );

      if (file) {
        reader.readAsText(file);
      }
    });
  };

  const saveFile = async (data, suggestedName) => {
    const blob = new Blob([JSON.stringify(data, undefined, 4)], { type: "application/json" });

    const supportsFileSystemAccess =
      "showSaveFilePicker" in window &&
      (() => {
        try {
          return window.self === window.top;
        } catch {
          return false;
        }
      })();
    //if the File System Access API is supported
    if (supportsFileSystemAccess) {
      try {
        // eslint-disable-next-line @typescript-eslint/ban-ts-comment
        // @ts-ignore
        const handle = await showSaveFilePicker({
          suggestedName,
        });
        const writable = await handle.createWritable();
        await writable.write(blob);
        await writable.close();
        return;
      } catch (err) {
        if (err.name !== "AbortError") {
          log.error(err.name, err.message);
          return Promise.reject(err);
        }
      }
    }
    // if the File System Access API is not supported
    const blobURL = URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = blobURL;
    a.download = suggestedName;
    a.style.display = "none";
    document.body.append(a);
    a.click();
    URL.revokeObjectURL(blobURL);
    a.remove();
  };

  const runTest = (testData, expectedResultData) => {
    //arrange
    const finalResult = {
      testId: testData.testId,
      testData: testData.data,
      totalLines: 0,
      failed: {
        count: 0,
        lineIds: [],
      },
      passed: {
        count: 0,
        lineIds: [],
      },
    };
    const lines: Line[] = [];
    const lineIds = new Map<string, any>();

    let counter = 1;
    testData.data.forEach((baseLine, baseIndex) => {
      const maxShiftZone = { start: baseLine.maxBaseLineRange[0], end: baseLine.maxBaseLineRange[1] };
      baseLine.lines.forEach((line, index) => {
        const alignLine = new Line(
          `${counter}`,
          { start: line.segment[0], end: line.segment[1] },
          { start: line.shiftZone[0], end: line.shiftZone[1] },
          maxShiftZone
        );
        if (expectedResultData && expectedResultData.data && expectedResultData.data[baseIndex] && expectedResultData.data[baseIndex]["result"]) {
          line.expectedResult = expectedResultData.data[baseIndex]["result"][index] ?? null;
        } else {
          line.expectedResult = null;
        }
        line.id = alignLine.id;
        lines.push(alignLine);
        lineIds.set(alignLine.id, line);
        counter++;
        finalResult.totalLines++;
      });
    });

    //act
    LineAlignment.alignLines(lines, settings.values.parametersSettings.openingAlignmentMinGap);

    //assert
    lines.forEach(line => {
      const jsonLine = lineIds.get(line.id);
      jsonLine.result = line.shiftDistance;
      if (jsonLine.result === jsonLine.expectedResult) {
        finalResult.passed.count++;
        finalResult.passed.lineIds.push(line.id);
      } else {
        finalResult.failed.count++;
        finalResult.failed.lineIds.push(line.id);
      }
    });
    return finalResult;
  };

  const handleRunTestClick = async () => {
    try {
      const testResults = [];

      if (testFiles.length !== expectedResultFiles.length) {
        return alert("Test files count and Expected Result files count not matched!");
      }

      const testFilePromises = [];
      testFiles.forEach(file => {
        testFilePromises.push(readFile(file.originFileObj));
      });
      const expectFilePromises = [];
      expectedResultFiles.forEach(file => {
        expectFilePromises.push(readFile(file.originFileObj));
      });

      const testFilesJsons = await Promise.all(testFilePromises);
      const expectFilesJsons = await Promise.all(expectFilePromises);

      testFilesJsons.forEach(testJson => {
        if (!testJson.testId) {
          throw new Error("testId property is not exist in json file");
        }
        //find expected result json
        const expectFileJson = expectFilesJsons.find(fileJson => fileJson.testId === testJson.testId);
        testResults.push(runTest(testJson, expectFileJson));
      });
      saveFile(testResults, "Test Result.json");
    } catch (error) {
      log.error(error);
      alert("Failed to run test with provided files");
    }
  };

  return (
    <Modal title="Opening Alignment Test" open={isOpen} onOk={onSelect} onCancel={onClose} footer={null}>
      <Row justify="space-evenly" align="middle">
        <Col>
          <div className="ant-form-vertical">
            <div className="form-group group-upload">
              <div className="ant-form-item-label label-upload">
                <label>Test JSON File</label>
              </div>
              <VeUpload multiple={true} fileList={testFiles} beforeUpload={() => false}>
                <Button className="upload">Select File</Button>
              </VeUpload>
            </div>
            <div className="form-group group-upload">
              <div className="ant-form-item-label label-upload">
                <label>Expected Result JSON File</label>
              </div>
              <VeUpload multiple={true} fileList={expectedResultFiles} beforeUpload={() => false}>
                <Button className="upload">Select File</Button>
              </VeUpload>
            </div>
          </div>
        </Col>
        <Col>
          <Button type="primary" onClick={handleRunTestClick}>
            <span>Run test</span>
          </Button>
        </Col>
      </Row>
    </Modal>
  );
});

export default AlignmentTestModal;

import * as THREE from "three";
import Edge from "./edge";
import Line3dEquation from "./line3dEquation";
import Node from "./node";
import type RoofSurfaceOutput from "./roofSurfaceOutput";
import HoleInSurface from "./holeInSurface";
import EdgeOutput from "./edgeOutput";

import patchThree from "../shared/patchThree";

patchThree();

const COLLINEAR_EPSILON = 1e-5;

export default class Utils {
  static Tolerance = 0.0001;
  static HighTolerance = 1e15;

  public static ValuesAreCloseEnough(a, b) {
    return Math.abs(a - b) < Utils.Tolerance;
  }

  /// <summary>
  /// Finds the Point3D corresponding to the closest matching X and Y values from the original surfaces.
  /// </summary>
  /// <param name="vertex">The vertex whose closest Point3D is being determined.</param>
  /// <param name="originalSurfaces">The original list of roof surfaces for reference.</param>
  /// <returns>The Point3D of the closest vertex; otherwise, returns a default Point3D (0,0,0).</returns>
  public static getClosestPoint3D(point2D: THREE.Vector2, originalSurfaces: RoofSurfaceOutput[]): THREE.Vector3 {
    let minDistance = Number.MAX_VALUE;
    let closestPoint = new THREE.Vector3(0, 0, 0);

    for (const surface of originalSurfaces) {
      const nodes = surface.getNodes();
      for (const node of nodes) {
        const distance = Math.sqrt(Math.pow(node.x - point2D.x, 2) + Math.pow(node.y - point2D.y, 2));
        if (distance < minDistance) {
          minDistance = distance;
          closestPoint = node;
        }
      }
    }

    return closestPoint;
  }

  public static pointIsInsidePlaneXyBbx(point: THREE.Vector3, contourEdges: Edge[]): boolean {
    const bbox = Edge.getBoundingBox(contourEdges);
    if (point.x < bbox.min.x || point.y < bbox.min.y || point.x > bbox.max.x || point.y > bbox.max.y) return false;
    return true;
  }

  /// <summary>
  /// return true if point is inside a ploygon
  /// </summary>
  public static pointIsInsidePolygon(point3d: THREE.Vector3, baseLevel: number, contourEdges: Edge[]): boolean {
    const point = new THREE.Vector2(point3d.x, point3d.y);
    const polygon: THREE.Vector2[] = [];
    for (const edge of contourEdges) {
      //if(node.Point3D.Z != baseLevel) { continue; }
      //polygon.Add(new(node.Point3D.X, node.Point3D.Y));
      // TODO: Multiple dynamic memory allocs...
      if (!Utils.containsPoint2d(polygon, new THREE.Vector2(edge.startNode.point3D.x, edge.startNode.point3D.y)))
        polygon.push(new THREE.Vector2(edge.startNode.point3D.x, edge.startNode.point3D.y));
      if (!Utils.containsPoint2d(polygon, new THREE.Vector2(edge.endNode.point3D.x, edge.endNode.point3D.y)))
        polygon.push(new THREE.Vector2(edge.endNode.point3D.x, edge.endNode.point3D.y));
    }
    if (Utils.pointInPolygon(point, polygon, baseLevel, contourEdges)) return true;
    if (Utils.isPointInContour(point, contourEdges)) return true;
    return false;
  }

  // eslint-disable-next-line
  public static pointInPolygon(point: THREE.Vector2, polygon: THREE.Vector2[], baseLevel: number, contourEdges: Edge[]): boolean {
    const numVertices = polygon.length;
    const x = point.x,
      y = point.y;
    let inside = false;

    // Store the first point in the polygon and initialize the second point
    let p1: THREE.Vector2 = polygon[0];
    let p2: THREE.Vector2;

    // Loop through each edge in the polygon
    for (let i = 1; i <= numVertices; i++) {
      // Get the next point in the polygon
      p2 = polygon[i % numVertices];

      // Check if the point is above the minimum y coordinate of the edge
      if (y > Math.min(p1.y, p2.y)) {
        // Check if the point is below the maximum y coordinate of the edge
        if (y <= Math.max(p1.y, p2.y)) {
          // Check if the point is to the left of the maximum x coordinate of the edge
          if (x <= Math.max(p1.x, p2.x) + 0.001) {
            // Calculate the x-intersection of the line connecting the point to the edge
            const xIntersection = ((y - p1.y) * (p2.x - p1.x)) / (p2.y - p1.y) + p1.x;

            // OFER: hack
            //Edge? edge = GetEdge(p1, p2, baseLevel, contourEdges);

            // Check if the point is on the same line as the edge or to the left of the x-intersection
            if (Utils.ValuesAreCloseEnough(p1.x, p2.x) || x <= xIntersection + 0.001) {
              // OFER: hack
              //if(edge != null && edge.Direction == EdgeFacadeDir.WEST && x == xIntersection) { p1 = p2; continue; }

              // Flip the inside flag
              inside = !inside;
            }
          }
        }
      }

      // Store the current point as the first point for the next iteration
      p1 = p2;
    }

    // Return the value of the inside flag
    return inside;
  }

  static doesSegmentContainPoint(vecStart, vecEnd, vecPoint) {
    const vecDir = vecEnd.subtract(vecStart);
    const fLen = vecDir.length();
    vecDir.normalize();

    // Point must be within epislon of the normal distance
    const vecDiff1 = vecPoint.subtract(vecStart);

    const fDist1 = (THREE.Vector3 as any).Dot(vecDiff1, vecDir);
    vecDir.scaleToRef(fDist1, vecDiff1);

    const vecPoint1 = vecStart.addNew(vecDiff1);
    const vecPoint2 = vecPoint1.subtract(vecPoint);

    const fPerpDist1 = vecPoint2.lengthSquared();
    if (fPerpDist1 > Math.abs(Utils.Tolerance * Utils.Tolerance)) return false;

    // Now, the distance along the curve must also be within segment one dist:
    if (fDist1 < -Utils.Tolerance || fDist1 > fLen + Utils.Tolerance) return false;

    return true;
  }

  public static isPointInContour(point: THREE.Vector2, contourEdges: Edge[], checkInDiagonalLines = false): boolean {
    for (const edge of contourEdges) {
      if (Utils.isPointInEdge(point, edge, checkInDiagonalLines)) return true;
    }
    return false;
  }

  public static isPointInEdge(point: THREE.Vector2 | THREE.Vector3, edge: Edge, checkInDiagonalLines = false): boolean {
    // TODO: What is this?? Why not use a generic point-on-line test without all the special
    // cases?
    if (
      edge.isVertical &&
      Utils.ValuesAreCloseEnough(point.x, edge.startNode.point3D.x) &&
      point.y <= edge.endNode.point3D.y &&
      point.y >= edge.startNode.point3D.y
    ) {
      return true;
    } else if (
      edge.isHorizontal &&
      Utils.ValuesAreCloseEnough(point.y, edge.startNode.point3D.y) &&
      point.x <= edge.endNode.point3D.x &&
      point.x >= edge.startNode.point3D.x
    ) {
      return true;
    } else if (checkInDiagonalLines && !edge.isVertical && !edge.isHorizontal) {
      const minX = Math.min(edge.startNode.point3D.x, edge.endNode.point3D.x);
      const maxX = Math.max(edge.startNode.point3D.x, edge.endNode.point3D.x);
      const minY = Math.min(edge.startNode.point3D.y, edge.endNode.point3D.y);
      const maxY = Math.max(edge.startNode.point3D.y, edge.endNode.point3D.y);

      // Check if the point is within the bounding box of the edge
      if (point.x < minX || point.x > maxX || point.y < minY || point.y > maxY) return false;

      // Calculate the equation of the line (y = mx + c) and check if the point satisfies it
      const deltaX = edge.endNode.point3D.x - edge.startNode.point3D.x;
      const deltaY = edge.endNode.point3D.y - edge.startNode.point3D.y;

      // General case: Calculate the slope (m) and y-intercept (c)
      const slope = deltaY / deltaX;
      const intercept = edge.startNode.point3D.y - slope * edge.startNode.point3D.x;

      // Check if the point lies on the line
      return Utils.ValuesAreCloseEnough(point.y, slope * point.x + intercept);
    }
    return false;
  }

  public static isPointInPoly(poly, pt) {
    let c, i, l, j;
    for (c = false, i = -1, l = poly.length, j = l - 1; ++i < l; j = i)
      ((poly[i].y <= pt.y && pt.y < poly[j].y) || (poly[j].y <= pt.y && pt.y < poly[i].y)) &&
        pt.x < ((poly[j].x - poly[i].x) * (pt.y - poly[i].y)) / (poly[j].y - poly[i].y) + poly[i].x &&
        (c = !c);
    // eslint-disable-next-line
    return c;
  }

  public static pointsAreCloseEnough(A: THREE.Vector3, B: THREE.Vector3, tol?: number): boolean {
    const fTolerance = tol === undefined ? Utils.Tolerance : tol;
    return Math.abs(B.x - A.x) < fTolerance && Math.abs(B.y - A.y) < fTolerance && Math.abs(B.z - A.z) < fTolerance;
  }

  public static remove(list, item) {
    for (let idx = list.length - 1; idx >= 0; idx--) {
      if (list[idx].isEqual(item)) {
        list.splice(idx, 1);
        return;
      }
    }
  }

  public static indexOf(list, item) {
    for (let idx = list.length - 1; idx >= 0; idx--) {
      if (list[idx].isEqual(item)) return idx;
    }

    return -1;
  }

  public static contains(list, item) {
    // This mimics C# contains method where each item is assumed to have an Equals() method.
    // In our case, we use isEqual().
    for (const currItem of list) {
      if (currItem.isEqual(item)) return true;
    }
    return false;
  }

  public static containsPoint(pointList, point) {
    // This mimics C# contains method where each item is assumed to have an Equals() method.
    // In our case, we use isEqual().
    for (const currPoint of pointList) {
      if (Utils.pointsAreCloseEnough(currPoint, point)) return true;
    }
    return false;
  }

  public static containsPoint2d(pointList, point) {
    // This mimics C# contains method where each item is assumed to have an Equals() method.
    // In our case, we use isEqual().
    for (const currPoint of pointList) {
      if (Utils.ValuesAreCloseEnough(currPoint.x, point.x) && Utils.ValuesAreCloseEnough(currPoint.y, point.y)) return true;
    }
    return false;
  }

  /// <summary>
  /// get the defaut setting angle. TODO: to take default angle
  /// </summary>
  public static getDefaultAngle(roofEdgesBeforMerge: Edge[]): number {
    for (const edge of roofEdgesBeforMerge) {
      if (edge.slope != 0) return edge.slope;
    }
    throw new Error("all slopes are 0");
  }

  /// <summary>
  /// merge two continuous edges. after first merge return true.
  /// return false if there was nothing to merge.
  /// </summary>
  public static mergeContinuousEdges(roofEdges: Edge[], roofNodes: Node[]): boolean {
    for (const edge1 of roofEdges) {
      for (const edge2 of roofEdges) {
        if (edge1.isEqual(edge2)) continue;

        if (edge1.isVertical != edge2.isVertical) continue;

        if (edge1.endNode.isEqual(edge2.startNode)) {
          // merge
          const index = Math.min(Utils.indexOf(roofEdges, edge1), Utils.indexOf(roofEdges, edge2));
          Utils.remove(roofEdges, edge1);
          Utils.remove(roofEdges, edge2);
          const newEdge = new Edge(edge1.startNode, edge2.endNode);
          newEdge.slope = Math.max(edge1.slope, edge2.slope);
          newEdge.depth = edge1.depth;
          newEdge.overhang = edge1.overhang;
          newEdge.id = edge1.id;
          newEdge.name = `${edge1.name} + ${edge2.name}`;
          roofEdges.splice(index, 0, newEdge);

          // deal with nodes after merge
          Utils.remove(roofNodes, edge1.endNode);
          edge1.startNode.edges.push(newEdge);
          edge2.endNode.edges.push(newEdge);
          Utils.remove(newEdge.startNode.edges, edge1);
          Utils.remove(newEdge.endNode.edges, edge2);

          return true;
        }
      }
    }
    return false;
  }

  public static mergeContinuousOutputEdges(roofEdges: EdgeOutput[]): boolean {
    for (const edge1 of roofEdges) {
      for (const edge2 of roofEdges) {
        if (edge1.edgeType !== edge2.edgeType) continue;

        if (!Utils.pointsAreCloseEnough(edge1.endPoint, edge2.startPoint)) continue;

        if (edge1.isEqual(edge2)) continue;

        if (!edge1.isCollinear(edge2, false)) continue;

        // Otherwise, the edges are collinear and share a point and are the same type - merge
        Utils.remove(roofEdges, edge2);
        edge1.mergeWith(edge2);
        return true;
      }
    }
    return false;
  }

  /// <summary>
  /// return intersection point between two lines
  /// </summary>
  public static findPointBetweenTwoLines(line1: Line3dEquation, line2: Line3dEquation): THREE.Vector3 {
    const a = new THREE.Vector3(line1.X0, line1.Y0, line1.Z0);
    const b = new THREE.Vector3(line1.X0 + line1.A, line1.Y0 + line1.B, line1.Z0 + line1.C);
    const c = new THREE.Vector3(line2.X0, line2.Y0, line2.Z0);
    const d = new THREE.Vector3(line2.X0 + line2.A, line2.Y0 + line2.B, line2.Z0 + line2.C);
    const v1 = new THREE.Vector3(b.x - a.x, b.y - a.y, b.z - a.z);
    const v2 = new THREE.Vector3(d.x - c.x, d.y - c.y, d.z - c.z);

    let numerator = (c.x - a.x) * -v2.y - (c.y - a.y) * -v2.x;
    let denominator = v1.x * -v2.y - v1.y * -v2.x;
    if (Math.abs(denominator) < Utils.Tolerance) {
      numerator = (c.z - a.z) * -v2.x - (c.x - a.x) * -v2.z;
      denominator = v1.z * -v2.x - v1.x * -v2.z;
      if (Math.abs(denominator) < Utils.Tolerance) {
        numerator = (c.z - a.z) * -v2.y - (c.y - a.y) * -v2.z;
        denominator = v1.z * -v2.y - v1.y * -v2.z;
      }
    }
    const t = numerator / denominator;
    const x = a.x + t * v1.x;
    const y = a.y + t * v1.y;
    const z = a.z + t * v1.z;

    return new THREE.Vector3(x, y, z);
  }

  /// <summary>
  /// check if this point is inside input line
  /// </summary>
  public static isPointInsideLine(point: THREE.Vector3, line: Line3dEquation): boolean {
    const vecAP = new THREE.Vector3(point.x - line.X0, point.y - line.Y0, point.z - line.Z0);
    const vecDir = new THREE.Vector3(line.A, line.B, line.C);

    const cross = new THREE.Vector3();
    cross.crossVectors(vecAP, vecDir);

    const numerator = Math.sqrt(Math.pow(cross.x, 2) + Math.pow(cross.y, 2) + Math.pow(cross.z, 2));
    const denominator = Math.sqrt(Math.pow(vecDir.x, 2) + Math.pow(vecDir.y, 2) + Math.pow(vecDir.z, 2));

    const dist = numerator / denominator;

    if (dist < 0.0001) return true;
    return false;
  }

  public static Same(v1: THREE.Vector2, v2: THREE.Vector2, tolSq = 1e-7): boolean {
    return v1.clone().sub(v2).lengthSq() < tolSq;
  }

  public static tryAddNodeFromRoofSurface(point: THREE.Vector2, roofSurfaceNodes: THREE.Vector3[], nodes: THREE.Vector3[]): boolean {
    for (const roofSurfaceNode of roofSurfaceNodes) {
      const roofSurfaceNodeVector2 = new THREE.Vector2(roofSurfaceNode.x, roofSurfaceNode.y);
      if (Utils.Same(point, roofSurfaceNodeVector2)) {
        nodes.push(new THREE.Vector3(point.x, point.y, roofSurfaceNode.z));
        return true;
      }
    }
    return false;
  }

  public static tryAddNodeFromHoles(point: THREE.Vector2, holes: HoleInSurface[], nodes: THREE.Vector3[]) {
    for (const hole of holes) {
      for (const holeNode of hole.nodes) {
        const holeNodeVector2: THREE.Vector2 = new THREE.Vector2(holeNode.point3D.x, holeNode.point3D.y);
        if (Utils.Same(point, holeNodeVector2)) {
          nodes.push(new THREE.Vector3(point.x, point.y, holeNode.point3D.z));
          return true;
        }
      }
    }
    return false;
  }

  public static pointIsInfinity(point: THREE.Vector3): boolean {
    if (point.x == Infinity || point.y == Infinity || point.z == Infinity) {
      return true;
    }
    return false;
  }

  public static pointIsInNan(point: THREE.Vector3): boolean {
    if (isNaN(point.x) || isNaN(point.y) || isNaN(point.z)) {
      return true;
    }
    return false;
  }

  public static getTwoEdges(point: THREE.Vector3, contourEdges: Edge[]): Edge[] {
    const edges: Edge[] = [];
    for (const edge of contourEdges) {
      if (Utils.pointsAreCloseEnough(edge.startNode.point3D, point)) edges.push(edge);
      if (Utils.pointsAreCloseEnough(edge.endNode.point3D, point)) edges.push(edge);
    }
    if (edges.length != 2) return [null, null];
    return [edges[0], edges[1]];
  }

  public static removeCollinearPoints(points: THREE.Vector3[], bIsClosed = false, collinearEpsilon = COLLINEAR_EPSILON) {
    if (points.length <= 2) return;

    const iEndValue = bIsClosed ? 2 : 0;
    let fDist;
    for (let index = points.length - 1; index >= iEndValue; index--) {
      const pPoint1 = points[index];
      let iPrevIndex = index - 1;
      if (iPrevIndex < 0) iPrevIndex += points.length;
      const pPoint2 = points[iPrevIndex];

      let iPrevIndex2 = index - 2;
      if (iPrevIndex2 < 0) iPrevIndex2 += points.length;
      const pPoint3 = points[iPrevIndex2];

      const vecToPoint = (pPoint3 as any).subtract(pPoint1);
      const vecToMidPoint = (pPoint1 as any).subtract(pPoint2);
      fDist = (THREE.Vector3 as any).Cross(vecToPoint, vecToMidPoint).length() / vecToPoint.length();

      if (fDist < collinearEpsilon) {
        points.splice(iPrevIndex, 1);
        if (points.length <= 2) break;
      }
    }
  }
}

import * as THREE from "three";
import type Edge from "./edge";
import Utils from "./utils";

export default class Node {
  public point3D: THREE.Vector3;
  public edges: Edge[] = [];

  constructor(point: THREE.Vector3) {
    this.point3D = point;
  }

  public isEqual(other: Node) {
    if (!other) return false;

    return (
      Utils.ValuesAreCloseEnough(other.point3D.x, this.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.point3D.y, this.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.point3D.z, this.point3D.z)
    );
  }

  /// <summary>
  /// check if this Node is in only one edge, out of input edges list, or more.
  /// if 1 return true, otherwise false.
  /// </summary>
  public hasOneEdgeOnly(edges: Edge[]) {
    for (const edge of edges) {
      if (this.isEqual(edge.startNode) || this.isEqual(edge.endNode)) return true;
    }
    return false;
  }
}

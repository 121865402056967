import RoomManager from "../../managers/SceneManager/SceneManager";
import { CommandTypes } from "./CommandTypes";
import { RoomCommand } from "./RoomCommand";

export class TranslateRoomCommand extends RoomCommand {
  constructor(
    entityId: string,
    private diff: THREE.Vector3
  ) {
    super(entityId);

    this.type = CommandTypes.TranslateRoomCommand;
  }

  apply(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    soRoom.position.add(this.diff);
    soRoom.updateMatrixWorld();
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);
    roomManager.updateGroupedRoomsChanged(soRoom, soRoom.userData.isGroupSelected);
  }
  undo(roomManager: RoomManager): void {
    const soRoom = roomManager.getActiveFloorSoRoom(this.entityId);
    roomManager.getActiveSoFloor().wallManager.removeRooms([soRoom]);
    soRoom.position.sub(this.diff);
    soRoom.updateMatrixWorld();
    roomManager.getActiveSoFloor().wallManager.addRooms([soRoom]);
    roomManager.updateRoomsProperties([this.entityId]);
    roomManager.checkRoomsSharedObjects([this.entityId]);

    roomManager.updateGroupedRoomsChanged(soRoom, soRoom.userData.isGroupSelected);
  }
}

import "./GroupProperties.sass";

const GroupProperties = ({ groupedRoom, groupedRooms }) => {
  return (
    <div className="group-properties">
      <div className="group-properties-main">
        <div className="title">Room Selected (Group)</div>
        <div className="list_item">
          <span>Name: </span>
          <span>{groupedRoom?.tagName ?? "N/A"}</span>
        </div>
      </div>

      <div className="group-properties-secondary">
        <span className="secondary_title">Grouped Rooms ({groupedRooms.length || 0})</span>
        {groupedRooms.map((groupedRoom, index) => (
          <span className="list_item" key={groupedRoom.soId}>
            {groupedRoom.tagName}
            {index < groupedRooms.length - 1 && ", "}
          </span>
        ))}
      </div>
    </div>
  );
};

export default GroupProperties;

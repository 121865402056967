import { soGroup } from "../soGroup";
import * as THREE from "three";
import { soBoundaryLine } from "./soBoundaryLine";
import { Side } from "../../../../models/Side";
import GeometryUtils from "../../../utils/GeometryUtils/GeometryUtils";
import SceneUtils from "../../../utils/SceneUtils";
import { soRoom2D } from "../Room/soRoom2D";
import BoundingBoxUtils from "../../../utils/GeometryUtils/BoundingBoxUtils";
import { RoomEntityType } from "../../../../models/RoomEntityType";
import { MODEL_LINE_COLOR, MODEL_LINE_RENDER_ORDER } from "../../../consts";
import UnitsUtils from "../../../utils/UnitsUtils";
import { OffsetOperation } from "./OffsetOperation";

/** Simple interface for rectangle side positions in XY plane. */
export interface RectangleState {
  top: number; // Y of top edge
  right: number; // X of right edge
  bottom: number; // Y of bottom edge
  left: number; // X of left edge
}

/**
 * soRoomBoundary extends THREE.Group to manage a 2D rectangle using soBoundaryLine edges.
 */
export class soRoomBoundary extends THREE.Group {
  /** Current bounding box defining the rectangle. */
  private _boundingBox: THREE.Box3;
  /** A clone of the original bounding box for resets. */
  private _originalBoundingBox: THREE.Box3;
  /** Holds the four boundary lines keyed by side. */
  private _lines: Record<Side, soBoundaryLine>;

  private _boundaryType: string;

  private offSetOperaions: OffsetOperation[] = [];
  /**
   * Map each Side to the specific axis (x or y) and which bound (min or max)
   * in the bounding box needs to be updated.
   */
  private _coordinateMap: Record<Side, { axis: "x" | "y"; bound: "min" | "max" }> = {
    [Side.top]: { axis: "y", bound: "max" },
    [Side.bottom]: { axis: "y", bound: "min" },
    [Side.right]: { axis: "x", bound: "max" },
    [Side.left]: { axis: "x", bound: "min" },
  };

  /**
   * Constructor for soRoomBoundary.
   * @param box - Optional bounding box to initialize the rectangle.
   *             Defaults to a 10×10 square from (0,0,0) to (10,10,0).
   */
  constructor(boundaryType: string, box?: THREE.Box3) {
    super();
    this._boundaryType = boundaryType;
    this._lines = {} as Record<Side, soBoundaryLine>;

    if (box && (box.min.x > box.max.x || box.min.y > box.max.y)) {
      throw new Error("Invalid bounding box: min values must be less than or equal to max values.");
    }

    this._boundingBox = box ? box.clone() : new THREE.Box3(new THREE.Vector3(0, 0, 0), new THREE.Vector3(10, 10, 0));
    this._originalBoundingBox = this._boundingBox.clone();

    // Create all edges from the bounding box
    this.initializeEdges();
    this.visible = false;
  }

  /**
   * Creates the initial four edges (lines) from the current bounding box.
   */
  private initializeEdges(): void {
    const { min, max } = this._boundingBox;

    // For convenience, define the four corner points in 2D (z=0).
    const topLeft = new THREE.Vector3(min.x, max.y, 0);
    const topRight = new THREE.Vector3(max.x, max.y, 0);
    const bottomRight = new THREE.Vector3(max.x, min.y, 0);
    const bottomLeft = new THREE.Vector3(min.x, min.y, 0);

    // Each entry links a side to the segment corners
    const edges = [
      { side: Side.top, points: [topLeft, topRight] },
      { side: Side.right, points: [topRight, bottomRight] },
      { side: Side.bottom, points: [bottomRight, bottomLeft] },
      { side: Side.left, points: [bottomLeft, topLeft] },
    ];

    for (const { side, points } of edges) {
      const geometry = new THREE.BufferGeometry().setFromPoints(points);
      const material =
        this._boundaryType == RoomEntityType.ModelLine
          ? new THREE.LineDashedMaterial({
              color: MODEL_LINE_COLOR,
              dashSize: 0.3 * UnitsUtils.getConversionFactor(),
              gapSize: 0.3 * UnitsUtils.getConversionFactor(),
              transparent: true,
              opacity: 1.0,
            })
          : new THREE.LineBasicMaterial({
              color: MODEL_LINE_COLOR,
              linewidth: 3,
            });

      const line = new THREE.Line(geometry, material);
      this.add(line);
      line.name = this._boundaryType;
      line.userData.type = this._boundaryType;
      line.renderOrder = MODEL_LINE_RENDER_ORDER + 20000;
      line.computeLineDistances();
      const boundaryLine = new soBoundaryLine(`${side}-id`, line);
      boundaryLine.ParentRoomSide = side;
      // Keep track of the soBoundaryLine instance
      this._lines[side] = boundaryLine;

      // Add to the group
    }
  }

  updateConnectedEdges(side: Side): void {
    const oppositeSides = side === Side.top || side === Side.bottom ? [Side.left, Side.right] : [Side.top, Side.bottom];
    oppositeSides.forEach(oppositeSide => this.updateSingleEdge(oppositeSide));
  }
  updateAllEdges(): void {
    Object.values(Side).forEach(side => this.updateSingleEdge(side as Side));
  }
  /**
   * Updates the geometry of an existing edge instead of recreating it.
   * @param side - The edge to update (top, right, bottom, left).
   */
  private updateSingleEdge(side: Side): void {
    const { min, max } = this._boundingBox;

    // Update points based on the bounding box
    const updatedPoints = {
      [Side.top]: [new THREE.Vector3(min.x, max.y, 0), new THREE.Vector3(max.x, max.y, 0)],
      [Side.right]: [new THREE.Vector3(max.x, max.y, 0), new THREE.Vector3(max.x, min.y, 0)],
      [Side.bottom]: [new THREE.Vector3(max.x, min.y, 0), new THREE.Vector3(min.x, min.y, 0)],
      [Side.left]: [new THREE.Vector3(min.x, min.y, 0), new THREE.Vector3(min.x, max.y, 0)],
    }[side];

    const line = this._lines[side].line;
    const geometry = line.geometry as THREE.BufferGeometry;
    // Needed for Net Boundary update
    geometry.setFromPoints(updatedPoints);
    geometry.computeBoundingBox();
    geometry.computeBoundingSphere();
    geometry.computeVertexNormals();
    geometry.attributes.position.needsUpdate = true;
  }

  applyOffsetOperation(offsetOperation: OffsetOperation, updateEdge = true): void {
    const { side, distance } = offsetOperation;
    const { axis, bound } = this._coordinateMap[side];

    // Determine direction based on bound (min or max) and adjust accordingly
    const direction = bound === "max" ? -1 : 1;
    (this._boundingBox[bound] as THREE.Vector3)[axis] += direction * distance;
    if (updateEdge) {
      this.updateSingleEdge(side);
      this.updateConnectedEdges(side);
    }
  }

  /**
   * Moves the specified edge by `offset`.
   * e.g. moveEdge(Side.top, 2) shifts the top edge 2 units upward in Y.
   * @param side - The edge to move (top, right, bottom, left).
   * @param offset - The distance to move the edge.
   */
  public moveEdge(side: Side, offset: number): void {
    const { axis, bound } = this._coordinateMap[side];

    // Determine direction based on bound (min or max) and adjust accordingly
    const direction = bound === "max" ? -1 : 1;
    (this._boundingBox[bound] as THREE.Vector3)[axis] += direction * offset;

    // Ensure min <= max in both x and y
    this.enforceBounds();
    const oppositeSides = side === Side.top || side === Side.bottom ? [Side.left, Side.right] : [Side.top, Side.bottom];
    // Update the specific edge instead of recreating all
    this.updateSingleEdge(side);
    oppositeSides.forEach(oppositeSide => this.updateSingleEdge(oppositeSide));
  }

  /**
   * Resets a specific edge to its original position using _originalBoundingBox.
   * @param side - Which edge to reset (top, right, bottom, left).
   */
  public resetEdge(side: Side): void {
    const { axis, bound } = this._coordinateMap[side];
    (this._boundingBox[bound] as THREE.Vector3)[axis] = (this._originalBoundingBox[bound] as THREE.Vector3)[axis];

    this.updateSingleEdge(side);
    this.updateConnectedEdges(side);
  }

  /**
   * Convenience method to reset all edges to their original bounding box.
   */
  public resetAllEdges(): void {
    Object.values(Side).forEach(side => {
      const { axis, bound } = this._coordinateMap[side];
      (this._boundingBox[bound] as THREE.Vector3)[axis] = (this._originalBoundingBox[bound] as THREE.Vector3)[axis];
    });
    this.updateAllEdges();
  }

  /**
   * Ensures min <= max in both X and Y. If there's a risk of "inverted" bounding box,
   * this method handles it gracefully. Adjust it as needed for your use-case
   * (e.g., clamp instead of swap).
   */
  private enforceBounds(): void {
    // Prevent min > max by swapping if needed.
    if (this._boundingBox.min.x > this._boundingBox.max.x) {
      [this._boundingBox.min.x, this._boundingBox.max.x] = [this._boundingBox.max.x, this._boundingBox.min.x];
    }
    if (this._boundingBox.min.y > this._boundingBox.max.y) {
      [this._boundingBox.min.y, this._boundingBox.max.y] = [this._boundingBox.max.y, this._boundingBox.min.y];
    }
  }

  /**
   * Returns the current positions of the four edges.
   */
  public getCurrentState(): RectangleState {
    return {
      top: this._boundingBox.max.y,
      right: this._boundingBox.max.x,
      bottom: this._boundingBox.min.y,
      left: this._boundingBox.min.x,
    };
  }

  /**
   * Returns the underlying soBoundaryLine for a given side, if needed.
   * @param side - top, right, bottom, or left
   */
  public getBoundaryLine(side: Side): soBoundaryLine | undefined {
    return this._lines[side];
  }

  /**
   * Creates an instance of soRoomBoundary from a list of soBoundaryLine.
   * @param lines - An array of soBoundaryLine objects.
   */
  public static fromBoundaryLines(lines: soBoundaryLine[], detachChildren = true): soRoomBoundary {
    if (lines.length !== 4) {
      throw new Error("Exactly four boundary lines are required to create a soRoomBoundary.");
    }
    const boundaryType = lines[0].line.name;
    if (!lines.every(line => line.line.name === boundaryType)) throw new Error("All boundary lines must have the same name.");
    const boundingBox = new THREE.Box3();
    lines.forEach(element => {
      const geometry = element.line.geometry as THREE.BufferGeometry;
      const points = geometry.attributes.position.array as Float32Array;
      boundingBox.expandByPoint(new THREE.Vector3(points[0], points[1], 0));
      boundingBox.expandByPoint(new THREE.Vector3(points[3], points[4], 0));
    });
    const center = lines
      .reduce((acc: THREE.Vector3, line: soBoundaryLine) => {
        acc.add(line.MidPoint.clone());
        return acc;
      }, new THREE.Vector3())
      .divideScalar(4);

    const lineMap = {} as Record<Side, soBoundaryLine>;

    for (const line of lines) {
      const side = line.IsLineVertical() ? (line.MidPoint.x < center.x ? Side.left : Side.right) : line.MidPoint.y < center.y ? Side.bottom : Side.top;

      lineMap[side] = line;
      line.ParentRoomSide = side;
    }

    const instance = new soRoomBoundary(boundaryType, boundingBox);
    instance._lines = lineMap;
    if (detachChildren) {
      instance.clear();
    }
    return instance;
  }
  /**
   * Attaches the provided soBoundaryLine instances to the soRoomBoundary.
   * @param lines - An array of soBoundaryLine objects.
   */
  public attachLinesToSoRoom(room: soRoom2D): void {
    const lines = this.boundaryLines;
    lines.forEach(ln => {
      room.attach(ln.line);
    });
  }

  /**
   * attaches lines to this boundary object.
   * @param lines - An array of soBoundaryLine objects.
   */
  public detachLinesFromSoRoom(): void {
    const lines = this.boundaryLines;
    lines.forEach(ln => {
      this.attach(ln.line);
    });
  }

  /**
   * Takes the boundary lines from the soRoomBoundary instance.
   * @returns An array of soBoundaryLine objects.
   */
  get boundaryLines(): soBoundaryLine[] {
    return Array.from(Object.values(this._lines));
  }
  get boundingBox(): THREE.Box3 {
    const boundingBox = new THREE.Box3();
    this.boundaryLines.forEach(element => {
      boundingBox.expandByObject(element.line);
    });
    return boundingBox;
  }

  addOffsetOperation(side: Side, offset: number): void {
    this.offSetOperaions.push(new OffsetOperation(side, offset));
  }
  applyOffsetOperations(): void {
    while (this.offSetOperaions.length > 0) {
      const offsetOperation = this.offSetOperaions.shift();
      this.applyOffsetOperation(offsetOperation, false);
    }
    this.updateAllEdges();
  }

  offsetBoundary(offset: number): void {
    Object.values(Side).forEach(side => {
      this.applyOffsetOperation(new OffsetOperation(side, offset), false);
    });
    this.updateAllEdges();
  }

  overrideOriginalBoundingBox(box: THREE.Box3): void {
    this._originalBoundingBox = box.clone();
  }
  get OriginalBoundingBox(): THREE.Box3 {
    return this._originalBoundingBox.clone();
  }
}

import * as THREE from "three";
import RoofSurfaceOutput from "./roofSurfaceOutput";
import EdgeOutput from "./edgeOutput";
import Edge, { EdgeFacadeDir } from "./edge";
import Utils from "./utils";
import HoleInSurface from "./holeInSurface";

export default class Polyline {
  public vertices: THREE.Vector2[];
  public isClosed: boolean;
  public static tol = 1e-6;
  public static clipper = null;

  constructor(vertices: THREE.Vector2[], isClosed: boolean) {
    this.vertices = vertices;
    this.isClosed = isClosed;
  }

  public static convertPolylinesToClipFormat(polylineList: Polyline[]) {
    const result = new Polyline.clipper.Paths64();
    polylineList.forEach(polyline => {
      const flatList = polyline.vertices.flatMap(vert => {
        return [Math.round(vert.x / Polyline.tol), Math.round(vert.y / Polyline.tol)];
      });
      result.push_back(Polyline.clipper.MakePath64(flatList));
    });

    return result;
  }

  public static convertClipFormatToPolylines(solution): Polyline[] {
    const result = [];
    for (let iPath = 0; iPath < solution.size(); iPath++) {
      const path = solution.get(iPath);
      const size = path.size();
      const verts = [];
      for (let i = 0; i < size; i++) {
        const point = path.get(i);
        verts.push(new THREE.Vector2(Number(point.x) * Polyline.tol, Number(point.y) * Polyline.tol));
      }

      result.push(new Polyline(verts, true));
    }
    return result;
  }

  public static union(pls: Polyline[], precision = 5): Polyline[] {
    const subj = Polyline.convertPolylinesToClipFormat(pls);
    let solution = Polyline.clipper.Union64(subj, subj, Polyline.clipper.FillRule.NonZero);
    solution = Polyline.clipper.SimplifyPaths64(solution, precision);
    return Polyline.convertClipFormatToPolylines(solution);
  }

  public static difference(positive: Polyline[], negative: Polyline[]): Polyline[] {
    const subj = Polyline.convertPolylinesToClipFormat(positive);
    const clip = Polyline.convertPolylinesToClipFormat(negative);

    let solution = Polyline.clipper.Difference64(subj, clip, Polyline.clipper.FillRule.NonZero);
    solution = Polyline.clipper.SimplifyPaths64(solution, 1);
    return Polyline.convertClipFormatToPolylines(solution);
  }

  /// <summary>
  /// Converts a polyline back into a roof surface, creating new nodes, edges, and preserving any holes in the surface group.
  /// </summary>
  /// <param name="polyline">The polyline to be converted into a roof surface.</param>
  /// <param name="originalSurfaces">The original list of roof surfaces for reference.</param>
  /// <param name="slope">The slope value to apply to the new surface.</param>
  /// <param name="dir">The direction of the edges in the facade.</param>
  /// <param name="holesInSurfaceGroup">A list of holes that need to be preserved in the new surface.</param>
  /// <returns>A new RoofSurfaceOutput object representing the converted polyline.</returns>
  public convertPolylineToRoofSurface(
    originalSurfaces: RoofSurfaceOutput[],
    slope: number,
    dir: EdgeFacadeDir,
    holesInSurfaceGroup: THREE.Vector3[][]
  ): RoofSurfaceOutput {
    const edgeOutputs: EdgeOutput[] = [];
    const edges: Edge[] = [];

    // Convert each vertex of the polyline into nodes and edges
    for (let i = 0; i < this.vertices.length; i++) {
      const startVertex = this.vertices[i];
      const endVertex = this.vertices[(i + 1) % this.vertices.length];

      const startPoint = Utils.getClosestPoint3D(startVertex, originalSurfaces);
      const endPoint = Utils.getClosestPoint3D(endVertex, originalSurfaces);

      const edgeOutput = new EdgeOutput(startPoint, endPoint);
      edgeOutputs.push(edgeOutput);

      const edge = Edge.createEdge(startVertex, endVertex, originalSurfaces);
      edges.push(edge);
    }

    // Filter and preserve holes in the surface group
    const holesInSurface = HoleInSurface.filterHolesInSurfaceGroup(holesInSurfaceGroup, edges);

    return new RoofSurfaceOutput(edgeOutputs, slope, dir, 0, holesInSurface);
  }

  /// <summary>
  /// Offsets the vertices of a closed polyline outward or inward by a specified distance. This method modifies the polyline's vertices to represent an expanded or contracted version of the original shape, based on the normal at each vertex. The offset is applied perpendicularly to each line segment formed by consecutive vertices, effectively altering the size of the polyline while attempting to maintain its original form. This method is intended for use with closed polylines only.
  /// </summary>
  /// <param name="distance">The distance by which to offset the polyline's vertices. Positive values offset outward, increasing the size of the shape, while negative values offset inward, reducing the size.</param>
  /// <exception cref="NotSupportedException">Thrown if the polyline is not closed, as the method does not support open polylines.</exception>
  public offset(distance: number) {
    // Check if the polyline is closed; the method only supports closed polylines
    if (!this.isClosed) return;

    const count = this.vertices.length;
    const deltas = []; // Prepare an array to hold offset deltas for each vertex
    for (let i = 0; i < count; i++) deltas[i] = new THREE.Vector2(0, 0);

    // Calculate offset deltas for each vertex
    const toStart = new THREE.Vector2();
    const normal = new THREE.Vector2();
    for (let i = 0; i < count; i++) {
      const nextIndex = i == count - 1 ? 0 : i + 1; // Determine the next vertex index, wrapping around to 0 if at the last vertex
      const currentVertex = this.vertices[i]; // Current vertex
      const nextVertex = this.vertices[nextIndex]; // Next vertex

      toStart.subVectors(nextVertex, currentVertex);
      normal.set(toStart.y, -toStart.x);
      normal.normalize();

      normal.multiplyScalar(distance);

      if (Utils.ValuesAreCloseEnough(deltas[i].x, 0)) deltas[i].x = normal.x;
      if (Utils.ValuesAreCloseEnough(deltas[i].y, 0)) deltas[i].y = normal.y;

      if (Utils.ValuesAreCloseEnough(deltas[nextIndex].x, 0)) deltas[nextIndex].x = normal.x;
      if (Utils.ValuesAreCloseEnough(deltas[nextIndex].y, 0)) deltas[nextIndex].y = normal.y;
    }

    // Apply the calculated offsets to each vertex
    for (let i = 0; i < count; i++) {
      this.vertices[i].add(deltas[i]); // Apply the delta to move the vertex
    }
  }
}

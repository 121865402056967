import * as THREE from "three";
import Utils from "./utils";

import Node from "./node";
import Gable from "./gable";
import Edge, { EdgeFacadeDir } from "./edge";
import RoofSurface from "./roofSurface";
import Plane from "./plane";
import Line3dEquation, { LineCreationType } from "./line3dEquation";
import Polyline from "./polyline";
import CoreUtils from "../shared/coreUtils";

export default class ContourEdgesUtil {
  /// <summary>
  /// return a list of contour edges with slopes, and a list of node edges
  /// </summary>
  public static createContourEdgesAndNodes(inputData) {
    // create all edges and nodes
    let roofEdges: Edge[] = [];
    const roofNodes: Node[] = [];
    let lineIndex = 0;
    for (const line of inputData) {
      // Note that we change from counter clockwise to clockwise.
      // TODO: What if the input *isn't* CCW?
      const pointB = new THREE.Vector3(line.startNode.x, line.startNode.y, 0);
      const pointA = new THREE.Vector3(line.endNode.x, line.endNode.y, 0);

      let nodeA = new Node(pointA);
      let nodeB = new Node(pointB);
      if (!Utils.contains(roofNodes, nodeA)) roofNodes.push(nodeA);
      else nodeA = roofNodes.filter(n => Utils.pointsAreCloseEnough(n.point3D, nodeA.point3D))[0];

      if (!Utils.contains(roofNodes, nodeB)) roofNodes.push(nodeB);
      else nodeB = roofNodes.filter(n => Utils.pointsAreCloseEnough(n.point3D, nodeB.point3D))[0];

      const edge = new Edge(nodeA, nodeB);
      edge.slope = line.slope == 0 ? 0 : Math.atan(1 / line.slope);
      edge.depth = line.gableDepth;
      lineIndex++;
      edge.name = `edge${lineIndex}`;
      if (line.overhang !== undefined) edge.overhang = line.overhang;
      if (line.id !== undefined) edge.id = line.id;

      nodeA.edges.push(edge);
      nodeB.edges.push(edge);
      roofEdges.push(edge);
    }

    // merge continous edges with default angle slope
    const roofEdgesBeforeMerge: Edge[] = [...roofEdges];
    const defaultAngle = Utils.getDefaultAngle(roofEdgesBeforeMerge); //TODO: shoult get real default angle
    let bDidMerge;
    do {
      // set default angle for all merged edges
      bDidMerge = Utils.mergeContinuousEdges(roofEdges, roofNodes);
    } while (bDidMerge);

    // set order like I built the shapes in Rhino. start from weseter edge and than clockwise.
    // not all edges will be in the positive direction here.
    roofEdges = ContourEdgesUtil.setOrderWithWestestEdgeFirst(roofEdges);

    // CREATE Gables
    let partialLines: Edge[] = [];
    const gables: Gable[] = [];
    // foreach edge can be not common angle
    for (let i = 0; i < roofEdgesBeforeMerge.length; i++) {
      if (roofEdgesBeforeMerge[i].slope < 0) continue;

      if (roofEdgesBeforeMerge[i].slope == defaultAngle) continue;

      partialLines.push(roofEdgesBeforeMerge[i]); // add first edge to list

      // add more edges to list
      for (let j = i + 1; j < roofEdgesBeforeMerge.length; j++) {
        if (roofEdgesBeforeMerge[j].isVertical != roofEdgesBeforeMerge[j - 1].isVertical) break;
        if (roofEdgesBeforeMerge[j].slope != roofEdgesBeforeMerge[j - 1].slope) break;
        partialLines.push(roofEdgesBeforeMerge[j]);
        i++;
      }

      // find origin contour edge
      const originContourEdge = Edge.getEdgeThatContainsPartialContinousEdges(partialLines[0], roofEdges);
      if (!originContourEdge) continue;

      // assign not default angle to full line
      if (ContourEdgesUtil.partialLinesIsFullLine(partialLines, roofEdges) && roofEdgesBeforeMerge[i].depth == 0)
        originContourEdge.slope = roofEdgesBeforeMerge[i].slope;
      // create LineGable class only if angle of gable is zero
      else if (roofEdgesBeforeMerge[i].slope == 0) {
        // In case the full line contains only one line, the slope is zero and it should be bigger than zero
        if (originContourEdge.slope == 0) originContourEdge.slope = roofEdges.filter(r => r.slope > 0)[0].slope;

        gables.push(Gable.createGable(partialLines, originContourEdge, roofEdgesBeforeMerge[i].depth));
      }
      partialLines = [];
    }

    return { contourEdges: roofEdges, gables: gables, roofEdgesBeforeMerge: roofEdgesBeforeMerge };
  }

  /// <summary>
  /// set polygon edges order. the first in list will be the westest vertical.
  /// </summary>
  static setOrderWithWestestEdgeFirst(edges: Edge[]): Edge[] {
    const verticalEdges: Edge[] = edges.filter(e => e.isVertical);
    verticalEdges.sort((e1, e2) => {
      return e1.startNode.point3D.x - e2.startNode.point3D.x;
    });
    const westestEdge: Edge = verticalEdges[0];
    const reOrder: Edge[] = [westestEdge];
    for (let i = 1; i < edges.length; i++) {
      for (const edge of edges) {
        if (Utils.contains(reOrder, edge)) continue;

        if (reOrder[reOrder.length - 1].endNode.isEqual(edge.startNode)) {
          reOrder.push(edge);
          continue;
        }
      }
    }
    return reOrder;
  }

  /// <summary>
  /// return true if input partialLines is a full roofEdge, false otherwise.
  /// </summary>
  public static partialLinesIsFullLine(partialLines: Edge[], roofEdges: Edge[]): boolean {
    const roofEgde: Edge = Edge.getEdgeThatContainsPartialContinousEdges(partialLines[0], roofEdges);
    const points: THREE.Vector3[] = [];
    for (const edge of partialLines) {
      if (!Utils.containsPoint(points, edge.startNode.point3D)) points.push(edge.startNode.point3D);
      if (!Utils.containsPoint(points, edge.endNode.point3D)) points.push(edge.endNode.point3D);
    }
    if (roofEgde != null && Utils.containsPoint(points, roofEgde.startNode.point3D) && Utils.containsPoint(points, roofEgde.endNode.point3D)) {
      return true;
    }
    return false;
  }

  /// <summary>
  /// assume first one is the westest vertical. and the rest goes clockwise
  /// </summary>
  public static assignFacadeDirectionForEachEdgeByClosePolygon(contourEdges: Edge[]) {
    contourEdges[0].direction = EdgeFacadeDir.WEST;
    for (let i = 1; i < contourEdges.length; i++) {
      const last: Edge = contourEdges[i - 1];
      const cur: Edge = contourEdges[i];
      if (!last.endNode.isEqual(cur.startNode)) {
        [cur.startNode, cur.endNode] = [cur.endNode, cur.startNode];
      }

      if (cur.isVertical && cur.startNode.point3D.y < cur.endNode.point3D.y) {
        cur.direction = EdgeFacadeDir.WEST;
      } else if (cur.isVertical && cur.startNode.point3D.y > cur.endNode.point3D.y) {
        cur.direction = EdgeFacadeDir.EAST;
      } else if (cur.isHorizontal && cur.startNode.point3D.x < cur.endNode.point3D.x) {
        cur.direction = EdgeFacadeDir.NORTH;
      } else if (cur.isHorizontal && cur.startNode.point3D.x > cur.endNode.point3D.x) {
        cur.direction = EdgeFacadeDir.SOUTH;
      }
    }
    // set back to poisitive direction
    for (const contourEdge of contourEdges) {
      if (
        (contourEdge.isVertical && contourEdge.startNode.point3D.y > contourEdge.endNode.point3D.y) ||
        (contourEdge.isHorizontal && contourEdge.startNode.point3D.x > contourEdge.endNode.point3D.x)
      ) {
        [contourEdge.startNode, contourEdge.endNode] = [contourEdge.endNode, contourEdge.startNode];
      }
    }
  }

  /// <summary>
  /// create a plane on each edge. conseder slope angle
  /// </summary>
  public static createPlanesFromEdges(contourEdges: Edge[]) {
    for (let i = 0; i < contourEdges.length; i++) {
      let rad = contourEdges[i].slope;

      if (contourEdges[i].direction == EdgeFacadeDir.NORTH || contourEdges[i].direction == EdgeFacadeDir.EAST) rad = -rad;

      const horizontalDist = Math.tan(rad);
      const thirdPoint = contourEdges[i].isVertical
        ? new THREE.Vector3(contourEdges[i].startNode.point3D.x + horizontalDist, contourEdges[i].startNode.point3D.y, contourEdges[i].startNode.point3D.z + 1)
        : new THREE.Vector3(contourEdges[i].startNode.point3D.x, contourEdges[i].startNode.point3D.y + horizontalDist, contourEdges[i].startNode.point3D.z + 1);
      const plane = new Plane(contourEdges[i].startNode.point3D, contourEdges[i].endNode.point3D, thirdPoint);
      plane.setPlane();
      contourEdges[i].plane = plane;
      contourEdges[i].roofSurface = new RoofSurface(contourEdges[i], Math.abs(rad), contourEdges[i].direction);
    }
  }

  /// <summary>
  /// for each contour edge plane, create all line equations on the plane.
  /// line equation is the intersection of two planes.
  /// </summary>
  public static createLineEquations(contourEdges: Edge[]) {
    for (const edge1 of contourEdges) {
      if (!edge1.plane || !edge1.roofSurface) throw new Error("Roof edge does not have Plane property");

      const baseLevel = edge1.startNode.point3D.z;
      for (const edge2 of contourEdges) {
        if (!edge2.plane || !edge2.roofSurface) throw new Error("Roof edge does not have RoofSurface property");

        if (edge1.plane.isParallelTo(edge2.plane)) continue;
        if (edge1.isEqual(edge2)) continue;

        let linePoint = new THREE.Vector3(0, 0, 0);
        let line3D = Line3dEquation.createLine3DFromTwoPlanesWithGivenPoint(edge1.plane, edge2.plane, linePoint);

        // if same point
        if (
          edge1.startNode.isEqual(edge2.endNode) ||
          edge1.endNode.isEqual(edge2.startNode) ||
          edge1.startNode.isEqual(edge2.startNode) ||
          edge1.endNode.isEqual(edge2.endNode)
        ) {
          if (edge1.startNode.isEqual(edge2.endNode)) {
            linePoint = new THREE.Vector3(edge1.startNode.point3D.x, edge1.startNode.point3D.y, edge1.startNode.point3D.z);
          }
          if (edge1.endNode.isEqual(edge2.startNode)) {
            linePoint = new THREE.Vector3(edge1.endNode.point3D.x, edge1.endNode.point3D.y, edge1.endNode.point3D.z);
          }
          if (edge1.startNode.isEqual(edge2.startNode)) {
            linePoint = new THREE.Vector3(edge1.startNode.point3D.x, edge1.startNode.point3D.y, edge1.startNode.point3D.z);
          }
          if (edge1.endNode.isEqual(edge2.endNode)) {
            linePoint = new THREE.Vector3(edge1.endNode.point3D.x, edge1.endNode.point3D.y, edge1.endNode.point3D.z);
          }

          line3D = Line3dEquation.createLine3DFromTwoPlanesWithGivenPoint(edge1.plane, edge2.plane, linePoint);
          line3D.type = LineCreationType.SAME_POINT;
        }
        // if parallel edges
        else if (Line3dEquation.createLine3DFromTwoPlanesWithGivenPoint(edge1.plane, edge2.plane, linePoint).isParallelToXyPlane()) {
          if (edge1.isVertical) {
            // if edge1 slope is zero, put in parameter function midEdge x,y. Same if edge2 slope is zero.
            // else, if both slopes are the same, put in parameter function x,y in the middle between the edges.
            // TODO: This needlessly computes the midpoint multiple times
            line3D =
              edge1.slope == 0
                ? Line3dEquation.createLine3DParallelToXyFromTwoPlanes(edge1.plane, edge2.plane, edge1.getMidPoint().x, edge1.getMidPoint().y)
                : edge2.slope == 0
                  ? Line3dEquation.createLine3DParallelToXyFromTwoPlanes(edge1.plane, edge2.plane, edge2.getMidPoint().x, edge2.getMidPoint().y)
                  : Line3dEquation.createLine3DParallelToXyFromTwoPlanes(
                      edge1.plane,
                      edge2.plane,
                      (edge1.startNode.point3D.x + edge2.startNode.point3D.x) / 2,
                      edge1.getMidPoint().y
                    );
          } else if (edge1.isHorizontal) {
            line3D =
              edge1.slope == 0
                ? Line3dEquation.createLine3DParallelToXyFromTwoPlanes(edge1.plane, edge2.plane, edge1.getMidPoint().x, edge1.getMidPoint().y)
                : edge2.slope == 0
                  ? Line3dEquation.createLine3DParallelToXyFromTwoPlanes(edge1.plane, edge2.plane, edge2.getMidPoint().x, edge2.getMidPoint().y)
                  : Line3dEquation.createLine3DParallelToXyFromTwoPlanes(
                      edge1.plane,
                      edge2.plane,
                      edge1.getMidPoint().x,
                      (edge1.startNode.point3D.y + edge2.startNode.point3D.y) / 2
                    );
          }
          line3D.type = LineCreationType.PARALLEL;
          if (Utils.ValuesAreCloseEnough(line3D.A, 0) && Utils.ValuesAreCloseEnough(line3D.B, 0) && Utils.ValuesAreCloseEnough(line3D.C, 0)) {
            continue; // its happen when two plane are almost parallel
          }
        }
        // prepandicular but not touch
        else {
          linePoint = Utils.findPointBetweenTwoLines(edge1.line3dEquation, edge2.line3dEquation);
          line3D = Line3dEquation.createLine3DFromTwoPlanesWithGivenPoint(edge1.plane, edge2.plane, linePoint);
          line3D.type = LineCreationType.NOT_TOUCH;

          if (!Utils.pointIsInsidePolygon(linePoint, baseLevel, contourEdges)) continue;
          // prepandicular but not touch and go through out of the base polygon
          /*if(OneOfSurfacesGoThroughOutOfBasePolygonToCreateLine(edge1, edge2, contourEdges))
                    {
                        continue;
                    }*/
        }

        // if one of three last options
        edge1.roofSurface.lines.push(line3D);

        line3D.contourEdge1 = edge1;
        line3D.contourEdge2 = edge2;
      }
    }
  }

  /// <summary>
  /// order contour lines by: samePoint, notTuch, parallel
  /// </summary>
  public static orderContourEdgeLines(contourEdges: Edge[]) {
    for (const edge of contourEdges) {
      const lines: Line3dEquation[] = [];
      if (!edge.roofSurface) continue;

      // TODO: Can't mwe just use a sort, especially if LineCreationType is an int?
      for (const line of edge.roofSurface.lines) {
        if (line.type == LineCreationType.SAME_POINT) {
          lines.push(line);
        }
      }

      for (const line of edge.roofSurface.lines) {
        if (line.type == LineCreationType.NOT_TOUCH) {
          lines.push(line);
        }
      }

      for (const line of edge.roofSurface.lines) {
        if (line.type == LineCreationType.PARALLEL) {
          lines.push(line);
        }
      }

      edge.roofSurface.lines = lines;
    }
  }

  public static offsetInputLines(inputData, distance) {
    // TODO: This assumes input in specific winding and continuous
    const verts = [];
    for (const lineInfo of inputData) {
      verts.push(new THREE.Vector2(lineInfo["startNode"].x, lineInfo["startNode"].y));
    }
    const polyline = new Polyline(verts, true);
    polyline.offset(distance);

    // Convert back
    const newData = CoreUtils.clone(inputData);
    for (let idx = 0; idx < newData.length; idx++) {
      const lineInfo = newData[idx];
      lineInfo["startNode"].x = polyline.vertices[idx].x;
      lineInfo["startNode"].y = polyline.vertices[idx].y;

      const nextIndex = (idx + 1) % newData.length;
      lineInfo["endNode"].x = polyline.vertices[nextIndex].x;
      lineInfo["endNode"].y = polyline.vertices[nextIndex].y;
    }

    return newData;
  }
}

import * as THREE from "three";
import Utils from "./utils";
import Line3dEquation from "./line3dEquation";
import Polygon from "./polygon";
import NodeGraph from "./nodeGraph";
import EdgeGraph from "./edgeGraph";
import Edge from "./edge";

export default class Graph {
  public edges: EdgeGraph[] = [];
  public nodes: NodeGraph[] = [];
  public allPossiblePolygons: Polygon[] = [];
  public reducePolygons: Polygon[] = [];
  public lines: Line3dEquation[];

  constructor(lines: Line3dEquation[]) {
    this.lines = lines;
  }

  public addEdge(p1: THREE.Vector3, p2: THREE.Vector3) {
    let node1 = new NodeGraph(new THREE.Vector3(p1.x, p1.y, p1.z));
    let node2 = new NodeGraph(new THREE.Vector3(p2.x, p2.y, p2.z));

    if (Utils.contains(this.nodes, node1)) {
      node1 = this.getExistsNode(node1);
    } else {
      this.nodes.push(node1);
    }
    if (Utils.contains(this.nodes, node2)) {
      node2 = this.getExistsNode(node2);
    } else {
      this.nodes.push(node2);
    }

    const edge12 = new EdgeGraph(node1, node2);
    this.edges.push(edge12);

    node1.edges.push(edge12);
    node2.edges.push(edge12);
  }

  public delete(node: NodeGraph) {
    // TODO: Delete without making a list copy...
    const listCopy = node.edges.slice();
    for (const edge of listCopy) {
      Utils.remove(this.edges, edge);
      Utils.remove(edge.node1.edges, edge);
      Utils.remove(edge.node2.edges, edge);
    }
    Utils.remove(this.nodes, node);
  }

  /// <summary>
  /// If the input node exist in gragh return the exist node, otherwise return the input node
  /// </summary>
  private getExistsNode(node: NodeGraph): NodeGraph {
    for (const existNode of this.nodes) {
      if (existNode.isEqual(node)) return existNode;
    }
    return node;
  }

  public getNode(p: THREE.Vector3): NodeGraph {
    for (const node of this.nodes) {
      if (Utils.pointsAreCloseEnough(p, node.point3D)) return node;
    }
    throw new Error("points not found in gragh");
  }

  public getRoofPolygon(polygons: Polygon[]): Polygon {
    for (const roofPolygon of polygons) {
      for (const polygon of this.allPossiblePolygons) {
        if (polygon.isEqual(roofPolygon)) return polygon;
      }
    }
    throw new Error("Roof not completed, missing polygon");
  }

  public static createGraph(contourEdge: Edge, contourEdges: Edge[]) {
    if (!contourEdge.roofSurface) return;

    contourEdge.graph = new Graph(contourEdge.roofSurface.lines);
    contourEdge.graph.addEdge(contourEdge.startNode.point3D, contourEdge.endNode.point3D);

    const baseElevetion = contourEdge.startNode.point3D.z;

    for (const line1 of contourEdge.roofSurface.lines) {
      let pointsOnLine1: THREE.Vector3[] = [];
      for (const line2 of contourEdge.roofSurface.lines) {
        if (line2.isEqual(line1)) continue;
        if (line1.isParallelToXyPlane() && line2.isParallelToXyPlane()) continue;

        const point = Utils.findPointBetweenTwoLines(line1, line2);
        if (!point) continue;
        if (Utils.pointIsInfinity(point)) continue;
        if (Utils.pointIsInNan(point)) continue;
        if (Math.abs(point.x) > Utils.HighTolerance || Math.abs(point.y) > Utils.HighTolerance || Math.abs(point.z) > Utils.HighTolerance) {
          continue;
        }
        if (point.z + Utils.Tolerance < baseElevetion) continue;
        if (Utils.containsPoint(pointsOnLine1, point)) continue;
        if (!Utils.pointIsInsidePolygon(point, baseElevetion, contourEdges)) continue;
        if (
          Utils.ValuesAreCloseEnough(point.z, baseElevetion) &&
          !Utils.pointsAreCloseEnough(contourEdge.startNode.point3D, point) &&
          !Utils.pointsAreCloseEnough(contourEdge.endNode.point3D, point)
        ) {
          continue;
        }

        pointsOnLine1.push(point);
      }

      // order points on line - note that while they're on the same line,
      // the points are created UNORDERED. This sort is also bad, because it
      // it only works for lines in axis-aligned directions. Instead, we should be
      // computing parameter values for points on the line (i.e., t in linear equation),
      // sorting *those*, and only THEN creating actual line points....
      pointsOnLine1 = line1.isParallelToXaxis()
        ? [...pointsOnLine1.sort((p1, p2) => p1.x - p2.x)]
        : line1.isParallelToYaxis()
          ? [...pointsOnLine1.sort((p1, p2) => p1.y - p2.y)]
          : [...pointsOnLine1.sort((p1, p2) => p1.z - p2.z)];

      // add nodes and edges to Graph
      for (let i = 0; i < pointsOnLine1.length - 1; i++) {
        const newEdge = new EdgeGraph(new NodeGraph(pointsOnLine1[i]), new NodeGraph(pointsOnLine1[i + 1]));
        if (!Utils.contains(contourEdge.graph.edges, newEdge)) {
          contourEdge.graph.addEdge(pointsOnLine1[i], pointsOnLine1[i + 1]);
        }
      }
    }
  }
}

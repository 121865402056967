import * as THREE from "three";
import Utils from "./utils";
import Roof from "./roof";
import RoofSurfaceOutput from "./roofSurfaceOutput";
import Polyline from "./polyline";

export default class MergeSurfaces {
  /// <summary>
  /// Merges overlapping roof surfaces by grouping them based on their direction, slope, and plane,
  /// and then unions the overlapping surfaces into a single surface.
  /// </summary>
  /// <param name="roof">The roof object containing the roof surfaces to be processed.</param>
  public static mergeTwoOverlapSurfaces(roof: Roof) {
    // Grouping roof surfaces by Direction, Slope and Plane
    const keyCompareFunc = (key1, key2) => {
      if (key1.direction !== key2.direction) return false;

      if (!Utils.ValuesAreCloseEnough(key1.slope, key2.slope)) return false;

      if (!key1.plane.isEqual(key2.plane)) return false;

      return true;
    };

    const validSurfaces = roof.roofSurfaces.filter(surface => surface.edges.length >= 3);
    const groupedSurfaces: RoofSurfaceOutput[][] = validSurfaces
      .reduce((accum, surface) => {
        const currKey = {
          direction: surface.direction,
          slope: MergeSurfaces.getGroupedSlope(surface.slope, validSurfaces),
          plane: surface.getPlane(validSurfaces),
        };

        const idx = accum.findIndex(elem => {
          return keyCompareFunc(elem.key, currKey);
        });
        if (idx >= 0) accum[idx].values.push(surface);
        else accum.push({ key: currKey, values: [surface] });

        return accum;
      }, [])
      .map(elem => elem.values);

    // Store the original surfaces and clear the current roof surfaces
    const originalSurfaces = validSurfaces.slice();
    roof.roofSurfaces = [];

    // Process each group of surfaces
    for (const group of groupedSurfaces) {
      // If the slope is zero or there is only one surface of the group, add it back to the roof
      if (group[0].slope == 0 || group.length == 1) {
        for (const surface of group) {
          roof.roofSurfaces.push(surface);
        }
        continue;
      }

      // Collect all holes of the current surface group
      let holesInSurfaceGroup: THREE.Vector3[][] = [];
      for (const surface of group) {
        if (surface.holesInSurface != null) holesInSurfaceGroup = holesInSurfaceGroup.concat(surface.holesInSurface);
      }

      // Convert each surface of the group to a polyline
      const polylines = group.map(surface => surface.convertSurfaceToPolyline());

      // Define the tolerance values to try
      const toleranceValues = [2, 1, 0];

      // Attempt to union the polylines with decreasing tolerance values
      let unionedPolylines: Polyline[] = null;
      for (const tolerance of toleranceValues) {
        unionedPolylines = Polyline.union(polylines, tolerance);
        if (polylines.length != unionedPolylines.length) break;
      }

      // Update the roof with the unioned polylines, preserving the slope, direction, and holes
      roof.updateRoofWithUnionedPolylines(originalSurfaces, unionedPolylines, group[0].slope, group[0].direction, holesInSurfaceGroup);
    }
  }

  /// <summary>
  /// Groups slopes with a tolerance check to ensure surfaces with nearly identical slopes are considered together.
  /// </summary>
  /// <param name="slope">The slope to be grouped.</param>
  /// <param name="surfaces">The list of roof surfaces for reference.</param>
  /// <returns>The closest slope value found of the group; otherwise, returns the original slope.</returns>
  static getGroupedSlope(slope: number, surfaces: RoofSurfaceOutput[]): number {
    for (const surface of surfaces) {
      if (Utils.ValuesAreCloseEnough(slope, surface.slope)) {
        return surface.slope;
      }
    }
    return slope; // If no close enough slope is found, return the original slope
  }
}

import * as THREE from "three";
import Utils from "./utils";
import type Edge from "./edge";
import type Plane from "./plane";

export enum LineCreationType {
  UNKNOWN,
  SAME_POINT,
  PARALLEL,
  NOT_TOUCH,
}

/// <summary>
/// (x,y,z)=(x0,y0,z0)+t(a,b,c)
/// </summary>
export default class Line3dEquation {
  public X0: number;
  public Y0: number;
  public Z0: number;
  public A: number;
  public B: number;
  public C: number;
  public type = LineCreationType.PARALLEL;
  public contourEdge1: Edge;
  public contourEdge2: Edge;

  constructor(startPoint: THREE.Vector3, direction: THREE.Vector3) {
    this.X0 = startPoint.x;
    this.Y0 = startPoint.y;
    this.Z0 = startPoint.z;
    this.A = direction.x;
    this.B = direction.y;
    this.C = direction.z;
  }

  public isEqual(other: Line3dEquation): boolean {
    if (!other) return false;

    return (
      Utils.ValuesAreCloseEnough(other.X0, this.X0) &&
      Utils.ValuesAreCloseEnough(other.Y0, this.Y0) &&
      Utils.ValuesAreCloseEnough(other.Z0, this.Z0) &&
      Utils.ValuesAreCloseEnough(other.A, this.A) &&
      Utils.ValuesAreCloseEnough(other.B, this.B) &&
      Utils.ValuesAreCloseEnough(other.C, this.C)
    );
  }

  public isParallelToXyPlane(): boolean {
    return Math.abs(this.C) < Utils.Tolerance;
  }
  public isParallelToXaxis(): boolean {
    return Math.abs(this.C) < Utils.Tolerance && Math.abs(this.B) < Utils.Tolerance;
  }
  public isParallelToYaxis(): boolean {
    return Math.abs(this.C) < Utils.Tolerance && Math.abs(this.A) < Utils.Tolerance;
  }
  public isParallelToZaxis(): boolean {
    return Math.abs(this.B) < Utils.Tolerance && Math.abs(this.A) < Utils.Tolerance;
  }

  public static CrossProduct(line1: Line3dEquation, line2: Line3dEquation): THREE.Vector3 {
    return new THREE.Vector3(line1.B * line2.C - line1.C * line2.B, line1.A * line2.C - line1.C * line2.A, line1.A * line2.B - line1.B * line2.A);
  }

  public isOnLevel(elevetion: number): boolean {
    return this.Z0 == elevetion && this.C == 0;
  }

  public getDirection() {
    const dir = new THREE.Vector3(this.A, this.B, this.C);
    dir.normalize();
    return dir;
  }

  /// <summary>
  /// Get a point on the line given parameter t
  /// </summary>
  /// <param name="t">Parameter t</param>
  /// <returns>Point on the line as a tuple (x, y, z)</returns>
  public getPoint(t: number): THREE.Vector3 {
    const x = this.X0 + t * this.A;
    const y = this.Y0 + t * this.B;
    const z = this.Z0 + t * this.C;
    return new THREE.Vector3(x, y, z);
  }

  /// <summary>
  /// Get a point on the line with a specific x-coordinate
  /// </summary>
  /// <param name="x">x-coordinate</param>
  /// <returns>Point on the line as a tuple (x, y, z)</returns>
  public getPointByX(x: number): THREE.Vector3 | null {
    if (this.A == 0) return null; // No point exists if the line is parallel to the y-z plane
    const t = (x - this.X0) / this.A;
    return this.getPoint(t);
  }

  /// <summary>
  /// Get a point on the line with a specific y-coordinate
  /// </summary>
  /// <param name="y">y-coordinate</param>
  /// <returns>Point on the line as a tuple (x, y, z)</returns>
  public getPointByY(y: number): THREE.Vector3 | null {
    if (this.B == 0) return null; // No point exists if the line is parallel to the x-z plane
    const t = (y - this.Y0) / this.B;
    return this.getPoint(t);
  }

  /// <summary>
  /// Get a point on the line with a specific z-coordinate
  /// </summary>
  /// <param name="z">z-coordinate</param>
  /// <returns>Point on the line as a tuple (x, y, z)</returns>
  public getPointByZ(z: number): THREE.Vector3 | null {
    if (this.C == 0) return null; // No point exists if the line is parallel to the x-y plane
    const t = (z - this.Z0) / this.C;
    return this.getPoint(t);
  }

  /// <summary>
  /// create line equation that parallel to xy plane, using input point
  /// </summary>
  public static createLine3DFromTwoPlanesWithGivenPoint(plane1: Plane, plane2: Plane, linePoint: THREE.Vector3): Line3dEquation {
    const lineVector = new THREE.Vector3();
    lineVector.crossVectors(new THREE.Vector3(plane1.A, plane1.B, plane1.C), new THREE.Vector3(plane2.A, plane2.B, plane2.C));

    return new Line3dEquation(linePoint, lineVector);
  }

  /// <summary>
  /// create line equation from two planes if ypu know the line will be parallel to xy plane
  /// </summary>
  public static createLine3DParallelToXyFromTwoPlanes(plane1: Plane, plane2: Plane, x: number, y: number): Line3dEquation {
    const lineVector = new THREE.Vector3();
    lineVector.crossVectors(new THREE.Vector3(plane1.A, plane1.B, plane1.C), new THREE.Vector3(plane2.A, plane2.B, plane2.C));

    // finding a point on the line
    const z = (plane1.A * x + plane1.B * y + plane1.D - plane2.A * x - plane2.B * y - plane2.D) / (plane2.C - plane1.C);

    return new Line3dEquation(new THREE.Vector3(x, y, z), lineVector);
  }

  public line2IsParallelAndOneEdgeGoThroughOutsidePolygon(point: THREE.Vector3, contourEdges: Edge[]): boolean {
    if (this.type != LineCreationType.PARALLEL) return false;
    if (!this.contourEdge2 || !this.contourEdge1) return false;

    const baseLevel = this.contourEdge2.startNode.point3D.z;

    if (this.contourEdge1.isVertical) {
      const point11 = new THREE.Vector3(
        this.contourEdge1.startNode.point3D.x,
        this.contourEdge1.startNode.point3D.y - 0.5,
        this.contourEdge1.startNode.point3D.z
      );
      const point12 = new THREE.Vector3(this.contourEdge1.endNode.point3D.x, this.contourEdge1.endNode.point3D.y + 0.5, this.contourEdge1.endNode.point3D.z);
      const point21 = new THREE.Vector3(
        this.contourEdge2.startNode.point3D.x,
        this.contourEdge2.startNode.point3D.y - 0.5,
        this.contourEdge2.startNode.point3D.z
      );
      const point22 = new THREE.Vector3(this.contourEdge2.endNode.point3D.x, this.contourEdge2.endNode.point3D.y + 0.5, this.contourEdge2.endNode.point3D.z);

      if (!Utils.pointIsInsidePolygon(point11, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point11, contourEdges) && point.y < point11.y) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point12, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point12, contourEdges) && point.y > point12.y) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point21, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point21, contourEdges) && point.y < point21.y) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point22, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point22, contourEdges) && point.y > point22.y) {
        return true;
      }
    }
    if (this.contourEdge1.isHorizontal) {
      const point11 = new THREE.Vector3(
        this.contourEdge1.startNode.point3D.x - 0.5,
        this.contourEdge1.startNode.point3D.y,
        this.contourEdge1.startNode.point3D.z
      );
      const point12 = new THREE.Vector3(this.contourEdge1.endNode.point3D.x + 0.5, this.contourEdge1.endNode.point3D.y, this.contourEdge1.endNode.point3D.z);
      const point21 = new THREE.Vector3(
        this.contourEdge2.startNode.point3D.x - 0.5,
        this.contourEdge2.startNode.point3D.y,
        this.contourEdge2.startNode.point3D.z
      );
      const point22 = new THREE.Vector3(this.contourEdge2.endNode.point3D.x + 0.5, this.contourEdge2.endNode.point3D.y, this.contourEdge2.endNode.point3D.z);

      if (!Utils.pointIsInsidePolygon(point11, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point11, contourEdges) && point.x < point11.x) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point12, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point12, contourEdges) && point.x > point12.x) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point21, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point21, contourEdges) && point.x < point21.x) {
        return true;
      }
      if (!Utils.pointIsInsidePolygon(point22, baseLevel, contourEdges) && Utils.pointIsInsidePlaneXyBbx(point22, contourEdges) && point.x > point22.x) {
        return true;
      }
    }
    return false;
  }
}

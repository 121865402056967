import { SettingsUnits, TSettingsDescriptions, TSettingsValues } from "./types";

// All settings saved in inches.
const defaultSettings: TSettingsValues = {
  corePlanSettings: {
    numOfStories: 2,
    storiesMax: 3,
    numOfBedrooms: 3,
    bedroomMin: 2,
    bedroomMax: 5,
    numOfBathrooms: 3,
    bathroomMin: 2,
    bathroomMax: 4,
    numOfHalfBathrooms: 0,
    numOfCars: 2,
    carsMax: 2,

    defaultWindowHeaderHeight: 30,
    windowTopOfWallClearance: 10,
    floorToPlateLevelHeight: 96,
    minFloorToPlateLevelHeight: 84,
    maxFloorToPlateLevelHeight: 120,

    floorThickness: 16.75,

    windowWidth: 72,
    windowHeight: 60,

    minBuildingNetArea: 302400,
    maxBuildingGrossArea: 345600,
    lotArea: 432000,
    lotWidth: 600,
    lotLength: 720,
    lotFrontSetback: 144,
    lotBackSetback: 108,
    lotSideSetback: 72,
    lotFront2Setback: 144,
  },
  parametersSettings: {
    plmFixtureWallSplitTolerance: 4,
    stretchAllowance: 4,
    minimalWallSegmentLength: 4,
    windowEdgeOffset: 0,
    doorEdgeOffset: 0,
    minDistanceForOpeningToCoreWall: 4.5,
    openingAlignmentMinGap: 8,

    gridCellSizeForNewCorePlans: 8,
    frameSize: 3,
    areaCalculationExteriorOffset: 4,

    roofDefaultSlope: 0.5,
    roofDefaultOverhang: 12,
    overhangOptions: "1,6,12,16,24",
    defaultHeelHeight: 0,
    minimumGableSeparation: 12,
    roofThickness: 1,

    roofDiagram: "/assets/roof-diagram.svg",

    floorWeight: 0.138888889,
    roofWeight: 0.119444444,

    downspoutOffset: 12,
    minDistanceBetweenDownspouts: 24,
  },
  webAppUISettings: {
    displayUnits: "feet",
    selectionColor: "#7EC2B6",
    highlightColor: "#FF00C7",
    wallsColor: "#000000",
    floorsColor: "#EFEFEF",
    openingsColor: "#D9D9D9",
    gridPrimaryColor: "#EFEFEF",
    gridSecondaryColor: "#E5E5E5",
    lotLinesColor: "#2856AE",
    lotSetbackColor: "#FF3B47",
    obsoleteRoomsColor: "#FFFFFF",

    glassColor: "#B3DCF5",
    doorColor: "#B3DCF5",
    roofSurface: "#B5B2B2",
    roofEdge: "#000000",

    livingSpaces: "#F516F9",
    garage: "#EF9610",
    porchesAndPatio: "#3351F1",
    roof: "#6A6A6A",
  },
};

const defaultUnits: TSettingsDescriptions = {
  corePlanSettings: {
    numOfStories: SettingsUnits.units,
    storiesMax: SettingsUnits.units,
    numOfBedrooms: SettingsUnits.units,
    bedroomMin: SettingsUnits.units,
    bedroomMax: SettingsUnits.units,
    numOfBathrooms: SettingsUnits.units,
    bathroomMin: SettingsUnits.units,
    bathroomMax: SettingsUnits.units,
    numOfHalfBathrooms: SettingsUnits.units,
    numOfCars: SettingsUnits.units,
    carsMax: SettingsUnits.units,

    defaultWindowHeaderHeight: SettingsUnits.ft,
    windowTopOfWallClearance: SettingsUnits.ft,
    floorToPlateLevelHeight: SettingsUnits.ft,
    minFloorToPlateLevelHeight: SettingsUnits.ft,
    maxFloorToPlateLevelHeight: SettingsUnits.ft,

    floorThickness: SettingsUnits.ft,

    windowWidth: SettingsUnits.ft,
    windowHeight: SettingsUnits.ft,

    minBuildingNetArea: SettingsUnits.sqft,
    maxBuildingGrossArea: SettingsUnits.sqft,
    lotArea: SettingsUnits.sqft,
    lotWidth: SettingsUnits.ft,
    lotLength: SettingsUnits.ft,
    lotFrontSetback: SettingsUnits.ft,
    lotBackSetback: SettingsUnits.ft,
    lotSideSetback: SettingsUnits.ft,
    lotFront2Setback: SettingsUnits.ft,
  },
  parametersSettings: {
    plmFixtureWallSplitTolerance: SettingsUnits.ft,
    stretchAllowance: SettingsUnits.ft,
    minimalWallSegmentLength: SettingsUnits.ft,
    windowEdgeOffset: SettingsUnits.ft,
    doorEdgeOffset: SettingsUnits.ft,
    minDistanceForOpeningToCoreWall: SettingsUnits.ft,
    openingAlignmentMinGap: SettingsUnits.ft,

    gridCellSizeForNewCorePlans: SettingsUnits.ft,
    frameSize: SettingsUnits.ft,
    areaCalculationExteriorOffset: SettingsUnits.ft,

    roofDefaultSlope: SettingsUnits.percent,
    roofDefaultOverhang: SettingsUnits.ft,
    overhangOptions: SettingsUnits.empty,
    defaultHeelHeight: SettingsUnits.inches,
    minimumGableSeparation: SettingsUnits.ft,
    roofThickness: SettingsUnits.ft,

    roofDiagram: SettingsUnits.img,

    floorWeight: SettingsUnits.psf,
    roofWeight: SettingsUnits.psf,

    downspoutOffset: SettingsUnits.ft,
    minDistanceBetweenDownspouts: SettingsUnits.ft,
  },
  webAppUISettings: {
    displayUnits: "feet",
    selectionColor: SettingsUnits.hex,
    highlightColor: SettingsUnits.hex,
    wallsColor: SettingsUnits.hex,
    floorsColor: SettingsUnits.hex,
    openingsColor: SettingsUnits.hex,
    gridPrimaryColor: SettingsUnits.hex,
    gridSecondaryColor: SettingsUnits.hex,
    lotLinesColor: SettingsUnits.hex,
    lotSetbackColor: SettingsUnits.hex,
    obsoleteRoomsColor: SettingsUnits.hex,

    glassColor: SettingsUnits.hex,
    doorColor: SettingsUnits.hex,
    roofSurface: SettingsUnits.hex,
    roofEdge: SettingsUnits.hex,

    livingSpaces: SettingsUnits.hex,
    garage: SettingsUnits.hex,
    porchesAndPatio: SettingsUnits.hex,
    roof: SettingsUnits.hex,
  },
};

const defaultDescriptions: TSettingsDescriptions = {
  corePlanSettings: {
    numOfStories: "Default number of Stories",
    storiesMax: "Max. number of Stories",
    numOfBedrooms: "Bedroom default",
    bedroomMin: "Bedroom min.",
    bedroomMax: "Bedroom max.",
    numOfBathrooms: "Bathroom default",
    bathroomMin: "Bathroom min.",
    bathroomMax: "Bathroom max.",
    numOfHalfBathrooms: "Number of half bathrooms",
    numOfCars: "Cars default",
    carsMax: "Cars max",

    defaultWindowHeaderHeight: "Default Window header height",
    windowTopOfWallClearance: "Window-Top of Wall Clearance",
    floorToPlateLevelHeight: "Default Floor to Plate level height",
    minFloorToPlateLevelHeight: "Min. Floor to Plate level height",
    maxFloorToPlateLevelHeight: "Max. Floor to Plate level height",

    floorThickness: "Floor Thickness",

    windowWidth: "Window Width",
    windowHeight: "Window Height",

    minBuildingNetArea: "Min building net area",
    maxBuildingGrossArea: "Max building gross area",
    lotArea: "Lot area",
    lotWidth: "Lot width",
    lotLength: "Lot length",
    lotFrontSetback: "Lot front setback",
    lotBackSetback: "Lot back setback",
    lotSideSetback: "Lot side setback",
    lotFront2Setback: "Lot front 2 setback",
  },
  parametersSettings: {
    plmFixtureWallSplitTolerance: "Plumbing fixture wall split tolerance",
    stretchAllowance: "Stretch allowance",
    minimalWallSegmentLength: "Minimal wall segment length",
    windowEdgeOffset: "Window Edge Offset",
    doorEdgeOffset: "Door Edge Offset",
    minDistanceForOpeningToCoreWall: "Min. distance for opening to core wall",
    openingAlignmentMinGap: "Opening alignment min. gap",

    gridCellSizeForNewCorePlans: "Grid cell size (for new corePlans)",
    frameSize: "3D frame size",
    areaCalculationExteriorOffset: "Area calculation exterior offset",

    roofDefaultSlope: "Default slope",
    roofDefaultOverhang: "Default overhang",
    overhangOptions: "Overhang options",
    defaultHeelHeight: "Default Heel Height",
    minimumGableSeparation: "Minimum gable separation",
    roofThickness: "Roof Thickness",

    roofDiagram: "Roof Diagram",

    floorWeight: "Floor weight",
    roofWeight: "Roof weight",

    downspoutOffset: "Downspouts offset",
    minDistanceBetweenDownspouts: "Min. downspouts distance",
  },
  webAppUISettings: {
    displayUnits: "Display units",
    selectionColor: "Selection",
    highlightColor: "Highlight",
    wallsColor: "Walls",
    floorsColor: "Floors",
    openingsColor: "Window/Door",
    gridPrimaryColor: "Grid Primary",
    gridSecondaryColor: "Grid Secondary",
    lotLinesColor: "Lot lines",
    lotSetbackColor: "Lot setback",
    obsoleteRoomsColor: "Obsolete rooms",

    glassColor: "Window",
    doorColor: "Door",
    roofSurface: "Roof surface",
    roofEdge: "Roof edge",

    livingSpaces: "Living Space",
    garage: "Garage",
    porchesAndPatio: "Porches and Patio",
    roof: "Roof",
  },
};

const defaultTooltips: TSettingsDescriptions = {
  corePlanSettings: {
    numOfStories: null,
    storiesMax: null,
    numOfBedrooms: null,
    bedroomMin: null,
    bedroomMax: null,
    numOfBathrooms: null,
    bathroomMin: null,
    bathroomMax: null,
    numOfHalfBathrooms: null,
    numOfCars: null,
    carsMax: null,

    defaultWindowHeaderHeight: null,
    windowTopOfWallClearance: null,
    floorToPlateLevelHeight: null,
    minFloorToPlateLevelHeight: null,
    maxFloorToPlateLevelHeight: null,

    floorThickness: null,

    windowWidth: null,
    windowHeight: null,

    minBuildingNetArea: null,
    maxBuildingGrossArea: null,
    lotArea: null,
    lotWidth: null,
    lotLength: null,
    lotFrontSetback: null,
    lotBackSetback: null,
    lotSideSetback: null,
    lotFront2Setback: null,
  },
  parametersSettings: {
    plmFixtureWallSplitTolerance: "Distance between edge of one plumbing fixture and another to define whether the system splits the wall or not",
    stretchAllowance: null,
    minimalWallSegmentLength: null,
    windowEdgeOffset: null,
    doorEdgeOffset: null,
    minDistanceForOpeningToCoreWall: null,
    openingAlignmentMinGap: null,

    gridCellSizeForNewCorePlans: null,
    frameSize: null,
    areaCalculationExteriorOffset: null,

    roofDefaultSlope: null,
    roofDefaultOverhang: null,
    overhangOptions: "Admin can input list of values for a Studio user to choose from to apply an overhang. The values must match to available soffits widths",
    defaultHeelHeight:
      "The Heel Height is the vertical distance from the top plate of the wall to the outer surface of the roof. Only whole numbers are accepted",
    minimumGableSeparation: null,
    roofThickness: null,

    roofDiagram: null,

    floorWeight: null,
    roofWeight: null,

    downspoutOffset: "The offset represents the distance of the downspout from the end of the roof's edge corner",
    minDistanceBetweenDownspouts:
      "The minimum distance between two downspouts considering the offset, if the distance is less than this value, a single downspout will be placed in the middle",
  },
  webAppUISettings: {
    displayUnits: null,
    selectionColor: null,
    highlightColor: null,
    wallsColor: null,
    floorsColor: null,
    openingsColor: null,
    gridPrimaryColor: null,
    gridSecondaryColor: null,
    lotLinesColor: null,
    lotSetbackColor: null,
    obsoleteRoomsColor: null,

    glassColor: null,
    doorColor: null,
    roofSurface: null,
    roofEdge: null,

    livingSpaces: null,
    garage: null,
    porchesAndPatio: null,
    roof: null,
  },
};

export { defaultSettings, defaultDescriptions, defaultUnits, defaultTooltips };

import React, { useState } from "react";
import { Box, Popper, Typography, IconButton, Paper } from "@mui/material";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import "./InfoPropper.sass";
import { Variation } from "../../../models/Variation";
import { settings } from "../../../entities/settings";

interface InfoPopperProps {
  variation: Variation;
  isOpen: boolean;
  onToggle: () => void;
}

interface InfoSectionProps {
  title: string;
  value: string | number;
}

const InfoSection: React.FC<InfoSectionProps> = ({ title, value }) => (
  <Box className="info-section">
    <Typography variant="body2" className="roof-shapes">
      <b>{title}</b>
      <br />
    </Typography>
    <Typography variant="body2" className="roof-details">
      {value}
    </Typography>
  </Box>
);

const InfoPopper: React.FC<InfoPopperProps> = ({ variation, isOpen, onToggle }) => {
  const [anchorEl, setAnchorEl] = useState<null | HTMLElement>(null);

  const handleClick = (event: React.MouseEvent<HTMLElement>) => {
    setAnchorEl(event.currentTarget);
    onToggle();
  };

  const roofShapes = determineRoofShapes(variation.data.roomsData);
  const roofOverhang = determineRoofOverhangs(variation.data.roomsData);
  const roofSlopePercentage = (settings.values.parametersSettings.roofDefaultSlope * 100).toString() + "%";

  const infoSections = [
    { title: "Roof Shapes", value: roofShapes },
    { title: "Slope", value: roofSlopePercentage },
    { title: "Overhang", value: `${roofOverhang}` },
    { title: "Heel Height", value: `${variation.data.heelHeight || settings.values.parametersSettings.defaultHeelHeight}"` },
  ];

  return (
    <Box className="info-popper">
      <IconButton className="info-button" onClick={handleClick} size="small">
        <InfoOutlinedIcon className="info-icon" />
      </IconButton>

      <Popper open={isOpen} anchorEl={anchorEl} placement="right-start">
        <Paper className="popper-content">
          {infoSections.map((section, index) => (
            <InfoSection key={index} title={section.title} value={section.value} />
          ))}
        </Paper>
      </Popper>
    </Box>
  );
};

const determineRoofShapes = (roomsData: any[]): string => {
  const roofShapes = new Set<string>();

  for (const room of roomsData) {
    if (Array.isArray(room.roofSlopes) && Array.isArray(room.dutchGableDepths)) {
      for (let i = 0; i < room.roofSlopes.length; i++) {
        if (room.roofSlopes[i] !== undefined && room.dutchGableDepths[i] !== undefined) {
          if (room.roofSlopes[i] === 0) {
            if (room.dutchGableDepths[i] === 0) {
              roofShapes.add("Gable");
            } else if (room.dutchGableDepths[i] === 12) {
              roofShapes.add("Dutch Gable");
            }
          } else if (room.roofSlopes[i] === -1) {
            roofShapes.add("Hip");
          }
        }
      }
    }
  }
  return Array.from(roofShapes).join(", ") || "Unknown";
};

const determineRoofOverhangs = (roomsData: any[]): string => {
  const overhangs = new Set<string>();
  const roofDefaultOverhang = settings.values.parametersSettings.roofDefaultOverhang.toString();

  for (const room of roomsData) {
    if (Array.isArray(room.roofOverhangs)) {
      for (let i = 0; i < room.roofOverhangs.length; i++) {
        if (room.roofOverhangs[i] > 0) {
          overhangs.add(room.roofOverhangs[i].toString());
        }
      }
    }
  }
  return (Array.from(overhangs).join('", ') || roofDefaultOverhang) + '"';
};

export default InfoPopper;

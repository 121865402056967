import * as THREE from "three";
import Utils from "./utils";
import RoofSurfaceOutput from "./roofSurfaceOutput";

export enum EdgeType {
  UNKNOWN = 0,
  HIP = 1,
  VALLEY = 2,
  RAKE = 3,
  RIDGE = 4,
  EAVE = 5,
  RAKE_TO_WALL = 6,
  EAVE_TO_WALL = 7,
}

export default class EdgeOutput {
  public startPoint: THREE.Vector3;
  public endPoint: THREE.Vector3;
  public edgeType: EdgeType;
  public inputEdgeIds: string[] = [];
  public effectiveOverhang: number = 0;

  constructor(startPoint: THREE.Vector3, endPoint: THREE.Vector3) {
    this.startPoint = startPoint;
    this.endPoint = endPoint;
    this.edgeType = EdgeType.UNKNOWN;
  }

  public flip() {
    const temp = this.startPoint;
    this.startPoint = this.endPoint;
    this.endPoint = temp;
  }

  public getDirection(): THREE.Vector3 {
    const dir = new THREE.Vector3();
    dir.subVectors(this.endPoint, this.startPoint);
    dir.normalize();
    return dir;
  }

  public getProjectedDirection(normal: THREE.Vector3, edgeTestPoint: THREE.Vector3, verts: THREE.Vector3[]): THREE.Vector3 {
    const edgeDir = this.getDirection();
    let moveDir = edgeDir;
    if (normal) moveDir = (THREE.Vector3 as any).Cross(edgeDir, normal);
    moveDir.z = 0;
    moveDir.normalize();

    const midPoint = edgeTestPoint ? edgeTestPoint.clone() : this.getMidPoint();
    midPoint.z = 0;
    midPoint.add(moveDir);
    if (Utils.isPointInPoly(verts, midPoint)) moveDir.multiplyScalar(-1);
    return moveDir;
  }

  public getNormal(): THREE.Vector3 {
    const norm = this.getDirection();
    const temp = norm.x;
    norm.x = -norm.y;
    norm.y = temp;
    return norm;
  }

  public getXYPlaneNormal(verts: THREE.Vector3[]): THREE.Vector3 {
    const norm = this.getNormal();
    norm.z = 0;
    norm.normalize();
    const testPoint = (this.getMidPoint() as any).addNew(norm);
    if (Utils.isPointInPoly(verts, testPoint)) norm.multiplyScalar(-1);
    return norm;
  }

  public isEqual(other: EdgeOutput): boolean {
    if (!other) return false;
    const first =
      Utils.ValuesAreCloseEnough(other.startPoint.x, this.startPoint.x) &&
      Utils.ValuesAreCloseEnough(other.startPoint.y, this.startPoint.y) &&
      Utils.ValuesAreCloseEnough(other.startPoint.z, this.startPoint.z) &&
      Utils.ValuesAreCloseEnough(other.endPoint.x, this.endPoint.x) &&
      Utils.ValuesAreCloseEnough(other.endPoint.y, this.endPoint.y) &&
      Utils.ValuesAreCloseEnough(other.endPoint.z, this.endPoint.z);
    const second =
      Utils.ValuesAreCloseEnough(other.startPoint.x, this.endPoint.x) &&
      Utils.ValuesAreCloseEnough(other.startPoint.y, this.endPoint.y) &&
      Utils.ValuesAreCloseEnough(other.startPoint.z, this.endPoint.z) &&
      Utils.ValuesAreCloseEnough(other.endPoint.x, this.startPoint.x) &&
      Utils.ValuesAreCloseEnough(other.endPoint.y, this.startPoint.y) &&
      Utils.ValuesAreCloseEnough(other.endPoint.z, this.startPoint.z);
    return first || second;
  }

  /// <summary>
  /// gets the other side of the edge
  /// </summary>
  public otherPoint(point3D: THREE.Vector3): THREE.Vector3 {
    if (Utils.pointsAreCloseEnough(point3D, this.startPoint)) return this.endPoint;
    return this.startPoint;
  }

  public belongToMoreThanOneSurface(roofSurfaces: RoofSurfaceOutput[], skipZeroSlope: boolean) {
    let sum = 0;
    for (const roofSurface of roofSurfaces) {
      if (skipZeroSlope && roofSurface.slope === 0) continue;

      for (const edge of roofSurface.edges) {
        if (this.isEqual(edge)) sum++;
      }
    }

    if (sum < 2) return false;
    return true;
  }

  /// <summary>
  /// Return mid point of this edge
  /// </summary>
  public getMidPoint(): THREE.Vector3 {
    const midX = (this.endPoint.x - this.startPoint.x) / 2 + this.startPoint.x;
    const midY = (this.endPoint.y - this.startPoint.y) / 2 + this.startPoint.y;
    return new THREE.Vector3(midX, midY, this.startPoint.z);
  }

  public isCollinear(otherEdge: EdgeOutput, bProjectTo2D: boolean): boolean {
    const selfDir = this.getDirection();
    const otherDir = otherEdge.getDirection();
    if (bProjectTo2D) {
      selfDir.z = 0;
      otherDir.z = 0;
      selfDir.normalize();
      otherDir.normalize();
    }
    return Math.abs(selfDir.dot(otherDir)) >= 0.999;
  }

  public mergeWith(edge2) {
    this.endPoint = edge2.endPoint.clone();
  }
}

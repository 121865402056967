import { action, computed, makeObservable, observable } from "mobx";
import { v4 as uuid } from "uuid";

export interface IRoofEdge {
  startNode: { x: number; y: number };
  endNode: { x: number; y: number };
  slope: number;
  overhang: number;
  gableDepth: number;
  hasGutter: boolean;
}

export interface IRoof {
  id: string;
  name: string;
  roofEdges: IRoofEdge[];
  addEdge(edge: IRoofEdge): void;
  removeEdge(index: number): void;
  perimeter: number;
}

export class RoofEdge implements IRoofEdge {
  startNode: { x: number; y: number };
  endNode: { x: number; y: number };
  slope: number;
  overhang: number;
  gableDepth: number;
  hasGutter: boolean;
  constructor(
    startNode: { x: number; y: number },
    endNode: { x: number; y: number },
    slope: number,
    gableDepth: number,
    hasGutter: boolean = false,
    overhang: number = 0
  ) {
    this.startNode = startNode;
    this.endNode = endNode;
    this.slope = slope;
    this.gableDepth = gableDepth;
    this.hasGutter = hasGutter;
    this.overhang = overhang;
  }
}

export class Roof implements IRoof {
  id: string = uuid();
  name: string = "";
  roofEdges: RoofEdge[] = [];

  constructor(name: string = "") {
    this.name = name;
    makeObservable(this, {
      name: observable,
      roofEdges: observable,
      addEdge: action,
      removeEdge: action,
      perimeter: computed,
    });
  }

  get perimeter(): number {
    return this.roofEdges.reduce(
      (acc, edge) => acc + Math.sqrt(Math.pow(edge.endNode.x - edge.startNode.x, 2) + Math.pow(edge.endNode.y - edge.startNode.y, 2)),
      0
    );
  }

  addEdge(edge: IRoofEdge): void {
    this.roofEdges.push(edge);
  }

  /**
   * Resets the overhang property for all roof edges to 0.
   */
  resetRoofOverhangs(): void {
    this.roofEdges.forEach(edge => (edge.overhang = 0));
  }

  /**
   * Updates the 'hasGutter' property for a roof edge that matches the provided start and end nodes.
   *
   * @param startNode - The starting node of the edge to be updated.
   * @param endNode - The ending node of the edge to be updated.
   * @param hasGutter - The boolean value indicating whether the edge should have a gutter.
   */
  public updateEdgeHasGutter(startNode, endNode, hasGutter: boolean): void {
    const index = this.roofEdges.findIndex(
      roofEdge =>
        roofEdge.startNode.x === startNode.x && roofEdge.startNode.y === startNode.y && roofEdge.endNode.x === endNode.x && roofEdge.endNode.y === endNode.y
    );

    if (index !== -1) {
      this.roofEdges[index].hasGutter = hasGutter;
    }
    return;
  }

  removeEdge(index: number): void {
    if (index >= 0 && index < this.roofEdges.length) {
      this.roofEdges.splice(index, 1);
    }
  }
}

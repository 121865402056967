import Utils from "./utils";
import Edge from "./edge";
import NodeGraph from "./nodeGraph";
import Line3dEquation from "./line3dEquation";

export default class Polygon {
  public nodes: NodeGraph[];
  public lines: Line3dEquation[] = [];

  constructor(nodes: NodeGraph[]) {
    this.nodes = nodes;
  }

  public isEqual(other: Polygon) {
    if (!other) return false;

    if (this.nodes.length != other.nodes.length) {
      return false;
    }

    for (const node of this.nodes) {
      if (!Utils.contains(other.nodes, node)) return false;
    }

    return true;
  }

  /*

    public bool IsMatchWith(Polygon polygon)
    {
        Line3dEquation? commonSameLine = GetCommonSameLine(polygon);
        
        if(commonSameLine is null) { return true; }

        List<NodeGraph> nodes1 = GetNodesInPolygonOnLine(this, commonSameLine);
        List<NodeGraph> nodes2 = GetNodesInPolygonOnLine(polygon, commonSameLine);
        if(nodes1.Count == nodes2.Count) { return true; }

        return false;
    }

    /// <summary>
    /// return SameLine if the edges are neigbors, return null otherwise
    /// </summary>
    public Line3dEquation? GetCommonSameLine(Polygon polygon)
    {
        if(Nodes[0].Equals(polygon.Nodes[0]))
        {
            return GetSameLineWithPoint(Nodes[0].Point3D);
        }
        if(Nodes[^1].Equals(polygon.Nodes[^1]))
        {
            return GetSameLineWithPoint(Nodes[^1].Point3D);
        }
        if(Nodes[0].Equals(polygon.Nodes[^1]))
        {
            return GetSameLineWithPoint(Nodes[0].Point3D);
        }
        if(Nodes[^1].Equals(polygon.Nodes[0]))
        {
            return GetSameLineWithPoint(Nodes[^1].Point3D);
        }
        return null;
    }

    public Line3dEquation? GetSameLineWithPoint(Point3D point)
    {
        foreach (Line3dEquation line in Lines)
        {
            if(line.type != LineCreationType.SAME_POINT) continue;
            if(Util.ValuesAreCloseEnough(point.X, line.X0) &&
                Util.ValuesAreCloseEnough(point.Y, line.Y0) &&
                Util.ValuesAreCloseEnough(point.Z, line.Z0))
            {
                return line;
            }
        }
        return null;
    }

    public static List<NodeGraph> GetNodesInPolygonOnLine(Polygon polygon, Line3dEquation line)
    {
        List<NodeGraph> nodes = [];
        foreach (NodeGraph node in polygon.Nodes)
        {
            if(node.Point3D.IsPointInsideLine(line)) nodes.Add(node);
        }
        return nodes;
    }

    /// <summary>
    /// return lines that contain input nodes
    /// </summary>
    public List<Line3dEquation> GetLines(List<NodeGraph> nodes)
    {
        List<Line3dEquation> lines = [];
        if(nodes.Count == 0) return lines;
        foreach (NodeGraph node in nodes)
        {
            foreach (Line3dEquation line in Lines)
            {
                if(!lines.Contains(line) && node.Point3D.IsPointInsideLine(line)) lines.Add(line);
            }
        }
        return lines;
    }
    */
  /// <summary>
  /// Orders the edges of a polygon such that each edge's end node matches the start node of the next edge.
  /// </summary>
  /// <param name="edges">The list of edges to be ordered.</param>
  /// <returns>A list of edges ordered to form a continuous polygon.</returns>
  public static orderPolygonEdges(edges: Edge[]): Edge[] {
    const reOrder: Edge[] = [edges[0]];
    for (let i = 1; i < edges.length; i++) {
      for (const edge of edges) {
        if (Utils.contains(reOrder, edge)) continue;

        if (reOrder[reOrder.length - 1].endNode.isEqual(edge.startNode)) {
          reOrder.push(edge);
        } else if (reOrder[reOrder.length - 1].endNode.isEqual(edge.endNode)) {
          const flippedEdge = new Edge(edge.endNode, edge.startNode);
          reOrder.push(flippedEdge);
        }
      }
    }
    return reOrder;
  }
}

import * as THREE from "three";
import Utils from "./utils";
import type EdgeGraph from "./edgeGraph";
import type Polygon from "./polygon";

export default class NodeGraph {
  public point3D: THREE.Vector3;
  public edges: EdgeGraph[] = [];

  constructor(point3D: THREE.Vector3) {
    this.point3D = point3D;
  }

  public isEqual(other: NodeGraph): boolean {
    if (!other) return false;
    return (
      Utils.ValuesAreCloseEnough(other.point3D.x, this.point3D.x) &&
      Utils.ValuesAreCloseEnough(other.point3D.y, this.point3D.y) &&
      Utils.ValuesAreCloseEnough(other.point3D.z, this.point3D.z)
    );
  }

  /// <summary>
  /// middle node is a node in-between two nodes on the same line
  /// </summary>
  public isMiddleNode(polygon: Polygon): boolean {
    const index = Utils.indexOf(polygon.nodes, this);
    if (index == 0 || index == polygon.nodes.length - 1) return false;

    for (const line of polygon.lines) {
      if (
        Utils.isPointInsideLine(polygon.nodes[index - 1].point3D, line) &&
        Utils.isPointInsideLine(polygon.nodes[index].point3D, line) &&
        Utils.isPointInsideLine(polygon.nodes[index + 1].point3D, line)
      ) {
        return true;
      }
    }
    return false;
  }
}

import { useEffect, useState } from "react";
import { observer } from "mobx-react-lite";
import { Col, Form, Row } from "antd";
import log from "loglevel";

import { CorePlanFormData } from "../../../models/CorePlan";
import { createArray, inchesToFeetInchesFraction } from "../../../helpers/measures";
import { settings } from "../../../entities/settings";
import { LennarCorePlan } from "../../../models/LennarCorePlanData";
import { entityManager } from "../../../entities/entityManager";
import UnitsUtils from "../../../editor/utils/UnitsUtils";
import FormButtons from "./CorePlanDetailesEditor/DetailesEditorFormButtons";
import AttributeGroup from "./CorePlanDetailesEditor/DetailesEditorAttributesGroup";

import "./CorePlans.sass";
import LotDefinition from "./CorePlanDetailesEditor/DetailesEditorLotAttributes";
import FloorToPlateDefinition from "./CorePlanDetailesEditor/DetailesEditorFloorToPlateAttributes";
import BuildingDefinition from "./CorePlanDetailesEditor/DetailesEditorBuildingAttributes";
import LennarAutoComplete from "./CorePlanDetailesEditor/DetailesEditorNaming";
import SupportingDocuments from "./CorePlanDetailesEditor/DetailesEditorSupportingDocuments";

export const minCoveragePercent = 30;

type CorePlanDetailsProps = {
  handleFormData: any;
  corePlanData: CorePlanFormData;
  isEditMode: boolean;
  isSaving: boolean;
  onCorePlanSave: () => void;
  onCorePlanSaveAndGoToEditor: () => void;
};

export function getFloorCoverages(floorNumber: number): number[] {
  const defaultCoverages = [100, 80, 60, 40];
  const coverages = defaultCoverages.slice(0, floorNumber);
  if (floorNumber > defaultCoverages.length) {
    coverages.push(...Array(floorNumber - defaultCoverages.length).fill(40));
  }

  return coverages;
}

type FormData = {
  lotSize: number;
  lotLength: number;
  lotWidth: number;
  minNetArea: number;
  floorThickness: number;
  maxGrossArea: number;
  floors: number;
  floorCoverages: number[];
  bedRooms: number;
  bathRooms: number;
  halfBathRooms: number;
  garage: number;
};

const getFormData = (corePlanData: CorePlanFormData): FormData => ({
  lotSize: corePlanData.attributes?.lotSize,
  lotLength: corePlanData.attributes?.lotLength,
  lotWidth: corePlanData.attributes?.lotWidth,
  minNetArea: corePlanData.attributes?.minNetArea,
  floorThickness: corePlanData.attributes?.floorThickness,
  maxGrossArea: corePlanData.attributes?.maxGrossArea,
  bedRooms: corePlanData.attributes?.bedRooms,
  bathRooms: corePlanData.attributes?.bathRooms,
  halfBathRooms: corePlanData.attributes?.halfBathRooms,
  garage: corePlanData.attributes?.garage,
  floors: corePlanData.attributes?.floors,
  floorCoverages: corePlanData.attributes?.floorCoverages || getFloorCoverages(corePlanData.attributes?.floors || 3),
});

let CorePlanDetailsEditor = ({ onCorePlanSave, onCorePlanSaveAndGoToEditor, handleFormData, corePlanData, isEditMode, isSaving }: CorePlanDetailsProps) => {
  const [form] = Form.useForm();
  const sizePrecision = UnitsUtils.getRoundPrecision();
  const [formData, setFormData] = useState<FormData>(() => getFormData(corePlanData));
  const bedroomNumberRange = createArray(settings.values.corePlanSettings.bedroomMin, settings.values.corePlanSettings.bedroomMax);
  const BathroomNumberRange = createArray(settings.values.corePlanSettings.bathroomMin, settings.values.corePlanSettings.bathroomMax);
  const carsNumberRange = ["N/A", ...createArray(1, settings.values.corePlanSettings.carsMax)];
  const [lennarCorePlans, setLennarCorePlans] = useState<LennarCorePlan[]>([]);
  const [lennarIdOptions, setLennarIdOptions] = useState([]);
  const [lennarNameOptions, setLennarNameOptions] = useState([]);
  const [goToEditor, setGoToEditor] = useState(false);

  useEffect(() => {
    const copyCorePlanData = {
      ...corePlanData,
      firstFloorToPlateHeight: inchesToFeetInchesFraction(corePlanData.firstFloorToPlateHeight),
      upperFloorToPlateHeight: inchesToFeetInchesFraction(corePlanData.upperFloorToPlateHeight),
    };
    form.setFieldsValue(copyCorePlanData);
    setFormData(getFormData(corePlanData));
  }, [corePlanData]);

  useEffect(() => {
    async function fetchLennarCorePlans() {
      try {
        const plans = await entityManager.pullList(LennarCorePlan);
        setLennarCorePlans(plans);
        setLennarIdOptions(plans.map(plan => ({ value: plan.lennar_id.toString() })));
        setLennarNameOptions(plans.map(plan => ({ value: plan.name })));
      } catch (error) {
        console.error("Failed to fetch Lennar Core Plans", error);
      }
    }

    fetchLennarCorePlans();
  }, []);

  const onFinish = (data: any) => {
    if (goToEditor) {
      onCorePlanSaveAndGoToEditor();
    } else {
      onCorePlanSave();
    }
  };
  const onFinishFailed = (errorInfo: any) => {
    log.error("Failed:", errorInfo);
  };

  const handleGoToEditor = () => {
    setGoToEditor(true);
    form.submit();
  };

  const handleSave = () => {
    setGoToEditor(false);
    form.submit();
  };

  const setCorePlanData = (name: string, value: any) => {
    if (corePlanData.attributes.hasOwnProperty(name)) {
      corePlanData.attributes[name] = value;
    } else {
      corePlanData[name] = value;
    }
    setFormData({ ...formData, [name]: value });
  };

  return (
    <Form
      form={form}
      name="corePlan"
      autoComplete="off"
      layout="vertical"
      className="form-corePlan"
      labelCol={{ span: 24 }}
      wrapperCol={{ span: 24 }}
      initialValues={{ remember: false }}
      onFinish={onFinish}
      onFinishFailed={onFinishFailed}
    >
      <Row gutter={78}>
        <Col span={12}>
          <LennarAutoComplete
            lennarIdOptions={lennarIdOptions}
            lennarNameOptions={lennarNameOptions}
            corePlanData={corePlanData}
            handleFormData={handleFormData}
            lennarCorePlans={lennarCorePlans}
            form={form}
            setFormData={setFormData}
            isEditMode={isEditMode}
            setCorePlanData={setCorePlanData}
          />

          <SupportingDocuments corePlanData={corePlanData} handleFormData={handleFormData} />
        </Col>
        <Col className="lot-line-inputs" span={12}>
          <LotDefinition
            corePlanData={corePlanData}
            formData={formData}
            setFormData={setFormData}
            sizePrecision={sizePrecision}
            setCorePlanData={setCorePlanData}
          />

          <BuildingDefinition corePlanData={corePlanData} formData={formData} setFormData={setFormData} setCorePlanData={setCorePlanData} />

          <AttributeGroup
            corePlanData={corePlanData}
            formData={formData}
            handleFormData={setFormData}
            bedroomNumberRange={bedroomNumberRange}
            BathroomNumberRange={BathroomNumberRange}
            carsNumberRange={carsNumberRange}
            setCorePlanData={setCorePlanData}
          />

          <FloorToPlateDefinition formData={formData} handleFormData={handleFormData} />
        </Col>
      </Row>
      <div className="btn-group right">
        <FormButtons isSaving={isSaving} isEditMode={isEditMode} handleGoToEditor={handleGoToEditor} handleSave={handleSave} />
      </div>
    </Form>
  );
};

CorePlanDetailsEditor = observer(CorePlanDetailsEditor);

export default CorePlanDetailsEditor;

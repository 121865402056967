import * as THREE from "three";
import SceneUtils from "../../utils/SceneUtils";
import GeometryUtils from "../../utils/GeometryUtils/GeometryUtils";
import { settings } from "../../../entities/settings";
import { OPENING_CLIPPER_RENDER_ORDER, OPENING_DEPTH, OPENING_FRAME_DEPTH, OPENING_OFFSET_FROM_ROOM_FACE, ROOM_3D_SWEEP_COLOR } from "../../consts";
import { RoomEntityType } from "../../../models/RoomEntityType";
import { WebAppUISettingsKeys } from "../../../entities/settings/types";

export class SoPreviewOpening extends THREE.Group {
  private zone: THREE.Line3;
  private width: number;
  private height: number;
  private sillHeight: number;
  private frameSize: number;

  public static create(soOpening: THREE.Object3D, roomSize: THREE.Vector3): SoPreviewOpening | null {
    if (!soOpening.userData?.roughWidth || !soOpening.userData?.roughHeight) {
      return null;
    }
    const openingData = SceneUtils.getOpeningZoneAndLine(soOpening);
    if (!openingData) {
      return null;
    }

    return new SoPreviewOpening(soOpening, roomSize);
  }

  private constructor(soOpening: THREE.Object3D, roomSize: THREE.Vector3) {
    super();

    this.zone = SceneUtils.getOpeningZoneAndLine(soOpening).zone;

    this.width = soOpening.userData.roughWidth;
    this.height = soOpening.userData.roughHeight;
    this.sillHeight = soOpening.userData.sillHeight;
    this.frameSize = settings.values.parametersSettings.frameSize;

    this.position.x = soOpening.position.x;
    this.position.y = soOpening.position.y;
    this.position.x *= soOpening.parent.scale.x;
    this.position.applyQuaternion(soOpening.parent.quaternion);

    this.position.z = -(roomSize.z / 2) + this.height / 2 + this.sillHeight;

    const axis = GeometryUtils.isLineHorizontal(this.zone) ? "x" : "y";
    if (axis === "y") {
      const temp = this.width;
      this.width = this.height;
      this.height = temp;
    }

    const frame = this.createFrame(OPENING_FRAME_DEPTH);
    const opening = this.createOpening(
      soOpening.userData.type === RoomEntityType.Window
        ? settings.getColorNumber(WebAppUISettingsKeys.glassColor)
        : settings.getColorNumber(WebAppUISettingsKeys.doorColor),
      OPENING_DEPTH
    );
    const clipper = this.createClipper();

    // Offset opening from wall face
    opening.position.z = (OPENING_DEPTH / 2 + OPENING_OFFSET_FROM_ROOM_FACE) * -0.1;
    frame.position.z = (OPENING_FRAME_DEPTH / 2) * -1;

    if (frame) {
      this.add(frame);
    }
    this.add(clipper);
    this.add(opening);

    const normal = this.zone.end.clone().sub(this.zone.start).normalize();
    this.rotateOnAxis(normal, THREE.MathUtils.degToRad(-90));
  }

  private createFrame(depth: number): THREE.Mesh | null {
    if (this.width < this.frameSize * 2 || this.height < this.frameSize * 2) {
      return null;
    }
    const w = this.width / 2;
    const h = this.height / 2;

    const shape = new THREE.Shape();
    shape.moveTo(-w, -h);
    shape.lineTo(w, -h);
    shape.lineTo(w, h);
    shape.lineTo(-w, h);
    shape.lineTo(-w, -h);

    const path = new THREE.Path();
    path.moveTo(-w + this.frameSize, -h + this.frameSize);
    path.lineTo(w - this.frameSize, -h + this.frameSize);
    path.lineTo(w - this.frameSize, h - this.frameSize);
    path.lineTo(-w + this.frameSize, h - this.frameSize);
    path.lineTo(-w + this.frameSize, -h + this.frameSize);
    shape.holes.push(path);

    const mesh = new THREE.Mesh(
      new THREE.ExtrudeBufferGeometry(shape, { depth, bevelEnabled: false }),
      new THREE.MeshStandardMaterial({
        color: ROOM_3D_SWEEP_COLOR,
        metalness: 0.092,
        roughness: 0,
        polygonOffset: true,
        polygonOffsetFactor: 0,
        polygonOffsetUnits: -3,
      })
    );
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  }
  private createOpening(color: number, depth: number): THREE.Mesh {
    const mesh = new THREE.Mesh(
      new THREE.BoxBufferGeometry(this.width, this.height, depth),
      new THREE.MeshStandardMaterial({
        color: color,
        metalness: 0.092,
        roughness: 0,
        polygonOffset: true,
        polygonOffsetFactor: 1,
        polygonOffsetUnits: 1,
      })
    );
    mesh.castShadow = true;
    mesh.receiveShadow = true;
    return mesh;
  }
  private createClipper(): THREE.Mesh {
    const mesh = new THREE.Mesh(
      new THREE.PlaneBufferGeometry(this.width, this.height),
      new THREE.MeshPhongMaterial({
        colorWrite: false,
        polygonOffset: true,
        polygonOffsetFactor: -2,
        polygonOffsetUnits: -2,
      })
    );
    mesh.renderOrder = OPENING_CLIPPER_RENDER_ORDER;
    return mesh;
  }
}

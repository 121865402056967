import { AutoComplete, Form, Input } from "antd";
import { runInAction } from "mobx";
import { US_STATES } from "../../../../editor/consts";
import { CorePlanFormData } from "../../../../models/CorePlan";
import { LennarCorePlan } from "../../../../models/LennarCorePlanData";
import { FormInstance } from "antd/es/form";
import { appModel } from "../../../../models/AppModel";
import { useState } from "react";

interface LennarAutoCompleteProps {
  lennarIdOptions;
  lennarNameOptions;
  corePlanData: any;
  handleFormData: any;
  lennarCorePlans: LennarCorePlan[];
  form: FormInstance;
  setFormData: any;
  isEditMode: boolean;
  setCorePlanData: any;
}

const LennarAutoComplete: React.FC<LennarAutoCompleteProps> = ({
  lennarIdOptions,
  lennarNameOptions,
  corePlanData,
  handleFormData,
  lennarCorePlans,
  form,
  setFormData,
  isEditMode,
  setCorePlanData,
}) => {
  const [FilteredLennarIdOptions, setLennarIdOptions] = useState([]);
  const [FilteredLennarNameOptions, setLennarNameOptions] = useState([]);

  const handleLennarAutoFill = (value: string, key: string) => {
    const selectedPlan = key === "lennar_id" ? lennarCorePlans.find(plan => plan.lennar_id === value) : lennarCorePlans.find(plan => plan.name === value);
    if (selectedPlan) {
      runInAction(() => {
        const formValues = {};
        const corePlanData = {};

        // Function to set form values and core plan data only if the value is not null or empty
        const setIfNotEmpty = (field, fieldValue, isCorePlanData = false) => {
          if (fieldValue != null && fieldValue != "" && fieldValue != undefined) {
            corePlanData[field] = fieldValue;
            formValues[field] = fieldValue;
          }
        };

        // Form values
        setIfNotEmpty("lennar_id", selectedPlan.lennar_id);
        setIfNotEmpty("name", selectedPlan.name);
        setIfNotEmpty("series", selectedPlan.series);
        setIfNotEmpty("state", selectedPlan.state);
        setIfNotEmpty("lotWidth", selectedPlan.width);
        setIfNotEmpty("lotLength", selectedPlan.length);
        setIfNotEmpty("minNetArea", selectedPlan.net_area);
        setIfNotEmpty("maxGrossArea", selectedPlan.gross_area);
        setIfNotEmpty("floorHeight", selectedPlan.ceiling);
        setIfNotEmpty("floors", selectedPlan.story);
        setIfNotEmpty("bedRooms", selectedPlan.beds);
        setIfNotEmpty("bathRooms", selectedPlan.bathrooms);
        setIfNotEmpty("halfBathRooms", selectedPlan.half_bathrooms);
        setIfNotEmpty("garage", selectedPlan.garage);

        //form.setFieldsValue(formValues);

        Object.entries(corePlanData).forEach(([field, fieldValue]) => {
          setCorePlanData(field, fieldValue, true);
        });
      });
    }
  };

  const handleLennarSelect = (value: string, key: string) => {
    handleLennarAutoFill(value, key);
    handleFormData(key)({ target: { value } });
  };

  const handleLennarBlur = (event: any, key: string) => {
    const value = event.target.value;
    handleLennarAutoFill(value, key);
    handleFormData(key)({ target: { value } });
  };

  const validateID = async (_: any, value: string) => {
    if (value && value.length > 6) {
      return Promise.reject(new Error("Maximum 6 characters only"));
    }

    const idExists = appModel.corePlans.find(p => p.lennar_id === value);
    if (idExists && (!isEditMode || idExists?.id !== corePlanData.id)) {
      return Promise.reject(new Error("ID already exists in library"));
    }
    return Promise.resolve();
  };

  const validateName = async (_: any, value: string) => {
    if (value && value.length > 15) {
      return Promise.reject(new Error("Maximum 15 characters only"));
    }

    const nameExists = appModel.corePlans.find(p => p.name === value);
    if (nameExists && (!isEditMode || nameExists?.id !== corePlanData.id)) {
      return Promise.reject(new Error("Name already exists in library"));
    }
    return Promise.resolve();
  };

  const validateSeries = async (_: any, value: string) => {
    if (value && value.length > 20) {
      return Promise.reject(new Error("Maximum 20 characters only"));
    }
    return Promise.resolve();
  };

  const validateState = (_: any, value: string) => {
    const stateNames = US_STATES.map(([name]) => name);
    if (!value || !stateNames.includes(value)) {
      return Promise.reject(new Error("US State does not exist"));
    }
    return Promise.resolve();
  };
  const filterValues = (inputValue, option) => {
    return (
      option.value.toString().toLowerCase().includes(inputValue.toLowerCase()) || option.label?.toString().toLowerCase().includes(inputValue.toLowerCase())
    );
  };
  const getValues = (searchText: string, list: any) => (!searchText ? [] : list.filter(option => filterValues(searchText, option)));
  return (
    <>
      <Form.Item name="lennar_id" rules={[{ required: true, message: "Please enter the ID" }, { validator: validateID }]}>
        <AutoComplete
          options={FilteredLennarIdOptions}
          onSelect={value => handleLennarSelect(value, "lennar_id")}
          onBlur={event => handleLennarBlur(event, "lennar_id")}
          onSearch={text => setLennarIdOptions(getValues(text, lennarIdOptions))}
          style={{ width: "100%" }}
        >
          <Input placeholder="* ID" />
        </AutoComplete>
      </Form.Item>

      <Form.Item name="name" rules={[{ validator: validateName }]}>
        <AutoComplete
          options={FilteredLennarNameOptions}
          onSelect={value => handleLennarSelect(value, "name")}
          onBlur={event => handleLennarBlur(event, "name")}
          onSearch={text => setLennarNameOptions(getValues(text, lennarNameOptions))}
          style={{ width: "100%" }}
        >
          <Input placeholder="* Name" />
        </AutoComplete>
      </Form.Item>

      <Form.Item name="series" rules={[{ required: true, message: "Please enter the Series" }, { validator: validateSeries }]}>
        <Input placeholder="* Series" value={corePlanData.series} onChange={handleFormData("series")} />
      </Form.Item>

      <Form.Item name="state" rules={[{ validator: validateState }]}>
        <AutoComplete
          options={US_STATES.map(([name, abbreviation]) => ({ value: name, label: `${name} (${abbreviation})` }))}
          value={corePlanData.state}
          onChange={value => {
            handleFormData("state")({ target: { value } });
            setFormData(prev => ({ ...prev, state: value }));
          }}
          filterOption={(inputValue, option) =>
            option.value.toLowerCase().includes(inputValue.toLowerCase()) || option.label.toLowerCase().includes(inputValue.toLowerCase())
          }
          style={{ width: "100%" }}
        >
          <Input placeholder="* State" />
        </AutoComplete>
      </Form.Item>
    </>
  );
};

export default LennarAutoComplete;

import * as THREE from "three";
import { EPSILON } from "../../consts";
import MathUtils from "../../utils/MathUtils";
import { Room } from "../../../../src/models/Room";
import { FuncCode, WallType } from "../../../entities/catalogSettings/types";
import wallFunctionRules from "../../../entities/wallFunctionCode/WallFunctionRules";
import SegmentsUtils from "../../utils/SegmentsUtils";

export class Segment {
  start: THREE.Vector2;
  end: THREE.Vector2;
  hasWall: boolean;
  roomId: string[];
  isExternal: boolean = true;
  extras: { [key: string]: any } = {};
  classification: WallType[] = [];
  private _functionCode: string = null;

  constructor(
    start: THREE.Vector2,
    end: THREE.Vector2,
    roomId: string | string[] = [],
    hasWall: boolean = false,
    extras: { [key: string]: any } = {},
    classification: WallType[] = [],
    functionCode?: FuncCode
  ) {
    this.start = start;
    this.end = end;
    this.roomId = Array.isArray(roomId) ? roomId : [roomId];
    this.hasWall = hasWall;
    this.extras = Object.assign({}, extras);
    this.classification = [...classification];
    this._functionCode = functionCode;
  }
  public get FunctionCode(): string {
    return this._functionCode;
  }
  public set FunctionCode(value: string) {
    this._functionCode = value;
  }
  public delta(): THREE.Vector2 {
    return this.end.clone().sub(this.start);
  }

  public evaluate(t: number): THREE.Vector2 {
    return this.delta().multiplyScalar(t).add(this.start);
  }

  public getCenter3(): THREE.Vector3 {
    return new THREE.Vector3(this.start.x + this.end.x, this.start.y + this.end.y).multiplyScalar(0.5);
  }

  public toLine3(): THREE.Line3 {
    return new THREE.Line3(new THREE.Vector3(this.start.x, this.start.y, 0), new THREE.Vector3(this.end.x, this.end.y, 0));
  }

  get isHorizontal(): boolean {
    return MathUtils.areNumbersEqual(this.start.y, this.end.y);
  }
  get isVertical(): boolean {
    return MathUtils.areNumbersEqual(this.start.x, this.end.x);
  }

  /**
   * An axis-aligned segment is either horizontal or vertical, and its start point is to the left or to the bottom of its end point, respectively.
   */
  public isAxisAligned(): boolean {
    const isHorizontal = this.isHorizontal;
    return (isHorizontal && this.start.x < this.end.x) || (!isHorizontal && this.start.y < this.end.y);
  }

  /**
   * Subtracts another segment from the current segment and returns the remaining parts.
   * Handles both horizontal and vertical segments based on the isHorizontal flag.
   *
   * @param {Segment} otherSegment - The segment to subtract from the current segment.
   * @param {boolean} isHorizontal - True if the segment is horizontal, false if vertical.
   * @returns {Segment[]} An array of the remaining segment parts after subtraction.
   */
  subtractSegment(otherSegment: Segment, isHorizontal: boolean): Segment[] {
    const remainingParts: Segment[] = [];

    // Select the appropriate coordinate (x for horizontal, y for vertical)
    const [startCoord, endCoord] = isHorizontal ? [this.start.x, this.end.x] : [this.start.y, this.end.y];
    const [otherStartCoord, otherEndCoord] = isHorizontal ? [otherSegment.start.x, otherSegment.end.x] : [otherSegment.start.y, otherSegment.end.y];

    // Helper function to create a vector based on the segment orientation
    const createVector = (coord: number, constant: number) => (isHorizontal ? new THREE.Vector2(coord, constant) : new THREE.Vector2(constant, coord));

    // Case 1: The current segment starts before and ends within or at the other segment
    if (startCoord < otherStartCoord && endCoord <= otherEndCoord) {
      remainingParts.push(this.createUpdatedSegment(this.start, createVector(otherStartCoord, isHorizontal ? this.start.y : this.start.x)));
    }

    // Case 2: The current segment starts within or at and ends after the other segment
    if (endCoord > otherEndCoord && startCoord >= otherStartCoord) {
      remainingParts.push(this.createUpdatedSegment(createVector(otherEndCoord, isHorizontal ? this.end.y : this.end.x), this.end));
    }

    // Case 3: The current segment fully overlaps the other segment, leaving two parts
    if (startCoord < otherStartCoord && endCoord > otherEndCoord) {
      remainingParts.push(this.createUpdatedSegment(this.start, createVector(otherStartCoord, isHorizontal ? this.start.y : this.start.x)));
      remainingParts.push(this.createUpdatedSegment(createVector(otherEndCoord, isHorizontal ? this.end.y : this.end.x), this.end));
    }

    // Return the remaining segment parts
    return remainingParts;
  }

  /**
   * Helper function to create an updated segment with a consistent roomId.
   * @param {THREE.Vector2} start - The start point of the new segment.
   * @param {THREE.Vector2} end - The end point of the new segment.
   * @returns {Segment} A new segment with the same roomId as the original segment.
   */
  private createUpdatedSegment(start: THREE.Vector2, end: THREE.Vector2): Segment {
    const updatedSegment = new Segment(start, end);
    updatedSegment.roomId = this.roomId;
    return updatedSegment;
  }

  /**
   * Filters and retrieves segments that are perpendicular to the current segment
   * based on its orientation along the x or y axis.
   *
   * @param {Segment[]} segments - The array of segments to check for perpendicularity.
   * @returns {Segment[]} - The array of segments that are perpendicular to the current segment.
   */
  getPerpendicularSegments(segments: Segment[]): Segment[] {
    // Determine the axis of the current segment: 'x' for horizontal, 'y' for vertical.
    const axis = this.isHorizontal ? "x" : "y";

    // Filter and return segments that are perpendicular (i.e., on a different axis).
    return segments.filter(seg => {
      const segAxis = seg.isHorizontal ? "x" : "y"; // Determine the axis of each segment.
      return segAxis !== axis; // Keep only segments with a different axis (perpendicular).
    });
  }

  public areSegmentsEqual(other: Segment, epsilon: number = EPSILON): boolean {
    return (
      (this.start.distanceTo(other.start) < epsilon && this.end.distanceTo(other.end) < epsilon) ||
      (this.start.distanceTo(other.end) < epsilon && this.end.distanceTo(other.start) < epsilon)
    );
  }

  // Method to check overlap with rectangle edges
  public overlapsRoomEdges(room: Room, epsilon = 0.01): boolean {
    const { x, y, width, height } = room;

    const roomEdges = [
      new Segment(new THREE.Vector2(x - width / 2, y - height / 2), new THREE.Vector2(x + width / 2, y - height / 2)), // Bottom edge
      new Segment(new THREE.Vector2(x + width / 2, y - height / 2), new THREE.Vector2(x + width / 2, y + height / 2)), // Right edge
      new Segment(new THREE.Vector2(x + width / 2, y + height / 2), new THREE.Vector2(x - width / 2, y + height / 2)), // Top edge
      new Segment(new THREE.Vector2(x - width / 2, y + height / 2), new THREE.Vector2(x - width / 2, y - height / 2)), // Left edge
    ];

    return roomEdges.some(edge => SegmentsUtils.segmentsOverlap(this, edge, epsilon));
  }

  public getRange(): number[] {
    const axis = this.isHorizontal ? "x" : "y";
    return [Math.min(this.start[axis], this.end[axis]), Math.max(this.start[axis], this.end[axis])];
  }

  public length(): number {
    return this.start.distanceTo(this.end);
  }

  public applyMatrix3(mtr: THREE.Matrix3): Segment {
    this.start.applyMatrix3(mtr);
    this.end.applyMatrix3(mtr);
    return this;
  }

  public revert(): Segment {
    const tmp = this.start;
    this.start = this.end;
    this.end = tmp;
    return this;
  }

  public clone(): Segment {
    return new Segment(this.start.clone(), this.end.clone(), this.roomId, this.hasWall, Object.assign({}, this.extras));
  }

  static fromLine3(line: THREE.Line3): Segment {
    const segment = new Segment(new THREE.Vector2(line.start.x, line.start.y), new THREE.Vector2(line.end.x, line.end.y));
    if (!segment.isAxisAligned()) {
      segment.revert();
    }
    return segment;
  }
  public overlapsSegment(other: Segment, epsilon: number = EPSILON): boolean {
    const isHorizontal = this.isHorizontal;
    if (isHorizontal !== other.isHorizontal) {
      return false;
    }

    const thisRange = this.getRange();
    const otherRange = other.getRange();
    const axis = isHorizontal ? "y" : "x";

    const res =
      MathUtils.areNumbersEqual(this.start[axis], other.start[axis], epsilon) &&
      thisRange[0] < otherRange[1] - epsilon &&
      otherRange[0] < thisRange[1] - epsilon;
    return res;
  }
  /**
   * Adds a classification to the segment if it doesn't already exist in the classification list.
   *
   * @param {WallType} newClassification - The classification to add.
   */
  public addClassification(newClassification: WallType): void {
    if (!this.classification.includes(newClassification)) {
      this.classification.push(newClassification);
      const funcCode = this.getWallFunctionCode();
      this.FunctionCode = Object.values(FuncCode).find(code => code === funcCode) || null;
    }
  }

  public changeFunctionCode(funcCode: FuncCode): void {
    this.FunctionCode = funcCode;
  }

  /**
   * Method to calculate and return the function code based on the classification and wall attributes
   * by matching against the external rule set.
   *
   * @returns {string | null} - The matching function code or null if no match is found.
   * @obsolete - This method is no longer used and will be removed in the future.
   */
  public getWallFunctionCode(): string | null {
    if (this._functionCode) return this._functionCode;
    for (const rule of wallFunctionRules.rules) {
      const ruleClassifications: WallType[] = rule.conditions.classification;

      // Check if both arrays have the same length and the same elements (ignoring order)
      const classificationMatch =
        ruleClassifications.length === this.classification.length &&
        ruleClassifications.every((cls: WallType) => this.classification.includes(cls)) &&
        this.classification.every((cls: WallType) => ruleClassifications.includes(cls));

      if (classificationMatch) {
        return rule.functionCode;
      }
    }
    // Fallback: Check if any rule matches the first item of this.classification
    const firstClassification = this.classification[0];
    for (const rule of wallFunctionRules.rules) {
      if (rule.conditions.classification.includes(firstClassification)) {
        return rule.functionCode;
      }
    }

    // Return null if no rule matches
    return null;
  }
  public DeepCopy(): Segment {
    const copy = this.clone();

    copy.start = this.start.clone();
    copy.end = this.end.clone();

    copy.hasWall = this.hasWall;
    copy.roomId = [...this.roomId];
    copy.extras = this.extras;
    copy.classification = [...this.classification];
    copy._functionCode = this._functionCode;
    return copy;
  }
}

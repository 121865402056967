import { UploadOutlined } from "@ant-design/icons";
import { Button, Col, Collapse, Modal, Row, Upload, UploadFile } from "antd";
import { UploadChangeParam } from "antd/lib/upload";
import { observer } from "mobx-react-lite";
import { useState } from "react";
import { entityManager } from "../../../../entities/entityManager";
import { showToastMessage } from "../../../../helpers/messages";
import { appModel } from "../../../../models/AppModel";
import { markPublic } from "../../../../services/allKinds";
import { addAuthParam } from "../../../../services/api/utilities";
import LoadingSpinner from "../../common/LoadingSpinner";
import { processUploadedFolders } from "../ModalBulkRoomUpload";
import DEngineFilesSection from "./DEngineFilesSection";
import MaterialsSection from "./MaterialsSection/MaterialsSection";
import "./RoomManagementTab.sass";

const { Panel } = Collapse;
const { confirm } = Modal;

let RoomManagementTab = ({ handleModalClose, handleBulkUploadModalOpen, activeTabKey, tabKey }) => {
  const [isReadingFolder, setIsReadingFolder] = useState<boolean>(false);
  const [isClearingCatalog, setIsClearingCatalog] = useState<boolean>(false);
  const [checkedRooms, setCheckedRooms] = useState([]);
  const [openPanels, setOpenPanels] = useState([]);
  let uploadedCount = 0;
  const sortedCategories = appModel.roomCategories
    .filter(rc => rc.roomTypes.some(roomType => roomType.mark >= markPublic.id))
    .sort((a, b) => (a.name.toLowerCase() > b.name.toLowerCase() ? 1 : -1));

  function onBulkUpload(info: UploadChangeParam<UploadFile<any>>) {
    ++uploadedCount;
    if (uploadedCount < info.fileList.length) return;

    uploadedCount = 0;

    processUploadedFolders(info, setIsReadingFolder, handleBulkUploadModalOpen);
  }

  function clearCatalog() {
    confirm({
      title: `Are you sure you want to clear catalog?`,
      okText: "Yes",
      okType: "primary",
      cancelText: "No",
      async onOk() {
        setIsClearingCatalog(true);
        await entityManager.clearCatalog();
        setIsClearingCatalog(false);
      },
    });
  }

  const onCancelClick = () => {
    handleModalClose();
  };
  const onExpandCollapseClick = key => {
    setOpenPanels(key);
  };
  const onRoomSelectChanged = (roomTypeId: string) => {
    let updatedList = [...checkedRooms];
    if (checkedRooms.includes(roomTypeId)) {
      updatedList.splice(checkedRooms.indexOf(roomTypeId), 1);
    } else {
      updatedList = [...checkedRooms, roomTypeId];
    }
    setCheckedRooms(updatedList);
  };
  const onRemoveRoomClick = ev => {
    ev.stopPropagation();
    if (!checkedRooms?.length) {
      showToastMessage("Error", "Select at least one room.");
      return false;
    }
    checkedRooms.forEach(async roomTypeId => {
      const roomType = appModel.getRoomType(roomTypeId);
      await entityManager.delete(roomType);
    });
    setCheckedRooms([]);
    const selectedIndex = ev.target.parentElement.getAttribute("data-index");
    setActiveAccordionTabs(selectedIndex);
    showToastMessage("Success", "Room(s) was successfully deleted.");
  };

  const getActionButtons = () => (
    <div className="actions">
      <Button type="primary" onClick={onRemoveRoomClick}>
        <i className="icon icon-trash" />
      </Button>
    </div>
  );
  const setActiveAccordionTabs = key => {
    if (!openPanels.includes(key)) {
      const activeTabs = openPanels;
      activeTabs.push(key);
      setOpenPanels(activeTabs);
    }
  };

  return (
    <div className="room-management-tab" hidden={activeTabKey !== tabKey}>
      <div className="category-container">
        <MaterialsSection />
        <DEngineFilesSection />
        {appModel.isBusy && (
          <div className="placeholder-section">
            <LoadingSpinner />
            <p>Loading room categories...</p>
          </div>
        )}
        {!appModel.isBusy && !sortedCategories.length && (
          <div className="placeholder-section">
            <p>There are no room categories.</p>
          </div>
        )}
        <Collapse activeKey={openPanels} onChange={onExpandCollapseClick} destroyInactivePanel>
          {sortedCategories.map((roomCategory, index) => (
            <Panel header={roomCategory.name} key={index} extra={getActionButtons()} className="category-collapse-item">
              <Collapse>
                {[...roomCategory.visibleRoomTypesByFunction].map(typesByFunction => (
                  <Panel header={typesByFunction[0]} key={typesByFunction[0]} className="function-collapse-item">
                    <Row className="room-types-list">
                      {typesByFunction[1].map(roomType => (
                        <Col className={`room-type-item ${checkedRooms.includes(roomType.id) ? "selected" : ""}`} key={roomType.id}>
                          <img
                            src={addAuthParam(roomType.thumbnailUrl)}
                            onClick={() => {
                              onRoomSelectChanged(roomType.id);
                            }}
                            alt={roomType.name}
                            onError={({ currentTarget }) => {
                              // prevents looping
                              currentTarget.onerror = null;
                              currentTarget.src = "/assets/room_placeholder.svg";
                            }}
                          />
                          <span>{roomType.name}</span>
                        </Col>
                      ))}
                    </Row>
                  </Panel>
                ))}
              </Collapse>
            </Panel>
          ))}
        </Collapse>
      </div>
      <Row className="buttons-row">
        <Button key="clear" loading={isClearingCatalog} onClick={clearCatalog} disabled={!sortedCategories.length}>
          Delete All
        </Button>
        <div className="right-buttons">
          <Upload className="bulk-upload-button" directory onChange={onBulkUpload} showUploadList={false} customRequest={() => {}} key="upload-button">
            <Button type="primary" loading={isReadingFolder} icon={<UploadOutlined />}>
              Upload
            </Button>
          </Upload>
          <Button key="close" onClick={onCancelClick}>
            Close
          </Button>
        </div>
      </Row>
    </div>
  );
};

RoomManagementTab = observer(RoomManagementTab);

export default RoomManagementTab;

import { appModel } from "../../../models/AppModel";
import { ChangeWallAlignmentCommand } from "../../models/commands/ChangeWallAlignmentCommand";
import { soWall2D } from "../../models/SceneObjects/Wall/soWall2D";

export class WallAlignmentManager {
  alignSelectedWallsByPosition(position: string) {
    const commands: ChangeWallAlignmentCommand[] = [];

    // reset the processed walls set that is used to prevent infinite loop #DCP-1872 (alignments of getPerpendicularWallIds)
    appModel.baseManager.roomManager.getActiveSoFloor().wallManager.processedWalls.clear();

    // centerlize the selected walls - remove offsets if exist
    if (position === "center") {
      Array.from(appModel.selectedRoomSoWall).forEach(wall => {
        commands.push(new ChangeWallAlignmentCommand(wall, 0));
      });
    } else {
      // otherwise, align the selected walls to the position
      const referenceWallThickness = this.findReferenceWallThickness();
      Array.from(appModel.selectedRoomSoWall).forEach(wall => {
        const wallThickness = WallAlignmentManager.getSegmentCoreThickness(wall);
        if (wallThickness === referenceWallThickness) {
          // in case the walls with the same thickness, reset them as reference line
          if (wall.wallOffset != this.getReferenceWall().wallOffset) {
            wall.wallOffset = this.getReferenceWall().wallOffset;
            commands.push(new ChangeWallAlignmentCommand(wall, 0));
            appModel.addModifiedSegmentWall(wall);
          }
          return;
        }
        let offset = referenceWallThickness - wallThickness;
        offset = position === "left" || position === "top" ? -offset : offset;
        wall.userData.offset = offset;
        commands.push(new ChangeWallAlignmentCommand(wall, offset));
        appModel.addModifiedSegmentWall(wall);
      });
    }

    appModel.baseManager.roomManager.runChangeWallWidthAlignmentCommand(commands);
    appModel.baseManager.roomManager.setRoomWallSelected(100);
  }

  findReferenceWallThickness = () => {
    // find the first=reference wall
    const referenceWall = this.getReferenceWall();
    referenceWall.userData.offset = 0;
    referenceWall.setWallOffset(null);
    return WallAlignmentManager.getSegmentCoreThickness(referenceWall);
  };

  getReferenceWall = () => {
    return appModel.selectedRoomSoWall.values().next().value;
  };

  static getSegmentCoreThickness(item: soWall2D): number {
    //check if modified, otherwise get core thickness from passed segment
    return item.coreSideThickness;
  }

  static getOffset = (segment: soWall2D): number => {
    return appModel.modifiedWallManager.findSegmentOffset(segment);
  };
}

const wallAlignmentManager = new WallAlignmentManager();
export default wallAlignmentManager;

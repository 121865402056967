import { useEffect, useState } from "react";
import { observer } from "mobx-react";
import { Tooltip } from "antd";
import { v4 as uuid } from "uuid";

import { SceneEditorMode } from "../../../editor/models/SceneEditorMode";
import { appModel } from "../../../models/AppModel";

import RoomEditToolPosition from "../../../editor/tools/RoomEditToolPosition";
import SceneUtils from "../../../editor/utils/SceneUtils";

import "./Editor.sass";

export interface IEditorToolOptions {
  show: boolean;
  top?: number;
  left?: number;
}

const EditorToolbar = observer(() => {
  const activeSoRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();
  const selectedRooms = appModel.activeCorePlan.getRooms(appModel.selectedRoomsIds);
  const [hasRoof, setHasRoof] = useState(selectedRooms && selectedRooms[0]?.hasRoof);

  useEffect(() => {
    setHasRoof(activeSoRooms[0]?.hasRoof);

    return () => {
      setHasRoof(false);
    };
  }, [activeSoRooms.map(room => Boolean(room.hasRoof)).join("")]);

  const options = appModel.editToolOptions;

  if (!options || !options.show || (!options?.top && !options?.left)) {
    return null;
  }

  const isSoRoomOutdoor = activeSoRooms.length === 1 && !activeSoRooms[0].isIndoor;
  const isSoRoomRoofed = isSoRoomOutdoor && hasRoof;
  const isEnabledRooms =
    !appModel.isViewOnlyMode &&
    appModel.sceneEditorMode === SceneEditorMode.Room &&
    appModel.activeCorePlan !== null &&
    appModel.activeCorePlan.getRooms(appModel.selectedRoomsIds).length > 0;

  /**
   * Groups all selected rooms under the room with the largest area.
   * Only applies to ungrouped rooms.
   */
  function addRoomsToGroup() {
    const id = uuid();

    const selectedRooms = appModel.activeCorePlan.getRooms(appModel.selectedRoomsIds);
    if (selectedRooms.length < 2) return; // Need at least two rooms to form a group

    const mainRoom = selectedRooms.reduce((largestRoom, currentRoom) => {
      return currentRoom.area > (largestRoom?.area || 0) ? currentRoom : largestRoom;
    }, null);

    const activeSoRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();
    activeSoRooms.forEach(soRoom => {
      SceneUtils.removeGroupedLines(soRoom, appModel.baseManager.roomManager.getActiveSoFloor());
    });

    activeSoRooms.forEach(soRoom => {
      SceneUtils.unhighlightSelectedRoom(soRoom);
      SceneUtils.displayRoomStretchControls(soRoom, false);

      if (mainRoom.id === soRoom.soId) {
        soRoom.setRoomGroup(id, true);
        return;
      }
      soRoom.setRoomGroup(id, false);
    });

    selectedRooms.forEach(room => {
      if (mainRoom.id === room.id) {
        room.setRoomGroup(id, true);
        return;
      }
      room.setRoomGroup(id, false);
    });

    RoomEditToolPosition.setPosition(true);
    appModel.setSelectedRoomsIds(selectedRooms.map(room => room.id));
    appModel.baseManager.roomManager.updateGroupedRoomsChanged(activeSoRooms[0], true);
  }

  /**
   * Ungroups all currently selected rooms.
   */
  function onUngroupRooms() {
    const activeSoRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();

    if (activeSoRooms.length === 0) return;

    activeSoRooms.forEach(room => {
      SceneUtils.removeGroupedLines(room, appModel.baseManager.roomManager.getActiveSoFloor());
    });
    activeSoRooms.forEach(soRoom => {
      soRoom.removeRoomGroup();
      SceneUtils.highlightSelectedRoom(soRoom);
      SceneUtils.displayRoomStretchControls(soRoom, true);
    });
    RoomEditToolPosition.setPosition(true);
    activeSoRooms.forEach(room => {
      room.removeRoomGroup();
    });
  }

  /**
   * Returns `true` if every selected room has at least one neighbor among the selected rooms.
   * (Based on your existing logic.)
   */
  function allRoomsHaveNeighbors() {
    const selectedRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();

    return selectedRooms.every(roomA => {
      const hasNeighbor = selectedRooms.some(roomB => {
        return roomB?.isRoomsAreNeighbors(roomA);
      });

      return hasNeighbor;
    });
  }

  function onRoomRoofChanged(newRoofState: boolean) {
    const activedRooms = appModel.activeCorePlan.getRooms(appModel.selectedRoomsIds);
    const selectedRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();

    activedRooms[0].setRoomRoof(newRoofState);
    selectedRooms[0].setRoomRoof(newRoofState);

    setHasRoof(newRoofState);
    appModel.baseManager.roomManager.updateFloorDimension();
  }

  const roomsAllHaveNeighbors = allRoomsHaveNeighbors();

  const shouldShowGroup = activeSoRooms.length > 1 && roomsAllHaveNeighbors && activeSoRooms.some(room => !room.groupedBy);

  return (
    <div className="editor-toolbar-container" style={{ left: `${options.left}px`, top: `${options.top}px` }}>
      <div className="editor-toolbar">
        <div className="editor-toolbar">
          <Tooltip title="Rotate" placement="bottom">
            <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onRotateRoomsClockwiseClicked} data-testid="rotate-room(s)-clockwise">
              <img src="/assets/icons/rotate-right.svg" alt="rotate-right" />
            </button>
          </Tooltip>
          <Tooltip title="Rotate Inner" placement="bottom">
            <button
              className="btn btn-icon icon"
              disabled={!isEnabledRooms}
              onClick={onRotateRoomsContentClockwiseClicked}
              data-testid="rotate-room(s)-furniture-clockwise"
            >
              <img src="/assets/icons/rotate-inner-right.svg" alt="rotate-inner-right" />
            </button>
          </Tooltip>
          <hr />
          <Tooltip title="Mirror H" placement="bottom">
            <button
              className="btn btn-icon icon"
              disabled={!isEnabledRooms}
              onClick={onMirrorRoomsHorizontallyClicked}
              data-testid="mirror-room(s)-horizontally"
            >
              <img src="/assets/icons/mirror-horizontally.svg" alt="mirror-horizontally" />
            </button>
          </Tooltip>
          <Tooltip title="Mirror V" placement="bottom">
            <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onMirrorRoomsVerticallyClicked} data-testid="mirror-room(s)-vertically">
              <img src="/assets/icons/mirror-vertically.svg" alt="mirror-vertically" />
            </button>
          </Tooltip>

          {isSoRoomOutdoor &&
            (isSoRoomRoofed ? (
              <Tooltip title="Roof Enabled" placement="bottom">
                <button className="btn btn-icon icon" onClick={() => onRoomRoofChanged(false)} data-testid="roof_enabled">
                  <img src="/assets/icons/roofed.svg" alt="Roof Enabled" />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Roof Disabled" placement="bottom">
                <button className="btn btn-icon icon" onClick={() => onRoomRoofChanged(true)} data-testid="roof_disabled">
                  <img src="/assets/icons/unroofed.svg" alt="Roof Disabled" />
                </button>
              </Tooltip>
            ))}

          {/* Show Group or Ungroup depending on conditions */}
          {roomsAllHaveNeighbors &&
            (shouldShowGroup ? (
              <Tooltip title="Group" placement="bottom">
                <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={addRoomsToGroup} data-testid="group">
                  <img src="/assets/icons/group.svg" alt="group" />
                </button>
              </Tooltip>
            ) : (
              <Tooltip title="Ungroup" placement="bottom">
                <button className="btn btn-icon icon" disabled={!isEnabledRooms} onClick={onUngroupRooms} data-testid="ungroup">
                  <img src="/assets/icons/ungroup.svg" alt="ungroup" />
                </button>
              </Tooltip>
            ))}
        </div>
      </div>
    </div>
  );
});

// -----------------------------------
// Rotation and Mirror Handlers
// -----------------------------------
const onRotateRoomsClockwiseClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.rotateSelectedRooms(true);
  RoomEditToolPosition.setPosition(true);
};

const onRotateRoomsContentClockwiseClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.rotateSelectedRoomsContent(true);
};

const onMirrorRoomsVerticallyClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.mirrorSelectedRooms(false);
};

const onMirrorRoomsHorizontallyClicked = (e: React.MouseEvent): void => {
  appModel.baseManager.roomManager.mirrorSelectedRooms(true);
};

export default EditorToolbar;

import { useEffect, useState } from "react";
import { Tabs } from "antd";
import { observer } from "mobx-react";

import RoomCatalogView from "./RoomCatalog/RoomCatalog";
import ProductParams from "./ProductParams/ProductParams";
import ValidationPanel from "./ValidationPanel/ValidationPanel";
import Configure from "./Configure/Configure";
import Manage from "./Manage/Manage";

import { appModel } from "../../../../models/AppModel";
import { entityManager } from "../../../../entities/entityManager";
import { FileExportType } from "../../../../models/FileExportType";
import { SceneMode } from "../../../../models/SceneMode";
import { SceneEditorMode } from "../../../../editor/models/SceneEditorMode";
import { handlePendingChanges } from "../../../../helpers/appUtils";
import { useHistory } from "react-router-dom";
import { openModalCorePlanPackageWindow } from "../../CorePlans/ModalCorePlanPackageWindow";
import { CorePlanPackageDetailTabs } from "../../CorePlans/CorePlanDetails/CorePlanPackageDetails";

import WallProperties from "./WallProperties/WallProperties";
import soWallProperties from "./WallProperties/soWallProperties";
import "./LeftBar.sass";
import GroupProperties from "./GroupProperties/GroupProperties";

export const enum FolderOptions {
  CorePlanFolder = "",
  VariationFolder = "",
  FloorPlanFolder = "FloorPlans",
  TmlFolder = "TML",
  JsonFolder = "JSON",
  DataModelFolder = "DataModels",
  ThreeDModelsFolder = "3dModels",
  RenderingsFolder = "Renderings",
  ThumbnailsFolder = "Thumbnails",
}

const LeftBar = observer(() => {
  const [activeTab, setActiveTab] = useState("");
  const history = useHistory();
  const activeSoRooms = appModel.baseManager.roomManager.getCorePlanSelectedSoRooms();

  useEffect(() => {
    if (
      appModel.selectedRoomWall.size ||
      appModel.selectedRoomSoWall.size ||
      activeSoRooms.some(groupedRoom => groupedRoom.groupedBy || groupedRoom.isPrimary)
    ) {
      setActiveTab("PrjP");
    } else {
      setActiveTab(appModel.isViewOnlyMode ? "PrjP" : "RC");
    }
  }, [activeSoRooms.some(groupedRoom => groupedRoom.groupedBy || groupedRoom.isPrimary), appModel.selectedRoomWall.size, appModel.selectedRoomSoWall.size]);

  const onSaveCorePlanClick = async () => {
    if (!(await handlePendingChanges())) {
      return;
    }

    appModel.baseManager.roomManager.updateRoomDrawings(true);
    // remove room overhangs before saving - no need
    appModel.baseManager.roomManager.setRoomDrawingsOverhangsForSave(false);
    const { notFound } = await entityManager.saveCorePlan(appModel.activeCorePlan, true);
    // return control for save overhangs
    appModel.baseManager.roomManager.setRoomDrawingsOverhangsForSave(true);
    if (notFound) {
      await appModel.setActiveCorePlan(null, false);
      history.push("/");
      return;
    }
    appModel.updateCorePlanCost();
    appModel.setSceneEditorMode(SceneEditorMode.Room);
  };

  const onOpenModalCorePlanPackageWindow = () => {
    openModalCorePlanPackageWindow(appModel.activeCorePlan, CorePlanPackageDetailTabs.CorePlanOverview);
  };

  const handleTabChange = key => {
    setActiveTab(key); // Update state when tab is clicked
    appModel.baseManager.roomManager.clearRoomWallSelected();
  };

  if (appModel.selectedRoomSoWall && appModel.selectedRoomSoWall.size > 0) {
    const i = appModel.selectedRoomSoWall.values().next().value;
    const i2 = appModel.selectedRoomSoWall.values().next();
  }

  const groupedRoom = appModel.baseManager.roomManager.getCorePlanSoRooms().find(room => {
    return appModel.selectedRoomsIds.includes(room.soId) && room.isPrimary;
  });
  const groupedRooms = appModel.baseManager.roomManager.getCorePlanSoRooms().filter(room => appModel.selectedRoomsIds.includes(room.soId));
  const isHorizontal =
    appModel.selectedRoomSoWall && appModel.selectedRoomSoWall.size > 0 ? appModel.selectedRoomSoWall.values().next().value.isHorizontal : false;
  const shouldShowWallProperties = appModel.selectedRoomWall.size || appModel.selectedRoomSoWall.size;
  const renderWallProperties = () => (
    <WallProperties
      multiWallMode={appModel.featureFlags["wallAlignment"] && appModel.selectedRoomSoWall.size > 1}
      isSelectedRoomWallsEligible={appModel.isSelectedRoomWallsEligible}
      isHorizontal={appModel.isSelectedRoomSegmentsHorizontal}
    />
  );

  const renderGroupedRooms = () => <GroupProperties groupedRoom={groupedRoom} groupedRooms={groupedRooms} />;

  const renderProductParams = () => <ProductParams />;

  const renderContent = () => {
    if (shouldShowWallProperties) return renderWallProperties();
    else if (groupedRoom) return renderGroupedRooms();
    else return renderProductParams();
  };

  return (
    <div className="left-bar">
      <div className="tabs-holder">
        {appModel.isSceneLoaded && (
          <>
            {appModel.sceneMode == SceneMode.Editor && (
              <Tabs
                onChange={handleTabChange}
                activeKey={activeTab}
                defaultActiveKey={appModel.isViewOnlyMode ? "PrjP" : "RC"}
                className="left-bar-tabs"
                destroyInactiveTabPane
                items={[
                  { label: "Room Catalog", key: "RC", children: <RoomCatalogView />, disabled: appModel.isViewOnlyMode },
                  {
                    label: "Parameters",
                    key: "PrjP",
                    children: renderContent(),
                  },
                  { label: "Validation", key: "VP", children: <ValidationPanel /> },
                ]}
              />
            )}
            {appModel.sceneMode !== SceneMode.Editor && (
              <Tabs
                defaultActiveKey="SV"
                className="left-bar-tabs"
                destroyInactiveTabPane
                items={[
                  { label: "Configure", key: "CO", children: <Configure /> },
                  { label: "Manage", key: "MA", children: <Manage /> },
                ]}
              />
            )}
          </>
        )}
      </div>

      <div className="buttons-holder">
        <button className="btn" disabled={!appModel.activeCorePlan || appModel.isViewOnlyMode} onClick={onSaveCorePlanClick}>
          <div className="icon-container">
            <img src="/assets/save-one.svg" alt="Save button" />
          </div>
          <span>Save</span>
        </button>
        <button className="btn" disabled={!appModel.activeCorePlan} onClick={onOpenModalCorePlanPackageWindow}>
          <div className="icon-container">
            <img src="/assets/folder-open.svg" alt="Package button" />
          </div>
          <span>Package</span>
        </button>
      </div>
    </div>
  );
});

export default LeftBar;

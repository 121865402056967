import { Col, Form, Input, Row } from "antd";
import MathUtils from "../../../../editor/utils/MathUtils";
import { inches2feet, inches2feetSq } from "../../../../helpers/measures";
import { settings } from "../../../../entities/settings";

const LotDefinition = ({ corePlanData, formData, setFormData, sizePrecision, setCorePlanData }) => {
  const setLotAreaFromSide = e => {
    if (e.target.value) {
      const otherSide = e.target.name === "lotLength" ? "lotWidth" : "lotWidth";
      const thisSide = Number.parseFloat(e.target.value);
      const lotArea = MathUtils.round(thisSide * formData[otherSide], sizePrecision);
      setCorePlanData(e.target.name, thisSide, true);
      setCorePlanData("lotSize", lotArea, true);
      setFormData({ ...formData, [e.target.name]: thisSide, lotSize: lotArea });
    } else {
      setCorePlanData(e.target.name, e.target.value, true);
      setFormData({ ...formData, [e.target.name]: e.target.value });
    }
  };

  const checkValidLotSizeValue = e => {
    if (!e.target.value) {
      const lotSize = inches2feetSq(settings.values.corePlanSettings.lotArea);
      const lotWidth = inches2feet(settings.values.corePlanSettings.lotWidth);
      const lotLength = inches2feet(settings.values.corePlanSettings.lotLength);
      setCorePlanData("lotSize", lotSize, true);
      setCorePlanData("lotLength", lotWidth, true);
      setCorePlanData("lotWidth", lotLength, true);

      setFormData({ ...formData, lotSize, lotLength, lotWidth });
    }
  };

  const checkValidLotSideValue = e => {
    if (!e.target.value) {
      const otherSide = e.target.name === "lotLength" ? "lotWidth" : "lotLength";
      const thisSideSize = MathUtils.round(formData.lotSize / formData[otherSide], sizePrecision);
      setCorePlanData(e.target.name, thisSideSize, true);
      setFormData({ ...formData, [e.target.name]: thisSideSize });
    }
  };

  return (
    <div>
      <div className="ant-form-item-label">
        <label>Lot Definition</label>
      </div>
      <Row className="area-group">
        <Col span={4}>
          <Form.Item rules={[{ required: false }]}>
            <Input
              data-testid="lotLengthInput"
              addonBefore="Length"
              addonAfter="ft"
              onChange={setLotAreaFromSide}
              name="lotLength"
              defaultValue={corePlanData.attributes.lotLength}
              type="number"
              step="any"
              min={0}
              value={formData.lotLength}
              onBlur={checkValidLotSizeValue}
            />
          </Form.Item>
        </Col>
        <Col span={4} offset={5}>
          <Form.Item rules={[{ required: false }]}>
            <Input
              data-testid="lotWidthInput"
              addonBefore="Width"
              addonAfter="ft"
              onChange={setLotAreaFromSide}
              name="lotWidth"
              defaultValue={corePlanData.attributes.lotWidth}
              type="number"
              step="any"
              min={0}
              value={formData.lotWidth}
              onBlur={checkValidLotSideValue}
            />
          </Form.Item>
        </Col>
      </Row>
    </div>
  );
};

export default LotDefinition;

import * as THREE from "three";
import ContourEdgesUtil from "./contourEdgesUtil";
import CoreAlg from "./coreAlg";
import type Edge from "./edge";
import type Polygon from "./polygon";
import Gable from "./gable";
import Roof from "./roof";
import RoofSurface from "./roofSurface";
import RoofSurfaceOutput from "./roofSurfaceOutput";
import EdgeOutput from "./edgeOutput";
import HoleInSurface from "./holeInSurface";
import ChangeSurfaceGeo from "./changeSurfaceGeo";
import ChangeSurfacePosition from "./changeSurfacePosition";
import MergeSurfaces from "./mergeSurfaces";
import LineKM from "./lineKM";
import RoofAlgParams from "./roofAlgParams";
import Clipper2ZFactory from "../roofGenerator/clipper2z.js";
import Polyline from "./polyline";

export default class RoofBuilder {
  static async create(inputData, roofAlgParamsIn = null, convertToJSONData = true) {
    if (Polyline.clipper) return Promise.resolve(RoofBuilder.createInternal(inputData, roofAlgParamsIn, convertToJSONData));

    Polyline.clipper = await Clipper2ZFactory();
    return Promise.resolve(RoofBuilder.createInternal(inputData, roofAlgParamsIn, convertToJSONData));
  }

  static createInternal(inputData, roofAlgParamsIn = null, convertToJSONData = true) {
    const startTime = new Date().getTime();

    // eslint-disable-next-line
    const roofDebugParams: any = {};
    const roofAlgParams = roofAlgParamsIn ? roofAlgParamsIn : new RoofAlgParams();

    const { contourEdges, gables, roofEdgesBeforeMerge } = ContourEdgesUtil.createContourEdgesAndNodes(inputData);
    ContourEdgesUtil.assignFacadeDirectionForEachEdgeByClosePolygon(contourEdges);
    ContourEdgesUtil.createPlanesFromEdges(contourEdges);
    ContourEdgesUtil.createLineEquations(contourEdges);
    ContourEdgesUtil.orderContourEdgeLines(contourEdges);

    CoreAlg.createGraphOnEachContourEdge(contourEdges);
    CoreAlg.findCompletePolygonsForEachContourEdge(contourEdges);
    CoreAlg.reducePolygons(contourEdges);

    const roofSolution: Polygon[] = CoreAlg.findCompleteRoof(contourEdges);
    let combinations: Polygon[][] = [];
    if (roofDebugParams.showAllCombinations) combinations = CoreAlg.findAllCombinations(contourEdges);
    if (roofSolution) combinations.push(roofSolution);

    Gable.setGables(gables);
    RoofSurface.setOrderOfRoofEdgesAndNodesOnEachSurface(contourEdges, gables);
    const roof = RoofBuilder.createRoofObject(contourEdges, gables);

    HoleInSurface.createHolesInSurfacesDueToMiniGables(roof, contourEdges);
    HoleInSurface.createHolesInSurfacesDueToDutchGables(roof, contourEdges);

    ChangeSurfaceGeo.strechSurfacesInConcaveCornerWithGable(roof, contourEdges, roofAlgParams.roofThickness, roofAlgParams.slopeDefault);
    ChangeSurfaceGeo.increaseBaseLevelOfGables(roof, contourEdges);

    MergeSurfaces.mergeTwoOverlapSurfaces(roof);
    ChangeSurfacePosition.makeEdgePointsUnique(roof);
    LineKM.classifyRoofEdges(roof, contourEdges);

    // Oleg: This is a work-in-progess new overhang code, disabled for now.
    ChangeSurfacePosition.mapOutputEdgesToInputEdges(roof, roofEdgesBeforeMerge);
    ChangeSurfacePosition.applyOverhang(roof, roofEdgesBeforeMerge, roofAlgParams.overhang);
    ChangeSurfacePosition.decreaseRoofLevel(roof, roofAlgParams.heelHeight);

    RoofSurfaceOutput.removeCollinearPoints(roof.roofSurfaces);

    const endTime = new Date().getTime();
    const totalTime = (endTime - startTime) / 1000.0;
    console.log("Roof generation time: " + totalTime + "s");

    const result = { contourEdges, roofSolution, combinations, roof, gables, roofAlgParams };
    if (convertToJSONData) return RoofBuilder.convertToExpectedOutput(result);
    else return result;
  }

  public static createRoofObject(contourEdges: Edge[], gables: Gable[]): Roof {
    const roofSurfacesOutput: RoofSurfaceOutput[] = [];
    for (const edge of contourEdges) {
      if (!edge.roofSurface) continue;

      // create output edges
      const edgesOutput: EdgeOutput[] = [];
      for (const roofEdge of edge.roofSurface.edges) {
        edgesOutput.push(new EdgeOutput(roofEdge.startNode.point3D, roofEdge.endNode.point3D));
      }

      const slope = edge.roofSurface.slope == 0 ? 0 : Math.tan(Math.PI / 2 - edge.roofSurface.slope);

      const holesInSurface: THREE.Vector3[][] = [];
      for (const dutchGableInnerHole of edge.roofSurface.dutchGableInnerHoles) {
        // create output dutch gable hole nodes
        const dutchGableHolesNodesOutput: THREE.Vector3[] = [];
        for (const dutchGableInnerHoleNode of dutchGableInnerHole.nodes) {
          dutchGableHolesNodesOutput.push(dutchGableInnerHoleNode.point3D.clone());
        }

        holesInSurface.push(dutchGableHolesNodesOutput);
      }

      roofSurfacesOutput.push(new RoofSurfaceOutput(edgesOutput, slope, edge.roofSurface.direction, 0, holesInSurface));
    }

    for (const gable of gables) {
      for (const surf of gable.roofSurfaces) {
        if (!surf) continue;

        // create output edges
        const edgesOutput: EdgeOutput[] = [];
        for (const roofEdge of surf.edges) {
          edgesOutput.push(new EdgeOutput(roofEdge.startNode.point3D, roofEdge.endNode.point3D));
        }

        const slope = surf.slope == 0 ? 0 : Math.tan(Math.PI / 2 - surf.slope);
        roofSurfacesOutput.push(new RoofSurfaceOutput(edgesOutput, slope, surf.direction, gable.depth));
      }
    }
    return new Roof(roofSurfacesOutput);
  }

  public static convertToExpectedOutput(result) {
    const converted = Object.assign({}, result);
    converted.roof = {
      RoofSurface: result.roof.roofSurfaces.map(roofSurface => {
        return roofSurface.getDataInOutputFormat();
      }),
    };

    return converted;
  }
}

import { useState } from "react";
import { Button, Card, Dropdown, Menu, Modal, Tooltip } from "antd";
import { observer } from "mobx-react-lite";
import { useHistory } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { entityManager } from "../../../entities/entityManager";
import { arrayIdSet, getDropdownTrigger } from "../../../helpers/utilities";
import { appModel } from "../../../models/AppModel";
import { CorePlan } from "../../../models/CorePlan";
import { ContentMode, openModalCorePlanWindow } from "./ModalCorePlanWindow";
import { corePlanLockModalState } from "./CorePlanLockModal";
import LoadingSpinner from "../common/LoadingSpinner";
import { openModalCorePlanPackageWindow } from "./ModalCorePlanPackageWindow";
import { CorePlanPackageDetailTabs } from "./CorePlanDetails/CorePlanPackageDetails";
import { US_STATES } from "../../../editor/consts";
import { inches2feet } from "../../../helpers/measures";
import { kindFloorRaw, kindRoomRaw } from "../../../services/allKinds";

import "./CorePlanListItem.sass";
import { showToastMessage } from "../../../helpers/messages";
import { RoomEntity } from "../../../models/RoomEntity";
import { RoomEntityType } from "../../../models/RoomEntityType";

type CorePlanItemProps = {
  corePlan: CorePlan;
  flags: Record<string, boolean>;
};

let CorePlanListItem = ({ corePlan, flags }: CorePlanItemProps) => {
  const history = useHistory();
  const [isConfirmModalOpen, setIsConfirmModalOpen] = useState(false);
  const [isCorePlanDeleting, setIsCorePlanDeleting] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const dropdownTrigger = getDropdownTrigger();

  const isLockedByCurrentUser = corePlan.isLockedByCurrentUser();
  const isLockedByAnotherUser = !isLockedByCurrentUser && corePlan.isLocked();

  const { floors, bathRooms, halfBathRooms, bedRooms, garage, lotWidth } = corePlan.attributes;
  const totalBathrooms = (bathRooms ? bathRooms : 0) + (halfBathRooms ? halfBathRooms / 2 : 0);
  const nodeEnv = process.env.REACT_APP_NODE_ENV ? `-${process.env.REACT_APP_NODE_ENV}` : "";

  const onCorePlanClick = async () => {
    try {
      setIsLoading(true);
      const newest = await entityManager.getCorePlan(corePlan.id);
      if (!newest) {
        return;
      }
      arrayIdSet(appModel.corePlans, newest);

      if (newest.isLockedByCurrentUser() || !newest.isLocked()) {
        history.push(`/editor/${newest.id}`);
        return;
      }

      // Open corePlan lock dialog
      corePlanLockModalState.setCorePlan(newest);
    } finally {
      setIsLoading(false);
    }
  };
  const onCorePlanUnlockClick = async e => {
    e.domEvent.stopPropagation();
    try {
      setIsLoading(true);
      await entityManager.unlockCorePlan(corePlan);
    } finally {
      setIsLoading(false);
    }
  };
  const onCorePlanEditClick = e => {
    e.domEvent.stopPropagation();
    openModalCorePlanWindow(corePlan, ContentMode.Edit);
  };
  const onCorePlanCloneClick = e => {
    e.domEvent.stopPropagation();

    openModalCorePlanWindow(corePlan, ContentMode.Clone);
  };
  const onCorePlanExportClick = async e => {
    e.domEvent.stopPropagation();
    exportCorePlan();
  };
  const onCorePlanDetails = e => {
    e.domEvent.stopPropagation();
    openModalCorePlanPackageWindow(corePlan, CorePlanPackageDetailTabs.CorePlanOverview);
  };
  const onCorePlanDeleteClick = e => {
    e.domEvent.stopPropagation();
    setIsConfirmModalOpen(true);
  };
  const onConfirmFloorsDeletion = async () => {
    setIsCorePlanDeleting(true);
    await entityManager.delete(corePlan);
    setIsCorePlanDeleting(false);
    setIsConfirmModalOpen(false);
  };
  const onCancelFloorsDeletion = () => {
    setIsConfirmModalOpen(false);
  };

  let editSettingKey = 1;
  const editSetting = (
    <div onClick={e => e.stopPropagation()}>
      <Menu
        className="dropdown-edit"
        items={[
          {
            label: "Edit Request",
            key: editSettingKey++,
            onClick: onCorePlanEditClick,
            disabled: isLockedByAnotherUser,
          },
          // {
          //   label: "Edit Layout",
          //   key: editSettingKey++,
          //   onClick: onCorePlanClick, // NOSONAR
          // },
          isLockedByCurrentUser && {
            label: "Unlock",
            key: editSettingKey++,
            onClick: onCorePlanUnlockClick, // NOSONAR
          },
          {
            label: "Package",
            key: editSettingKey++,
            onClick: onCorePlanDetails,
          },
          {
            label: "Duplicate Core Plan",
            key: editSettingKey++,
            onClick: onCorePlanCloneClick,
          },
          flags["copyCorePlan"] && {
            label: "Export Core Plan",
            key: editSettingKey++,
            onClick: onCorePlanExportClick,
          },
          {
            label: "Delete Core Plan",
            key: editSettingKey++,
            onClick: onCorePlanDeleteClick,
            className: "withSeparator",
            disabled: isLockedByAnotherUser,
          },
        ]}
      />
    </div>
  );

  // Function to get the state abbreviation with a "#"
  const getStateAbbreviation = stateName => {
    const state = US_STATES.find(([name]) => name === stateName);
    return state ? `#${state[1]}` : "--";
  };

  const getCorePlanConcatenatedName = corePlan => {
    return `${corePlan.lennar_id} ${corePlan.name}`;
  };

  const getCorePlanConcatenatedSeries = corePlan => {
    return `${inches2feet(lotWidth).toString()}ft ${corePlan.series}`;
  };

  const handleOnCorePlanClick = () => {
    onCorePlanClick();
  };

  const generateFilenameWithDateTime = baseName => {
    const now = new Date();
    const year = now.getFullYear();
    const month = String(now.getMonth() + 1).padStart(2, "0");
    const day = String(now.getDate()).padStart(2, "0");
    const hours = String(now.getHours()).padStart(2, "0");
    const minutes = String(now.getMinutes()).padStart(2, "0");

    return `${baseName}_${year}-${month}-${day}_${hours}-${minutes}${nodeEnv}`;
  };

  /**
   * Modify corePlan data for export - to replace id-s of elements with new ones
   * @param {Object} dtoCorePlan - corePlan data
   * * @param {Array} variations - array of elevations data
   * @private
   * @returns {void}
   */
  const modifyCorePlanForExport = (dtoCorePlan, variations) => {
    dtoCorePlan.name = `${generateFilenameWithDateTime(dtoCorePlan.name)}`;
    dtoCorePlan.id = uuid();
    const roomIds = {};
    dtoCorePlan.attached?.forEach(floor => {
      if (floor.kind == kindFloorRaw.id && floor.corePlanId) {
        floor.corePlanId = dtoCorePlan.id;
      }
      floor.id = uuid();
      floor.attached?.forEach(room => {
        if (room.kind == kindRoomRaw.id) {
          room.floorId = floor.id;
        }
        roomIds[room.id] = uuid();
        room.id = roomIds[room.id];

        // add the identifier for exported stretch data to convert it by properties
        room.drawing.stretch.forEach(stretch => {
          const roomType = appModel.getRoomType(room.roomTypeId);
          const entities = roomType.roomEntities;

          entities.forEach(async (roomEntity: RoomEntity) => {
            if (roomEntity.type == RoomEntityType.ReferenceLine && stretch.id == roomEntity.id) {
              stretch.properties = JSON.stringify(roomEntity.properties);
            }
          });
        });
      });
    });
    dtoCorePlan.ext_info ||= {};
    dtoCorePlan.ext_info.exported = true; // identifier for exported data

    variations.forEach(variation => {
      variation.id = "-1";
      variation.guid = uuid();
      variation.corePlanId = dtoCorePlan.id;
      variation.data.roomsData.forEach(room => {
        // replace room id with new one
        room.roomGuid = roomIds[room.roomGuid] || 0;
      });
    });
    dtoCorePlan.variations = variations;
  };

  const downloadJSON = () => {
    const dtoCorePlan = corePlan.toDTO("APIdata", { scope: "full", mode: "noUpdate" });
    const variations = [...corePlan.variations];
    modifyCorePlanForExport(dtoCorePlan, variations);
    const jsonData = new Blob([JSON.stringify({ meta: { v: 1 }, data: [dtoCorePlan] }, null, 2)], { type: "application/json" });
    const jsonURL = URL.createObjectURL(jsonData);
    const link = document.createElement("a");
    link.href = jsonURL;
    link.download = `${generateFilenameWithDateTime(`${corePlan.lennar_id}-${corePlan.name}`)}.json`;
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);
  };

  const exportCorePlan = async () => {
    try {
      downloadJSON();
      showToastMessage("Success", `${corePlan.name} was successfully exported`, { autoClose: 2000 });
    } catch (error) {
      console.error(error);
      showToastMessage("Error", `"An error occurred while exporting ${error}`, { autoClose: 2000 });
    }
  };

  return (
    <div className="card">
      {isLoading && (
        <div className="spinner-container spinner-container-global">
          <LoadingSpinner />
        </div>
      )}
      <Modal open={isConfirmModalOpen} confirmLoading={isCorePlanDeleting} onOk={onConfirmFloorsDeletion} onCancel={onCancelFloorsDeletion}>
        Are you sure you want to delete corePlan {corePlan.name}?<br />
        All data related to it will be deleted.
      </Modal>
      <Card onClick={handleOnCorePlanClick} className="card-corePlan" title={getStateAbbreviation(corePlan.state)} bordered={false} data-testid="corePlanItem">
        {/* <div className="client">{corePlan.client && <p>{corePlan.client}</p>}</div> */}
        <div>
          <span className="corePlan-name">{getCorePlanConcatenatedName(corePlan)}</span>
        </div>
        <div>
          <span className="corePlan-series">{getCorePlanConcatenatedSeries(corePlan)}</span>
        </div>
        <div className="attributes-wrapper">
          <div className="attributes-list">
            <Tooltip placement="bottom" title={`${floors ? floors : 0} Story`}>
              <div className="room-type-element">
                <i className="icon icon-floors"></i>
                <span className="room-type-info">{floors ? floors : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${bedRooms ? bedRooms : 0} Bedrooms`}>
              <div className="room-type-element">
                <i className="icon icon-bedrooms"></i>
                <span className="room-type-info">{bedRooms ? bedRooms : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${totalBathrooms} Bathrooms`}>
              <div className="room-type-element">
                <i className="icon icon-bathrooms"></i>
                <span className="room-type-info">{totalBathrooms}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${garage ? garage : 0} Cars`}>
              <div className="room-type-element">
                <i className="icon icon-garage"></i>
                <span className="room-type-info">{garage ? garage : 0}</span>
              </div>
            </Tooltip>
            <Tooltip placement="bottom" title={`${corePlan.variations ? corePlan.variations.length : 0} Elevations`}>
              <div className="room-type-element">
                <i className="icon icon-variations"></i>
                <span className="room-type-info">{corePlan.variations ? corePlan.variations.length : 0}</span>
              </div>
            </Tooltip>
          </div>
          <Dropdown overlay={editSetting} placement="bottomLeft" trigger={dropdownTrigger as any}>
            <Button className="dropdown-edit-button" onClick={e => e.stopPropagation()} data-testid="dropdown-edit-button">
              ...
            </Button>
          </Dropdown>
        </div>
      </Card>
    </div>
  );
};

CorePlanListItem = observer(CorePlanListItem);

export default CorePlanListItem;
